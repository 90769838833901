import { baseAxiosInstance } from 'axios-config';
import {
  CreateProductOptionTemplatesData,
  UpdateProductOptionTemplatesData,
  ProductOptionTemplate,
  ManageProductOptionTemplate,
  ProductOptionTemplateResponse,
} from 'services/product-option-templates.model';
import { IdName } from 'types/common-types';

export class ProductOptionTemplateService {
  public static async getAll() {
    const { data } = await baseAxiosInstance.get<ProductOptionTemplate[]>('product-option-templates/all');

    return data;
  }

  public static async getById(id: string) {
    const { data } = await baseAxiosInstance.get<ProductOptionTemplate>(`product-option-templates/${id}`);

    return data;
  }

  public static async create(body: CreateProductOptionTemplatesData) {
    const { data } = await baseAxiosInstance.post<IdName>('product-option-templates/create', body);

    return data;
  }

  public static async manage(body: ManageProductOptionTemplate[]) {
    const { data } = await baseAxiosInstance.post<ProductOptionTemplateResponse>('product-option-templates/manage', body);

    return data;
  }

  public static async update(id: string, body: UpdateProductOptionTemplatesData) {
    const { data } = await baseAxiosInstance.put<IdName>(`product-option-templates/${id}`, body);

    return data;
  }

  public static async delete(id: string) {
    const { data } = await baseAxiosInstance.delete<IdName>(`product-option-templates/delete/${id}`);

    return data;
  }
}
