import { FC, SVGProps } from 'react';

export const DiamondSearchIcon: FC<SVGProps<SVGSVGElement>> = ({ ...props }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M13.6854 20.1597C12.8342 21.2801 11.1658 21.2801 10.3146 20.1597L2.44095 9.79476C1.81861 8.9755 1.85896 7.82402 2.53709 7.05149L5.45918 3.72267C5.86269 3.263 6.44106 3 7.04842 3H10.1099M14.1263 3H16.9516C17.5589 3 18.1373 3.263 18.5408 3.72267L21.4629 7.05149C22.141 7.82402 22.1814 8.9755 21.559 9.79476L20.1582 11.6388M14.1263 3L16.2526 8.38285M14.1263 3H10.1099M16.2526 8.38285H21.5684M16.2526 8.38285H7.74736M16.2526 8.38285L15.8689 9.5M12 20.7634L7.74736 8.38285M10.1099 3L7.74736 8.38285M2.43156 8.38285H7.74736"
      stroke="#5A5A5A"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.3905 18.8277C18.2771 18.8277 19.8065 17.2984 19.8065 15.4118C19.8065 13.5252 18.2771 11.9958 16.3905 11.9958C14.504 11.9958 12.9746 13.5252 12.9746 15.4118C12.9746 17.2984 14.504 18.8277 16.3905 18.8277Z"
      stroke="#5A5A5A"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M21.1862 20.1886L18.8228 17.8252" stroke="#5A5A5A" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);
