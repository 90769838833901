/* eslint-disable array-callback-return */
import { notify } from 'notifications';
import { StateController } from 'state-controller';
import { ProductVariant } from 'services/products.model';
import { AppState, GetStateFunction } from 'redux/store';
import { BulkChangeProductVariantStatusData, UpdateProductVariantData } from 'services/product-variant.model';
import { Actions as ProductRootController } from 'pages/product-flow/pages/product/controllers/product-root.controller';
import { ProductVariantService } from 'services/product-variant.service';
import { ProductsService } from 'services/products.service';

export const VARIANT_NO_ACTIVE_ITEMS_VALIDATION_TEXT = 'There are no active variants to publish';
export const VARIANT_INVALID_SKU_VALIDATION_TEXT =
  'To publish a product, you must add a configuration SKU or an individual variant SKU';

export type ProductVariantsValidations = {
  errors: {
    noActive: string;
    invalidSku: string;
  };
};

export type SelectedVariantPhotoModalData = {
  id: string;
  configuration_photo_relation_id: string;
};

export type ProductState = {
  isInitLoading: boolean;
  product_variants: Array<ProductVariant>;
  variantMediaModal: {
    isOpen: boolean;
    initActiveImageId: string;
  };
  selectedVariant: SelectedVariantPhotoModalData;
  isVariantLoading: boolean;
  photoDownloadUrl: string;
  validations: ProductVariantsValidations;
};

const validationsDefaultState: ProductVariantsValidations = {
  errors: {
    noActive: '',
    invalidSku: '',
  },
};

const defaultState: ProductState = {
  isInitLoading: true,
  product_variants: [],
  variantMediaModal: {
    isOpen: false,
    initActiveImageId: '',
  },
  selectedVariant: {
    id: '',
    configuration_photo_relation_id: '',
  },
  isVariantLoading: false,
  photoDownloadUrl: '',
  validations: validationsDefaultState,
};

const sort = (arr: Array<ProductVariant>) =>
  arr.sort((a, b) => {
    const left = a.is_active ? 1 : 0;
    const rigth = b.is_active ? 1 : 0;
    return rigth - left;
  });

const stateController = new StateController<ProductState>('PRODUCT_VARIANT', defaultState);

export class Actions {
  public static init(variants: Array<ProductVariant>) {
    return async (dispatch) => {
      const copyVariants = JSON.parse(JSON.stringify(variants));

      await dispatch(
        stateController.setState((prev) => ({
          ...prev,
          product_variants: sort(copyVariants),
          isInitLoading: false,
        })),
      );
      dispatch(Actions.revalidate());
    };
  }

  public static refetchVariants(configurationId: string) {
    return async (dispatch) => {
      try {
        dispatch(Actions.setInitLoading());
        const data = await ProductVariantService.getAll(configurationId);
        dispatch(Actions.init(data));
      } finally {
        dispatch(stateController.setState({ isInitLoading: false }));
      }
    };
  }

  public static disposeState() {
    return (dispatch) => {
      dispatch(stateController.setState(defaultState));
    };
  }

  public static setInitLoading() {
    return (dispatch) => {
      dispatch(stateController.setState({ isInitLoading: true }));
    };
  }

  public static modifyVariantState(variantId: string | null, updateData: any, isNeedToResort: boolean) {
    return async (dispatch, getState: GetStateFunction) => {
      const { product_variants, selectedVariant } = getState().product.product_variant;

      const updateSelectedVariant = {
        ...selectedVariant,
      };

      let mapped = product_variants.map((variant) => {
        if (variant.id === variantId || !variantId) {
          updateSelectedVariant.configuration_photo_relation_id = updateData.configuration_photo_relation_id;
          return {
            ...variant,
            ...updateData,
          };
        }
        return variant;
      });

      if (isNeedToResort) {
        const configurationId = getState().product.product_configurations.active_configuration.id;
        const array = await ProductVariantService.getAll(configurationId);
        mapped = sort(array);
      }

      await dispatch(
        stateController.setState((prev) => ({
          ...prev,
          product_variants: mapped,
          selectedVariant: updateSelectedVariant,
        })),
      );

      dispatch(Actions.revalidate());
    };
  }

  public static updateProductVariant(id: string, data: UpdateProductVariantData, isNeedToResort: boolean) {
    return async (dispatch) => {
      try {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            isVariantLoading: true,
          })),
        );
        const newVariant = await ProductVariantService.update(id, data);
        dispatch(ProductRootController.updateProductModifiedAt());
        await dispatch(
          Actions.modifyVariantState(
            id,
            {
              product_configuration_id: newVariant.product_configuration_id,
              barcode: newVariant.barcode,
              sku: newVariant.sku,
              is_active: newVariant.is_active,
              configuration_photo_relation_id: newVariant.configuration_photo_relation_id,
              variant_photo: newVariant.variant_photo,
            },
            isNeedToResort,
          ),
        );

        dispatch(Actions.revalidate());
      } finally {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            isVariantLoading: false,
          })),
        );
      }
    };
  }

  public static barcodeUniqueValidation(value: string): any {
    return async (dispatch, getState: () => AppState) => {
      try {
        if (value.length === 0) return true;
        const productId = getState().product.product_root.id;
        const data = await ProductsService.barcodeUniqueValidation(value, productId);

        if (!data.is_valid) {
          notify.error(`Barcode ${value} is already used in product ${data.productName}. Barcodes must be unique`);
        }

        return data.is_valid;
      } catch (err) {
        console.error(err);
        notify.error('Something went wrong');
        return false;
      }
    };
  }

  public static skuVariantUniqueValidation(value: string): any {
    return async (dispatch, getState: () => AppState) => {
      try {
        if (value.length === 0) return true;
        const productConfigurationId = getState().product.product_configurations.active_configuration.id;
        const data = await ProductVariantService.validate(value, productConfigurationId);

        if (!data.is_valid) {
          notify.error(`Sku ${value} is already used in another product or configuration.`);
        }

        return data.is_valid;
      } catch (err) {
        console.error(err);
        notify.error('Something went wrong');
        return false;
      }
    };
  }

  public static skuEditCodesUniqueValidation(value: string): any {
    return async (dispatch, getState: () => AppState) => {
      try {
        if (value.length === 0) return true;
        const productConfigurationId = getState().product.edit_codes_modal.configurationId;
        const data = await ProductVariantService.validate(value, productConfigurationId);

        if (!data.is_valid) {
          notify.error(`Sku ${value} is already used in another product or configuration.`);
        }

        return data.is_valid;
      } catch (err) {
        console.error(err);
        notify.error('Something went wrong');
        return false;
      }
    };
  }

  public static bulkChangeStatus(body: BulkChangeProductVariantStatusData) {
    return async (dispatch, getState: GetStateFunction) => {
      try {
        // const { product_variants } = getState().product.product_variant;

        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            isVariantLoading: true,
          })),
        );
        await ProductVariantService.bulkChangeStatus(body);

        // let mapped = [];

        // if (!body.where.variant_id_array?.length) {
        //   mapped = product_variants.map((item) => ({
        //     ...item,
        //     is_active: body.data.is_active,
        //   }));
        // } else {
        //   mapped = product_variants.map((item) => {
        //     if (body.where.variant_id_array.findIndex((el) => el === item.id) !== -1) {
        //       return {
        //         ...item,
        //         is_active: body.data.is_active,
        //       };
        //     }
        //     return item;
        //   });
        // }

        const configurationId = getState().product.product_configurations.active_configuration.id;
        const array = await ProductVariantService.getAll(configurationId);

        await dispatch(
          stateController.setState((prev) => ({
            ...prev,
            product_variants: sort(array),
          })),
        );

        dispatch(Actions.revalidate());
      } finally {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            isVariantLoading: false,
          })),
        );
      }
    };
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public static uploadVariantPhotoByUrl(configurationId: string, url: string) {
    return async (dispatch) => {
      dispatch(
        stateController.setState((prev) => ({
          ...prev,
        })),
      );
    };
  }

  public static setPhotoDownloadUrl(url: string) {
    return async (dispatch) => {
      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          photoDownloadUrl: url,
        })),
      );
    };
  }

  public static openVariantMediaModal(initActiveImageId: string, selectedVariant: SelectedVariantPhotoModalData) {
    return (dispatch) => {
      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          variantMediaModal: {
            ...prev.variantMediaModal,
            isOpen: true,
            initActiveImageId,
          },
          selectedVariant,
        })),
      );
    };
  }

  public static closeModal(modalName: string) {
    return (dispatch) => {
      dispatch(
        stateController.setState((prevState) => ({
          ...prevState,
          [modalName]: defaultState[modalName],
        })),
      );
    };
  }

  public static revalidate() {
    return (dispatch, getState: GetStateFunction) => {
      const { product_variants } = getState().product.product_variant;

      const newValidation: ProductVariantsValidations = {
        errors: {
          invalidSku: '',
          noActive: '',
        },
      };

      if (product_variants.some((item) => !item.sku)) {
        newValidation.errors.invalidSku = VARIANT_INVALID_SKU_VALIDATION_TEXT;
      }

      if (product_variants.every((item) => !item.is_active)) {
        newValidation.errors.noActive = VARIANT_NO_ACTIVE_ITEMS_VALIDATION_TEXT;
      }

      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          validations: newValidation,
        })),
      );
    };
  }
}

export class Selectors {
  public static isVariantsValid(state: AppState) {
    const { validations } = state.product.product_variant;
    const { errors } = validations;

    return !errors.invalidSku && !errors.noActive;
  }
}

export const reducer = stateController.getReducer();
