import { FC, SVGProps } from 'react';

export const LinePosition: FC<SVGProps<SVGSVGElement>> = ({ ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none" {...props}>
    <path
      d="M5.12837 15.8581H10.8714C13.7572 15.8581 14.2741 14.7023 14.4249 13.2953L14.9633 7.55226C15.1571 5.80065 14.6546 4.37207 11.5893 4.37207H4.41049C1.34516 4.37207 0.842644 5.80065 1.03647 7.55226L1.57488 13.2953C1.72563 14.7023 2.2425 15.8581 5.12837 15.8581Z"
      stroke="#141414"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.12891 4.37151V3.79721C5.12891 2.52656 5.12891 1.5 7.42611 1.5H8.57472C10.8719 1.5 10.8719 2.52656 10.8719 3.79721V4.37151"
      stroke="#141414"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.43596 9.39659V10.1145C9.43596 10.1216 9.43596 10.1216 9.43596 10.1288C9.43596 10.9113 9.42878 11.5502 8.00021 11.5502C6.57881 11.5502 6.56445 10.9185 6.56445 10.136V9.39659C6.56445 8.67871 6.56445 8.67871 7.28233 8.67871H8.71808C9.43596 8.67871 9.43596 8.67871 9.43596 9.39659Z"
      stroke="#141414"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.9273 7.96094C13.269 9.16697 11.3738 9.88485 9.43555 10.1289"
      stroke="#141414"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.2666 8.15527C2.88182 9.2608 4.70523 9.92843 6.56453 10.1366"
      stroke="#141414"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
