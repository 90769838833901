import { FC, SVGProps } from 'react';

export const IncrementIcon: FC<SVGProps<SVGSVGElement>> = ({ ...props }) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_15_1164)">
      <path d="M8.00156 0.617188V15.4743" stroke="#91979F" strokeWidth="1.44" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M0.573441 8H15.4306" stroke="#91979F" strokeWidth="1.44" strokeLinecap="round" strokeLinejoin="round" />
    </g>
    <defs>
      <clipPath id="clip0_15_1164">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
