/* eslint-disable no-else-return, guard-for-in, no-restricted-syntax */
import {
  MoveItemLogTypes,
  ReplacedLogItem,
  MovedToEmptySlotLogItem,
  MovedToAdditionalLogItem,
} from 'pages/production/controllers/types';
import { ProductionStatusEnum } from 'types/status-enums';
import { notify } from 'notifications';
import { EMPTY_SLOT, SLOT } from 'pages/production/controllers/constants';
import {
  updateComponentArgs,
  addMetaDataToComponentsArgs,
  GetAllAdditionalComponentsFromProductionTreeArgs,
} from 'pages/production/controllers/manage-components-modal.controller/types';
import { ProductionWorkflow, ProductionWorkflowResponseDataT } from 'services/production-workflow.model';
import { Component } from 'pages/production/controllers/manage-components-modal.controller/manage-components-modal.controller';

export const deleteProductionFromArray = (array: Component[], id: string, createSlot: boolean = true): Component[] => {
  const deleteHelper = (arr: Component[]) => {
    return arr?.reduce((acc: Component[], item) => {
      if (item.id === id) {
        if (createSlot) {
          // empty slot is created after the move
          acc.push({
            ...EMPTY_SLOT,
            id: `${SLOT}_${item.nestedProductionWorkflowItems[0].id}`,
            parent_production_workflow_id: item.parent_production_workflow_id,
            main_root_id: item.main_root_id,
            parentStatus: item.parentStatus || undefined,
            additionalZoneId: item.additionalZoneId || '',
          });
        }
      } else {
        // empty slot is not created after the move
        const newItem =
          item.nested_workflows.length > 0 ? { ...item, nested_workflows: deleteHelper(item.nested_workflows) } : item;
        acc.push(newItem);
      }
      return acc;
    }, []);
  };

  return deleteHelper(array);
};

export const updateNestedComponents = (
  component: Component,
  main_root_id: string | null,
  additionalZoneId: string = '',
): Component => {
  try {
    if (!main_root_id) {
      throw new Error('main_root_id is undefined');
    }

    return {
      ...component,
      main_root_id,
      additionalZoneId,
      isComponentMoved: true,
      nested_workflows: component.nested_workflows.map((nestedComponent) =>
        updateNestedComponents(nestedComponent, main_root_id, additionalZoneId),
      ),
    };
  } catch (err) {
    notify.error(err.message);
    throw err;
  }
};

export const swapProductions = (data: Component[], element: Component, target: Component): Component[] => {
  const recursiveSwap = (array: Component[]): Component[] => {
    return array.map((item) => {
      if (item.id === element.id) {
        return {
          ...target,
          isComponentMoved: true,
          parentStatus: element.parentStatus,
          main_root_id: element.main_root_id,
          parent_production_workflow_id: element.parent_production_workflow_id,
          nestedProductionWorkflowItems: element.nestedProductionWorkflowItems?.map((n) => ({
            ...n,
            // updated slot id
            id: element.nestedProductionWorkflowItems[0].id,
          })),
          nested_workflows: target.nested_workflows.map((i) => updateNestedComponents(i, element.main_root_id)),
        };
      }

      if (item.id === target.id) {
        return {
          ...element,
          isComponentMoved: true,
          parentStatus: target.parentStatus,
          main_root_id: target.main_root_id,
          parent_production_workflow_id: target.parent_production_workflow_id,
          nestedProductionWorkflowItems: target.nestedProductionWorkflowItems?.map((n) => ({
            ...n,
            // updated slot id
            id: target.nestedProductionWorkflowItems[0].id,
          })),
          nested_workflows: element.nested_workflows.map((i) => updateNestedComponents(i, target.main_root_id)),
        };
      }

      if (item.nested_workflows.length > 0) {
        return {
          ...item,
          nested_workflows: recursiveSwap(item.nested_workflows),
        };
      }

      return item;
    });
  };

  return recursiveSwap(data);
};

export const createComponentInEmptySlot = (data: Component[], element: Component, target: Component): Component[] => {
  const recursiveReplace = (array: Component[]) => {
    return array.map((item) => {
      if (item.id === target.id) {
        const slotId = target.id.split('_')[1];
        const updatedElement = updateNestedComponents(
          {
            ...element,
            isComponentMoved: true,
            parentStatus: target.parentStatus,
            main_root_id: target.main_root_id,
            parent_production_workflow_id: target.parent_production_workflow_id,
            additionalZoneId: '',
            nestedProductionWorkflowItems: [
              {
                // updated slot id
                ...element.nestedProductionWorkflowItems[0],
                id: slotId,
              },
            ],
          },
          target.main_root_id,
          '',
        );

        return updatedElement;
      }

      if (item.nested_workflows.length > 0) {
        return {
          ...item,
          nested_workflows: recursiveReplace(item.nested_workflows),
        };
      }

      return item;
    });
  };

  return recursiveReplace(data);
};

export const processLogItems = (logItems: MoveItemLogTypes[]) => {
  const groupedItems: { [key: string]: MoveItemLogTypes[] } = {};

  logItems.forEach((item) => {
    if (!groupedItems[item.production_workflow_id]) {
      groupedItems[item.production_workflow_id] = [];
    }
    groupedItems[item.production_workflow_id].push(item);
  });

  const result: MoveItemLogTypes[] = [];

  Object.values(groupedItems).forEach((group) => {
    if (group.length > 1) {
      result.push(group[0]);
      result.push(group[group.length - 1]);
    } else {
      result.push(group[0]);
    }
  });

  return result;
};

const checkIfProductionWorkflowType = (
  productionWorkflows: ProductionWorkflowResponseDataT,
): productionWorkflows is ProductionWorkflow[] => {
  return !('production_workflows' in productionWorkflows[0]);
};

const collectProductionsWithEmptySlots = (productionWorkflows: Component[], parentProductionId?: string) => {
  return productionWorkflows.map((production) => {
    const nestedWorkflowsWithEmptySlots: Component[] = [];

    const emptySlots = production.productionWorkflowItems
      .filter((item) => !item.production_task_id && !item.nested_production_workflow_id)
      .map((slot) => ({
        ...EMPTY_SLOT,
        main_root_id: production.main_root_id || production.id,
        id: `${SLOT}_${slot.id}`,
        parent_production_workflow_id: production.id,
        additionalZoneId: parentProductionId || undefined,
        product_meta_id: production.product_meta_id,
      }));

    if (production.nested_workflows?.length) {
      nestedWorkflowsWithEmptySlots.push(...collectProductionsWithEmptySlots(production.nested_workflows, parentProductionId));
    }

    return {
      ...production,
      nested_workflows: [...nestedWorkflowsWithEmptySlots, ...emptySlots],
      ...(parentProductionId ? { additionalZoneId: parentProductionId } : {}),
    };
  });
};

export const collectAdditionalComponents = (productions: Component[], acc: Record<string, Component[]> = {}) => {
  productions.forEach((production) => {
    acc[production.id] = addMetaDataToComponents({
      productions: collectProductionsWithEmptySlots(production.additionalComponents, production.id),
      status: production.status,
    });

    if (production.nested_workflows?.length) {
      collectAdditionalComponents(production.nested_workflows, acc);
    }
  });

  return acc;
};

export const getSelectedProductions = (
  productionWorkflows: ProductionWorkflowResponseDataT,
  parentIdsOfSelectedProductions: string[],
) => {
  if (!checkIfProductionWorkflowType(productionWorkflows)) return undefined;

  const uniqueParentIds = parentIdsOfSelectedProductions.filter((value, index, array) => array.indexOf(value) === index);
  const selectedProductions = productionWorkflows.filter((i) => uniqueParentIds.includes(i.id));
  const selectedProductionsWithEmptySlots = collectProductionsWithEmptySlots(selectedProductions);

  return selectedProductionsWithEmptySlots;
};

export const removeAdditionalZoneIdsFromTheProductionTree = (component: Component): Component => {
  const { additionalZoneId, ...componentWithoutAdditionalZoneId } = component;
  const nestedComponents: Component[] = [];

  if (componentWithoutAdditionalZoneId.nested_workflows) {
    componentWithoutAdditionalZoneId.nested_workflows.forEach((nestedComponent) => {
      nestedComponents.push(removeAdditionalZoneIdsFromTheProductionTree(nestedComponent));
    });
  }

  return { ...componentWithoutAdditionalZoneId, nested_workflows: nestedComponents };
};

export const addMetaDataToComponents = ({
  status,
  productions,
  additionalZoneId,
  isComponentMoved = false,
}: addMetaDataToComponentsArgs): Component[] => {
  const updateComponent = ({ parentId, component, parentStatus, isMoved = false }: updateComponentArgs): Component => {
    const updatedNestedWorkflows = component.nested_workflows.map((nested) =>
      updateComponent({
        isMoved,
        component: nested,
        parentId: component.id,
        parentStatus: component.status ?? parentStatus,
      }),
    );

    const additionalFields = {
      parentStatus,
      additionalZoneId,
      main_root_id: additionalZoneId,
      parent_production_workflow_id: parentId || null,
    };

    return {
      ...component,
      parentStatus,
      isComponentMoved: isMoved,
      nested_workflows: updatedNestedWorkflows,
      ...(additionalZoneId ? additionalFields : {}),
    };
  };

  return productions.map((component) => updateComponent({ component, parentStatus: status, isMoved: isComponentMoved }));
};

export const getAllAdditionalComponentsFromProductionTree = ({
  component,
  additionalComponents,
  removeAdditionalComponents,
}: GetAllAdditionalComponentsFromProductionTreeArgs) => {
  const allAdditionalComponents = additionalComponents[component.id]?.length ? [...additionalComponents[component.id]] : [];

  if (component.nested_workflows.length) {
    component.nested_workflows.map((nestedWorkflow) => {
      return allAdditionalComponents.push(
        ...getAllAdditionalComponentsFromProductionTree({
          additionalComponents,
          component: nestedWorkflow,
          removeAdditionalComponents,
        }),
      );
    });
  }

  removeAdditionalComponents(component.id);
  return allAdditionalComponents;
};

export const findComponentById = (productions: Component[], id: string): Component | null => {
  return productions.reduce((found, component) => {
    if (found) return found;
    if (component.id === id) return component;

    return findComponentById(component.nested_workflows, id);
  }, null as Component | null);
};

export const findMyParentIds = (
  productions: Component[] | undefined,
  id: string,
  rootId: string | null,
  includeMyId: boolean = false,
): string[] => {
  const rootComponent = productions?.find((component) => component.id === rootId);

  if (!rootComponent) return [];

  const queue: { component: Component; path: string[] }[] = [{ component: rootComponent, path: [] }];

  while (queue.length > 0) {
    const queueItem = queue.shift();

    if (!queueItem) return [];

    const { component, path } = queueItem;
    const newPath = [...path, component.product_meta_id];

    if (component.id === id) {
      return includeMyId ? newPath : newPath.slice(0, -1);
    }

    for (const nestedComponent of component.nested_workflows) {
      queue.push({ component: nestedComponent, path: newPath });
    }
  }

  return [];
};

const collectIds = (component: Component, productMetaIds: string[], itemIds: string[]) => {
  const queue: Component[] = [component];

  while (queue.length > 0) {
    const current = queue.shift()!;
    productMetaIds.push(current.product_meta_id);
    itemIds.push(current.id);

    for (const nested of current.nested_workflows) {
      queue.push(nested);
    }
  }
};

const collectAdditionalComponentIds = (
  additionalComponents: Record<string, Component[]>,
  itemIds: string[],
  productMetaIds: string[],
) => {
  for (const key of Object.keys(additionalComponents)) {
    if (itemIds.includes(key)) {
      for (const additionalComponent of additionalComponents[key]) {
        collectIds(additionalComponent, productMetaIds, itemIds);
      }
    }
  }
};

export const findProductMetaIds = (component: Component, additionalComponents: Record<string, Component[]>): string[] => {
  const productMetaIds: string[] = [];
  const itemIds: string[] = [];

  collectIds(component, productMetaIds, itemIds);
  collectAdditionalComponentIds(additionalComponents, itemIds, productMetaIds);

  return productMetaIds.filter((value, index, array) => array.indexOf(value) === index); // only unique product meta ids (no duplicates)
};

export const findStatusById = (components: Component[], id: string | null): ProductionStatusEnum | null => {
  for (const component of components) {
    if (id && component.id === id) {
      return component.status;
    }

    const foundInNested = findStatusById(component.nested_workflows, id);
    if (foundInNested) {
      return foundInNested;
    }
  }

  return null;
};

export const findSlotIds = (components: Component[]): string[] => {
  const slotIds: string[] = [];

  const recursiveSearch = (items: Component[]): void => {
    for (const component of items) {
      if (component.id.startsWith('slot')) {
        slotIds.push(component.id.split('_')[1]);
      }
      if (component.nested_workflows.length > 0) {
        recursiveSearch(component.nested_workflows);
      }
    }
  };

  recursiveSearch(components);
  return slotIds;
};

export const filterUnusableLogs = (logs: MoveItemLogTypes[], productionWorkflowId: string): MoveItemLogTypes[] => {
  if (!logs.length || logs.length < 2) return logs;
  const filteredById = logs.filter((i) => i.production_workflow_id === productionWorkflowId);
  const filteredLogs = logs.filter((i) => i.production_workflow_id !== productionWorkflowId);

  const firstItem = filteredById[0];
  const lastItem = filteredById[filteredById.length - 1];

  const isSameParent = firstItem.detached_from === lastItem.attached_to;
  const isFromRootToRoot = firstItem.detached_from === null && lastItem.attached_to === null;
  const isSameSlot =
    firstItem.detached_from_slot ===
    (lastItem as ReplacedLogItem | MovedToEmptySlotLogItem).production_workflow_item_id_to_attach;
  const isFromAdditionalToAdditional =
    firstItem.is_moved_from_additional_components && (lastItem as MovedToAdditionalLogItem).is_moved_to_additional_components;

  if (isSameSlot) return filteredLogs;
  if (isFromRootToRoot) return filteredLogs;
  if (isSameParent && isFromAdditionalToAdditional) return filteredLogs;

  return logs;
};
