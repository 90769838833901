import { FC, SVGProps } from 'react';

export const StockIcon: FC<SVGProps<SVGSVGElement>> = ({ stroke = '#465214', ...props }) => (
  <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M14.9084 12.4874C14.9219 12.9936 14.7869 13.4727 14.544 13.8844C14.409 14.1273 14.2268 14.35 14.0243 14.5322C13.5587 14.9641 12.9446 15.2273 12.263 15.2475C11.2777 15.2678 10.4072 14.7617 9.92808 13.9856C9.67164 13.5874 9.51643 13.1083 9.50969 12.6022C9.48944 11.7519 9.86734 10.9826 10.4747 10.4764C10.9336 10.0985 11.514 9.86235 12.1483 9.84885C13.6397 9.81511 14.8746 10.9961 14.9084 12.4874Z"
      stroke={stroke}
      strokeWidth="1.1"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.1563 12.5689L11.8379 13.2167L13.2483 11.8535"
      stroke={stroke}
      strokeWidth="1.1"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.52643 5.42236L7.48523 8.87077L13.4035 5.44259"
      stroke={stroke}
      strokeWidth="1.1"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M7.48517 14.985V8.86426" stroke={stroke} strokeWidth="1.1" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M13.9703 6.59005V10.4096C13.9703 10.4434 13.9704 10.4703 13.9636 10.5041C13.4912 10.0924 12.8839 9.84952 12.209 9.84952C11.5747 9.84952 10.9876 10.0722 10.5219 10.4434C9.90109 10.936 9.50968 11.6986 9.50968 12.5489C9.50968 13.055 9.65139 13.5341 9.90108 13.939C9.96181 14.047 10.0361 14.1482 10.117 14.2427L8.88209 14.9243C8.11278 15.3562 6.85757 15.3562 6.08825 14.9243L2.48463 12.9268C1.66808 12.4746 1 11.3409 1 10.4096V6.59005C1 5.65877 1.66808 4.52506 2.48463 4.07292L6.08825 2.07539C6.85757 1.64349 8.11278 1.64349 8.88209 2.07539L12.4857 4.07292C13.3023 4.52506 13.9703 5.65877 13.9703 6.59005Z"
      stroke={stroke}
      strokeWidth="1.1"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
