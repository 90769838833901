import { FC } from 'react';
import { connect } from 'react-redux';
import { PlusIcon } from 'icons/plus';
import { Button } from '@mui/material';
import { Actions as AddPositionModalActions } from 'components/add-position-modal/add-position-modal.controller';
import { Actions as DepartmentsActions } from 'pages/departments/departments.controller';
import plug from './plug.png';
import s from './empty-state.module.scss';

type StateProps = {};
type DispatchProps = {
  addPosition: () => void;
  addDepartment: () => void;
};
type Props = StateProps & DispatchProps;

const EmptyState: FC<Props> = ({ addPosition, addDepartment }) => {
  return (
    <div className={s.container}>
      <div>
        <div className={s.text_cta}>Elements not yet added. Click the button to add the first one.</div>
        <div className={s.buttons}>
          <Button
            size="L"
            color="primary"
            variant="contained"
            className={s.button}
            onClick={() => addDepartment()}
            startIcon={<PlusIcon />}
          >
            Add department
          </Button>
          <Button
            size="L"
            color="primary"
            variant="outlined"
            className={`${s.button} ${s.button_outlined}`}
            onClick={() => addPosition()}
            startIcon={<PlusIcon />}
          >
            Add position
          </Button>
        </div>
        <div />
      </div>
      <div>
        <img src={plug} alt="departments plug" />
      </div>
    </div>
  );
};

const mapStateToProps = (): StateProps => ({});
const mapDispatchToProps: DispatchProps = {
  addPosition: AddPositionModalActions.openModal,
  addDepartment: DepartmentsActions.createCandidateDepartment,
};
export default connect(mapStateToProps, mapDispatchToProps)(EmptyState);
