import { FC, SVGProps } from 'react';

export const SettingsCollectionIcon: FC<SVGProps<SVGSVGElement>> = ({ ...props }) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M14 12L8.54152 14.426C8.19676 14.5792 7.80324 14.5792 7.45848 14.426L2 12M14 8.66668L8.54152 11.0927C8.19676 11.2459 7.80324 11.2459 7.45848 11.0927L2 8.66668M2.5259 5.26296L7.40372 7.70187C7.77909 7.88955 8.22092 7.88955 8.59629 7.70187L13.4741 5.26296C13.9655 5.01728 13.9655 4.31608 13.4741 4.07039L8.59629 1.63149C8.22092 1.4438 7.77909 1.4438 7.40372 1.63149L2.5259 4.07039C2.03454 4.31607 2.03454 5.01728 2.5259 5.26296Z"
      stroke="#141414"
      strokeWidth="1.1"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
