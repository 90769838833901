import { FC, SVGProps } from 'react';

export const TagIcon: FC<SVGProps<SVGSVGElement> & { title?: string }> = (props) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M13.5355 8.8215C12.8846 9.47237 11.8293 9.47237 11.1785 8.8215C10.5276 8.17062 10.5276 7.11535 11.1785 6.46447C11.8293 5.8136 12.8846 5.8136 13.5355 6.46447C14.1864 7.11535 14.1864 8.17062 13.5355 8.8215Z"
      stroke="#28303F"
      strokeWidth="1.5"
    />
    <path
      d="M7.84287 3.90751C8.5403 3.21008 9.50357 2.84814 10.4799 2.91668L13.7835 3.14862C15.433 3.26442 16.7355 4.56692 16.8513 6.21648L17.0833 9.52008C17.1518 10.4964 16.7899 11.4597 16.0924 12.1571L10.6213 17.6282C9.30186 18.9477 7.17696 18.962 5.87521 17.6603L2.33968 14.1247C1.03793 12.823 1.05228 10.6981 2.37173 9.37864L7.84287 3.90751Z"
      stroke="#28303F"
      strokeWidth="1.5"
      strokeLinejoin="round"
    />
    <title>{props.title}</title>
  </svg>
);
