import { AxiosError } from 'axios';
import { t } from 'setup-localization';
import { notify } from '../notifications';

let previousErrorMessage = '';

export type ErrorMessageObjectInArray = {
  errorCode: number;
  errorMessage: string;
};

export const showBackEndErrorMessage = (payload, error?: AxiosError) => {
  const isUnauthorizedError = (value: string) => value.startsWith('Unauthorized');

  const responseURL = error?.request?.responseURL || '';
  const statusCode = error?.request?.status;

  if (responseURL.endsWith('production-workflows/delete-many') && statusCode === 500) {
    return;
  }
  if (Array.isArray(payload)) {
    payload.forEach((item: ErrorMessageObjectInArray) => {
      notify.error(item.errorMessage);
    });
    return;
  }
  if (Array.isArray(payload?.message)) {
    payload.message.forEach((item: string) => {
      notify.error(item);
    });
    return;
  }

  const errorMessage = payload?.errorMessage || payload?.message || payload?.error || payload || undefined;

  if (errorMessage && isUnauthorizedError(errorMessage)) {
    return;
  }

  if (errorMessage === previousErrorMessage) {
    setTimeout(() => {
      previousErrorMessage = '';
    }, 5000);

    return;
  }

  previousErrorMessage = errorMessage;

  notify.error(errorMessage || t('global.init_loading'));
};
