import { FC, SVGProps, useMemo, useState } from 'react';

type Props = SVGProps<SVGSVGElement> & {
  linesColor?: string;
  withBorder?: boolean;
  withHoverEffect?: boolean;
};

export const WarningIcon: FC<Props> = ({ withBorder, linesColor = '#f8bc86', className, withHoverEffect, ...props }) => {
  const [isHover, setIsHover] = useState(false);
  const [color, setColor] = useState(linesColor);

  const style = useMemo(() => {
    if (withBorder) {
      const basik = {
        display: 'flex',
        padding: '4px 6px',
        border: `1px solid ${linesColor}`,
        borderRadius: '8px',
      };

      if (isHover) {
        setColor('#ffffff');
        return {
          ...basik,
          background: linesColor,
          cursor: 'pointer',
        };
      }

      setColor(linesColor);
      return basik;
    }
    return { display: 'flex' };
  }, [withBorder, isHover, linesColor]);

  return (
    <div
      style={style}
      className={className}
      onMouseEnter={() => withHoverEffect && setIsHover(true)}
      onMouseLeave={() => withHoverEffect && setIsHover(false)}
    >
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" stroke={color} {...props}>
        <path d="M8.07056 6.08398V9.27081" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" />
        <path
          d="M8.07056 13.9936H4.20813C1.99647 13.9936 1.07229 12.4129 2.14307 10.4817L4.13164 6.89974L6.00549 3.53445C7.14 1.48852 9.00111 1.48852 10.1356 3.53445L12.0095 6.90611L13.998 10.4881C15.0688 12.4193 14.1383 14 11.933 14H8.07056V13.9936Z"
          strokeWidth="1.3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M8.06702 11.1829H8.07274" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    </div>
  );
};
