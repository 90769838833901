import { notify } from 'notifications';
import { IdName } from 'types/common-types';
import { GetStateFunction } from 'redux/store';
import { AppLanguage } from 'types/common-enums';
import { StateController } from 'state-controller';
import { SettingsService } from 'services/settings.service';
import { Actions as AuthTenantsActions } from '../../redux/auth.controller';

export enum LanguageName {
  English = 'English',
  Ukrainian = 'Українська',
}

export enum InputNames {
  Currency = 'currency',
  CompanyName = 'companyName',
  Abbreviation = 'abbreviation',
  AnalyticalLink = 'analyticalLink',
  DefaultDeadline = 'defaultDeadline',
  PriorityTaskItemByXDays = 'priorityTaskItemByXDays',
  PriorityProductionItemByXDays = 'priorityProductionItemByXDays',
  CorrectionDaysAfterReachingReportingPeriod = 'correctionDaysAfterReachingReportingPeriod',
}

export enum CheckboxNames {
  Hierarchy = 'isDisplayHierarchy',
  RootProduct = 'isDisplayRootProduct',
  ProductionNote = 'isDisplayProductionNote',
}

export type SettingsState = {
  language: IdName;
  companyLogo: {
    url: string;
    fileType: string;
    fileName: string;
    isLoading: boolean;
  };
  currency: string;
  companyName: string;
  isFetching: boolean;
  abbreviation: string;
  analyticalLink: string;
  defaultDeadline: number;
  isDisplayHierarchy: boolean;
  isDisplayRootProduct: boolean;
  isDeadlineFromExternal: boolean;
  priorityTaskItemByXDays: number;
  isDisplayProductionNote: boolean;
  priorityProductionItemByXDays: number;
  isAllowedUndoChangingTaskStatus: boolean;
  updateTaskPriorityBeforeDeadline: boolean;
  updateProductionPriorityBeforeDeadline: boolean;
  correctionDaysAfterReachingReportingPeriod: number;
};

const defaultState: SettingsState = {
  language: {
    id: '',
    name: '',
  },
  companyLogo: {
    url: '',
    fileType: '',
    fileName: '',
    isLoading: false,
  },
  currency: '',
  companyName: '',
  abbreviation: '',
  isFetching: false,
  analyticalLink: '',
  defaultDeadline: 0,
  isDisplayHierarchy: false,
  priorityTaskItemByXDays: 0,
  isDisplayRootProduct: false,
  isDeadlineFromExternal: false,
  isDisplayProductionNote: false,
  priorityProductionItemByXDays: 0,
  isAllowedUndoChangingTaskStatus: false,
  updateTaskPriorityBeforeDeadline: false,
  correctionDaysAfterReachingReportingPeriod: 0,
  updateProductionPriorityBeforeDeadline: false,
};

const stateController = new StateController<SettingsState>('SETTINGS', defaultState);

export class SettingsActions {
  public static init(silent?: boolean) {
    return async (dispatch) => {
      try {
        if (!silent) dispatch(stateController.setState({ isFetching: true }));

        const data = await SettingsService.getSettings();

        const companyName = data.company_name;
        let abbreviationFromName = '';
        if (companyName) {
          const words = companyName.split(' ');
          const numOfWords = words.length;
          if (numOfWords === 1) {
            abbreviationFromName = companyName.slice(0, 2).toUpperCase();
          } else if (numOfWords > 1) {
            abbreviationFromName = words[0][0].toUpperCase() + words[1][0].toUpperCase();
          }
        }

        const taskPriorityXMinutesToDays = data.update_task_priority_before_deadline_minutes / (24 * 60);
        const productionPriorityXMinutesToDays = data.update_production_priority_before_deadline_minutes / (24 * 60);

        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            currency: data.currency ?? '',
            companyName: data.company_name ?? '',
            analyticalLink: data.analytical_report_link ?? '',
            isDeadlineFromExternal: data.use_external_deadline,
            priorityTaskItemByXDays: taskPriorityXMinutesToDays ?? 0,
            defaultDeadline: data.default_production_deadline_days ?? 0,
            isDisplayHierarchy: data.mobile_display_hierarchy_on_task_card,
            isAllowedUndoChangingTaskStatus: data.allow_to_undo_done_tasks,
            abbreviation: data.company_abbreviation ?? abbreviationFromName,
            isDisplayRootProduct: data.mobile_display_root_product_on_task_card,
            priorityProductionItemByXDays: productionPriorityXMinutesToDays ?? 0,
            isDisplayProductionNote: data.mobile_display_production_note_on_task_card,
            updateTaskPriorityBeforeDeadline: data.update_task_priority_before_deadline,
            updateProductionPriorityBeforeDeadline: data.update_production_priority_before_deadline,
            correctionDaysAfterReachingReportingPeriod: data.task_correction_days_after_reaching_reporting_period ?? 0,
            language: {
              id: data.mobile_default_language,
              name: data.mobile_default_language === AppLanguage.English ? 'English' : 'Українська',
            },
            companyLogo: {
              url: data.companyLogoFile?.link ?? '',
              fileName: data.companyLogoFile?.name.split('.')[0] ?? '',
              fileType: `.${data.companyLogoFile?.name.split('.')[1]}` || '',
            },
          })),
        );
      } catch (err) {
        notify.error('Something went wrong');
        throw err;
      } finally {
        if (!silent) dispatch(stateController.setState({ isFetching: false }));
      }
    };
  }

  public static dispose() {
    return async (dispatch) => {
      dispatch(stateController.setState({ ...defaultState }));
    };
  }

  public static onChangeLanguage(value: IdName) {
    return async (dispatch) => {
      try {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            language: value,
          })),
        );

        const body = new FormData();
        body.append('mobile_default_language', value.id);

        await SettingsService.updateSettings(body);
      } catch (err) {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            language: {
              id: value.id === AppLanguage.English ? AppLanguage.Ukrainian : AppLanguage.English,
              name: value.name === LanguageName.English ? LanguageName.Ukrainian : LanguageName.English,
            },
          })),
        );
        throw err;
      }
    };
  }

  public static onChangeDeadlineFromExternal() {
    return async (dispatch, getState: GetStateFunction) => {
      try {
        const isChecked = getState().settings.isDeadlineFromExternal;
        dispatch(stateController.setState({ isDeadlineFromExternal: !isChecked }));

        const body = new FormData();
        // @ts-ignore
        body.append('use_external_deadline', !isChecked);
        await SettingsService.updateSettings(body);
      } catch (err) {
        const isChecked = getState().settings.isDeadlineFromExternal;
        dispatch(stateController.setState({ isDeadlineFromExternal: isChecked }));
        throw err;
      }
    };
  }

  public static onChangeUndoChangingTaskStatus() {
    return async (dispatch, getState: GetStateFunction) => {
      try {
        const isChecked = getState().settings.isAllowedUndoChangingTaskStatus;
        dispatch(stateController.setState({ isAllowedUndoChangingTaskStatus: !isChecked }));

        const body = new FormData();
        // @ts-ignore
        body.append('allow_to_undo_done_tasks', !isChecked);
        await SettingsService.updateSettings(body);
      } catch (err) {
        const isChecked = getState().settings.isAllowedUndoChangingTaskStatus;
        dispatch(stateController.setState({ isAllowedUndoChangingTaskStatus: isChecked }));
        throw err;
      }
    };
  }

  public static onChangePriorityProductionItemByXDays() {
    return async (dispatch, getState: GetStateFunction) => {
      try {
        const isChecked = getState().settings.updateProductionPriorityBeforeDeadline;
        dispatch(stateController.setState({ updateProductionPriorityBeforeDeadline: !isChecked }));

        const body = new FormData();
        // @ts-ignore
        body.append('update_production_priority_before_deadline', !isChecked);
        await SettingsService.updateSettings(body);
      } catch (err) {
        const isChecked = getState().settings.updateProductionPriorityBeforeDeadline;
        dispatch(stateController.setState({ updateProductionPriorityBeforeDeadline: isChecked }));
        throw err;
      }
    };
  }

  public static onChangeDatailsOnTheTaskCard(checkboxName: CheckboxNames, value: boolean) {
    return async (dispatch) => {
      try {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            [checkboxName]: value,
          })),
        );

        const body = new FormData();
        if (checkboxName === CheckboxNames.Hierarchy) {
          body.append('mobile_display_hierarchy_on_task_card', String(value));
        } else if (checkboxName === CheckboxNames.RootProduct) {
          body.append('mobile_display_root_product_on_task_card', String(value));
        } else if (checkboxName === CheckboxNames.ProductionNote) {
          body.append('mobile_display_production_note_on_task_card', String(value));
        }
        await SettingsService.updateSettings(body);
      } catch (err) {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            [checkboxName]: !value,
          })),
        );
        notify.error(err.message);
        throw err;
      }
    };
  }

  public static onChangePriorityTaskItemByXDays() {
    return async (dispatch, getState: GetStateFunction) => {
      try {
        const isChecked = getState().settings.updateTaskPriorityBeforeDeadline;
        dispatch(stateController.setState({ updateTaskPriorityBeforeDeadline: !isChecked }));

        const body = new FormData();
        // @ts-ignore
        body.append('update_task_priority_before_deadline', !isChecked);
        await SettingsService.updateSettings(body);
      } catch (err) {
        const isChecked = getState().settings.updateTaskPriorityBeforeDeadline;
        dispatch(stateController.setState({ updateTaskPriorityBeforeDeadline: isChecked }));
        throw err;
      }
    };
  }

  public static onSaveInputValue(inputName: InputNames, value: string | number) {
    return async (dispatch) => {
      try {
        const body = new FormData();

        const priorityXDaysToMinutes = (+value * (24 * 60)).toString();

        if (inputName === InputNames.Currency) {
          body.append('currency', value as string);
        }
        if (inputName === InputNames.CompanyName) {
          body.append('company_name', value as string);
        }
        if (inputName === InputNames.Abbreviation) {
          body.append('company_abbreviation', value as string);
        }
        if (inputName === InputNames.AnalyticalLink) {
          body.append('analytical_report_link', value as string);
        }
        if (inputName === InputNames.DefaultDeadline) {
          // @ts-ignore
          body.append('default_production_deadline_days', value as number);
        }
        if (inputName === InputNames.CorrectionDaysAfterReachingReportingPeriod) {
          body.append('task_correction_days_after_reaching_reporting_period', value as string);
        }

        if (inputName === InputNames.PriorityProductionItemByXDays) {
          body.append('update_production_priority_before_deadline_minutes', priorityXDaysToMinutes);
        }

        if (inputName === InputNames.PriorityTaskItemByXDays) {
          body.append('update_task_priority_before_deadline_minutes', priorityXDaysToMinutes);
        }

        const updatedBody = await SettingsService.updateSettings(body);

        dispatch(AuthTenantsActions.updateUserTenants(updatedBody.company_name));

        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            [inputName]: value,
          })),
        );
      } catch (err) {
        notify.error(err.message);
        throw err;
      }
    };
  }

  public static uploadCompanyLogo(logo: File) {
    return async (dispatch) => {
      try {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            companyLogo: {
              ...prev.companyLogo,
              isLoading: true,
            },
          })),
        );

        const body = new FormData();
        body.append('file', logo);

        await SettingsService.updateSettings(body);
        await dispatch(SettingsActions.init(true));
      } catch (err) {
        notify.error('Something went wrong');
        throw err;
      } finally {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            companyLogo: {
              ...prev.companyLogo,
              isLoading: false,
            },
          })),
        );
      }
    };
  }

  public static removeCompanyLogo() {
    return async (dispatch) => {
      try {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            companyLogo: {
              ...prev.companyLogo,
              isLoading: true,
            },
          })),
        );

        await SettingsService.removeLogo();

        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            companyLogo: {
              ...prev.companyLogo,
              url: '',
              fileName: '',
              fileType: '',
            },
          })),
        );
      } catch (err) {
        notify.error('Something went wrong');
        throw err;
      } finally {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            companyLogo: {
              ...prev.companyLogo,
              isLoading: false,
            },
          })),
        );
      }
    };
  }
}

export const reducer = stateController.getReducer();
