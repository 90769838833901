import { AppState, GetStateFunction } from 'redux/store';
import { StateController } from 'state-controller';
import { UserService } from 'services/user.service';
import { notify } from 'notifications';
import { Actions as UsersActions } from 'pages/users/users.controller';
import { DEFAULT_ALLOWED_MAX_LENGTH, EMAIL_ALLOWED_MAX_LENGTH, PHONE_ALLOWED_MAX_LENGTH } from 'modules/add-user-flow/constants';
import { validateMaxLength } from 'modules/add-user-flow/helpers';
import { UserData, UserDataWithId } from 'modules/add-user-flow/types';
import { Actions as UserActions } from '../../pages/user/user.controller';
import { createValidator, rules, validators } from '../../utils/validator';

export const defaultUserData = {
  id: '',
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  externalUserId: '',
};

export type AddUserFlowState = {
  isModalOpen: boolean;
  mode: 'edit' | 'add';
  user: UserDataWithId;
  validation: UserData;
  isProcessing: boolean;
  didTryToSave: boolean;
  editUserData: UserData;
};

const defaultState: AddUserFlowState = {
  isModalOpen: false,
  mode: 'add',
  user: defaultUserData,
  validation: defaultUserData,
  isProcessing: false,
  didTryToSave: false,
  editUserData: defaultUserData,
};

const stateController = new StateController<AddUserFlowState>('ADD_USER_FLOW_STATE', defaultState);

export class Actions {
  public static openAddUserModal() {
    return (dispatch) => {
      dispatch(stateController.setState({ isModalOpen: true, mode: 'add' }));
    };
  }

  public static openEditUserModal(userData: UserDataWithId) {
    return (dispatch) => {
      dispatch(stateController.setState({ isModalOpen: true, mode: 'edit', user: userData, editUserData: userData }));
    };
  }

  public static closeUserFlowModal() {
    return (dispatch) => {
      dispatch(stateController.setState(defaultState));
    };
  }

  public static onInputValueChange(values: Partial<UserData>) {
    return (dispatch, getState: GetStateFunction) => {
      dispatch(stateController.setState((prev) => ({ ...prev, user: { ...prev.user, ...values } })));

      const { didTryToSave } = getState().addUserFlow;

      if (didTryToSave) {
        dispatch(Actions.validateData());
      }
    };
  }

  public static validateData() {
    return (dispatch, getState: GetStateFunction) => {
      const { user } = getState().addUserFlow;

      const [, firstNameErrorMessage] = validators.isNotEmpty(user.firstName, 'First name should be filled');
      const [, lastNameErrorMessage] = validators.isNotEmpty(user.lastName, 'Last name should be filled');

      const isUserEmailValid = createValidator([
        { rule: rules.isNotEmpty, message: 'Email should not be empty.' },
        { rule: rules.isEmail, message: 'Not correct email format.' },
      ]);
      const [, emailErrorMessages] = isUserEmailValid(user.email);

      const firstNameLengthError = validateMaxLength(user.firstName, DEFAULT_ALLOWED_MAX_LENGTH);
      const lastNameLengthError = validateMaxLength(user.lastName, DEFAULT_ALLOWED_MAX_LENGTH);
      const emailLengthError = validateMaxLength(user.email, EMAIL_ALLOWED_MAX_LENGTH);
      const phoneLengthError = validateMaxLength(user.phone, PHONE_ALLOWED_MAX_LENGTH);
      const externalUserIdLengthError = validateMaxLength(user.externalUserId, DEFAULT_ALLOWED_MAX_LENGTH);

      const validation: UserData = {
        firstName: firstNameErrorMessage || firstNameLengthError,
        lastName: lastNameErrorMessage || lastNameLengthError,
        email: emailErrorMessages.join('') || emailLengthError,
        phone: phoneLengthError,
        externalUserId: externalUserIdLengthError,
      };

      dispatch(stateController.setState((prev) => ({ ...prev, validation })));

      return Object.values(validation).some((item) => Boolean(item));
    };
  }

  public static addUser() {
    return async (dispatch, getState: () => AppState) => {
      dispatch(stateController.setState({ didTryToSave: true }));

      if (dispatch(Actions.validateData())) {
        return;
      }
      const { firstName, lastName, email, phone, externalUserId } = getState().addUserFlow.user;

      const userData = new FormData();

      userData.append('status', 'Pending');
      userData.append('email', email.trim());
      userData.append('last_name', lastName.trim());
      userData.append('first_name', firstName.trim());

      if (phone && phone.length > 0) {
        userData.append('phone', phone.trim() || '');
      }

      if (externalUserId && externalUserId.length > 0) {
        userData.append('external_user_id', externalUserId.trim() || '');
      }

      try {
        dispatch(stateController.setState({ isProcessing: true }));

        await UserService.createUser(userData);

        await dispatch(UsersActions.init());
        dispatch(Actions.closeUserFlowModal());

        notify.success('Successfully added');
      } finally {
        dispatch(stateController.setState({ isProcessing: false }));
      }
    };
  }

  public static editUser() {
    return async (dispatch, getState: () => AppState) => {
      dispatch(stateController.setState({ didTryToSave: true }));

      if (dispatch(Actions.validateData())) {
        return;
      }
      const { firstName, lastName, email, phone, id, status, externalUserId } = getState().addUserFlow.user;
      const { avatar_image_url } = getState().user;

      const userData = new FormData();

      if (status) {
        userData.append('status', status);
      }
      userData.append('email', email.trim());
      userData.append('last_name', lastName.trim());
      userData.append('first_name', firstName.trim());
      userData.append('avatar_image_url', avatar_image_url);

      if (phone && phone.length > 0) {
        userData.append('phone', phone.trim());
      }
      if (externalUserId && externalUserId.length > 0) {
        userData.append('external_user_id', externalUserId.trim());
      }

      try {
        dispatch(stateController.setState({ isProcessing: true }));

        const data = await UserService.updateUser(id, userData);

        dispatch(Actions.closeUserFlowModal());
        dispatch(UserActions.setUser(data));

        notify.success('Successfully updated');
      } finally {
        dispatch(stateController.setState({ isProcessing: false }));
      }
    };
  }
}

export class Selectors {}

export const reducer = stateController.getReducer();
