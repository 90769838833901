import { FC, SVGProps } from 'react';

export const ClientIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M11.3335 14H4.66683C2.00016 14 1.3335 13.3333 1.3335 10.6667V5.33333C1.3335 2.66667 2.00016 2 4.66683 2H11.3335C14.0002 2 14.6668 2.66667 14.6668 5.33333V10.6667C14.6668 13.3333 14.0002 14 11.3335 14Z"
      stroke="#141414"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M9.3335 5.3335H12.6668" stroke="#292D32" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M10 8H12.6667" stroke="#292D32" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M11.3335 10.6665H12.6668" stroke="#292D32" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M5.66663 7.52661C6.33305 7.52661 6.87329 6.98637 6.87329 6.31995C6.87329 5.65352 6.33305 5.11328 5.66663 5.11328C5.0002 5.11328 4.45996 5.65352 4.45996 6.31995C4.45996 6.98637 5.0002 7.52661 5.66663 7.52661Z"
      stroke="#292D32"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.00016 10.8867C7.90683 9.92001 7.14016 9.16001 6.1735 9.07334C5.84016 9.04001 5.50016 9.04001 5.16016 9.07334C4.1935 9.16667 3.42683 9.92001 3.3335 10.8867"
      stroke="#292D32"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
