import { baseAxiosInstance } from 'axios-config';
import { Filters, GetUsersWithFiltersResponse } from 'services/users-filter.model';

export class UserFilterService {
  public static async getUsersWithFilters(filters: Partial<Filters>, skip: number, take: number) {
    const { search, sort_by, order, status_filter, vacation_filter, user_type_filter } = filters;

    const { data } = await baseAxiosInstance.get<GetUsersWithFiltersResponse>('/users/all-by-page', {
      params: {
        search,
        sortBy: sort_by?.id,
        order,
        status: status_filter?.queryParams,
        vacation_filter,
        is_admin: user_type_filter,
        take,
        skip,
      },
    });

    return data;
  }
}
