import { AppState } from 'redux/store';
import { LockIcon } from 'icons/lock-icon';
import { Stack, Tooltip } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { TasksActions } from 'pages/tasks/tasks.controller';
import { AccessLevel, Permission } from 'services/permission.model';
import { PermissionGuardActions, PermissionGuardSelectors } from 'modules/permission-guard/permission-guard.controller';
import s from './is-in-queue-cell.module.scss';

const IsInQueueCell = ({ value, data }) => {
  const dispatch = useDispatch();

  const taskDepartments = data.department_ids.map((i: string) => ({ id: i })) || [];
  const accessLevels = [AccessLevel.userDepartment, AccessLevel.userDepartmentSubDepartments, AccessLevel.allDepartments];
  const isPermitted = useSelector((state: AppState) =>
    PermissionGuardSelectors.checkEditLevel(state, Permission.webTaskEditLevel, accessLevels, taskDepartments),
  );

  const toggleTaskQueueStatus = () => {
    if (!isPermitted) {
      dispatch(PermissionGuardActions.openModal());

      return;
    }

    dispatch(TasksActions.updateTask(data.id, { is_in_queue: !value }));
  };

  return (
    <Stack className={s.a} data-is-visible={value}>
      <Tooltip title={`Press to ${value ? 'unlock' : 'lock'} the task`} placement="top">
        <button type="button" data-is-visible={value} className={s.lock_wrapper} onClick={toggleTaskQueueStatus}>
          <LockIcon />
        </button>
      </Tooltip>
    </Stack>
  );
};

export default IsInQueueCell;
