import { FC, SVGProps } from 'react';

export const PriorityLowIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="#91979F" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.91641 0.999999C3.59425 0.999999 3.33309 1.26116 3.33309 1.58332L3.33309 4.49994C3.33309 6.4329 4.90006 7.99988 6.83303 7.99988C8.12167 7.99988 9.16632 9.04453 9.16632 10.3332L9.16632 13.0084L7.82882 11.6709C7.60102 11.4431 7.23168 11.4431 7.00388 11.6709C6.77608 11.8987 6.77608 12.2681 7.00388 12.4959L9.33717 14.8291C9.44657 14.9385 9.59494 15 9.74964 15C9.90435 15 10.0527 14.9385 10.1621 14.8291L12.4954 12.4959C12.7232 12.2681 12.7232 11.8987 12.4954 11.6709C12.2676 11.4431 11.8983 11.4431 11.6705 11.6709L10.333 13.0084L10.333 10.3332C10.333 8.40021 8.76599 6.83323 6.83303 6.83323C5.54439 6.83323 4.49973 5.78858 4.49973 4.49994L4.49973 1.58332C4.49973 1.26116 4.23857 0.999999 3.91641 0.999999Z"
    />
  </svg>
);
