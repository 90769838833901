import { CheckboxEmptyIcon } from 'icons/checkbox-empty';
import { CheckboxFailIcon } from 'icons/checkbox-fail';
import { CheckboxSuccessIcon } from 'icons/checkbox-success';
import s from './checkbox.module.scss';

const CheckBoxes = {
  empty: <CheckboxEmptyIcon />,
  true: <CheckboxSuccessIcon />,
  false: <CheckboxFailIcon />,
};

type Props = {
  title: string;
  status: 'empty' | 'true' | 'false';
};

const CheckBox = ({ title, status = 'empty' }: Props) => {
  return (
    <div className={s.wrapper}>
      {CheckBoxes[status]}
      <div>{title}</div>
    </div>
  );
};

export default CheckBox;
