import { connect, useDispatch } from 'react-redux';
import { AppState } from 'redux/store';
import Checkbox from 'components/checkbox/checkbox';
import { TasksActions } from 'pages/tasks/tasks.controller';
import { SelectedTask } from 'services/task-table.model';
import s from './select-all-cell.module.scss';

type StateProps = {
  selectedTasks: SelectedTask[];
};

type Props = StateProps;

const SelectAllCell = ({ selectedTasks }: Props) => {
  const dispatch = useDispatch();

  const handleOnClick = () => {
    dispatch(TasksActions.manageSelectOrDeselectAllTasks({}));
  };

  return (
    <Checkbox size="medium" className={s.checkbox} checked={!!selectedTasks.length} data-show-checkbox onClick={handleOnClick} />
  );
};

const mapStatToProps = (state: AppState): StateProps => ({
  selectedTasks: state.tasks.selectedTasks,
});

export default connect(mapStatToProps, null)(SelectAllCell);
