import { FC, SVGProps } from 'react';

export const PriorityHighestIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none" {...props}>
    <path
      d="M7.4 15C7.4 15.3314 7.66863 15.6 8 15.6C8.33137 15.6 8.6 15.3314 8.6 15H7.4ZM7.4 1.5V15H8.6V1.5H7.4Z"
      fill="#FF7D7D"
    />
    <path d="M5 4.5L8 1.5L11 4.5" stroke="#FF7D7D" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);
