import { FC, SVGProps } from 'react';

const BurgerMenu: FC<SVGProps<SVGSVGElement>> = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path d="M3 7H21" stroke="#141414" strokeWidth="1.2" strokeLinecap="round" />
      <path d="M3 12H21" stroke="#141414" strokeWidth="1.2" strokeLinecap="round" />
      <path d="M3 17H21" stroke="#141414" strokeWidth="1.2" strokeLinecap="round" />
    </svg>
  );
};

export default BurgerMenu;
