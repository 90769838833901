import { Link } from 'react-router-dom';
import { Paths } from 'routes/paths';
import s from './invalid-link.module.scss';

const InvalidLink = () => {
  return (
    <div className={s.container}>
      <div className={s.wrapper}>
        <div>
          <h3>Link does not work</h3>
          <p>Reset password links expires after 24 hours if unused or expires after being used</p>
        </div>
        <p>
          If your link not working for any reason request a new one using
          <Link to={Paths.ForgotPassword} className={s.navigation_link}>
            Forgot password page
          </Link>
        </p>
      </div>
    </div>
  );
};

export default InvalidLink;
