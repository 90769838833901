import { FC, SVGProps } from 'react';

export const ProductCategoryIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M5.9 15H10.1C13.6 15 15 13.6 15 10.1V5.9C15 2.4 13.6 1 10.1 1H5.9C2.4 1 1 2.4 1 5.9V10.1C1 13.6 2.4 15 5.9 15Z"
      stroke="#141414"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M8 1V15" stroke="#141414" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M1 6.25H8" stroke="#141414" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M8 9.75H15" stroke="#141414" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);
