import { Tooltip } from '@mui/material';

const ArrayCell = ({ value }) => {
  const joinedValue = Array.isArray(value) ? value.join(', ') : '';

  return (
    <Tooltip placement="top" title={value?.length > 1 ? joinedValue : ''}>
      <div style={{ display: 'inline' }}>{joinedValue}</div>
    </Tooltip>
  );
};

export default ArrayCell;
