import { baseAxiosInstance } from 'axios-config';
import { ManageWorkflowTaskTemplateBonuses, WorkflowTaskTemplateBonus } from 'services/workflow-task-template-bonuses.model';
import { TaskTemplateBonus } from 'services/workflow-task-template.model';

export class WorkflowTemplateBonusesService {
  public static async manage(body: ManageWorkflowTaskTemplateBonuses) {
    const { data } = await baseAxiosInstance.post<TaskTemplateBonus>('/workflow-task-template-bonus/manage', body);

    return data;
  }

  public static async create(body: WorkflowTaskTemplateBonus[]) {
    const { data } = await baseAxiosInstance.post<TaskTemplateBonus>('/workflow-task-template-bonus/create', body);
    return data;
  }

  public static async update(id: string, body: WorkflowTaskTemplateBonus) {
    const { data } = await baseAxiosInstance.put<WorkflowTaskTemplateBonus>(`/workflow-task-template-bonus/update/${id}`, body);

    return data;
  }

  public static async delete(id: string) {
    const { data } = await baseAxiosInstance.delete<WorkflowTaskTemplateBonus>(`/workflow-task-template-bonus/delete/${id}`);

    return data;
  }
}
