import { FC, SVGProps } from 'react';

export const DiamondIcon: FC<SVGProps<SVGSVGElement>> = ({ ...props }) => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M7.06316 1.5H8.47579C8.77947 1.5 9.06866 1.6315 9.27041 1.86134L10.7315 3.52575C11.0705 3.91201 11.0907 4.48775 10.7795 4.89738L6.84269 10.0798C6.41711 10.6401 5.58289 10.6401 5.15731 10.0798L1.22048 4.89738C0.909303 4.48775 0.92948 3.91201 1.26855 3.52575L2.72959 1.86134C2.93135 1.6315 3.22053 1.5 3.52421 1.5H5.05497M7.06316 1.5L8.12632 4.19143M7.06316 1.5H5.05497M8.12632 4.19143H10.7842M8.12632 4.19143L6 10.3817L3.87368 4.19143M8.12632 4.19143H3.87368M5.05497 1.5L3.87368 4.19143M1.21578 4.19143H3.87368"
      stroke="#878B92"
      strokeWidth="0.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
