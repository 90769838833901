import { FC, SVGProps } from 'react';

export const GooglePlayIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M14.0892 8.49597L4.5967 3.07057C4.47742 3.00131 4.32736 3.00516 4.21192 3.07442C4.09264 3.14368 4.01953 3.26681 4.01953 3.40533C4.01953 3.40533 4.02338 3.90554 4.02723 4.72897L10.9417 11.6435L14.0892 8.49597Z"
      fill="url(#paint0_linear_4234_4947)"
    />
    <path
      d="M4.01953 4.72852C4.03107 7.69899 4.0657 14.9097 4.0811 18.4997L10.9379 11.6429L4.01953 4.72852Z"
      fill="url(#paint1_linear_4234_4947)"
    />
    <path
      d="M19.518 11.5983L14.0849 8.49316L10.9336 11.6406L14.489 15.196L19.5219 12.264C19.6412 12.1947 19.7143 12.0677 19.7143 11.9331C19.7143 11.7945 19.6373 11.6676 19.518 11.5983Z"
      fill="url(#paint2_linear_4234_4947)"
    />
    <path
      d="M4.07812 18.4983C4.08582 19.7796 4.08967 20.5992 4.08967 20.5992C4.08967 20.7377 4.16278 20.8647 4.28206 20.9301C4.40134 20.9994 4.54756 20.9994 4.66684 20.9301L14.4903 15.2008L10.9349 11.6455L4.07812 18.4983Z"
      fill="url(#paint3_linear_4234_4947)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_4234_4947"
        x1="4.01953"
        y1="7.3337"
        x2="14.091"
        y2="7.3337"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#63BE6B" />
        <stop offset="0.506" stopColor="#5BBC6A" />
        <stop offset="1" stopColor="#4AB96A" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_4234_4947"
        x1="4.01768"
        y1="11.6157"
        x2="10.9339"
        y2="11.6157"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#3EC6F2" />
        <stop offset="1" stopColor="#45AFE3" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_4234_4947"
        x1="10.9373"
        y1="11.847"
        x2="19.7133"
        y2="11.847"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FAA51A" />
        <stop offset="0.387" stopColor="#FAB716" />
        <stop offset="0.741" stopColor="#FAC412" />
        <stop offset="1" stopColor="#FAC80F" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_4234_4947"
        x1="4.07982"
        y1="16.3136"
        x2="14.4901"
        y2="16.3136"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#EC3B50" />
        <stop offset="1" stopColor="#E7515B" />
      </linearGradient>
    </defs>
  </svg>
);
