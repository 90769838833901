import { ProductionForActionsT, QrLabelData } from 'modules/print-label-with-qr/types';

export const generateQrData = (production: ProductionForActionsT, destination: string): QrLabelData => ({
  qrUrl: destination,
  variantName: production.variant.name,
  productName: production.product_name,
  deadline: production.deadline_at || '-',
  productionKey: production.production_key,
  clientName: production.order.client_name || '-',
  configurationName: production.configuration.name,
  primaryClientName: production.order.primary_client?.name || '-',
  externalOrderNumber: production.order.external_order_number || '-',
  marketplaceOrderNumber: production.order.marketplace_order_number || '-',
});

export const generateQrURL = (protocol: string, hostname: string, id: string) =>
  `${protocol}//${hostname}/production-tasks-list/${id}?qr=true`;
