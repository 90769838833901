import { BaseEdge, EdgeLabelRenderer, EdgeProps, useNodes } from 'reactflow';
import { getSmartEdge } from '@tisoap/react-flow-smart-edge';

const CustomEdge = ({
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  markerStart,
  markerEnd,
}: EdgeProps) => {
  const nodes = useNodes();
  const getSmartEdgeResponse = getSmartEdge({
    sourcePosition,
    targetPosition,
    sourceX,
    sourceY,
    targetX,
    targetY,
    nodes,
  });

  return (
    <>
      <BaseEdge path={getSmartEdgeResponse?.svgPathString || ''} markerStart={markerStart} markerEnd={markerEnd} style={style} />

      <EdgeLabelRenderer>
        <div
          style={{
            transform: `translate(-50%, -50%) translate(${getSmartEdgeResponse?.edgeCenterX}px,${getSmartEdgeResponse?.edgeCenterY}px)`,
          }}
        />
      </EdgeLabelRenderer>
    </>
  );
};

export default CustomEdge;
