/* eslint-disable no-else-return */
import {
  booleanFilters,
  dateFilters,
  filtersWithOptions,
  keepNullFields,
  objectFilters,
  TASKS_FIELDS,
} from 'pages/tasks/constants';
import { TaskTableModel } from 'services/task-table.model';
import { IdName, SortOrderOption } from 'types/common-types';
import { ColumnSizesT, TasksFiltersEnum, TasksFiltersT } from 'pages/tasks/types/types';
import { ShowCompletedPeriodEnum } from 'services/production-workflow.model';
import { ColGroupDef, ColDef, GridReadyEvent, GetMainMenuItemsParams } from 'ag-grid-community';

const extractSelectedOptions = <T>(field: { selectedOptions: T[] }): T[] | undefined => {
  return field.selectedOptions.length > 0 ? field.selectedOptions : undefined;
};

const extractBooleanSelectedOptions = (field: { selectedOptions: string[] }) => {
  const { selectedOptions } = field;
  if (!selectedOptions.length) {
    return null;
  }

  const allTrue = selectedOptions.every((option) => option === 'true');

  const allFalse = selectedOptions.every((option) => option === 'false');

  if (allTrue) return true;

  if (allFalse) return false;

  return null;
};

const extractMatchingOptions = (field: { selectedOptions: string[]; options: IdName[] }) => {
  return field.options.filter((option) => field.selectedOptions.includes(option.id));
};

const cleanObject = (obj: Record<string, any>) => {
  return Object.entries(obj).reduce((acc, [key, value]) => {
    if (
      (value !== null || keepNullFields.includes(key as TasksFiltersEnum)) &&
      value !== undefined &&
      value !== '' &&
      value !== 0 &&
      !(Array.isArray(value) && (value.length === 0 || value.every((v) => v === null)))
    ) {
      acc[key] = value;
    }

    return acc;
  }, {});
};

export const generateRequestBody = (filters: TasksFiltersT, fields: TasksFiltersEnum[] | null, columnSizes: ColumnSizesT) => {
  const showCompletedPeriod = Number((filters.filters[TasksFiltersEnum.ShowCompleted]?.value as IdName)?.id);

  return {
    fields: fields ?? (TASKS_FIELDS as TasksFiltersEnum[]),
    filters: cleanObject({
      // Production
      [TasksFiltersEnum.ProductionStatus]: extractSelectedOptions(filters.filters[TasksFiltersEnum.ProductionStatus]),
      [TasksFiltersEnum.ProductionDeadline]: filters.filters[TasksFiltersEnum.ProductionDeadline].value,
      [TasksFiltersEnum.RootProductionDeadline]: filters.filters[TasksFiltersEnum.RootProductionDeadline].value,

      // Task
      [TasksFiltersEnum.IsInQueue]: extractBooleanSelectedOptions(filters.filters[TasksFiltersEnum.IsInQueue]),
      [TasksFiltersEnum.IsFailed]: extractBooleanSelectedOptions(filters.filters[TasksFiltersEnum.IsFailed]),
      [TasksFiltersEnum.FailedAt]: filters.filters[TasksFiltersEnum.FailedAt].value,
      [TasksFiltersEnum.TaskType]: extractSelectedOptions(filters.filters[TasksFiltersEnum.TaskType]),
      [TasksFiltersEnum.TaskStatus]: extractSelectedOptions(filters.filters[TasksFiltersEnum.TaskStatus]),
      [TasksFiltersEnum.TaskPriority]: extractSelectedOptions(filters.filters[TasksFiltersEnum.TaskPriority]),
      [TasksFiltersEnum.ReportingPeriod]: filters.filters[TasksFiltersEnum.ReportingPeriod].value,
      [TasksFiltersEnum.ReasonForFailure]: extractSelectedOptions(filters.filters[TasksFiltersEnum.ReasonForFailure]),

      // Assignment
      [TasksFiltersEnum.Assignee]: extractMatchingOptions(filters.filters[TasksFiltersEnum.Assignee]),
      [TasksFiltersEnum.AssigneeType]: extractSelectedOptions(filters.filters[TasksFiltersEnum.AssigneeType]),

      // Warnings
      [TasksFiltersEnum.AssigneeRequired]: extractBooleanSelectedOptions(filters.filters[TasksFiltersEnum.AssigneeRequired]),
      [TasksFiltersEnum.TimeLimitExceeded]: extractBooleanSelectedOptions(filters.filters[TasksFiltersEnum.TimeLimitExceeded]),

      // Show Completed
      [TasksFiltersEnum.ShowCompleted]: {
        type: filters.filters[TasksFiltersEnum.ShowCompleted].radioValue,
        period:
          filters.filters[TasksFiltersEnum.ShowCompleted].radioValue === ShowCompletedPeriodEnum.Some
            ? showCompletedPeriod
            : undefined,
      },
    }),
    queries: cleanObject({
      // Product
      [TasksFiltersEnum.ProductName]: filters.queries[TasksFiltersEnum.ProductName],
      [TasksFiltersEnum.ProductVersion]: Number(filters.queries[TasksFiltersEnum.ProductVersion]),
      [TasksFiltersEnum.RootProductName]: filters.queries[TasksFiltersEnum.RootProductName],
      [TasksFiltersEnum.RootProductVersion]: Number(filters.queries[TasksFiltersEnum.RootProductVersion]),
      [TasksFiltersEnum.RootProductVariant]: filters.queries[TasksFiltersEnum.RootProductVariant],
      [TasksFiltersEnum.RootProductConfiguration]: filters.queries[TasksFiltersEnum.RootProductConfiguration],

      // Production
      [TasksFiltersEnum.ProductionKey]: filters.queries[TasksFiltersEnum.ProductionKey],
      [TasksFiltersEnum.ProductVariant]: filters.queries[TasksFiltersEnum.ProductVariant],
      [TasksFiltersEnum.RootProductionKey]: filters.queries[TasksFiltersEnum.RootProductionKey],
      [TasksFiltersEnum.ProductConfiguration]: filters.queries[TasksFiltersEnum.ProductConfiguration],

      // Order
      [TasksFiltersEnum.Client]: filters.queries[TasksFiltersEnum.Client],
      [TasksFiltersEnum.OrderKey]: filters.queries[TasksFiltersEnum.OrderKey],
      [TasksFiltersEnum.PrimaryClient]: filters.queries[TasksFiltersEnum.PrimaryClient],
      [TasksFiltersEnum.ExternalOrderNumber]: filters.queries[TasksFiltersEnum.ExternalOrderNumber],
      [TasksFiltersEnum.MarketPlaceOrderNumber]: filters.queries[TasksFiltersEnum.MarketPlaceOrderNumber],

      // Task
      [TasksFiltersEnum.TaskKey]: filters.queries[TasksFiltersEnum.TaskKey],
      [TasksFiltersEnum.TaskName]: filters.queries[TasksFiltersEnum.TaskName],
      [TasksFiltersEnum.ReasonForFailure]: filters.queries[TasksFiltersEnum.ReasonForFailure],

      // Assignment
      [TasksFiltersEnum.AssigneePosition]: filters.queries[TasksFiltersEnum.AssigneePosition],
      [TasksFiltersEnum.AssigneeDepartment]: filters.queries[TasksFiltersEnum.AssigneeDepartment],
    }),
    pins: {
      left: filters.pins.left as TasksFiltersEnum[],
      right: filters.pins.right as TasksFiltersEnum[],
    },
    sort: {
      orderBy: filters.sort.orderBy,
      orderOption: filters.sort.orderOption || SortOrderOption.Ascending,
    },
    sizes: columnSizes ?? {},
  };
};

export const reorderColumnsByFieldOrder = (
  groupedColumns: ColGroupDef<TaskTableModel>[],
  fieldOrder: string[] = TASKS_FIELDS,
): ColGroupDef<TaskTableModel>[] => {
  const sortColumns = (columns: ColDef[]): ColDef[] =>
    columns.sort((a, b) => {
      const indexA = fieldOrder.indexOf(a.field as TasksFiltersEnum);
      const indexB = fieldOrder.indexOf(b.field as TasksFiltersEnum);
      return indexA - indexB;
    });

  return groupedColumns.map((group) => ({
    ...group,
    children: group.children ? sortColumns(group.children) : group.children,
  }));
};

export const filterValidFields = (columnOrderArray: TasksFiltersEnum[]): TasksFiltersEnum[] => {
  return columnOrderArray.filter((field) => TASKS_FIELDS.includes(field));
};

export const getPinnedSide = (column: ColDef, pins: TasksFiltersT['pins'] | undefined): 'left' | 'right' | null => {
  if (pins?.left?.includes(column.field as TasksFiltersEnum)) {
    return 'left';
  } else if (pins?.right?.includes(column.field as TasksFiltersEnum)) {
    return 'right';
  }
  return null;
};

export const getFormattedFilters = (filtersArray, filters) => {
  const result = JSON.parse(JSON.stringify(filters));

  dateFilters.forEach((key) => {
    if (filtersArray && filtersArray[key]) {
      result[key] = { value: filtersArray[key] };
    }
  });

  objectFilters.forEach((key) => {
    if (filtersArray && filtersArray[key]) {
      result[key] = {
        ...result[key],
        options: filtersArray[key],
        selectedOptions: filtersArray[key].map((assignee) => assignee.id),
      };
    }
  });

  filtersWithOptions.forEach((key) => {
    if (filtersArray && filtersArray[key]) {
      result[key] = {
        ...result[key],
        selectedOptions: filtersArray[key],
      };
    }
  });

  booleanFilters.forEach((key) => {
    if (filtersArray) {
      result[key] = {
        ...result[key],
        selectedOptions: filtersArray[key] || filtersArray[key] === false ? [String(filtersArray[key])] : [],
      };
    }
  });

  return result;
};

export const isTableEndReached = (api: GridReadyEvent['api']) => {
  const verticalScrollPosition = api.getVerticalPixelRange();
  const scrollableHeight = api.getSizesForCurrentTheme().rowHeight;
  const lastRowIndex = api.getDisplayedRowCount();
  const lastRowNode = api.getDisplayedRowAtIndex(lastRowIndex - 1);

  const lastRowTopPixel = lastRowNode?.rowTop || 0;

  return verticalScrollPosition.bottom >= lastRowTopPixel + scrollableHeight;
};

export const checkHiddenColumn = (columnName: TasksFiltersEnum, activeColumns: string[] = TASKS_FIELDS): boolean => {
  const isColumnValid = TASKS_FIELDS.includes(columnName);

  return isColumnValid && !activeColumns.includes(columnName as TasksFiltersEnum);
};

export const getColumnWidth = (
  columnName: TasksFiltersEnum,
  sizes: { [key in TasksFiltersEnum]: number } | undefined,
): number | null => {
  const isColumnValid = TASKS_FIELDS.includes(columnName);

  let result: number | null;

  if (isColumnValid && sizes) {
    result = sizes[columnName] || null;
  } else if (!isColumnValid) {
    result = 80;
  } else {
    result = null;
  }

  return result;
};

export const getActiveFilters = (obj: Record<string, unknown>) => {
  const keys: string[] = [];

  if (obj.filters) {
    keys.push(...Object.keys(obj.filters));
  }

  if (obj.queries) {
    keys.push(...Object.keys(obj.queries));
  }

  return keys;
};

export const getMainMenuItems = (params: GetMainMenuItemsParams<TaskTableModel>) => {
  return params.defaultItems.filter((item) => item !== 'columnFilter');
};
