import { AppState } from 'redux/store';
import { StateController } from 'state-controller';
import { OrdersService } from 'services/orders.service';
import { AccessLevel, Permission } from 'services/permission.model';
import { ProductionWorkflowService } from 'services/production-workflow.service';
import { PermissionGuardActions } from 'modules/permission-guard/permission-guard.controller';
import { ProductionWorkflow, ProductionWorkflowUpdateBody } from 'services/production-workflow.model';
import { Actions as ProductionWorkflowActions } from 'pages/production-workflow/controllers/production-workflow.controller';
import { NewComponentModalActions } from 'pages/production/manage-components-modal/components/new-component-modal/new-component-modal.controller';
import { notify } from 'notifications';

export type AdditionalComponentsState = {
  rootId: string;
  keyword: string;
  isOpen: boolean;
  orderKey: string;
  clientName: string;
  productionId: string;
  productionKey: string;
  productionName: string;
  externalOrderNumber: string;
};

const defaultState: AdditionalComponentsState = {
  rootId: '',
  keyword: '',
  orderKey: '',
  isOpen: false,
  clientName: '',
  productionId: '',
  productionKey: '',
  productionName: '',
  externalOrderNumber: '',
};

const stateController = new StateController<AdditionalComponentsState>('PRODUCTION_WORKFLOW_ADDITIONAL_COMPONENTS', defaultState);

export class AdditionalComponentsActions {
  public static openModal(productionId: string) {
    return async (dispatch, getState: () => AppState) => {
      const { production_key, product_name, main_root_id } = getState().production_workflow.productionInfo;
      const { order_key, client_name, external_order_number } = getState().production_workflow.productionInfo.order;

      dispatch(stateController.setState({ isOpen: true }));
      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          productionId,
          isOpen: true,
          orderKey: order_key,
          rootId: main_root_id,
          clientName: client_name,
          productionName: product_name,
          productionKey: production_key,
          externalOrderNumber: external_order_number,
        })),
      );
    };
  }

  public static closeModal() {
    return async (dispatch) => {
      dispatch(stateController.setState({ isOpen: false }));
      setTimeout(() => dispatch(stateController.setState({ ...defaultState })), 100);
    };
  }

  public static updateProduction(id: string, value: Partial<ProductionWorkflow>, isUpdateResponsible?: boolean) {
    return async (dispatch, getState: () => AppState) => {
      if (!dispatch(PermissionGuardActions.checkPermissionAndShowModal(Permission.webProductionEdit, [AccessLevel.access]))) {
        return;
      }

      const { productionId } = getState().production_workflow_additional_components;

      const updateData: ProductionWorkflowUpdateBody = {
        title: value.title,
        status: value.status,
        priority: value.priority,
        ...(isUpdateResponsible && value.responsible?.id ? { responsible_id: value.responsible?.id } : {}),
      };

      try {
        if (value.order) await OrdersService.updateOrder(id, { priority: value.order.priority });
        if (!value.order) await ProductionWorkflowService.update(id, updateData);

        await dispatch(ProductionWorkflowActions.silentLoad({ id: productionId }));
      } catch (error) {
        notify.error(error);
      }
    };
  }

  public static deleteAdditionalProduction(id: string) {
    return async (dispatch, getState: () => AppState) => {
      try {
        await ProductionWorkflowService.delete(id);

        const { productionId } = getState().production_workflow_additional_components;
        await dispatch(ProductionWorkflowActions.silentLoad({ id: productionId }));

        notify.success('Deleted successfully');
      } catch (err) {
        notify.error(err);
      }
    };
  }

  public static onSearchChange(value: string) {
    return async (dispatch) => {
      dispatch(stateController.setState({ keyword: value }));
    };
  }

  public static addAdditionalComponent() {
    return async (dispatch, getState: () => AppState) => {
      try {
        dispatch(NewComponentModalActions.setFetching(true));

        const variantValueId = getState().production.newComponentModal.variant.value?.id;
        const { productionId } = getState().production_workflow_additional_components;

        if (!variantValueId) {
          throw new Error('variantValueId is undefined');
        }

        const logItem = {
          attached_to: productionId,
          components_to_add: [{ product_variant_id: variantValueId }],
        };

        await ProductionWorkflowService.manageComponents([logItem]);
        await dispatch(ProductionWorkflowActions.silentLoad({ id: productionId }));

        dispatch(NewComponentModalActions.closeModal());
      } catch (err) {
        notify.error(err.message);
        throw err;
      } finally {
        dispatch(NewComponentModalActions.setFetching(false));
      }
    };
  }
}

export class AdditionalComponentsSelectors {
  public static filteredItems(state: AppState): ProductionWorkflow[] {
    const { keyword } = state.production_workflow_additional_components;
    // additionalComponents are used from state.production_workflow.productionInfo instead of items from state.production_workflow_additional_components
    // to enable reloading of the items when the production was launched or any other change happened to the production outside the modal
    // as items are gathered from additionalComponents on the modal open
    const { additionalComponents } = state.production_workflow.productionInfo;
    if (!keyword) return additionalComponents;
    return additionalComponents.filter((i) => i.title.toLowerCase().includes(keyword.toLowerCase()));
  }
}

export const reducer = stateController.getReducer();
