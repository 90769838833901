import { FC, SVGProps } from 'react';

export const PublishedProductIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect x="0.6" y="0.6" width="28.8" height="28.8" rx="7.4" stroke="#3ED296" strokeWidth="1.2" />
    <path
      d="M15 19.5V21.642C15 23.325 14.325 24 12.633 24H8.358C6.675 24 6 23.325 6 21.642V17.367C6 15.675 6.675 15 8.358 15H10.5V17.133C10.5 18.825 11.175 19.5 12.858 19.5H15Z"
      stroke="#3ED296"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.5 15V17.133C19.5 18.825 18.825 19.5 17.133 19.5H12.858C11.175 19.5 10.5 18.825 10.5 17.133V12.858C10.5 11.175 11.175 10.5 12.858 10.5H15V12.633C15 14.325 15.675 15 17.358 15H19.5Z"
      stroke="#3ED296"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M24 8.358V12.633C24 14.325 23.325 15 21.633 15H17.358C15.675 15 15 14.325 15 12.633V8.358C15 6.675 15.675 6 17.358 6H21.633C23.325 6 24 6.675 24 8.358Z"
      stroke="#3ED296"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
