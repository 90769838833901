import { baseAxiosInstance } from 'axios-config';
import {
  CreateProductTagData,
  CreateProductTagRelationData,
  ManageProductTagsData,
  ProductTagsData,
  Tag,
} from 'services/product-tags.model';
import { ProductTagRelation } from 'services/products.model';

export class ProductTagsService {
  public static async getAllTags() {
    const { data } = await baseAxiosInstance.get<Tag[]>('/product-tags/all');

    return data;
  }

  public static async createProductTag(body: CreateProductTagData) {
    const { data } = await baseAxiosInstance.post<Tag>('/product-tags/create', body);

    return data;
  }

  public static async createProductTagRelation(body: CreateProductTagRelationData) {
    const { data } = await baseAxiosInstance.post<ProductTagRelation>('/product-tag-relation/create', body);

    return data;
  }

  public static async deleteProductTagRelation(id: string) {
    const { data } = await baseAxiosInstance.delete<ProductTagRelation>(`/product-tag-relation/${id}`);

    return data;
  }

  public static async manageProductTags(body: ManageProductTagsData) {
    const { data } = await baseAxiosInstance.post<ProductTagsData[]>(`/product-tag-relation/manage`, body);

    return data;
  }
}
