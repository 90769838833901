import FireMinusIcon from 'icons/fire-minus';
import { MODALS } from 'modules/root-modals/modals';
import { DeleteConfirmationOwnProps } from 'modules/root-modals/modals/confirmation-modal/confirmation-modal';
import { Actions as ProductRootController } from 'pages/product-flow/pages/product/controllers/product-root.controller';
import { Actions as ProductVariantController } from 'pages/product-flow/pages/product/controllers/product-variant.controller';
import { ModalActions } from 'modules/root-modals/root-modals.controller';
import { t } from 'setup-localization';
import { GetStateFunction } from 'redux/store';
import { PermissionGuardActions } from 'modules/permission-guard/permission-guard.controller';
import { AccessLevel, Permission } from 'services/permission.model';
import { ProductConfigurationPhoto } from 'services/product-configuration-photo.model';
import { ProductConfigurationPhotoService } from 'services/product-configuration-photo.service';
import { StateController } from 'state-controller';
import { simpleFileUploading } from 'utils/file-uploading';
import { notify } from 'notifications';

export type ProductState = {
  isInitLoading: boolean;
  product_configuration_photos: Array<ProductConfigurationPhoto>;
  productMediaModal: {
    isOpen: boolean;
    initActiveImageId: string;
  };
  fileSizeLimitModal: {
    isOpen: boolean;
  };
  isConfigPhotoLoading: boolean;
  photoDownloadUrl: string;
};

const defaultState: ProductState = {
  isInitLoading: true,
  product_configuration_photos: [],
  productMediaModal: {
    isOpen: false,
    initActiveImageId: '',
  },
  fileSizeLimitModal: {
    isOpen: false,
  },
  isConfigPhotoLoading: false,
  photoDownloadUrl: '',
};

const stateController = new StateController<ProductState>('PRODUCT_MEDIA', defaultState);

export class ProductMediaActions {
  public static init(files: Array<ProductConfigurationPhoto>) {
    return (dispatch) => {
      const filesCopy: Array<ProductConfigurationPhoto> = JSON.parse(JSON.stringify(files));

      const sortedFiles = filesCopy.sort((fileA, fileB) => {
        const dateA = new Date(fileA.created_at);
        const dateB = new Date(fileB.created_at);
        return dateB.toLocaleString().localeCompare(dateA.toLocaleString());
      });

      dispatch(
        stateController.setState((prev) => ({ ...prev, product_configuration_photos: sortedFiles, isInitLoading: false })),
      );
    };
  }

  public static disposeState() {
    return (dispatch) => {
      dispatch(stateController.setState(defaultState));
    };
  }

  public static setInitLoading() {
    return (dispatch) => {
      dispatch(stateController.setState({ isInitLoading: true }));
    };
  }

  public static openProductMediaModal(initActiveImageId: string) {
    return (dispatch) => {
      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          productMediaModal: {
            ...prev.productMediaModal,
            isOpen: true,
            initActiveImageId,
          },
        })),
      );
    };
  }

  public static openFileSizeLimitModal() {
    return (dispatch) => {
      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          fileSizeLimitModal: {
            ...prev.fileSizeLimitModal,
            isOpen: true,
          },
        })),
      );
    };
  }

  public static closeModal(modalName: string) {
    return (dispatch) => {
      dispatch(
        stateController.setState((prevState) => ({
          ...prevState,
          [modalName]: defaultState[modalName],
        })),
      );
    };
  }

  public static setPhotoDownloadUrl(url: string) {
    return async (dispatch) => {
      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          photoDownloadUrl: url,
        })),
      );
    };
  }

  public static uploadConfigurationPhoto(files: File[]) {
    return async (dispatch, getState: GetStateFunction) => {
      const { active_configuration } = getState().product.product_configurations;
      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          isConfigPhotoLoading: true,
        })),
      );
      const uploadPromises = files.map(async (file) => {
        try {
          const newFile = await simpleFileUploading(
            file,
            active_configuration.id,
            ProductConfigurationPhotoService.create,
            () => {},
          );
          return newFile;
        } catch (error) {
          notify.error(error.message);
          return null;
        }
      });
      const uploadedFiles = (await Promise.all(uploadPromises)).filter(Boolean);
      if (uploadedFiles.length) {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            product_configuration_photos: [...uploadedFiles, ...prev.product_configuration_photos],
          })),
        );
        await dispatch(ProductRootController.updateProductModifiedAt());
      }
      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          isConfigPhotoLoading: false,
        })),
      );
    };
  }

  public static uploadConfigurationPhotoByUrl(configurationId: string, url: string) {
    return async (dispatch, getState: GetStateFunction) => {
      const { product_configuration_photos } = getState().product.product_media;

      try {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            isConfigPhotoLoading: true,
          })),
        );
        const newFile = await ProductConfigurationPhotoService.createByUrl({
          product_configuration_id: configurationId,
          url,
        });
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            product_configuration_photos: [newFile, ...product_configuration_photos],
          })),
        );
      } finally {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            isConfigPhotoLoading: false,
          })),
        );
      }
    };
  }

  public static setConfigurationPhotoToCover(photoId: string) {
    return async (dispatch, getState: GetStateFunction) => {
      const { product_configuration_photos } = getState().product.product_media;
      const { active_configuration } = getState().product.product_configurations;

      try {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            isConfigPhotoLoading: true,
          })),
        );

        await ProductConfigurationPhotoService.setDefaultCover(photoId, {
          product_configuration_id: active_configuration.id,
        });

        const mapped = product_configuration_photos.map((item) => {
          if (item.is_show_by_default && item.id !== photoId) {
            return {
              ...item,
              is_show_by_default: false,
            };
          }
          if (item.id === photoId) {
            return {
              ...item,
              is_show_by_default: true,
            };
          }
          return item;
        });

        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            product_configuration_photos: mapped,
          })),
        );
      } finally {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            isConfigPhotoLoading: false,
          })),
        );
      }
    };
  }

  public static openDeletePhotoConfirmationModal(file: ProductConfigurationPhoto) {
    return (dispatch) => {
      if (!dispatch(PermissionGuardActions.checkPermissionAndShowModal(Permission.webProductsEdit, [AccessLevel.access]))) {
        return;
      }

      dispatch(
        ModalActions.openModal<DeleteConfirmationOwnProps>({
          id: MODALS.CONFIRM,
          props: {
            title: 'Delete photo?',
            text: (
              <div style={{ marginBottom: '7px' }}>
                <span>
                  Are you sure you want to delete photo <strong>{file.name}</strong>?
                </span>
              </div>
            ),
            icon: <FireMinusIcon />,
            withCloseButton: false,
            actionText: <>{t('global.button_delete')}</>,
            action: () => dispatch(ProductMediaActions.deleteProductConfigurationPhoto(file.id)),
          },
        }),
      );
    };
  }

  public static deleteProductConfigurationPhoto(photoId: string) {
    return async (dispatch, getState: GetStateFunction) => {
      const { product_configuration_photos } = getState().product.product_media;
      const { active_configuration } = getState().product.product_configurations;

      try {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            isConfigPhotoLoading: true,
          })),
        );
        await ProductConfigurationPhotoService.delete(photoId);
        dispatch(ProductRootController.updateProductModifiedAt());
        dispatch(ProductVariantController.refetchVariants(active_configuration.id));
        const filtered = product_configuration_photos.filter((item) => item.id !== photoId);

        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            product_configuration_photos: filtered,
          })),
        );

        notify.success('Deleted successfully');

        // get oldest photo id
        let oldestDate = new Date();
        let oldestId = '';

        getState().product.product_media.product_configuration_photos.forEach((item) => {
          const itemDate = new Date(item.created_at);
          if (itemDate < oldestDate) {
            oldestDate = itemDate;
            oldestId = item.id;
          }
        });

        if (oldestId) dispatch(ProductMediaActions.setConfigurationPhotoToCover(oldestId));
        if (!oldestId) dispatch(ProductMediaActions.closeModal('productMediaModal'));
      } finally {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            isConfigPhotoLoading: false,
          })),
        );
      }
    };
  }
}

export class Selectors {}

export const reducer = stateController.getReducer();
