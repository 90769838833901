import { baseAxiosInstance } from 'axios-config';
import { TAKE_ITEMS } from 'constants/take-items';
import { ProductVendor } from 'services/product-vendor.model';
import { IdName, PaginatedResponse } from 'types/common-types';
import { buildQueryString } from 'utils/build-query-string';

export class ProductVendorService {
  public static async getAll(search = '', skip = 0, take = TAKE_ITEMS): Promise<PaginatedResponse<IdName[]>> {
    const queryParams = buildQueryString({ search, skip, take });

    const { data } = await baseAxiosInstance.get(`/product-vendors/all?${queryParams}`);

    return data;
  }

  public static async create(text: string) {
    const { data } = await baseAxiosInstance.post<ProductVendor>('/product-vendors/create', { name: text });
    return data;
  }
}
