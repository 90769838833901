import { Stack } from '@mui/material';
import { AppState } from 'redux/store';
import { useDispatch, useSelector } from 'react-redux';
import { TasksActions } from 'pages/tasks/tasks.controller';
import { AccessLevel, Permission } from 'services/permission.model';
import StatusSelector from 'components/status-selector/status-selector';
import { PermissionGuardSelectors } from 'modules/permission-guard/permission-guard.controller';

const TaskStatusCell = ({ value, data }) => {
  const dispatch = useDispatch();

  const taskDepartments = data.department_ids.map((i: string) => ({ id: i })) || [];
  const accessLevels = [AccessLevel.userDepartment, AccessLevel.userDepartmentSubDepartments, AccessLevel.allDepartments];
  const isPermitted = useSelector((state: AppState) =>
    PermissionGuardSelectors.checkEditLevel(state, Permission.webTaskEditLevel, accessLevels, taskDepartments),
  );

  return (
    <Stack justifyContent="center" alignItems="center" width="100%" height="100%">
      <StatusSelector
        optionsFor="task"
        status={value}
        isEditPermitted={isPermitted}
        onSelect={async (selectedStatus) => {
          dispatch(TasksActions.updateTask(data.id, { status: selectedStatus }));
        }}
      />
    </Stack>
  );
};

export default TaskStatusCell;
