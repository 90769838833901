import { connect } from 'react-redux';
import { Button } from '@mui/material';
import { AppState } from 'redux/store';
import { useKeyPress } from 'ahooks';
import { Selectors, Actions as SignInActions, SignInState, ValidationCriteria } from 'pages/auth/auth.controller';
import Success from 'pages/auth/components/success/success';
import { useSearchParams } from 'react-router-dom';
import { Paths } from 'routes/paths';
import CreateNewPasswordWithValidation from 'components/create-new-password-with-validation/create-new-password-with-validation';
import shared_s from '../../shared-styles.module.scss';

type StateProps = {
  newPassword: string;
  confirmPassword: string;
  isPasswordReset: boolean;
  validationCriteria: ValidationCriteria;
};

type DispatchProps = {
  resetPassword: (token: string) => void;
  onChange: (values: Partial<SignInState>) => void;
};

type Props = StateProps & DispatchProps;

const CreateNewPassword = ({
  newPassword,
  isPasswordReset,
  confirmPassword,
  validationCriteria,
  onChange,
  resetPassword,
}: Props) => {
  const [param] = useSearchParams();

  const token = param.get('token') ?? '';
  const isPasswordInvalid =
    (newPassword !== '' || confirmPassword !== '') && Object.values(validationCriteria).some((criteria) => !criteria);
  const isDisableButton = newPassword === '' || confirmPassword === '' || isPasswordInvalid;

  useKeyPress('enter', () => (!isDisableButton ? resetPassword(token) : null));

  if (isPasswordReset) {
    return <Success header="Success!" description="Your password has been successfully saved" isShowButton path={Paths.SignIn} />;
  }

  return (
    <form className={shared_s.form} onSubmit={(e) => e.preventDefault()}>
      <div className={shared_s.intro}>
        <h3>Create new password</h3>
        <p>Please enter a new password</p>
      </div>
      <CreateNewPasswordWithValidation
        newPassword={newPassword}
        confirmPassword={confirmPassword}
        validationCriteria={validationCriteria}
        onChange={onChange}
      />
      <Button type="submit" className={shared_s.button} disabled={isDisableButton} onClick={() => resetPassword(token)}>
        Confirm new password
      </Button>
    </form>
  );
};

const mapStateToProps = (state: AppState): StateProps => ({
  newPassword: state.authentication.newPassword,
  isPasswordReset: state.authentication.isPasswordReset,
  confirmPassword: state.authentication.confirmPassword,
  validationCriteria: { ...state.authentication.validationCriteria, isPasswordMatch: Selectors.returnIsPasswordMatch(state) },
});

const mapDispatchToProps: DispatchProps = {
  onChange: SignInActions.onChange,
  resetPassword: SignInActions.resetPassword,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateNewPassword);
