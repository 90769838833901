import { EnvCookies } from 'env-cookies';
import { AppState } from 'redux/store';
import { getNonAuthorizedEnvHostname } from 'tenant-helpers';
import { Tenant } from 'services/tenant.model';
import { TenantService } from 'services/tenant.service';
import { StateController } from 'state-controller';

export type AuthSelectCompanyState = {
  items: Array<Tenant>;
  isLoading: boolean;
};

const defaultState: AuthSelectCompanyState = {
  items: [],
  isLoading: false,
};

const stateController = new StateController<AuthSelectCompanyState>('AUTH-SELECT-COMPANY', defaultState);

export class Actions {
  public static loadUserCompanies() {
    return async (dispatch) => {
      try {
        dispatch(stateController.setState({ isLoading: true }));
        const userTenants = await TenantService.getUserTenants();
        dispatch(stateController.setState({ items: userTenants }));
      } finally {
        dispatch(stateController.setState({ isLoading: false }));
      }
    };
  }

  public static selectCompany(tenantId: string) {
    return async (dispatch, getState: () => AppState) => {
      EnvCookies.set('tenant', tenantId);
      // router.navigate(Paths.Home); // Do not remove. Is needed to prevent rendering of NorFound page for 200 miliseconds
      // await dispatch(AuthActions.loadCurrentUserData());

      // we need to handle it in another place.

      // const returnUrl = EnvCookies.get('return-url');
      // if (returnUrl) {
      //   window.location.replace(returnUrl);
      //   return;
      // }

      const userTenants = getState().authSelectCompany.items;
      const selectedTenant = userTenants?.find((item) => item.id === tenantId);
      const destinationUrl = `${window.location.protocol}//${selectedTenant?.subdomain}.${getNonAuthorizedEnvHostname()}`;
      window.location.replace(destinationUrl);
    };
  }
}

export class Selectors {}

export const reducer = stateController.getReducer();
