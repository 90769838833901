import { UserService } from 'services/user.service';
import { PaginatedResponse } from 'types/common-types';
import { UserShortModel, User } from 'services/user.model';
import { PAGE_SIZE } from 'components/ui-new/dropdown-user-search-selector/dropdown-user-search-selector';

export const getUsersForWorkflow = async (
  users?: UserShortModel[],
  search?: string,
  isKeywordChanged?: boolean,
  getUsers?: (skip?: number, take?: number, search?: string) => Promise<PaginatedResponse<User[]>>,
) => {
  let isLast = false;
  const skip = isKeywordChanged ? 0 : users?.length || 0;
  let usersData: PaginatedResponse<User[]>;

  const take = PAGE_SIZE;

  if (getUsers) {
    usersData = await getUsers(skip, take, search);
  } else {
    usersData = await UserService.getAllUsers({ skip, take, search });
  }

  if (usersData.meta.currentPage === usersData.meta.lastPage) {
    isLast = true;
  }

  const usersFormatted: UserShortModel[] = usersData?.data.map((item) => ({
    id: item.id,
    last_name: item.last_name,
    first_name: item.first_name,
    position: item.positions?.[0],
    department: item.departments?.[0],
    avatar_image_url: item.avatar_image_url,
  }));

  return {
    usersData: usersFormatted || [],
    isLast,
  };
};
