import { TextFieldProps } from '@mui/material';
import { SearchIcon } from 'icons/search';
import { Input } from './input';

export type SearchInputProps = TextFieldProps;

export function SearchInput({ className, ...props }: SearchInputProps) {
  return (
    <Input
      className={className}
      InputProps={{
        startAdornment: <SearchIcon fontSize="small" style={{ fontSize: 18 }} />,
      }}
      {...props}
    />
  );
}
