import { QRCode } from 'react-qrcode-logo';
import Modal from 'components/ui-new/modal/modal';
import { AppState } from 'redux/store';
import { useDispatch, useSelector } from 'react-redux';
import ModalHeader from 'components/modal-header/modal-header';
import useDisableDocumentScroll from 'hooks/use-disable-document-scroll';
import { PrinterIcon } from 'icons/printer';
import Button from 'components/button/button';
import dayjs from 'dayjs';
import { QrLabelData } from 'modules/print-label-with-qr/types';
import qrLogo from './qr-logo.png';
import { Actions as PrintLabelActions, prepareAndPrintQrLabel } from './label-with-qr.controller';
import s from './label-with-qr.module.scss';

export const NODE_CLASS = 'print-qr-label';
export const NODE_CLASS_HIDDEN = 'qr-label-hidden';

export function PrintLabelWithQrModal() {
  const dispatch = useDispatch();
  const productionToPrint = useSelector((state: AppState) => state.print_label_with_qr.productionToPrint);
  const { isModalOpen } = useSelector((state: AppState) => state.print_label_with_qr);

  useDisableDocumentScroll(isModalOpen, 'printLabelWithQrModal');

  return (
    <Modal PaperProps={{ className: s.modal }} onClose={() => dispatch(PrintLabelActions.closeLabelModal())} open={isModalOpen}>
      <ModalHeader title="Production label" className={s.modal_header} isCenterModeWithoutTitle withBorderBottom>
        <Button
          variant="contained"
          size="L"
          startIcon={<PrinterIcon width={20} height={20} />}
          onClick={() => {
            if (productionToPrint) prepareAndPrintQrLabel(productionToPrint);
          }}
        >
          Print
        </Button>
      </ModalHeader>
      <LabelWithQrConnected isHidden={false} />
    </Modal>
  );
}

type Props = { isHidden?: boolean; data: QrLabelData };

function LabelWithQr(props: Props) {
  const { isHidden = true, data } = props;

  if (!data) return null;

  return (
    <div style={isHidden ? { zIndex: -1, position: 'fixed', pointerEvents: 'none', opacity: 0 } : {}}>
      <div className={`${s.container} ${isHidden ? NODE_CLASS_HIDDEN : NODE_CLASS}`}>
        <div className={s.header}>
          <div>
            Production key <span>{data.productionKey}</span>
          </div>
          <div>
            Deadline <span>{dayjs(data.deadline).format('D.MM.YYYY, H:mm')}</span>
          </div>
        </div>
        <div className={s.production_details}>
          <div className={s.product_name}>{data.productName}</div>
          <div className={s.configuration}>{data.configurationName}</div>
          <div className={s.variant}>{data.variantName}</div>
        </div>
        <div className={s.row}>
          <QRCode size={403} quietZone={5} qrStyle="dots" logoImage={qrLogo} logoPadding={14} eyeRadius={3} value={data.qrUrl} />
          <div className={s.col_right}>
            <div className={s.order_details_row}>
              <div>External order number</div>
              <div className={s.value}>{data.externalOrderNumber}</div>
            </div>
            <div className={s.order_details_row}>
              <div>Marketplace order number</div>
              <div className={s.value}>{data.marketplaceOrderNumber}</div>
            </div>
            <div className={s.order_details_row}>
              <div>Client</div>
              <div className={s.value}>{data.clientName}</div>
            </div>
            <div className={s.order_details_row}>
              <div>Primary client</div>
              <div className={s.value}>{data.primaryClientName}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export const LabelWithQrConnected = (props: { isHidden?: boolean }) => {
  const qrData = useSelector((state: AppState) => state.print_label_with_qr.data);

  if (Array.isArray(qrData)) {
    return (
      <>
        {qrData.map((item) => (
          <LabelWithQr key={item.qrUrl} data={item} />
        ))}
      </>
    );
  }

  return <LabelWithQr data={qrData} {...props} />;
};
