import { FC, SVGProps } from 'react';

export const OrderKeyIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_13421_126173)">
      <path
        d="M7.13408 1.66738L3.51557 3.03066C2.68164 3.34248 2 4.32869 2 5.21337V10.6013C2 11.4569 2.56562 12.5809 3.25451 13.0958L6.37267 15.4235C7.39513 16.1922 9.07749 16.1922 10.1 15.4235L13.2181 13.0958C13.907 12.5809 14.4726 11.4569 14.4726 10.6013V5.21337C14.4726 4.32143 13.791 3.33523 12.9571 3.02341L9.33854 1.66738C8.72216 1.44258 7.73596 1.44258 7.13408 1.66738Z"
        stroke="#141414"
        strokeWidth="1.1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.22899 9.11448C9.02997 9.11448 9.6793 8.46515 9.6793 7.66417C9.6793 6.86319 9.02997 6.21387 8.22899 6.21387C7.42801 6.21387 6.77869 6.86319 6.77869 7.66417C6.77869 8.46515 7.42801 9.11448 8.22899 9.11448Z"
        stroke="#141414"
        strokeWidth="1.1"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.229 9.1145V11.29"
        stroke="#141414"
        strokeWidth="1.1"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_13421_126173">
        <rect width="16" height="16" fill="white" transform="translate(0 0.5)" />
      </clipPath>
    </defs>
  </svg>
);
