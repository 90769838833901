import { FC, SVGProps } from 'react';

export const StatisticIcon: FC<SVGProps<SVGSVGElement>> = ({ ...props }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.9779 13.789C17.6526 13.789 18.2189 14.3459 18.1158 15.0122C17.5105 18.9322 14.1547 21.8427 10.1074 21.8427C5.62947 21.8427 2 18.2133 2 13.7364C2 10.048 4.80211 6.6122 7.95684 5.83536C8.63474 5.66799 9.32947 6.14484 9.32947 6.84273C9.32947 11.5712 9.48842 12.7943 10.3863 13.4596C11.2842 14.1248 12.34 13.789 16.9779 13.789Z"
      stroke="#141414"
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.3926 9.8516C21.4463 6.8137 17.7147 1.91686 13.1673 2.00107C12.8136 2.00739 12.5305 2.30212 12.5147 2.65476C12.4 5.15265 12.5547 8.38949 12.641 9.85686C12.6673 10.3137 13.0263 10.6727 13.4821 10.699C14.9905 10.7853 18.3452 10.9032 20.8073 10.5305C21.1421 10.48 21.3873 10.1895 21.3926 9.8516Z"
      stroke="#141414"
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
