import { StateController } from 'state-controller';
import { NODE_CLASS, NODE_CLASS_HIDDEN } from 'modules/print-label-with-qr/label-with-qr-modal';
import store, { AppState } from 'redux/store';
import { EnvCookies } from 'env-cookies';
import { getTenantHostname } from 'tenant-helpers';
import { ProductionForActionsT, QrLabelData } from 'modules/print-label-with-qr/types';
import { generateQrData, generateQrURL } from 'modules/print-label-with-qr/helpers';
import { notify } from 'notifications';
// eslint-disable-next-line import/no-extraneous-dependencies
import { toCanvas } from 'dom-to-image-more';
import { Stack, Typography } from '@mui/material';

export type LabelWithQrState = {
  isModalOpen: boolean;
  isHiddenMode: boolean;
  data: QrLabelData | QrLabelData[];
  readyForPrintingProductionsNumber: number;
  isOpenPreparingLabelsForPrintModal: boolean;
  productionToPrint: ProductionForActionsT | null;
};

const defaultState: LabelWithQrState = {
  data: [],
  isModalOpen: false,
  isHiddenMode: false,
  productionToPrint: null,
  readyForPrintingProductionsNumber: 0,
  isOpenPreparingLabelsForPrintModal: false,
};

const stateController = new StateController<LabelWithQrState>('PRINT_LABEL_WITH_QR', defaultState);

export class Actions {
  public static openLabelModal(production: ProductionForActionsT, isHiddenMode?: boolean) {
    return async (dispatch) => {
      await dispatch(Actions.setupLabelData(production));
      dispatch(stateController.setState({ isModalOpen: true, productionToPrint: production, isHiddenMode: !!isHiddenMode }));
    };
  }

  public static setReadyForPrintingProductionsNumber(value: number) {
    return async (dispatch) => {
      dispatch(stateController.setState({ readyForPrintingProductionsNumber: value }));
    };
  }

  public static setIsOpenPreparingLabelsForPrintModal(value: boolean) {
    return async (dispatch) => {
      dispatch(stateController.setState({ isOpenPreparingLabelsForPrintModal: value }));
    };
  }

  public static closeLabelModal() {
    return (dispatch) => {
      dispatch(stateController.setState({ isModalOpen: false }));
    };
  }

  public static setupLabelData(production: ProductionForActionsT | ProductionForActionsT[]) {
    return (dispatch, getState: () => AppState) => {
      const { userTenants } = getState().auth;
      const userTenantId = EnvCookies.get('tenant');

      const { protocol } = window.location;
      const hostname = userTenantId ? getTenantHostname(userTenantId, userTenants) : 'No tenant found';

      let data: QrLabelData | QrLabelData[];

      if (Array.isArray(production)) {
        data = production.map((item) => generateQrData(item, generateQrURL(protocol, hostname, item.id)));
      } else {
        data = generateQrData(production, generateQrURL(protocol, hostname, production.id));
      }

      dispatch(stateController.setState({ data }));
    };
  }

  public static disposeState() {
    return (dispatch) => {
      dispatch(stateController.setState(defaultState));
    };
  }
}

export async function prepareAndPrintQrLabel(production: ProductionForActionsT | ProductionForActionsT[]) {
  // !!!! DON'T REMOVE AWAIT
  await store.dispatch(Actions.setupLabelData(production));

  // setTimeout is needed to get the first element with logo in qr
  setTimeout(async () => {
    let elements = document.querySelectorAll<HTMLElement>(`[class*='${NODE_CLASS}']`);

    if (!elements.length) elements = document.querySelectorAll<HTMLElement>(`[class*='${NODE_CLASS_HIDDEN}']`);

    const canvas: HTMLCanvasElement[] = [];

    if (elements.length > 8) {
      store.dispatch(Actions.setIsOpenPreparingLabelsForPrintModal(true));
    }

    for (let i = 0; i < elements.length; i += 1) {
      // eslint-disable-next-line no-await-in-loop
      const convertedCanvas = await toCanvas(elements[i]);

      canvas.push(convertedCanvas);
      store.dispatch(Actions.setReadyForPrintingProductionsNumber(i + 1));
    }

    if (elements.length > 8) {
      store.dispatch(Actions.setIsOpenPreparingLabelsForPrintModal(false));
    }

    const newWindow = window.open('', 'Print QR label', 'width=1200, height=825');

    if (!newWindow) {
      setTimeout(() => store.dispatch(Actions.setReadyForPrintingProductionsNumber(0)), 100);
      notify.error(
        <Stack>
          <Typography fontWeight={500}>Browser Settings Update Required for Label Printing</Typography>
          <Typography>Please update your browser settings to allow pop-ups on this website.</Typography>
        </Stack>,
      );
      return false;
    }

    const afterPrintListener = () => {
      store.dispatch(Actions.setReadyForPrintingProductionsNumber(0));
      newWindow.removeEventListener('afterprint', afterPrintListener);
      newWindow.close();
      window.focus();
    };

    const styleElement = newWindow.document.createElement('style');
    styleElement.textContent = `
        @media print {
          @page {
            size: 1200px 825px;
            margin: 0 !important;
          }
          html, body {
            height: 100%; 
            margin: 0 !important; 
            padding: 0 5px !important;
          }
        }
        `;
    newWindow.document.head.appendChild(styleElement);

    canvas.forEach((item) => newWindow.document.body.appendChild(item));

    newWindow.addEventListener('afterprint', afterPrintListener);

    newWindow.focus();
    newWindow.print();

    return true;
  }, 30);
}

export class Selectors {}

export const reducer = stateController.getReducer();
