import { FC, SVGProps } from 'react';

export const ExpandIcon: FC<SVGProps<SVGSVGElement>> = ({ ...props }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_9692_219413)">
      <path d="M9 22.5H1.5V15" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M15 1.5H22.5V9" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </g>
    <defs>
      <clipPath id="clip0_9692_219413">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
