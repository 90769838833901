import { FC, SVGProps } from 'react';

export const InactivatedIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M10.5998 2.5415C12.8953 3.54447 14.4996 5.83506 14.4996 8.50033C14.4996 12.09 11.5896 15 7.9999 15C4.41024 15 1.50024 12.09 1.50024 8.50033C1.50024 5.83506 3.10448 3.54447 5.40004 2.5415"
      stroke={props.stroke || '#E55656'}
      strokeWidth="1.2"
      strokeLinecap="round"
    />
    <path d="M8 2V4.59986" stroke={props.stroke || '#E55656'} strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M1 2L15 14.5" stroke={props.stroke || '#E55656'} strokeWidth="1.2" strokeLinecap="round" />
  </svg>
);
