import { ProductDeleteReason, Reason } from 'services/products.model';
import s from './get-delete-product-messages.module.scss';

export function getDeleteProductMessages(deleteReasons: Reason[], onClick?: () => void) {
  const messages = deleteReasons.map((deleteReason) => {
    switch (deleteReason.reason) {
      case ProductDeleteReason.WasProduced:
        return (
          <span className={s.title} key={deleteReason.reason}>
            ‧ Product was produced and its SKU is used in warehouse
          </span>
        );
      case ProductDeleteReason.InUse:
        return (
          <span className={s.title} key={deleteReason.reason}>
            ‧ This product is used as a component in{' '}
            <span className={`${s.title} ${s.button}`} onClick={onClick}>
              {deleteReason.relatedWorkflowsCount} product workflows
            </span>
          </span>
        );
      case ProductDeleteReason.IsBeingEdited:
        return (
          <span className={s.title} key={deleteReason.reason}>
            Product or its nested entities (workflow and task templates) are edited by other users
          </span>
        );
      default:
        return null;
    }
  });

  return messages;
}
