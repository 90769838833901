import { FC, SVGProps } from 'react';

export const SandClockIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M9.82418 1H5.28818C2.65618 1 2.45318 3.366 3.87418 4.654L11.2382 11.346C12.6592 12.634 12.4562 15 9.82418 15H5.28818C2.65618 15 2.45318 12.634 3.87418 11.346L11.2382 4.654C12.6592 3.366 12.4562 1 9.82418 1Z"
      stroke="#141414"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
