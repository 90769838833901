import { FC, SVGProps } from 'react';

export const BarcodeIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect x="1.5" y="1" width="14" height="14" rx="2.5" stroke="#141414" />
    <path d="M4.75 11.7109V5.00041" stroke="#141414" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M7.25 10V5" stroke="#141414" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M9.75 11.7109V5.00041" stroke="#141414" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M12.25 10V5" stroke="#141414" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);
