import { FC, SVGProps } from 'react';

export const EstimatedTimeIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M14.5625 8.9375C14.5625 12.56 11.6225 15.5 8 15.5C4.3775 15.5 1.4375 12.56 1.4375 8.9375C1.4375 5.315 4.3775 2.375 8 2.375C11.6225 2.375 14.5625 5.315 14.5625 8.9375Z"
      stroke="#141414"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M8 5V8.75" stroke="#141414" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M5.75 0.5H10.25" stroke="#141414" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);
