import { FC, SVGProps } from 'react';

export const CategoriesIcon: FC<SVGProps<SVGSVGElement>> = ({ ...props }) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M15 7.3V11.5C15 14.3 14.3 15 11.5 15H4.5C1.7 15 1 14.3 1 11.5V4.5C1 1.7 1.7 1 4.5 1H5.55C6.6 1 6.831 1.308 7.23 1.84L8.28 3.24C8.546 3.59 8.7 3.8 9.4 3.8H11.5C14.3 3.8 15 4.5 15 7.3Z"
      stroke="#141414"
      strokeWidth="1.1"
      strokeMiterlimit="10"
    />
  </svg>
);
