import ReactGA from 'react-ga4';

type GoogleAnalyticsReturn = {
  registerGa4Event: (category: string, action: string, value?: number, label?: string) => void;
  initializeGoogleAnalytics: () => void;
  registerPageViewEvent: (page: string) => void;
};

const testMode = false;
const gaMeasurementId = process.env.REACT_APP_GA_MEASUREMENT_ID;

export const useGoogleAnalytics = (): GoogleAnalyticsReturn => {
  const initializeGoogleAnalytics = () => {
    if (gaMeasurementId) {
      ReactGA.initialize(gaMeasurementId, { testMode });
      ReactGA.set({ anonymizeIp: true });
    }
  };

  const registerGa4Event = (category: string, action: string, value?: number, label?: string) => {
    if (!gaMeasurementId) return;
    ReactGA.event({
      category,
      action,
      value,
      label,
    });
  };

  const registerPageViewEvent = (page: string) => {
    if (!gaMeasurementId) return;
    ReactGA.send({ hitType: 'pageview', page, title: window.location.hostname });
  };

  return { registerGa4Event, registerPageViewEvent, initializeGoogleAnalytics };
};
