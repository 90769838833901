import { FC, SVGProps } from 'react';

export const EditCalendarIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M5.3335 1.3335V3.3335"
      stroke="#141414"
      strokeWidth="1.1"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.6665 1.3335V3.3335"
      stroke="#141414"
      strokeWidth="1.1"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.3335 6.06006H13.6668"
      stroke="#141414"
      strokeWidth="1.1"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.8065 10.5134L10.4465 12.8734C10.3532 12.9667 10.2665 13.14 10.2465 13.2667L10.1199 14.1667C10.0732 14.4934 10.2999 14.72 10.6265 14.6734L11.5265 14.5467C11.6532 14.5267 11.8332 14.44 11.9199 14.3467L14.2799 11.9867C14.6865 11.58 14.8799 11.1067 14.2799 10.5067C13.6865 9.91337 13.2132 10.1067 12.8065 10.5134Z"
      stroke="#141414"
      strokeWidth="1.1"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.4663 10.8535C12.6663 11.5735 13.2263 12.1335 13.9463 12.3335"
      stroke="#141414"
      strokeWidth="1.1"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8 14.6668H5.33333C3 14.6668 2 13.3335 2 11.3335V5.66683C2 3.66683 3 2.3335 5.33333 2.3335H10.6667C13 2.3335 14 3.66683 14 5.66683V8.00016"
      stroke="#141414"
      strokeWidth="1.1"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M7.99715 9.13314H8.00314" stroke="#141414" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M5.5293 9.1333H5.53528" stroke="#141414" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M5.52938 11.1331H5.53537" stroke="#141414" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);
