const getFormattedSpentTime = (seconds: number, withSeconds?: boolean): string => {
  if (seconds === 0) {
    if (withSeconds) {
      return '0s';
    }
    return '0m';
  }

  const hours = Math.floor(seconds / 3600);
  const remainingMinutes = Math.floor((seconds % 3600) / 60);
  let remainingSeconds: number;

  const formattedHours = hours > 0 ? `${hours}h ` : '';
  const formattedMinutes = remainingMinutes > 0 ? `${remainingMinutes}m ` : '';
  let formattedSeconds: string = '';

  if (withSeconds) {
    remainingSeconds = seconds % 60;
    formattedSeconds = remainingSeconds > 0 ? ` ${remainingSeconds}s` : '';
  }

  return `${formattedHours} ${formattedMinutes}${formattedSeconds}`;
};

export default getFormattedSpentTime;
