import { AppState } from 'redux/store';
import { StateController } from 'state-controller';
import { IdName } from 'types/common-types';

export type ProductState = {
  productRoad: IdName[];
};

const defaultState: ProductState = {
  productRoad: [],
};

const stateController = new StateController<ProductState>('PRODUCT_FLOW_LAYOUT', defaultState);

export class Actions {
  public static setProductRoad(id: string, name: string) {
    return (dispatch) => {
      dispatch(stateController.setState((prev) => ({ ...prev, productRoad: [...prev.productRoad, { id, name }] })));
    };
  }

  public static saveProductRoad() {
    return (dispatch, getState: () => AppState) => {
      const productRoad = getState().product.product_flow_layout.productRoad[0];
      dispatch(stateController.setState((prev) => ({ ...prev, productRoad: [productRoad] })));
    };
  }

  public static saveProductWithConfigurationRoad() {
    return (dispatch, getState: () => AppState) => {
      const productWithConfigurationRoad = getState().product.product_flow_layout.productRoad.slice(0, 2);
      dispatch(stateController.setState((prev) => ({ ...prev, productRoad: productWithConfigurationRoad })));
    };
  }

  public static setDefaultProductRoad() {
    return (dispatch) => {
      dispatch(stateController.setState(defaultState));
    };
  }
}

export class Selectors {}

export const reducer = stateController.getReducer();
