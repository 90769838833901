/* eslint-disable react/jsx-no-useless-fragment */
import { AssignmentType } from 'services/workflow-task-template-responsibility.model';

const assignmentTypes = {
  [AssignmentType.Manual]: 'Manual',
  [AssignmentType.Auto]: 'Auto',
  [AssignmentType.Self_Assignment]: 'Self assignment',
};

const AssignmentTypeCell = ({ value }) => {
  return <>{value?.map((assignmentType) => assignmentTypes[assignmentType]).join(', ')}</>;
};

export default AssignmentTypeCell;
