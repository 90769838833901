import { Dialog, DialogProps } from '@mui/material';
import useDisableDocumentScroll from 'hooks/use-disable-document-scroll';
import s from './modal.module.scss';

export type Props = DialogProps & {};

export default function Modal({ children, ...rest }: Props) {
  useDisableDocumentScroll(rest.open, 'modal');

  return (
    <Dialog className={s.dialog} PaperProps={{ className: s.modal }} {...rest}>
      {children}
    </Dialog>
  );
}
