import { baseAxiosInstance } from 'axios-config';
import { PositionType } from 'services/position-types.model';
import { buildQueryString } from 'utils/build-query-string';

export class PositionTypesService {
  public static async getPositionTypes(search?: string, user_count?: boolean, department_id?: any) {
    const queryParams = buildQueryString({ search, user_count, department_id });
    const { data } = await baseAxiosInstance.get<PositionType[]>(`position-type/all?${queryParams}`);
    return data;
  }
}
