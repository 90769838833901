import { FC, SVGProps } from 'react';

export const ConfigurationIcon: FC<SVGProps<SVGSVGElement> & { title?: string }> = (props) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2 4.66669H4M4 4.66669C4 5.77126 4.89543 6.66669 6 6.66669C7.10457 6.66669 8 5.77126 8 4.66669C8 3.56212 7.10457 2.66669 6 2.66669C4.89543 2.66669 4 3.56212 4 4.66669ZM2 11.3334H6M12 11.3334H14M12 11.3334C12 12.4379 11.1046 13.3334 10 13.3334C8.89543 13.3334 8 12.4379 8 11.3334C8 10.2288 8.89543 9.33335 10 9.33335C11.1046 9.33335 12 10.2288 12 11.3334ZM10 4.66669H14"
      stroke="#C2C2C2"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <title>{props.title}</title>
  </svg>
);
