import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Paths, isNonAuthorizedPath } from 'routes/paths';

export const useCompanyRoutesRedirect = () => {
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      if (isNonAuthorizedPath()) {
        navigate(Paths.Home);
      }
    }, 100);
  }, []);
};
