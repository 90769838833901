export enum Permission {
  webAdmin = 'web_admin',
  webTaskView = 'web_task_view',
  webProductsView = 'web_products_view',
  webProductsEdit = 'web_products_edit',
  webAnalyticsView = 'web_analytics_view',
  webTaskEditLevel = 'web_task_edit_level',
  webUsersViewEdit = 'web_users_view_edit',
  webProductionView = 'web_production_view',
  webProductionEdit = 'web_production_edit',
  webSettingsViewEdit = 'web_settings_view_edit',
  webManageFailedTasks = 'web_manage_failed_tasks',
  webManageInQueueState = 'web_manage_in_queue_state',
  webManageTaskRewardsAfterReportingPeriod = 'manage_task_reward_editing',
  webDepartmentsViewEdit = 'web_departments_view_edit',
  mobileMyTasksViewEdit = 'mobile_my_tasks_view_edit',
  mobileManageFailedTasks = 'mobile_manage_failed_tasks',
  mobileChangeTasksPriority = 'mobile_change_tasks_priority',
  mobileManageTasksStatusesTimer = 'mobile_manage_tasks_statuses_timer',
  mobileViewOtherUsersTasksLevel = 'mobile_view_other_users_tasks_level',
  mobileEditOtherUsersTasksLevel = 'mobile_edit_other_users_tasks_level',
  mobileTasksStatusManagementLevel = 'mobile_tasks_status_management_level',
  mobileViewSalaryOfOtherUsersLevel = 'mobile_view_salary_of_other_users_level',
}

export enum AccessLevel {
  access = 'access',
  noAccess = 'no_access',
  userDepartment = 'user_department',
  userDepartmentSubDepartments = 'user_department_and_sub_departments',
  userTasks = 'user_tasks',
  allTasksAtLevel = 'all_tasks_at_level',
  allDepartments = 'all_departments',
  performer = 'performer',
  manager = 'manager',
  specificDepartments = 'specific_departments',
}

export type UserPermissions = {
  [Permission.webAdmin]?: AccessLevel.access | AccessLevel.noAccess;
  [Permission.webProductsView]?: AccessLevel.access | AccessLevel.noAccess;
  [Permission.webProductsEdit]?: AccessLevel.access | AccessLevel.noAccess;
  [Permission.webProductionView]?: AccessLevel.access | AccessLevel.noAccess;
  [Permission.webProductionEdit]?: AccessLevel.access | AccessLevel.noAccess;
  [Permission.webTaskView]?: AccessLevel.access | AccessLevel.noAccess;
  [Permission.webTaskEditLevel]?:
    | AccessLevel.noAccess
    | AccessLevel.userDepartment
    | AccessLevel.userDepartmentSubDepartments
    | AccessLevel.allDepartments
    | AccessLevel.specificDepartments;
  [Permission.webManageFailedTasks]?: AccessLevel.access | AccessLevel.noAccess;
  [Permission.webManageInQueueState]?: AccessLevel.access | AccessLevel.noAccess;
  [Permission.webManageTaskRewardsAfterReportingPeriod]?: AccessLevel.access | AccessLevel.noAccess;
  [Permission.webDepartmentsViewEdit]?: AccessLevel.access | AccessLevel.noAccess;
  [Permission.webUsersViewEdit]?: AccessLevel.access | AccessLevel.noAccess;
  [Permission.webSettingsViewEdit]?: AccessLevel.access | AccessLevel.noAccess;
  [Permission.webAnalyticsView]?: AccessLevel.access | AccessLevel.noAccess;
  [Permission.mobileMyTasksViewEdit]?: AccessLevel.access | AccessLevel.noAccess;
  [Permission.mobileViewOtherUsersTasksLevel]?:
    | AccessLevel.noAccess
    | AccessLevel.userDepartment
    | AccessLevel.userDepartmentSubDepartments
    | AccessLevel.allDepartments
    | AccessLevel.specificDepartments;
  [Permission.mobileChangeTasksPriority]?: AccessLevel.access | AccessLevel.noAccess;
  [Permission.mobileManageTasksStatusesTimer]?: AccessLevel.access | AccessLevel.noAccess;
  [Permission.mobileTasksStatusManagementLevel]?: AccessLevel.performer | AccessLevel.manager;
  [Permission.mobileEditOtherUsersTasksLevel]?:
    | AccessLevel.noAccess
    | AccessLevel.userDepartment
    | AccessLevel.userDepartmentSubDepartments
    | AccessLevel.allDepartments
    | AccessLevel.specificDepartments;
  [Permission.mobileViewSalaryOfOtherUsersLevel]?:
    | AccessLevel.noAccess
    | AccessLevel.userDepartment
    | AccessLevel.userDepartmentSubDepartments
    | AccessLevel.allDepartments;
  [Permission.mobileManageFailedTasks]?: AccessLevel.access | AccessLevel.noAccess;
};

export type UserPermissionsResponse = Array<{
  id: string;
  permission: Permission;
  value: AccessLevel;
}>;

export type UpdateUserPermissionRequest = {
  user_id: string;
  permission: Permission;
  value: AccessLevel;
};
