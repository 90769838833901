import { Dispatch, SetStateAction, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { AppState } from 'redux/store';
import { TabDownIcon } from 'icons/tab-down';
import { useNavigate } from 'react-router-dom';
import { AvatarCircle } from 'components/ui-new/avatar-circle/avatar-circle';
import BurgerMenu from 'icons/burger-menu';
import Button from 'components/button/button';
import { LogoBlueIcon } from 'icons/logo-blue';
import { HEADER_HEIGHT } from 'modules/authorized-layout/authorize-layout';
import { GetAppModal } from 'modules/authorized-layout/components/header/components/get-app-modal.tsx/get-app-modal';
import { MobileApp } from 'icons/mobile-app';
import UserMenu from './components/user-menu/user-menu';
import s from './header.module.scss';
import { Actions as AppLabelQrActions } from './components/get-app-modal.tsx/get-app-modal.controller';

type OwnProps = {
  isMobileMenuOpened: boolean;
  setIsMobileMenuOpened: Dispatch<SetStateAction<boolean>>;
};
type DispatchProps = {
  openQrLabelModal: () => void;
};
type Props = OwnProps & DispatchProps;

const Header = ({ isMobileMenuOpened, setIsMobileMenuOpened, openQrLabelModal }: Props) => {
  const navigate = useNavigate();
  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
  const user = useSelector((state: AppState) => state.auth.user);
  const toggleMobileMenuVisibility = () => setIsMobileMenuOpened((prev) => !prev);

  return (
    <header className={s.header} id="main-header" style={{ height: HEADER_HEIGHT }}>
      <button className={s.burger_menu} type="button" data-is-active={isMobileMenuOpened} onClick={toggleMobileMenuVisibility}>
        <BurgerMenu />
      </button>
      <div className={s.logo_wrapper} onClick={() => navigate('/')}>
        <LogoBlueIcon height={22} width={72} />
      </div>
      <div className={s.right_col}>
        {user && (
          <div className={s.user_container} onClick={() => setIsUserMenuOpen((prev) => !prev)}>
            <div className={s.user}>
              <AvatarCircle
                className=""
                withHover={false}
                style={{ width: 35, height: 35 }}
                isEmpty={!!user?.avatar_image_url}
                firstName={user?.first_name || ''}
                lastName={user?.last_name || ''}
                src={user?.avatar_image_url || ''}
              />

              <div className={s.user_info}>
                <span className={s.user_name}>{`${user?.first_name} ${user?.last_name}`}</span>
              </div>
            </div>
            <div className={s.chevron_icon_container} data-is-menu-open={isUserMenuOpen}>
              <TabDownIcon height="20px" width="20px" fill="#878B92" />
            </div>
          </div>
        )}
        <Button size="S" variant="text" startIcon={<MobileApp />} className={s.mobile_qr} onClick={openQrLabelModal} />
      </div>
      <GetAppModal />
      <UserMenu user={user} isMenuOpen={isUserMenuOpen} onBackdropClick={setIsUserMenuOpen} />
    </header>
  );
};

const mapDispatchToProps: DispatchProps = {
  openQrLabelModal: AppLabelQrActions.openLabelModal,
};

export default connect(null, mapDispatchToProps)(Header);
