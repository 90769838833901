import { FC, CSSProperties, useState, useEffect } from 'react';
import InputButtons from 'components/input-buttons/input-buttons';
import { TextField, TextFieldProps, Tooltip } from '@mui/material';
import MaxLettersWarning from 'components/max-letters-warning/max-letters-warning';
import s from './input-with-buttons.module.scss';

type OwnProps = {
  value: string;
  className?: string;
  allowedEmpty?: boolean;
  maxLettersWarning?: number;
  isConfirmDisabled?: boolean;
  onCancelClick?: () => void;
  onConfirmClick: (value: string) => void;
  valueFormatter?: (newValue: string) => string;
} & TextFieldProps;

const InputWithButtons: FC<OwnProps> = ({
  value,
  className,
  allowedEmpty,
  isConfirmDisabled,
  maxLettersWarning = 255,
  onCancelClick,
  onConfirmClick,
  valueFormatter,
  ...props
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [initialValue, setInitialValue] = useState('');
  const [currentValue, setCurrentValue] = useState('');

  const hiddenStyles: CSSProperties = { opacity: 0, pointerEvents: 'none', transform: 'translateY(20px)' };
  const visibleStyles: CSSProperties = { opacity: 1, pointerEvents: 'all', transform: 'translateY(30px)' };

  const isDisabled = isConfirmDisabled || (!allowedEmpty && currentValue.length === 0) || initialValue === currentValue;

  const handleOnFocus = () => {
    setIsEditMode(true);
    setInitialValue(value);
  };

  const handleOnBlur = () => {
    if (!isLoading) {
      setIsEditMode(false);
      setCurrentValue(initialValue);
    }
  };

  const handleConfirm = async () => {
    try {
      setIsLoading(true);
      await onConfirmClick(currentValue.trim());
      setInitialValue(currentValue);
    } catch (err) {
      setCurrentValue(initialValue);
    } finally {
      setIsLoading(false);
      setIsEditMode(false);
    }
  };

  const onChangeValue = (newValue: string) => {
    if (newValue.length > maxLettersWarning) {
      return;
    }
    if (valueFormatter) {
      setCurrentValue(valueFormatter(newValue));
    } else {
      setCurrentValue(newValue);
    }
  };

  const handleKeyDown = async (event) => {
    if (event.code === 'Escape') {
      event.target.blur();
      handleOnBlur();
    }
    if (event.code === 'Enter' && !isDisabled) {
      await handleConfirm();
      event.target.blur();
    }
  };

  useEffect(() => {
    setCurrentValue(value);
  }, [value]);

  return (
    <div className={s.container}>
      <Tooltip
        placement="top-start"
        title={currentValue.length >= maxLettersWarning ? <MaxLettersWarning max={maxLettersWarning} /> : ''}
        open
      >
        <div>
          <TextField
            value={currentValue}
            onBlur={handleOnBlur}
            onFocus={handleOnFocus}
            classes={{ root: s.text_field }}
            onKeyDown={(event) => handleKeyDown(event)}
            className={className && className}
            onChange={(event) => onChangeValue(event.currentTarget.value)}
            {...props}
          />
          <div className={s.buttons} style={{ ...(isEditMode ? visibleStyles : hiddenStyles) }}>
            <InputButtons
              isLoading={isLoading}
              isConfirmDisabled={isDisabled}
              onConfirmMouseDown={handleConfirm}
              onCancelMouseDown={() => {
                if (onCancelClick) onCancelClick();
              }}
            />
          </div>
        </div>
      </Tooltip>
    </div>
  );
};

export default InputWithButtons;
