import { Tooltip } from '@mui/material';
import { useEffect, useRef, useState, ReactNode } from 'react';
import s from './text-ellipsis.module.scss';

type Props = React.HTMLAttributes<HTMLDivElement> & {
  maxWidth?: string;
  children: ReactNode;
  tooltipTitle?: unknown;
  [key: string]: any;
};

export const TextEllipsis = ({ children, tooltipTitle = '', maxWidth = '100%', ...rest }: Props) => {
  const divRef = useRef<HTMLDivElement>(null);
  const [isShowValueTooltip, setIsShowValueTooltip] = useState(false);

  useEffect(() => {
    const currentScrollWidth = divRef?.current?.scrollWidth || 0;
    const currentClientWidth = divRef?.current?.clientWidth || 0;

    const checkScrollWidth = () => {
      if (currentScrollWidth > currentClientWidth) {
        setIsShowValueTooltip(true);
      } else {
        setIsShowValueTooltip(false);
      }
    };
    checkScrollWidth();

    window.addEventListener('resize', checkScrollWidth);
    return () => {
      window.removeEventListener('resize', checkScrollWidth);
    };
  }, [maxWidth, tooltipTitle, divRef?.current?.scrollWidth, divRef?.current?.clientWidth]);

  return (
    <Tooltip
      placement="top"
      leaveDelay={0}
      PopperProps={{
        sx: {
          '&[data-popper-reference-hidden]': {
            visibility: 'hidden',
            pointerEvents: 'none',
          },
        },
      }}
      classes={{ popper: s.tooltip }}
      title={isShowValueTooltip ? tooltipTitle : ''}
    >
      <div
        ref={divRef}
        style={{
          maxWidth,
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
        {...rest}
      >
        {children}
      </div>
    </Tooltip>
  );
};
