import { TextEllipsis } from '../text-ellipsis/text-ellipsis';
import s from './highlight-text.module.scss';

type Props = {
  text: string;
  query: string;
  maxWidth?: string;
};

const HighlightText = ({ text, query, maxWidth = '100%' }: Props) => {
  const index = text.toLowerCase().indexOf(query.toLowerCase());
  if (index === -1) return <TextEllipsis maxWidth={maxWidth}>{text}</TextEllipsis>;

  return (
    <TextEllipsis tooltipTitle={text} maxWidth={maxWidth}>
      {text.substring(0, index)}
      <span className={s.text_highlight}>{text.substring(index, index + query.length)}</span>
      {text.substring(index + query.length)}
    </TextEllipsis>
  );
};

export default HighlightText;
