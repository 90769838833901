import { baseAxiosInstance } from 'axios-config';
import { SettingsResponseModel } from 'services/settings.model';

export class SettingsService {
  public static async getSettings() {
    const { data } = await baseAxiosInstance.get<SettingsResponseModel>('/settings');
    return data;
  }

  public static async updateSettings(requestBody: FormData) {
    const { data } = await baseAxiosInstance.put<SettingsResponseModel>(`/settings`, requestBody);
    return data;
  }

  public static async removeLogo() {
    const { data } = await baseAxiosInstance.delete(`/settings`);
    return data;
  }
}
