import { CircularProgress, CircularProgressProps } from '@mui/material';
import s from './spinner.module.scss';

type Props = CircularProgressProps & {
  containerClassName?: string;
};

const Spinner = ({ containerClassName, ...rest }: Props) => {
  return (
    <div className={`${s.container} ${containerClassName}`}>
      <CircularProgress size={40} {...rest} />
    </div>
  );
};

export default Spinner;
