import Input from 'pages/auth/components/input/input';
import CheckBox from 'pages/auth/components/create-new-password/components/checkbox/checkbox';
import { rules } from 'utils/validator';
import { SignInState, ValidationCriteria } from 'pages/auth/auth.controller';
import { ChangeUserPasswordState } from 'modules/change-user-password/change-user-password.controller';
import s from './create-new-password-with-validation.module.scss';
import shared_s from '../../pages/auth/shared-styles.module.scss';

type OwnProps = {
  newPassword: string;
  confirmPassword: string;
  validationCriteria: ValidationCriteria;
  onChange: (values: Partial<SignInState | ChangeUserPasswordState>) => void;
};

const criteriaLabels = {
  length: 'Minimum 8 characters',
  specialChar: 'One special character',
  uppercaseLowercase: 'One uppercase and lowercase character',
  number: 'One number',
  isPasswordMatch: 'Passwords do match',
};

const criteriaList = Object.keys(criteriaLabels);

export default function CreateNewPasswordWithValidation({
  newPassword,
  confirmPassword,
  validationCriteria,
  onChange,
}: OwnProps) {
  const isPasswordInvalid =
    (newPassword !== '' || confirmPassword !== '') && Object.values(validationCriteria).some((criteria) => !criteria);
  return (
    <div className={s.body_container}>
      <div className={shared_s.fields}>
        <Input
          type="password"
          value={newPassword}
          onChange={(value) =>
            onChange({
              newPassword: value,
              validationCriteria: rules.isValidPassword(value),
            })
          }
          placeHolder="Enter new password"
          label="New password"
          error={isPasswordInvalid}
        />
        <Input
          type="password"
          value={confirmPassword}
          onChange={(value) => onChange({ confirmPassword: value })}
          placeHolder="Enter the password again"
          label="Confirm new password"
          error={isPasswordInvalid}
        />
      </div>
      <div className={s.checkboxes}>
        {criteriaList.map((labelName) => (
          <CheckBox
            key={labelName}
            title={criteriaLabels[labelName]}
            status={newPassword === '' && confirmPassword === '' ? 'empty' : validationCriteria[labelName]}
          />
        ))}
      </div>
    </div>
  );
}
