import { FC, SVGProps } from 'react';

export const TabLeftIcon: FC<SVGProps<SVGSVGElement>> = ({ ...props }) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M12.1428 15.2857L6.99997 10.1429L12.1428 5"
      stroke="#91979F"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
