import { FC, SVGProps } from 'react';

export const SizeIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M3.06739 15.3161L8.17455 15.3161C8.32123 15.3161 8.46143 15.2557 8.56224 15.1492L15.1501 8.18595C15.9446 7.34623 15.9263 6.0267 15.1089 5.2093L12.0248 2.12526C11.2005 1.30092 9.86728 1.29034 9.02996 2.10149L2.16235 8.75449C2.05858 8.85502 2 8.99333 2 9.13781L2 14.2487C2 14.8382 2.47789 15.3161 3.06739 15.3161Z"
      stroke="#91979F"
      strokeWidth="1.3"
    />
    <path
      d="M6.80324 11.8471C6.80324 12.584 6.20588 13.1813 5.46901 13.1813C4.73213 13.1813 4.13477 12.584 4.13477 11.8471C4.13477 11.1102 4.73213 10.5129 5.46901 10.5129C6.20588 10.5129 6.80324 11.1102 6.80324 11.8471Z"
      stroke="#91979F"
      strokeWidth="1.3"
    />
  </svg>
);
