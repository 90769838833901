import { FC, SVGProps } from 'react';

export const PriorityLow2Icon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_5823_31866)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9997 11.8337C9.42239 11.8337 7.33305 9.74432 7.33305 7.16699C7.33305 3.85328 4.64676 1.16699 1.33306 1.16699L0.666108 1.16699C0.297917 1.16699 -0.00055885 1.46547 -0.000558845 1.83366C-0.000558841 2.20185 0.297918 2.50033 0.666108 2.50033L1.33306 2.50033C3.91038 2.50033 5.99972 4.58966 5.99972 7.16699C5.99972 10.4807 8.68601 13.167 11.9997 13.167L13.7239 13.167L12.1953 14.6956C11.9349 14.9559 11.9349 15.378 12.1953 15.6384C12.4556 15.8987 12.8777 15.8987 13.1381 15.6384L15.8047 12.9717C15.9298 12.8467 16 12.6771 16 12.5003C16 12.3235 15.9298 12.1539 15.8047 12.0289L13.1381 9.36225C12.8777 9.1019 12.4556 9.1019 12.1953 9.36225C11.9349 9.6226 11.9349 10.0447 12.1953 10.3051L13.7239 11.8337L11.9997 11.8337Z"
        fill="#B0BEC5"
      />
    </g>
    <defs>
      <clipPath id="clip0_5823_31866">
        <rect width="16" height="16" fill="white" transform="translate(0 0.5)" />
      </clipPath>
    </defs>
  </svg>
);
