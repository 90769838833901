// import { useIsPermitted } from 'hooks/use-is-permitted'; // TODO: CHANGE AFTER PERMISSION DONE
import { PropsWithChildren } from 'react';
import { useSelector } from 'react-redux';
import PagePlug from 'pages/page-plug/page-plug';
import { AccessLevel, Permission } from 'services/permission.model';
import { PermissionGuardSelectors } from 'modules/permission-guard/permission-guard.controller';
import { AppState } from 'redux/store';

type OwnProps = PropsWithChildren<{
  permission: Permission;
  accessLevel: AccessLevel[];
}>;

export function ViewPermissionGuard({ permission, accessLevel, children }: OwnProps) {
  const isPermitted = useSelector((state: AppState) =>
    PermissionGuardSelectors.selectIsPermitted(state, permission, accessLevel),
  );
  if (isPermitted === null) return null;

  if (!isPermitted) {
    return (
      <PagePlug
        title="Access is denied"
        subtitle="You don`t have permission to access this page. Please contact your HESH administrator."
      />
    );
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
}
