import { FC, SVGProps } from 'react';

export const OpenIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M6 9.83398L7.16855 10.7688C7.44713 10.9917 7.8518 10.9557 8.08673 10.6872L10 8.50065M10.6667 3.16732H11.3333C12.8061 3.16732 14 4.36122 14 5.83398V12.5007C14 13.9734 12.8061 15.1673 11.3333 15.1673H4.66667C3.19391 15.1673 2 13.9734 2 12.5007V5.83398C2 4.36122 3.19391 3.16732 4.66667 3.16732H5.33333M10.6667 3.16732C10.6667 3.9037 10.0697 4.50065 9.33333 4.50065H6.66667C5.93029 4.50065 5.33333 3.9037 5.33333 3.16732M10.6667 3.16732C10.6667 2.43094 10.0697 1.83398 9.33333 1.83398H6.66667C5.93029 1.83398 5.33333 2.43094 5.33333 3.16732"
      stroke="#141414"
      strokeLinecap="round"
    />
  </svg>
);
