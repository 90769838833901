import { baseAxiosInstance } from 'axios-config';
import { WorkflowEdge, CreateWorkflowEdgeData, UpdateWorkflowEdgeData } from 'services/workflow-edges.model';

export class WorkflowEdgesService {
  public static async getAllWorkflowEdges() {
    const { data } = await baseAxiosInstance.get<WorkflowEdge[]>('/workflow-edges/all');

    return data;
  }

  public static async getWorkflowEdgeById(id: string) {
    const { data } = await baseAxiosInstance.get<WorkflowEdge>(`/workflow-edges/${id}`);

    return data;
  }

  public static async create(body: CreateWorkflowEdgeData) {
    const { data } = await baseAxiosInstance.post<WorkflowEdge>('/workflow-edges/create', body);

    return data;
  }

  public static async update(id: string, body: UpdateWorkflowEdgeData) {
    const { data } = await baseAxiosInstance.put<WorkflowEdge>(`/workflow-edges/update/${id}`, body);

    return data;
  }

  public static async delete(id: string) {
    const { data } = await baseAxiosInstance.delete<WorkflowEdge>(`/workflow-edges/${id}`);

    return data;
  }
}
