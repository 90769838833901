import { FC, SVGProps } from 'react';

const ExpandIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M21.9118 9.09839C22.202 9.09839 22.4376 8.86283 22.4376 8.57259V2.52581C22.4376 2.23556 22.202 2 21.9118 2H15.865C15.5747 2 15.3392 2.23556 15.3392 2.52581C15.3392 2.81605 15.5747 3.05161 15.865 3.05161H20.6333L13.6989 9.98596C13.4936 10.1913 13.4936 10.5241 13.6989 10.7297C13.8014 10.8322 13.9361 10.8835 14.0707 10.8835C14.2053 10.8835 14.3399 10.8322 14.4424 10.7297L21.386 3.78617V8.57259C21.386 8.86283 21.6215 9.09839 21.9118 9.09839Z"
      fill="#878B92"
      stroke="#878B92"
      strokeWidth="0.7"
    />
    <path
      d="M11.3472 13.5117C11.1421 13.3063 10.8087 13.3063 10.6037 13.5117L3.61411 20.5012V15.7237C3.61411 15.4335 3.37855 15.1979 3.08831 15.1979C2.79806 15.1979 2.5625 15.4335 2.5625 15.7237V21.7705C2.5625 22.0608 2.79806 22.2963 3.08831 22.2963H9.13509C9.42533 22.2963 9.66089 22.0608 9.66089 21.7705C9.66089 21.4803 9.42533 21.2447 9.13509 21.2447H4.35787L11.3472 14.2552C11.5525 14.0498 11.5525 13.717 11.3472 13.5117Z"
      fill="#878B92"
      stroke="#878B92"
      strokeWidth="0.7"
    />
  </svg>
);
export default ExpandIcon;
