import { baseAxiosInstance } from '../axios-config';
import { Tenant } from './tenant.model';

export class TenantService {
  public static async getUserTenants(): Promise<Tenant[]> {
    const { data } = await baseAxiosInstance.get(`/tenants/me`);
    return data;
  }

  public static async getTenantsByUser(userId: string): Promise<Tenant[]> {
    const { data } = await baseAxiosInstance.get(`/tenants/by-user/${userId}`);
    return data;
  }
}
