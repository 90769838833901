import { StateController } from 'state-controller';

export type TasksLaunchingProgressStateType = {
  isShow: boolean;
  assignUsersTasksCount: number;
};

const defaultState: TasksLaunchingProgressStateType = {
  isShow: false,
  assignUsersTasksCount: 0,
};

const stateController = new StateController<TasksLaunchingProgressStateType>('TASK_ASSIGN_USERS_PROGRESS', defaultState);

export class TasksLaunchingProgressActions {
  static showModalAndSetAssignUsersTasksCount(assignUsersTasksCount: number) {
    return (dispatch) => {
      dispatch(stateController.setState({ isShow: true, assignUsersTasksCount }));
    };
  }

  static setTasksAssignUsersCount = (value: number) => {
    return (dispatch) => {
      dispatch(stateController.setState({ assignUsersTasksCount: value }));
    };
  };

  static hideModal() {
    return (dispatch) => {
      dispatch(stateController.setState(defaultState));
    };
  }
}

export const TasksAssignUsersProgressReducer = stateController.getReducer();
