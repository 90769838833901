export const abbreviationFormatter = (newValue: string) => {
  const onlyLetters = newValue.replace(/[^a-zA-Zа-яА-Я]/g, '').slice(0, 2);
  return onlyLetters.toUpperCase();
};

export const currencyFormatter = (newValue: string) => {
  return newValue.slice(0, 5);
};

export const onlyNumbersFormatter = (newValue: string) => {
  const onlyNumbers = newValue.replace(/\D/g, '');
  let result: string;
  if (onlyNumbers.length > 1 && onlyNumbers.charAt(0) === '0') {
    result = newValue.slice(1);
  } else {
    result = onlyNumbers;
  }
  return result;
};

export const reportingPeriodFormatter = (newValue: string) => {
  let result = onlyNumbersFormatter(newValue);

  const numericValue = Number(result);
  if (numericValue > 20) {
    result = '20';
  }

  return result;
};
