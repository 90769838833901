import { DetailedHTMLProps, HTMLAttributes, PropsWithChildren } from 'react';
import s from './width-container.module.scss';

type Props = PropsWithChildren<DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>>;

export default function WidthContainer({ children, className, ...rest }: Props) {
  return (
    <div className={`${className} ${s.width_container}`} {...rest}>
      {children}
    </div>
  );
}
