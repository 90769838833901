import { connect } from 'react-redux';
import { AppState } from 'redux/store';
import { Link } from 'react-router-dom';
import { useKeyPress } from 'ahooks';
import { Actions as SignInActions, SignInState } from 'pages/auth/auth.controller';
import Input from 'pages/auth/components/input/input';
import { Paths } from 'routes/paths';
import { rules } from 'utils/validator';
import Button from 'components/button/button';
import s from './sign-in.module.scss';
import shared_s from '../../shared-styles.module.scss';

type StateProps = {
  email: string;
  password: string;
  isSignInProcessing: boolean;
};
type DispatchProps = {
  handleClickSignIn: () => void;
  onChange: (values: Partial<SignInState>) => void;
};
type Props = StateProps & DispatchProps;

const LogInForm = ({ email, password, isSignInProcessing, onChange, handleClickSignIn }: Props) => {
  useKeyPress('enter', handleClickSignIn);

  const isEnableSubmit = rules.isEmail(email) && password !== '';

  return (
    <form className={shared_s.form} onSubmit={(e) => e.preventDefault()}>
      <div className={shared_s.intro}>
        <h3>Log in</h3>
        <p>Log in to your HESH account!</p>
      </div>
      <div className={shared_s.fields}>
        <Input
          type="email"
          value={email}
          onChange={(value) => onChange({ email: value })}
          placeHolder="Enter email"
          label="Email"
        />
        <div className={s.password_wrapper}>
          <Input
            type="password"
            value={password}
            onChange={(value) => onChange({ password: value })}
            placeHolder="Enter password"
            label="Password"
          />
          <Link to={Paths.ForgotPassword} className={s.forgot_password}>
            Forgot password?
          </Link>
        </div>
      </div>
      <Button
        type="submit"
        variant="contained"
        color="primary"
        loading={isSignInProcessing}
        disabled={!isEnableSubmit}
        className={shared_s.button}
        onClick={handleClickSignIn}
      >
        Log in
      </Button>
      <p className={shared_s.sign_up_log_in}>
        Don&apos;t have an account yet? <Link to={Paths.SignUp}>Sign up</Link>
      </p>
    </form>
  );
};

const mapStateToProps = (state: AppState): StateProps => ({
  email: state.authentication.email,
  isSignInProcessing: state.authentication.isSignInProcessing,
  password: state.authentication.password,
});
const mapDispatchToProps: DispatchProps = {
  onChange: SignInActions.onChange,
  handleClickSignIn: SignInActions.handleClickSignIn,
};

export default connect(mapStateToProps, mapDispatchToProps)(LogInForm);
