import { FC, SVGProps } from 'react';

export const CopyIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M5.33398 5.33301V3.99967C5.33398 2.52692 6.52789 1.33301 8.00065 1.33301L12.0007 1.33301C13.4734 1.33301 14.6673 2.52692 14.6673 3.99967V7.99967C14.6673 9.47243 13.4734 10.6663 12.0007 10.6663H10.6673M5.33398 5.33301H4.00065C2.52789 5.33301 1.33398 6.52692 1.33398 7.99967V11.9997C1.33398 13.4724 2.52789 14.6663 4.00065 14.6663H8.00065C9.47341 14.6663 10.6673 13.4724 10.6673 11.9997V10.6663M5.33398 5.33301H8.00065C9.47341 5.33301 10.6673 6.52692 10.6673 7.99967V10.6663"
      stroke="black"
      strokeWidth="1.3"
      strokeLinejoin="round"
    />
  </svg>
);
