import Modal from 'components/ui-new/modal/modal';
import { connect } from 'react-redux';
import { AppState } from 'redux/store';
import { Actions as ManageFailedTasksActions, Nodes } from 'modules/manage-failed-tasks-modal/manage-failed-tasks.controller';
import ModalHeader from 'components/modal-header/modal-header';
import Subtitle from 'components/subtitle/subtitle';
import Button from 'components/button/button';
import ModalFooter from 'components/modal-footer/modal-footer';
import ProductionReactFlow from 'components/ui-new/production-react-flow/produciotn-react-flow';
import { Edge, ReactFlowProvider } from 'reactflow';
import Spinner from 'components/spinner/spinner';
import s from './manage-failed-tasks-modal.module.scss';

type StateProps = {
  nodes: Nodes;
  edges: Edge[];
  isOpen: boolean;
  isLoading: boolean;
};
type DispatchProps = {
  closeModal: VoidFunction;
  openApplyChangesConfirmationModal: VoidFunction;
};
type Props = StateProps & DispatchProps;

const ManageFailedTasks = ({ nodes, edges, isOpen, isLoading, closeModal, openApplyChangesConfirmationModal }: Props) => {
  const isEnableSave = nodes?.some((node) => node.type === 'task' && node.data.isFail);

  return (
    <ReactFlowProvider>
      <Modal open={isOpen} onClose={closeModal} PaperProps={{ className: s.modal }}>
        <ModalHeader size="newLarge" onClose={closeModal}>
          <Subtitle fontSize="large" text="Manage failed tasks" />
        </ModalHeader>
        <div className={s.body}>
          <ProductionReactFlow nodes={nodes} edges={edges} isLoading={isLoading} />
          {isLoading && (
            <div className={s.spinner_container}>
              Loading
              <Spinner containerClassName={s.spinner} disableShrink size={70} />
            </div>
          )}
        </div>
        <ModalFooter size="newLarge" className={s.footer}>
          <Button
            size="L"
            color="primary"
            variant="contained"
            disabled={!isEnableSave}
            onClick={openApplyChangesConfirmationModal}
          >
            Save
          </Button>
        </ModalFooter>
      </Modal>
    </ReactFlowProvider>
  );
};

const mapStateToProps = (state: AppState): StateProps => ({
  nodes: state.manage_failed_tasks.nodes,
  edges: state.manage_failed_tasks.edges,
  isOpen: state.manage_failed_tasks.isModalOpen,
  isLoading: state.manage_failed_tasks.isLoading,
});
const mapDispatchToProps: DispatchProps = {
  closeModal: ManageFailedTasksActions.closeManageFailedTasksModal,
  openApplyChangesConfirmationModal: ManageFailedTasksActions.openApplyChangesConfirmationModal,
};
export default connect(mapStateToProps, mapDispatchToProps)(ManageFailedTasks);
