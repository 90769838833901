import { FC, SVGProps } from 'react';

export const ViewsIcon: FC<SVGProps<SVGSVGElement>> = ({ ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
    <path
      d="M13.5301 8.55005H2.47001C1.42 8.55005 1 8.99805 1 10.1111V12.9391C1 14.0521 1.42 14.5001 2.47001 14.5001H13.5301C14.5801 14.5001 15.0001 14.0521 15.0001 12.9391V10.1111C15.0001 8.99805 14.5801 8.55005 13.5301 8.55005Z"
      stroke="#141414"
      strokeWidth="1.1"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.5301 0.5H2.47001C1.42 0.5 1 0.948005 1 2.06102V4.88904C1 6.00206 1.42 6.45006 2.47001 6.45006H13.5301C14.5801 6.45006 15.0001 6.00206 15.0001 4.88904V2.06102C15.0001 0.948005 14.5801 0.5 13.5301 0.5Z"
      stroke="#141414"
      strokeWidth="1.1"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
