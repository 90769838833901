import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { Paths } from 'routes/paths';
import { AuthService } from 'services/auth.service';

export const useLogIn = () => {
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [param] = useSearchParams();

  const validateToken = useCallback(async () => {
    try {
      setIsLoading(true);
      await AuthService.validateResetPassword({ token: param.get('token') || '' });
    } catch (error) {
      navigate(Paths.InvalidLink);
    } finally {
      setTimeout(() => setIsLoading(false), 20);
    }
  }, [navigate, param]);

  useEffect(() => {
    if (location.pathname === Paths.CreatePassword) {
      validateToken();
    }
  }, [location.pathname, validateToken]);

  return { location, isLoading };
};
