import { FC, SVGProps } from 'react';

export const TypeIcon: FC<SVGProps<SVGSVGElement> & { title?: string }> = (props) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect x="1.25" y="1.5" width="13.5" height="13.5" rx="2.5" stroke="#141414" />
    <path d="M3.80273 11.6848H7.63757" stroke="#141414" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M5.6001 1.5H10.4001V5.35C10.4001 5.47426 10.2994 5.575 10.1751 5.575H5.8251C5.70083 5.575 5.6001 5.47426 5.6001 5.35V1.5Z"
      stroke="#141414"
    />
    <title>{props.title}</title>
  </svg>
);
