import { router } from 'index';
import { notify } from 'notifications';
import { AppState } from 'redux/store';
import { Paths } from 'routes/paths';
import { IdName, PaginationData } from 'types/common-types';
import { Actions as DepartmentsActions } from 'pages/departments/departments.controller';
import { PositionSlotByDepartmentId as PositionItem } from 'services/position-slots.model';
import { PositionTypesService } from 'services/position-types.service';
import { PositionSlotsService } from 'services/position-slots.service';
import { UserService } from 'services/user.service';
import { StateController } from 'state-controller';
import { noUser } from 'components/add-position-modal/constants/no-user';
import { debounce } from 'utils/debounce';

export enum Page {
  Departments = 'departments',
}

export enum Mode {
  Edit = 'edit',
  Add = 'add',
}

export const TAKE_USERS = 25;

export type InputItem = {
  value: IdName;
  options: IdName[];
  isDisabled: boolean;
};

export type EmployeeItem = {
  id: string;
  name: string;
  avatar: string;
  lastName: string;
  firstName: string;
};

export type EmployeeInputItem = {
  value: EmployeeItem;
  options: EmployeeItem[];
  isLoading: boolean;
};

export type AddPositionModalState = {
  mode: Mode;
  positionId: string;
  isLoading: boolean;
  jobTitle: InputItem;
  isModalOpen: boolean;
  isProcessing: boolean;
  positionTitle: InputItem;
  employee: EmployeeInputItem;
  paginationEmployee: PaginationData;
};

const defaultState: AddPositionModalState = {
  isLoading: false,
  isModalOpen: false,
  isProcessing: false,
  mode: Mode.Add,
  positionId: '',
  jobTitle: {
    value: { id: '', name: '' },
    options: [],
    isDisabled: false,
  },
  employee: {
    value: {
      id: noUser.id,
      name: noUser.name,
      avatar: noUser.avatar_image_url,
      firstName: noUser.first_name,
      lastName: noUser.last_name,
    },
    options: [],
    isLoading: false,
  },
  positionTitle: {
    value: { id: '', name: '' },
    options: [],
    isDisabled: false,
  },
  paginationEmployee: {
    total: 0,
    perPage: 10,
    next: 0,
    lastPage: 0,
    currentPage: 1,
  },
};

const stateController = new StateController<AddPositionModalState>('ADD_POSITION_MODAL_STATE', defaultState);

export class Actions {
  public static loadData() {
    return async (dispatch) => {
      try {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            isLoading: true,
            jobTitle: {
              ...prev.jobTitle,
              isDisabled: true,
            },

            positionTitle: {
              ...prev.positionTitle,
              isDisabled: true,
            },
          })),
        );

        const positions = await PositionTypesService.getPositionTypes('', false);
        const descriptions = await PositionSlotsService.descriptionsByPositionType();

        const positionsMapped = positions
          .filter((pos) => pos.name !== '')
          .map((item) => ({
            name: item.name,
            id: item.id,
          }));
        const descriptionsMapped = descriptions
          .filter((des) => des !== '')
          .map((item, index) => ({
            name: item,
            id: `${item}-${index}`,
          }));

        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            jobTitle: {
              ...prev.jobTitle,
              options: positionsMapped,
            },
            positionTitle: {
              ...prev.positionTitle,
              options: descriptionsMapped,
            },
          })),
        );

        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            jobTitle: {
              ...prev.jobTitle,
              isDisabled: false,
            },

            positionTitle: {
              ...prev.positionTitle,
              isDisabled: false,
            },
          })),
        );
      } catch (error) {
        notify.error(error.message);
      } finally {
        dispatch(stateController.setState({ isLoading: false }));
      }
    };
  }

  public static initEmployee() {
    return async (dispatch, getState: () => AppState) => {
      const { options } = getState().addPositionModal.employee;
      if (options.length) return;
      try {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            employee: {
              ...prev.employee,
              isLoading: true,
            },
          })),
        );

        const { data, meta } = await UserService.getAllUsers({ skip: 0, take: TAKE_USERS });

        const usersMapped = [noUser, ...data].map((item) => ({
          id: item.id,
          lastName: item.last_name,
          firstName: item.first_name,
          avatar: item.avatar_image_url,
          name: `${item.first_name} ${item.last_name}`,
        }));

        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            employee: {
              ...prev.employee,
              options: usersMapped,
              isLoading: false,
            },
            paginationEmployee: {
              total: meta.total,
              currentPage: meta.currentPage,
              perPage: meta.perPage,
              lastPage: meta.lastPage,
              next: meta.next,
            },
          })),
        );
      } catch (error) {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            employee: {
              ...prev.employee,
              isLoading: false,
            },
          })),
        );
        notify.error(error.message);
      }
    };
  }

  static loadMoreOrSearchEmployee(value: string = '', isScroll: boolean = false) {
    return async (dispatch, getState: () => AppState) => {
      if (!isScroll) {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            paginationEmployee: {
              ...prev.paginationEmployee,
              next: 0,
            },
          })),
        );
      }
      const { paginationEmployee } = getState().addPositionModal;
      if (isScroll && paginationEmployee.currentPage >= paginationEmployee.lastPage) return;
      try {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            employee: {
              ...prev.employee,
              isLoading: true,
            },
          })),
        );
        debounce(async () => {
          const { data, meta } = await UserService.getAllUsers({
            skip: paginationEmployee.next,
            take: paginationEmployee.perPage,
            search: value.trim(),
          });
          const usersMapped = [noUser, ...data].map((item) => ({
            id: item.id,
            lastName: item.last_name,
            firstName: item.first_name,
            avatar: item.avatar_image_url,
            name: `${item.first_name} ${item.last_name}`,
          }));
          dispatch(
            stateController.setState((prev) => ({
              ...prev,
              employee: {
                ...prev.employee,
                options: isScroll ? [...prev.employee.options, ...usersMapped] : usersMapped,
                isLoading: false,
              },
              paginationEmployee: {
                ...prev.paginationEmployee,
                currentPage: meta.currentPage,
                lastPage: meta.lastPage,
                total: meta.total,
                perPage: meta.perPage,
                next: meta.next,
              },
            })),
          );
        }, 500);
      } catch (error) {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            employee: {
              ...prev.employee,
              isLoading: false,
            },
          })),
        );
        notify.error(error.message);
      }
    };
  }

  public static openModal(item?: PositionItem) {
    return (dispatch) => {
      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          isModalOpen: true,
          mode: item ? Mode.Edit : Mode.Add,
        })),
      );

      if (item) {
        const user = item.users[0] || noUser;
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            positionId: item.id,
            jobTitle: {
              ...prev.jobTitle,
              value: {
                id: item.positionType.id,
                name: item.positionType.name,
              },
            },
            positionTitle: {
              ...prev.positionTitle,
              value: {
                id: 'positionTitle',
                name: item.name,
              },
            },
            employee: {
              ...prev.employee,
              value: user.id
                ? {
                    id: user.id,
                    name: `${user.first_name} ${user.last_name}`,
                    avatar: user?.avatar_image_url,
                    lastName: user.last_name,
                    firstName: user.first_name,
                  }
                : noUser,
            },
          })),
        );
      }

      dispatch(Actions.loadData());
    };
  }

  public static closeModal() {
    return (dispatch) => {
      dispatch(stateController.setState(defaultState));
    };
  }

  public static onChangeJobTitleValue(value: IdName) {
    return (dispatch) => {
      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          jobTitle: {
            ...prev.jobTitle,
            value,
          },
        })),
      );
    };
  }

  public static onChangePositionTitleValue(value: IdName) {
    return (dispatch) => {
      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          positionTitle: {
            ...prev.positionTitle,
            value,
          },
        })),
      );
    };
  }

  public static onChangeEmployeeValue(value: EmployeeItem) {
    return (dispatch) => {
      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          employee: {
            ...prev.employee,
            value,
          },
        })),
      );
    };
  }

  public static onConfirmClick(page: Page) {
    return async (dispatch, getState: () => AppState) => {
      const { mode } = getState().addPositionModal;

      if (mode === Mode.Add) dispatch(Actions.addPosition(page));
      if (mode === Mode.Edit) dispatch(Actions.updatePosition(page));
    };
  }

  public static addPosition(page: Page) {
    return async (dispatch, getState: () => AppState) => {
      try {
        dispatch(stateController.setState({ isProcessing: true }));

        const { jobTitle, employee, positionTitle } = getState().addPositionModal;
        const { breadcrumbs } = getState().departments;
        const departmentId = breadcrumbs[breadcrumbs.length - 1].id;

        let positionType = {};

        if (jobTitle.value.id !== 'new') {
          positionType = { id: jobTitle.value.id, name: jobTitle.value.name };
        } else {
          positionType = { name: jobTitle.value.name, id: null };
        }

        const body = {
          position_type: positionType,
          department_id: departmentId,
          user_id: employee.value.id || undefined,
          position_slot_name: positionTitle?.value?.name || '',
        };

        const data = await PositionSlotsService.createWithAssignment(body);

        if (page === Page.Departments) {
          await dispatch(DepartmentsActions.loadData(departmentId, false));
        }

        dispatch(Actions.closeModal());
        dispatch(DepartmentsActions.setLastAddedItemId(data.id));
      } catch {
        notify.error('Something went wrong');
      } finally {
        dispatch(stateController.setState({ isProcessing: false }));
      }
    };
  }

  public static updatePosition(page: Page) {
    return async (dispatch, getState: () => AppState) => {
      try {
        dispatch(stateController.setState({ isProcessing: true }));

        const { jobTitle, employee, positionTitle } = getState().addPositionModal;
        const { breadcrumbs } = getState().departments;
        const departmentId = breadcrumbs[breadcrumbs.length - 1].id;
        const { positionId } = getState().addPositionModal;

        let positionType = {};

        if (jobTitle.value.id !== 'new') {
          positionType = { id: jobTitle.value.id, name: jobTitle.value.name };
        } else {
          positionType = { name: jobTitle.value.name, id: null };
        }

        const body = {
          id: positionId,
          position_type: positionType,
          department_id: departmentId,
          user_id: employee.value.id || undefined,
          position_slot_name: positionTitle?.value?.name || '',
        };

        const data = await PositionSlotsService.updateWithAssignment(body);

        if (page === Page.Departments) {
          await dispatch(DepartmentsActions.loadData(departmentId, false));
        }

        dispatch(Actions.closeModal());
        dispatch(DepartmentsActions.setLastAddedItemId(data.id));
      } catch {
        notify.error('Something went wrong');
      } finally {
        dispatch(stateController.setState({ isProcessing: false }));
      }
    };
  }

  public static manageUserPosition(departmentId: string) {
    return async (dispatch, getState: () => AppState) => {
      const { user } = getState();

      if (departmentId) {
        const position = getState().user.positions[0];
        const slotId = position?.positionSlotId;
        const slotName = position?.positionSlotName;
        const positionTypeId = position?.positionTypeId;
        const positionTypeName = position?.positionTypeName;

        const item = {
          id: slotId,
          name: slotName,
          positionType: {
            description: '',
            id: positionTypeId,
            name: positionTypeName,
          },
          users: [
            {
              avatar_image_url: user.avatar_image_url,
              first_name: user.first_name,
              id: user.id,
              last_name: user.last_name,
            },
          ],
        };

        router.navigate(`${Paths.Departments}/${departmentId}`);
        dispatch(Actions.openModal(item));
      } else {
        const employee = {
          id: user.id,
          lastName: user.last_name,
          firstName: user.first_name,
          avatar: user.avatar_image_url,
          name: `${user.first_name} ${user.last_name}`,
        };

        router.navigate(`${Paths.Departments}`);
        dispatch(Actions.openModal());
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            employee: {
              ...prev.employee,
              value: employee,
            },
          })),
        );
      }
    };
  }
}

export class Selectors {
  public static isConfirmButtonDisabled(state: AppState) {
    const jobTitle = state.addPositionModal.jobTitle.value;
    const employee = state.addPositionModal.employee.value;
    return !jobTitle.name.length || !employee;
  }
}

export const reducer = stateController.getReducer();
