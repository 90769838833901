import { FC, SVGProps } from 'react';

export const ConnectorSplitRightIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.24829 3.1652L13.7229 3.1652L12.1943 1.63661C11.9339 1.37626 11.9339 0.954147 12.1943 0.693797C12.4546 0.433448 12.8767 0.433448 13.1371 0.693797L14.8609 2.41765L15.5335 3.09024L15.7319 3.28859L15.7853 3.34195L15.7991 3.35577L15.8026 3.35928L15.8035 3.36017C15.9285 3.48519 15.999 3.65506 15.999 3.83187C15.999 4.00868 15.9288 4.17825 15.8038 4.30327L13.1371 6.96994C12.8767 7.23029 12.4546 7.23029 12.1943 6.96994C11.9339 6.70959 11.9339 6.28748 12.1943 6.02713L13.7229 4.49853L7.332 4.49854C7.75026 5.05549 7.9981 5.74774 7.9981 6.49787L7.9981 9.83187C7.9981 11.3046 9.192 12.4985 10.6648 12.4985L13.7222 12.4985L12.1936 10.9699C11.9333 10.7096 11.9333 10.2875 12.1936 10.0271C12.454 9.76678 12.8761 9.76678 13.1365 10.0271L15.8031 12.6938C15.9281 12.8188 15.9984 12.9884 15.9984 13.1652C15.9984 13.342 15.9281 13.5116 15.8031 13.6366L13.1365 16.3033C12.8761 16.5636 12.454 16.5636 12.1936 16.3033C11.9333 16.0429 11.9333 15.6208 12.1936 15.3605L13.7222 13.8319L10.6648 13.8319C8.45562 13.8319 6.66476 12.041 6.66476 9.83187L6.66476 6.49787C6.66476 5.58831 6.05739 4.82061 5.2261 4.57822C4.90323 5.68739 3.87958 6.49788 2.66673 6.49788C1.19468 6.49788 0.00135904 5.30398 0.00135903 3.83122C0.00135901 2.35846 1.19468 1.16455 2.66673 1.16455C3.90891 1.16455 4.95263 2.01472 5.24829 3.1652ZM2.66673 5.16455C1.93168 5.16455 1.33469 4.56821 1.33469 3.83122C1.33469 3.09422 1.93168 2.49788 2.66673 2.49788C3.40177 2.49788 3.99876 3.09422 3.99876 3.83122C3.99876 4.56821 3.40177 5.16455 2.66673 5.16455Z"
      fill="#F8A586"
    />
  </svg>
);
