import { baseAxiosInstance } from 'axios-config';
import { AssignResponsibleToTasksBody, TaskTableModel } from 'services/task-table.model';
import { PaginateResponse } from 'types/paginate-response';

export class TaskTableService {
  public static async getAllTask(
    body: any,
    params?: { skip?: number | null; take?: number },
    signal?: AbortController['signal'],
  ) {
    const { data } = await baseAxiosInstance.post<PaginateResponse<TaskTableModel>>('/tasks/all', body, { params, signal });

    return data;
  }

  public static async bulkAssignUsersToTasks(body: AssignResponsibleToTasksBody[]) {
    const { data } = await baseAxiosInstance.post<string[]>('/tasks/multi-assign', body);
    return data;
  }
}
