import { baseAxiosInstance } from 'axios-config';
import { AssignUserResponse } from 'services/task-assigment.model';

export class TaskAssigmentService {
  public static async assignUser(user_id: string, slot_id: string) {
    const { data } = await baseAxiosInstance.post<AssignUserResponse>(`/task-assignment/assign`, {
      user_id,
      slot_id,
    });
    return data;
  }

  public static async unassignUser(slot_id: string) {
    await baseAxiosInstance.post(`/task-assignment/unassign/${slot_id}`);
  }
}
