import { FC, SVGProps } from 'react';

export const PauseCircleIcon: FC<SVGProps<SVGSVGElement>> = ({ ...props }) => (
  <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M9.33473 5.50732C8.9719 5.50732 8.67777 5.80145 8.67777 6.16428V10.836C8.67777 11.1988 8.9719 11.4929 9.33473 11.4929C9.69756 11.4929 9.99169 11.1988 9.99169 10.836V6.16428C9.99169 5.80145 9.69759 5.50732 9.33473 5.50732ZM6.6652 5.50732C6.30237 5.50732 6.00824 5.80145 6.00824 6.16428V10.836C6.00824 11.1988 6.30237 11.4929 6.6652 11.4929C7.02803 11.4929 7.32216 11.1988 7.32216 10.836V6.16428C7.32216 5.80145 7.02806 5.50732 6.6652 5.50732Z"
      fill="white"
    />
    <circle cx="8" cy="8.5" r="6.4" stroke="white" strokeWidth="1.2" />
  </svg>
);
