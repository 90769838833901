import { useSelector } from 'react-redux';
import { AppState } from 'redux/store';
import { AccessLevel, Permission } from 'services/permission.model';

export const useCurrentUserPermissions = () => {
  const userPermissions = useSelector((state: AppState) => state.auth.user?.user_permissions);

  return userPermissions;
};

export const useIsPermitted = (permission: Permission, accessLevel: AccessLevel[]) => {
  const userPermissions = useSelector((state: AppState) => state.auth.user?.user_permissions);

  if (!userPermissions) return false;

  const isAdmin = userPermissions[Permission.webAdmin] === AccessLevel.access;
  const isPermitted = isAdmin || accessLevel.some((item) => item === userPermissions[permission]);

  return isPermitted;
};

export const useIsAdmin = () => {
  return useSelector((state: AppState) => state.auth.user?.user_permissions[Permission.webAdmin] === AccessLevel.access);
};
