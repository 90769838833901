import { FC, SVGProps } from 'react';

export const BillIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none" {...props}>
    <g clipPath="url(#clip0_11659_11777)">
      <path
        d="M2.19153 1.97461V10.5149C2.19153 11.2141 2.51973 11.8776 3.08337 12.2985L6.80055 15.0811C7.5925 15.6733 8.68412 15.6733 9.47607 15.0811L13.1933 12.2985C13.7569 11.8776 14.0851 11.2141 14.0851 10.5149V1.97461H2.19153Z"
        stroke="#141414"
        strokeMiterlimit="10"
      />
      <path d="M1 1.97461H15.2694" stroke="#141414" strokeMiterlimit="10" strokeLinecap="round" />
      <path d="M5.28076 5.89844H10.9885" stroke="#141414" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M5.28076 9.4668H10.9885" stroke="#141414" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    </g>
    <defs>
      <clipPath id="clip0_11659_11777">
        <rect width="16" height="16" fill="white" transform="translate(0 0.5)" />
      </clipPath>
    </defs>
  </svg>
);
