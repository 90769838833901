import { FC } from 'react';
import { SearchIcon } from 'icons/search';
import { useNavigate } from 'react-router-dom';
import Spinner from 'components/spinner/spinner';
import { Position2Icon } from 'icons/position-2';
import { DepartmentsIcon } from 'icons/departments';
import useDebounceSearch from 'hooks/use-debounce-search';
import { InputAdornment, OutlinedInput } from '@mui/material';
import { ItemType } from 'pages/departments/departments.controller';
import { replaceStr } from 'utils/search-replacer';
import s from './quick-search.module.scss';

type Item = {
  id: string;
  name: string;
  path: string;
  route: string;
  type: ItemType;
  description: string;
};

type OwnProps = {
  items: Item[];
  isFetching?: boolean;
  clearItemList: () => void;
  getItemsByKeyword: (keyword: string) => void;
};

const QuickSearch: FC<OwnProps> = ({ items, isFetching, clearItemList, getItemsByKeyword }) => {
  const navigate = useNavigate();

  const { searchState, setSearch } = useDebounceSearch(
    '',
    (result) => {
      getItemsByKeyword(result);
    },
    300,
  );

  const handleItemClick = (item: Item) => {
    navigate(item.route);
  };

  const onBlurClick = () => {
    setSearch('');
    clearItemList();
  };

  return (
    <div className={s.wrapper}>
      <OutlinedInput
        startAdornment={
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        }
        sx={{
          '&.MuiOutlinedInput-root.Mui-focused': {
            width: 400,
          },
          '&.MuiOutlinedInput-root': {
            width: 200,
            height: 40,
            fontSize: 14,
            transition: '0.25s',
          },
        }}
        value={searchState}
        className={s.input}
        onBlur={onBlurClick}
        placeholder="Search by keyword"
        onChange={(e) => setSearch(e.currentTarget.value)}
      />
      <div className={s.dropdown} data-hide={!searchState}>
        {isFetching && (
          <div className={s.spinner}>
            <Spinner containerClassName={s.spinner} disableShrink size={24} />
          </div>
        )}
        {!isFetching && (
          <div>
            {items.length ? (
              <div className={s.finded_items_wrapper}>
                <div className={s.finded_items}>
                  {items.map((item) => (
                    <div className={s.list_item} key={`${item.id}-${item.type}`} onMouseDown={() => handleItemClick(item)}>
                      <div className={s.icon}>
                        {item.type === ItemType.Department ? <DepartmentsIcon /> : <Position2Icon height={16} width={16} />}
                      </div>
                      <div className={s.col}>
                        {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
                        <div className={s.row}>
                          <p dangerouslySetInnerHTML={{ __html: replaceStr(item.name, searchState) }} />
                          {item.description && (
                            <p dangerouslySetInnerHTML={{ __html: replaceStr(`(${item.description})`, searchState) }} />
                          )}
                        </div>
                        <p>{item.path}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              <div className={s.no_results}>
                <SearchIcon width={16} height={16} />
                <span>No results</span>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default QuickSearch;
