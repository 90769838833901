import { FC, SVGProps } from 'react';

export const SkuIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M10.5981 10.7702C10.2204 10.7702 9.91677 10.652 9.68734 10.4156C9.46023 10.1792 9.34668 9.8687 9.34668 9.48401V9.27544H10.0071V9.48401C10.0071 9.67172 10.0604 9.82351 10.167 9.93939C10.2737 10.0529 10.4173 10.1097 10.5981 10.1097C10.7789 10.1097 10.9225 10.0529 11.0291 9.93939C11.1357 9.82351 11.189 9.67172 11.189 9.48401V8.94173C11.189 8.75402 11.1357 8.60339 11.0291 8.48983C10.9225 8.37396 10.7789 8.31603 10.5981 8.31603H10.1462V7.65556H10.5981C10.7789 7.65556 10.9225 7.59878 11.0291 7.48523C11.1357 7.36936 11.189 7.21757 11.189 7.02985V6.61967C11.189 6.43196 11.1357 6.28132 11.0291 6.16777C10.9225 6.0519 10.7789 5.99396 10.5981 5.99396C10.4173 5.99396 10.2737 6.0519 10.167 6.16777C10.0604 6.28132 10.0071 6.43196 10.0071 6.61967V6.82824H9.34668V6.61967C9.34668 6.23266 9.46023 5.92212 9.68734 5.68806C9.91677 5.45168 10.2204 5.3335 10.5981 5.3335C10.9781 5.3335 11.2817 5.45168 11.5088 5.68806C11.7359 5.92212 11.8495 6.23266 11.8495 6.61967V7.02985C11.8495 7.27087 11.8032 7.48291 11.7105 7.66599C11.6201 7.84906 11.4915 7.9939 11.3246 8.10051L11.3281 7.86065C11.4926 7.96957 11.6201 8.11673 11.7105 8.30212C11.8032 8.4852 11.8495 8.6984 11.8495 8.94173V9.48401C11.8495 9.8687 11.7359 10.1792 11.5088 10.4156C11.2817 10.652 10.9781 10.7702 10.5981 10.7702Z"
      fill="#141414"
    />
    <path
      d="M6.25146 10.6589V10.0784C6.25146 9.81193 6.2955 9.57671 6.38356 9.37277C6.47394 9.16884 6.58865 8.98692 6.7277 8.82702C6.86906 8.66712 7.01737 8.52112 7.17264 8.38903C7.33023 8.25462 7.47854 8.12484 7.61759 7.9997C7.75895 7.87456 7.87366 7.74594 7.96173 7.61385C8.04979 7.47943 8.09382 7.33228 8.09382 7.17238V6.61967C8.09382 6.43196 8.04052 6.28132 7.93392 6.16777C7.82732 6.0519 7.68364 5.99396 7.50288 5.99396C7.32212 5.99396 7.17844 6.0519 7.07183 6.16777C6.96523 6.28132 6.91193 6.43196 6.91193 6.61967V6.82824H6.25146V6.61967C6.25146 6.23266 6.36502 5.92212 6.59213 5.68806C6.82155 5.45168 7.12514 5.3335 7.50288 5.3335C7.88293 5.3335 8.18652 5.45168 8.41363 5.68806C8.64073 5.92444 8.75429 6.23498 8.75429 6.61967V7.17238C8.75429 7.37863 8.71026 7.56634 8.62219 7.73551C8.53645 7.90468 8.42521 8.06227 8.28848 8.20827C8.15176 8.35195 8.00576 8.48983 7.85049 8.62193C7.69754 8.7517 7.5527 8.8838 7.41597 9.01821C7.27924 9.1503 7.16685 9.29051 7.07879 9.43882C6.99072 9.58482 6.94669 9.74588 6.94669 9.922V10.2974L6.64775 9.99848H8.75429V10.6589H6.25146Z"
      fill="#141414"
    />
    <path
      d="M4.74389 10.659V6.70319H4V6.04272H4.1599C4.3453 6.04272 4.48898 5.99637 4.59094 5.90368C4.69291 5.81098 4.74389 5.68352 4.74389 5.5213V5.44482H5.40436V10.659H4.74389Z"
      fill="#141414"
    />
    <path
      d="M11.333 13.6668H4.66634C2.66634 13.6668 1.33301 12.6668 1.33301 10.3335V5.66683C1.33301 3.3335 2.66634 2.3335 4.66634 2.3335H11.333C13.333 2.3335 14.6663 3.3335 14.6663 5.66683V10.3335C14.6663 12.6668 13.333 13.6668 11.333 13.6668Z"
      stroke="#141414"
      strokeWidth="1.1"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
