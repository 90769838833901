/* eslint-disable react/no-danger */
import { Tooltip } from '@mui/material';
import { replaceStr } from 'utils/search-replacer';
import { FC, useEffect, useRef, useState } from 'react';
import s from './search-row-item.module.scss';

type OwnProps = {
  value: string;
  maxWidth?: string;
  className?: string;
  searchedValue: string;
  onClick?: (value: string) => void;
};

const SearchRowItem: FC<OwnProps> = ({ value = '', className = '', maxWidth = '100%', searchedValue = '', onClick }) => {
  const textRef = useRef<HTMLParagraphElement>(null);

  const [isShowValueTooltip, setIsShowValueTooltip] = useState(false);

  const handleOnClick = () => {
    if (onClick) onClick(value);
  };

  useEffect(() => {
    const currentScrollWidth = textRef?.current?.scrollWidth || 0;
    const currentClientWidth = textRef?.current?.clientWidth || 0;

    const checkScrollWidth = () => {
      if (currentScrollWidth > currentClientWidth) {
        setIsShowValueTooltip(true);
      } else {
        setIsShowValueTooltip(false);
      }
    };
    checkScrollWidth();

    window.addEventListener('resize', checkScrollWidth);
    return () => {
      window.removeEventListener('resize', checkScrollWidth);
    };
  }, [textRef?.current?.scrollWidth, textRef?.current?.clientWidth]);

  return (
    <Tooltip title={value} placement="top" disableHoverListener={!isShowValueTooltip}>
      <div style={{ maxWidth }} onClick={handleOnClick}>
        <p
          ref={textRef}
          className={`${s.row} ${className}`}
          data-is-clickable={Boolean(onClick)}
          dangerouslySetInnerHTML={{ __html: replaceStr(value, searchedValue) }}
        />
      </div>
    </Tooltip>
  );
};

export default SearchRowItem;
