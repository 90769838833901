import { FC, SVGProps } from 'react';

export const DocumentEmptyIcon: FC<SVGProps<SVGSVGElement>> = ({ ...props }) => (
  <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M6.66634 4.50016H9.33301C10.6663 4.50016 10.6663 3.8335 10.6663 3.16683C10.6663 1.8335 9.99967 1.8335 9.33301 1.8335H6.66634C5.99967 1.8335 5.33301 1.8335 5.33301 3.16683C5.33301 4.50016 5.99967 4.50016 6.66634 4.50016Z"
      stroke="#5E7285"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.6667 3.17969C12.8867 3.29969 14 4.11969 14 7.16635V11.1664C14 13.833 13.3333 15.1664 10 15.1664H6C2.66667 15.1664 2 13.833 2 11.1664V7.16635C2 4.12635 3.11333 3.29969 5.33333 3.17969"
      stroke="#5E7285"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
