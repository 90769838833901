import { FC, SVGProps } from 'react';

export const KeySquareIcon: FC<SVGProps<SVGSVGElement>> = ({ ...props }) => (
  <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_1360_64152)">
      <path
        d="M5.86094 15.5H10.0609C13.5609 15.5 14.9609 14.1 14.9609 10.6V6.4C14.9609 2.9 13.5609 1.5 10.0609 1.5H5.86094C2.36094 1.5 0.960938 2.9 0.960938 6.4V10.6C0.960938 14.1 2.36094 15.5 5.86094 15.5Z"
        stroke="#141414"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.9564 9.62666C10.1654 10.4177 9.03139 10.6627 8.03039 10.3477L6.21739 12.1537C6.09139 12.2867 5.83239 12.3707 5.64339 12.3427L4.80339 12.2307C4.52339 12.1957 4.27139 11.9297 4.22939 11.6567L4.11739 10.8167C4.08939 10.6347 4.18039 10.3757 4.30639 10.2427L6.11239 8.43666C5.80439 7.43566 6.04239 6.30166 6.83339 5.51066C7.96739 4.37666 9.81539 4.37666 10.9564 5.51066C12.0904 6.63766 12.0904 8.48566 10.9564 9.62666Z"
        stroke="#141414"
        strokeWidth="1.2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.87625 11.4956L6.28125 10.8936"
        stroke="#141414"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M8.93698 7.59023H8.94326" stroke="#141414" strokeLinecap="round" strokeLinejoin="round" />
    </g>
    <defs>
      <clipPath id="clip0_1360_64152">
        <rect width="16" height="16" fill="white" transform="translate(0 0.5)" />
      </clipPath>
    </defs>
  </svg>
);
