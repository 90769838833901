import { useCallback, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

type Options = {
  ignoreWhenLeavePaths: string[];
};

export function useLeaveRoute(callback: (prevPath?: string, nextPath?: string) => void, options?: Options) {
  const { pathname } = useLocation();
  const prevPathname = useRef('');

  let callbackFunc = useCallback(callback, [callback]);

  useEffect(() => {
    if (prevPathname.current && prevPathname.current !== pathname) {
      // Ignore specific paths configured in options (see ignoreWhenLeavePaths option)
      const isOptionsExists = Boolean(options?.ignoreWhenLeavePaths);
      const isLeaveFromCurrentPathSouldBeIgnored = options?.ignoreWhenLeavePaths.some((path) => path === prevPathname.current);
      if (isOptionsExists && isLeaveFromCurrentPathSouldBeIgnored) {
        callbackFunc = () => {};
      }

      callbackFunc(prevPathname.current, pathname);
    }

    prevPathname.current = pathname;
  }, [pathname]);

  useEffect(() => {
    return () => {
      callback(prevPathname.current, pathname);
    };
  }, []);
}
