export const PRODUCT_CONFIG_FILE_INPUT_ACCEPT_TYPES =
  '.txt, .doc, .docx, .pdf, .jpg, .jpeg, .png, .mp4, .xls, .xlsx, .mov, .webp';

export const IMAGES_FILES_TYPES = '.jpg, .jpeg, .png, .webp';

export const VIDEOS_FILES_TYPES = '.mp4, .mov';

export const TEXT_FILES_TYPES = '.txt, .doc, .docx, .pdf, .xlsx, .xls';

export const LOGO_FILES_TYPES = '.svg, .jpg, .jpeg, .png, .webp';
