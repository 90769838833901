import { FC, SVGProps } from 'react';

export const CategoryIcon: FC<SVGProps<SVGSVGElement>> = ({ ...props }) => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M11 4.26V1.99C11 1.285 10.68 1 9.885 1H7.865C7.07 1 6.75 1.285 6.75 1.99V4.255C6.75 4.965 7.07 5.245 7.865 5.245H9.885C10.68 5.25 11 4.965 11 4.26Z"
      stroke="#878B92"
      strokeWidth="1.1"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11 9.885V7.865C11 7.07 10.68 6.75 9.885 6.75H7.865C7.07 6.75 6.75 7.07 6.75 7.865V9.885C6.75 10.68 7.07 11 7.865 11H9.885C10.68 11 11 10.68 11 9.885Z"
      stroke="#878B92"
      strokeWidth="1.1"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.25 4.26V1.99C5.25 1.285 4.93 1 4.135 1H2.115C1.32 1 1 1.285 1 1.99V4.255C1 4.965 1.32 5.245 2.115 5.245H4.135C4.93 5.25 5.25 4.965 5.25 4.26Z"
      stroke="#878B92"
      strokeWidth="1.1"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.25 9.885V7.865C5.25 7.07 4.93 6.75 4.135 6.75H2.115C1.32 6.75 1 7.07 1 7.865V9.885C1 10.68 1.32 11 2.115 11H4.135C4.93 11 5.25 10.68 5.25 9.885Z"
      stroke="#878B92"
      strokeWidth="1.1"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
