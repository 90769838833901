import { ModalItem, ModalActions } from 'modules/root-modals/root-modals.controller';
import Modal from 'components/ui-new/modal/modal';
import { MODAL_COMPONENTS } from 'modules/root-modals/modals';
import { connect } from 'react-redux';
import { AppState } from 'redux/store';
import { MODALS } from './modals';

type StateProps = {
  modals: ModalItem[];
};
type DispatchProps = {
  closeModal: (id: MODALS) => void;
};
type Props = StateProps & DispatchProps;

function RootModals({ modals, closeModal }: Props) {
  return (
    <div>
      {modals.length
        ? modals.map((item) => {
            return (
              <Modal
                open
                key={item.id}
                onClose={() => closeModal(item.id)}
                PaperProps={{ sx: { borderRadius: '8px', overflowY: 'unset' } }}
              >
                {MODAL_COMPONENTS[item.id](item.props as any)}
              </Modal>
            );
          })
        : null}
    </div>
  );
}

const mapStateToProps = (state: AppState): StateProps => ({
  modals: state.root_modals.modal_items,
});
const mapDispatchToProps: DispatchProps = {
  closeModal: ModalActions.closeModal,
};
export default connect(mapStateToProps, mapDispatchToProps)(RootModals);
