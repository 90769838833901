import { FC, SVGProps } from 'react';

export const TabRightIcon: FC<SVGProps<SVGSVGElement>> = ({ ...props }) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M6.99994 5.00042L12.1428 10.1433L6.99994 15.2861"
      stroke={props.stroke || '#91979F'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
