import { FC, SVGProps } from 'react';

export const CalendarWarningIcon: FC<SVGProps<SVGSVGElement>> = ({ ...props }) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M6.66797 1.66602V4.16602"
      stroke="#FF4E4E"
      strokeWidth="1.1"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.332 1.66602V4.16602"
      stroke="#FF4E4E"
      strokeWidth="1.1"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.3333 2.91602C16.1083 3.06602 17.5 4.12435 17.5 8.04102V13.191C17.5 16.6243 16.6667 18.341 12.5 18.341H7.5C3.33333 18.341 2.5 16.6243 2.5 13.191V8.04102C2.5 4.12435 3.89167 3.07435 6.66667 2.91602H13.3333Z"
      stroke="#FF4E4E"
      strokeWidth="1.1"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.2904 14.666H2.70703"
      stroke="#FF4E4E"
      strokeWidth="1.1"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M10 6.41699V9.86527" stroke="#FF4E4E" strokeWidth="1.1" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M10 11.665H10.0062" stroke="#FF4E4E" strokeWidth="1.1" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);
