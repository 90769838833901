import { Card } from '@mui/material';
import ModalBody from 'components/modal-body/modal-body';
import ModalFooter from 'components/modal-footer/modal-footer';
import ModalHeader from 'components/modal-header/modal-header';
import Subtitle from 'components/subtitle/subtitle';
import ModalSvgWrapper from 'icons/modal-svg-wrapper/svg-wrapper';

export type ModalBaseProps = {
  title?: string | React.ReactNode;
  children: React.ReactNode | JSX.Element;
  icon?: string | React.ReactNode;
  footer?: React.ReactNode | JSX.Element;
  onClose: () => void;
  header?: React.ReactNode | JSX.Element;
  isCenterModeWithoutTitle?: boolean;
  iconWithTitle?: boolean;
  witohutShadow?: boolean;
  headerClassName?: string;
  backgroundColor?: string;
  bodyClassName?: string;
  footerClassName?: string;
  withCloseButton?: boolean;
  paddingSize?: Sizes;
  [key: string]: any;
};

export type Sizes = 'small' | 'medium' | 'large';

export default function ModalBase({
  title,
  icon,
  header,
  footer,
  children,
  onClose,
  headerClassName,
  bodyClassName,
  footerClassName,
  backgroundColor,
  withCloseButton = false,
  paddingSize,
  isCenterModeWithoutTitle = false,
  iconWithTitle = false,
  witohutShadow = false,
  ...rest
}: ModalBaseProps) {
  return (
    <Card {...rest}>
      <ModalHeader
        className={headerClassName && headerClassName}
        isCenterModeWithoutTitle={isCenterModeWithoutTitle}
        size="small"
        onClose={onClose}
        witohutShadow={witohutShadow}
        withCloseButton={withCloseButton}
      >
        {(icon && title) || iconWithTitle ? (
          <Subtitle
            fontSize="newLarge"
            text={title}
            icon={<ModalSvgWrapper containerSize={38} iconSize={20} backgroundColor={backgroundColor} icon={icon} />}
          />
        ) : (
          <Subtitle isCenterModeWithoutTitle={isCenterModeWithoutTitle} icon={icon} />
        )}
      </ModalHeader>
      <ModalBody size={paddingSize} className={bodyClassName}>
        {children}
      </ModalBody>
      {footer && (
        <ModalFooter size="small" witohutShadow={witohutShadow} className={footerClassName && footerClassName}>
          {footer}
        </ModalFooter>
      )}
    </Card>
  );
}
