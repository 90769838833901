import { FC, SVGProps } from 'react';

export const PriorityLowest2Icon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_5823_31874)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.33301 0.499999C2.96482 0.499999 2.66634 0.798476 2.66634 1.16667L2.66634 4.5C2.66634 6.70914 4.4572 8.5 6.66634 8.5C8.1391 8.5 9.33301 9.6939 9.33301 11.1667L9.33301 14.2241L7.80441 12.6955C7.54406 12.4352 7.12195 12.4352 6.8616 12.6955C6.60125 12.9559 6.60125 13.378 6.8616 13.6383L9.52827 16.305C9.65329 16.43 9.82286 16.5003 9.99967 16.5003C10.1765 16.5003 10.3461 16.43 10.4711 16.305L13.1377 13.6383C13.3981 13.378 13.3981 12.9559 13.1377 12.6955C12.8774 12.4352 12.4553 12.4352 12.1949 12.6955L10.6663 14.2241L10.6663 11.1667C10.6663 8.95753 8.87548 7.16666 6.66634 7.16666C5.19358 7.16666 3.99968 5.97276 3.99967 4.5L3.99967 1.16667C3.99967 0.798476 3.7012 0.499999 3.33301 0.499999Z"
        fill="#CFD8DC"
      />
    </g>
    <defs>
      <clipPath id="clip0_5823_31874">
        <rect width="16" height="16" fill="white" transform="translate(0 0.5)" />
      </clipPath>
    </defs>
  </svg>
);
//
