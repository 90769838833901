import { FC, SVGProps } from 'react';

export const EmptySlotIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M14.668 3.93325V6.39992C14.668 7.89992 14.068 8.49992 12.568 8.49992H10.768C9.26797 8.49992 8.66797 7.89992 8.66797 6.39992V3.93325C8.66797 2.43325 9.26797 1.83325 10.768 1.83325H12.568C14.068 1.83325 14.668 2.43325 14.668 3.93325Z"
      stroke="#204366"
      strokeLinejoin="round"
    />
    <path
      d="M7.33203 10.6V13.0667C7.33203 14.5667 6.73203 15.1667 5.23203 15.1667H3.43203C1.93203 15.1667 1.33203 14.5667 1.33203 13.0667V10.6C1.33203 9.1 1.93203 8.5 3.43203 8.5H5.23203C6.73203 8.5 7.33203 9.1 7.33203 10.6Z"
      stroke="#204366"
      strokeLinejoin="round"
      strokeDasharray="1 1"
    />
    <path
      d="M14.6667 10.5C14.6667 13.08 12.58 15.1667 10 15.1667L10.7 14"
      stroke="#204366"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.33203 6.49992C1.33203 3.91992 3.4187 1.83325 5.9987 1.83325L5.2987 2.99992"
      stroke="#204366"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
