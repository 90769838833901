import { Handle, NodeProps, Position } from 'reactflow';
import { MultiLineEllipsis } from 'components/multiline-ellipsis/multiline-ellipsis';
import NodeWrapper from 'components/ui-new/production-react-flow/components/node-wrapper/node-wrapper';
import { PlusIcon } from 'icons/plus';
import { AccessLevel, Permission } from 'services/permission.model';
import { PermissionGuardSelectors } from 'modules/permission-guard/permission-guard.controller';
import { AppState } from 'redux/store';
import { useSelector } from 'react-redux';
import {
  TaskData as ProductionWorkflowTaskData,
  Actions as ProductionWorkflowActions,
} from 'pages/production-workflow/controllers/production-workflow.controller';
import { statuses } from 'components/status-selector/constants';
import {
  TaskData as ManageFailedTasksTaskData,
  Actions as ManageFailedTasksActions,
} from 'modules/manage-failed-tasks-modal/manage-failed-tasks.controller';
import { User } from 'services/user.model';
import { useTaskNode } from 'components/ui-new/production-react-flow/components/task-node/useTaskNode';
import s from './task-node.module.scss';

const TaskNode = ({ id, data }: NodeProps<ProductionWorkflowTaskData | ManageFailedTasksTaskData>) => {
  const { users, hasTarget, hasSource, userSlots, statusTooltip, dispatch, handleNavigate } = useTaskNode(id, data);
  const isManageFailedTasks = 'isFail' in data;

  const accessLevels = [AccessLevel.userDepartment, AccessLevel.userDepartmentSubDepartments, AccessLevel.allDepartments];
  const departmentsAll = (data as ProductionWorkflowTaskData).departmetsAll;
  const isPermitted = useSelector((state: AppState) =>
    PermissionGuardSelectors.checkEditLevel(state, Permission.webTaskEditLevel, accessLevels, departmentsAll),
  );
  const isManageInQueueStatusPermitted = useSelector((state: AppState) =>
    PermissionGuardSelectors.checkEditLevel(state, Permission.webManageInQueueState, [AccessLevel.access], departmentsAll),
  );
  const isAllowedUndoChangingTaskStatus = useSelector((state: AppState) => state.settings.isAllowedUndoChangingTaskStatus);

  return (
    <NodeWrapper
      type="task"
      itemId={data.id}
      status={data.status}
      userSlots={userSlots}
      disable={data.disable}
      users={users as User[]}
      statusTooltip={statusTooltip}
      isEditPermitted={isPermitted}
      is_in_queue={data.is_in_queue}
      isTaskReopened={data.isReopened}
      isForFailedOptions={isManageFailedTasks}
      isFail={isManageFailedTasks ? data.isFail : undefined}
      issues={!isManageFailedTasks ? data.issues : undefined}
      isManageInQueueStatusPermitted={isManageInQueueStatusPermitted}
      isAllowedUndoChangingTaskStatus={isAllowedUndoChangingTaskStatus}
      isWithMoney={'isWithMoney' in data ? data.isWithMoney : undefined}
      productionStatus={(data as ProductionWorkflowTaskData).productionStatus}
      isReportingPeriodClosed={(data as ProductionWorkflowTaskData).is_reporting_period_closed}
      handleFailTask={(values) => dispatch(ManageFailedTasksActions.handleFailTask(data.id, values))}
      onUpdate={(value) => dispatch(ProductionWorkflowActions.updateCanvasNodes(data.id, value, 'task'))}
      handleTaskUsers={(taskId, user, slotId, type) =>
        dispatch(ProductionWorkflowActions.handleTaskUses(taskId, user, slotId, type))
      }
    >
      <button
        type="button"
        className={`${s.task_container} ${s[statuses[data.status].className]}`}
        onClick={handleNavigate}
        onMouseUp={handleNavigate}
      >
        <Handle
          id="a"
          type="target"
          isConnectable={false}
          position={Position.Left}
          className={`${s.handle} ${s.handle_left} ${hasTarget ? '' : s.handle_hide}`}
        >
          <PlusIcon width={10} height={10} color="#878b92" />
        </Handle>

        <div className={s.text}>
          <MultiLineEllipsis lineLimit={3} containerClassName={s.name}>
            {data.name}
          </MultiLineEllipsis>
        </div>
        <Handle
          id="b"
          type="source"
          isConnectable={false}
          position={Position.Right}
          className={`${s.handle} ${s.handle_right} ${hasSource ? '' : s.handle_hide}`}
        >
          <PlusIcon width={10} height={10} color="#878b92" />
        </Handle>
      </button>
    </NodeWrapper>
  );
};

export default TaskNode;
