import { baseAxiosInstance } from 'axios-config';
import { TaskNewSlotModel } from 'services/task-slot.service.model';

export class TaskSlotService {
  public static async createSlot(task_responsibility_id: string, task_assignment_id: string | null) {
    const { data } = await baseAxiosInstance.post<TaskNewSlotModel>(`/task-slot/create`, {
      task_assignment_id,
      task_responsibility_id,
    });
    return data;
  }

  public static async removeSlot(slot_id: string) {
    const { data } = await baseAxiosInstance.delete<TaskNewSlotModel>(`/task-slot/${slot_id}`);
    return data;
  }
}
