import { IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import s from './modal-header.module.scss';

export type ModalHeaderProps = React.HTMLAttributes<HTMLDivElement> & {
  className?: string;
  buttonClassName?: string;
  children: React.ReactNode;
  subtitle?: string;
  onClose?: () => void;
  isCenterModeWithoutTitle?: boolean;
  witohutShadow?: boolean;
  withBorderBottom?: boolean;
  withCloseButton?: boolean;
  size?: Sizes;
};

type Sizes = 'small' | 'medium' | 'oldMedium' | 'newMedium' | 'large' | 'without' | 'newLarge' | 'largeModal' | 'viewMode';

export default function ModalHeader({
  children,
  onClose,
  className = '',
  buttonClassName = '',
  subtitle = '',
  size = 'large',
  isCenterModeWithoutTitle = false,
  witohutShadow = false,
  withCloseButton = true,
  withBorderBottom = false,
  ...rest
}: ModalHeaderProps) {
  const paddingSizes = {
    small: '24px 24px 8px 24px',
    medium: '24px 24px 4px 24px',
    newMedium: '24px 24px 0 24px',
    oldMedium: '24px 32px',
    large: '24px',
    largeModal: '24px 24px 20px 24px',
    newLarge: '16px 24px',
    viewMode: '6px 30px',
    without: 0,
  };

  return (
    <div
      style={{ padding: paddingSizes[size] }}
      className={`${subtitle ? s.withSubtitle : ''} ${withBorderBottom && s.with_border_bottom} ${
        !isCenterModeWithoutTitle ? `${witohutShadow ? s.section_without_shadow : s.section}` : s.centerModeWithOutTitile
      } ${className || ''}`}
      {...rest}
    >
      {children}
      {!isCenterModeWithoutTitle && withCloseButton && (
        <div className={s.close_action}>
          <IconButton className={`${s.icon} ${buttonClassName && buttonClassName}`} color="primary" onClick={() => onClose?.()}>
            <CloseIcon style={{ height: 24, width: 24, fill: 'rgba(0, 0, 0, 0.35)' }} />
          </IconButton>
        </div>
      )}
      {subtitle && <Typography className={s.subtitle}>{subtitle}</Typography>}
    </div>
  );
}
