import { TaskTypeEnum } from 'types/common-enums';

const taskTypes = {
  [TaskTypeEnum.Workflow]: 'Workflow',
  [TaskTypeEnum.Additional]: 'Additional',
};

const TaskTypeCell = (params) => {
  return <div>{taskTypes[params.value]}</div>;
};

export default TaskTypeCell;
