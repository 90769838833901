// import { AppState } from 'redux/store';
import { StateController } from 'state-controller';
import { ComponentHistoryItem } from 'services/production-workflow.model';

export type HistoryItem = ComponentHistoryItem & {
  id: number;
};

export type ComponentsHistoryModalState = {
  isOpen: boolean;
  historyItems: HistoryItem[];
};

const defaultState: ComponentsHistoryModalState = {
  isOpen: false,
  historyItems: [],
};

const stateController = new StateController<ComponentsHistoryModalState>('COMPONENTS_HISTORY_MODAL', defaultState);

export class ComponentsHistoryActions {
  public static openModal(componentsHistory: ComponentHistoryItem[]) {
    return async (dispatch) => {
      const componentsHistoryMapped = componentsHistory.map((i, index) => ({
        ...i,
        id: index + 1,
      }));

      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          isOpen: true,
          historyItems: componentsHistoryMapped,
        })),
      );
    };
  }

  public static closeModal() {
    return async (dispatch) => {
      dispatch(stateController.setState({ isOpen: false }));
      setTimeout(() => dispatch(stateController.setState({ ...defaultState })), 100);
    };
  }
}

export const reducer = stateController.getReducer();
