import { FC, SVGProps } from 'react';

export const PlayOutlinedIcon: FC<SVGProps<SVGSVGElement>> = ({ ...props }) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M2 7.85575V5.19086C2 1.87171 4.34575 0.515324 7.21809 2.1749L9.53191 3.51532L11.8457 4.85575C14.7181 6.51533 14.7181 9.22809 11.8457 10.8877L9.53191 12.2281L7.21809 13.5685C4.34575 15.2281 2 13.8717 2 10.5526V7.85575Z"
      stroke="#141414"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
