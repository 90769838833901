import { baseAxiosInstance } from 'axios-config';
import {
  CreateProductOptionData,
  DeleteProductOptionResponseData,
  ProductOptionResponse,
  UpdateProductOptionData,
  ManageProductOptionData,
  UpdateProductOptionResponseData,
  ProductOptionWithValues,
} from 'services/product-options.model';
import { IdName } from 'types/common-types';

export class ProductOptionsService {
  public static async getAll(search?: string, id?: string) {
    const { data } = await baseAxiosInstance.get<ProductOptionWithValues[]>('product-options/all', {
      params: {
        search,
        product_configuration_id: id,
      },
    });

    return data;
  }

  public static async getById(id: string) {
    const { data } = await baseAxiosInstance.get<IdName>(`product-options/${id}`);

    return data;
  }

  public static async create(body: CreateProductOptionData) {
    const { data } = await baseAxiosInstance.post('product-options/create', body);

    return data;
  }

  public static async update(id: string, body: UpdateProductOptionData) {
    const { data } = await baseAxiosInstance.put<UpdateProductOptionResponseData>(`/product-options/${id}`, body);

    return data;
  }

  public static async delete(id: string) {
    const { data } = await baseAxiosInstance.delete<DeleteProductOptionResponseData>(`/product-options/${id}`);

    return data;
  }

  public static async manageOrder(body: ManageProductOptionData) {
    const { data } = await baseAxiosInstance.post<ProductOptionResponse>(`/product-options/manage`, body);

    return data;
  }
}
