import { StateController } from 'state-controller';

export type LabelQrState = {
  isModalOpen: boolean;
};

const defaultState: LabelQrState = {
  isModalOpen: false,
};

const stateController = new StateController<LabelQrState>('LABEL_QR', defaultState);

export class Actions {
  public static openLabelModal() {
    return async (dispatch) => {
      await dispatch(stateController.setState({ isModalOpen: true }));
    };
  }

  public static closeLabelModal() {
    return (dispatch) => {
      dispatch(stateController.setState({ isModalOpen: false }));
    };
  }

  public static disposeState() {
    return (dispatch) => {
      dispatch(stateController.setState(defaultState));
    };
  }
}

export class Selectors {}

export const GetAppModalReducer = stateController.getReducer();
