import { FC, SVGProps } from 'react';

export const UsersIcon: FC<SVGProps<SVGSVGElement>> = ({ ...props }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M21.997 17.3845V20.4614C21.997 20.8695 21.8349 21.2608 21.5464 21.5493C21.2579 21.8378 20.8665 21.9999 20.4585 21.9999H17.3816"
      stroke="#91979F"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.3816 2H20.4585C20.8665 2 21.2579 2.16209 21.5464 2.4506C21.8349 2.73912 21.997 3.13044 21.997 3.53846V6.61538"
      stroke="#91979F"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.99561 6.61538V3.53846C1.99561 3.13044 2.15769 2.73912 2.44621 2.4506C2.73473 2.16209 3.12604 2 3.53407 2H6.61099"
      stroke="#91979F"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.61099 21.9999H3.53407C3.12604 21.9999 2.73473 21.8378 2.44621 21.5493C2.15769 21.2608 1.99561 20.8695 1.99561 20.4614V17.3845"
      stroke="#91979F"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.9949 11.2305C13.6942 11.2305 15.0718 9.85292 15.0718 8.15358C15.0718 6.45425 13.6942 5.07666 11.9949 5.07666C10.2956 5.07666 8.91797 6.45425 8.91797 8.15358C8.91797 9.85292 10.2956 11.2305 11.9949 11.2305Z"
      stroke="#91979F"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.8688 17.3847C16.4313 16.466 15.7425 15.6901 14.882 15.1468C14.0216 14.6035 13.0249 14.3152 12.0073 14.3152C10.9897 14.3152 9.99296 14.6035 9.13254 15.1468C8.27212 15.6901 7.58325 16.466 7.14575 17.3847"
      stroke="#91979F"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
