import s from './modal-body.module.scss';

type ModalBodyProps = React.HTMLAttributes<HTMLDivElement> & {
  className?: string;
  children: React.ReactNode;
  size?: Sizes;
};

export interface ModalBodyProposes extends ModalBodyProps {}

type Sizes = 'small' | 'medium' | 'large' | 'newMedium' | 'newLarge' | 'newM' | 'uploadImageModal' | 'without';

export default function ModalBody({ children, className = '', size = 'large', ...rest }: ModalBodyProposes) {
  const paddingSizes = {
    small: '0 24px',
    medium: '15px 50px',
    newMedium: '16px 24px',
    newM: '20px 24px 24px 24px',
    uploadImageModal: '16px 24px 24px 24px',
    large: '25px 50px',
    newLarge: '25px 32px',
    without: 0,
  };
  return (
    <div className={`${s.section} ${className}`} style={{ padding: paddingSizes[size] }} {...rest}>
      {children}
    </div>
  );
}
