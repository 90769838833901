import { FC } from 'react';
import { Dayjs } from 'dayjs';
import { DateRange } from '@mui/x-date-pickers-pro';
import { ArrowLongLeftIcon } from 'icons/arrow-long-left';
import { getValidDateValues } from 'pages/production/controllers/helpers';
import { DateRangeCalendar } from '@mui/x-date-pickers-pro/DateRangeCalendar';
import { MultiInputDateRangeField } from '@mui/x-date-pickers-pro/MultiInputDateRangeField';
import { IconButton, InputAdornment } from '@mui/material';
import { Clear } from '@mui/icons-material';
import s from './custom-calendar-layout.module.scss';

type OwnProps = {
  value?: DateRange<Dayjs>;
  onChange: (dateRange: DateRange<Dayjs>) => void;
};

const separator = () => <ArrowLongLeftIcon className={s.separator_icon} />;

const CustomCalendarLayout: FC<OwnProps> = ({ value, onChange }) => {
  const validValue = getValidDateValues(value);

  const handleOnChange = (val: DateRange<Dayjs>) => {
    const newValue = getValidDateValues(val);
    const isArraysEqual = validValue.every((v, index) => v === newValue[index]);

    if (isArraysEqual) return;

    onChange(newValue);
  };

  const handleClear = (index: 0 | 1) => {
    const newValue = [...validValue] as DateRange<Dayjs>;
    newValue[index] = null;

    handleOnChange(newValue);
  };

  return (
    <div className={s.container}>
      <div className={s.multi_input_container}>
        <MultiInputDateRangeField
          value={validValue}
          format="DD.MM.YYYY"
          slots={{ separator }}
          classes={{ root: s.multi_input }}
          onChange={(newValue) => handleOnChange(newValue)}
          slotProps={{
            textField: ({ position }) => ({
              InputProps: {
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => handleClear(position === 'start' ? 0 : 1)} size="small">
                      <Clear />
                    </IconButton>
                  </InputAdornment>
                ),
                placeholder: 'DD.MM.YYYY',
              },
            }),
          }}
        />
      </div>
      <DateRangeCalendar
        reduceAnimations
        value={validValue}
        className={s.date_picker}
        onChange={(newValue) => handleOnChange(newValue)}
        classes={{ monthContainer: s.date_header }}
        slotProps={{
          nextIconButton: { className: s.date_button },
          previousIconButton: { className: s.date_button },
        }}
      />
    </div>
  );
};

export default CustomCalendarLayout;
