import { FC, SVGProps } from 'react';

export const PriorityHightIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.25015 15C2.92799 15 2.66683 14.7388 2.66683 14.4167L2.66683 11.5001C2.66683 9.5671 4.23381 8.00012 6.16677 8.00012C7.45541 8.00012 8.50006 6.95547 8.50006 5.66683L8.50006 2.99159L7.16256 4.32909C6.93476 4.55689 6.56542 4.55689 6.33762 4.32909C6.10982 4.10129 6.10982 3.73195 6.33762 3.50415L8.67091 1.17085C8.78031 1.06146 8.92868 1 9.08338 1C9.23809 1 9.38646 1.06146 9.49586 1.17085L11.8291 3.50414C12.0569 3.73195 12.0569 4.10129 11.8291 4.32909C11.6013 4.55689 11.232 4.55689 11.0042 4.32909L9.66671 2.99159L9.66671 5.66683C9.66671 7.59979 8.09973 9.16677 6.16677 9.16677C4.87813 9.16677 3.83348 10.2114 3.83347 11.5001L3.83348 14.4167C3.83348 14.7388 3.57231 15 3.25015 15Z"
      fill={props.fill || '#91979F'}
    />
  </svg>
);
