import { FC, SVGProps } from 'react';

export const CheckMarksInSquareIcon: FC<SVGProps<SVGSVGElement>> = ({ ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none" {...props}>
    <path d="M8.25879 6.31641H11.9338" stroke="#5C668C" strokeWidth="1.1" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M4.06641 6.31563L4.59141 6.84063L6.16641 5.26562"
      stroke="#5C668C"
      strokeWidth="1.1"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M8.25879 11.2158H11.9338" stroke="#5C668C" strokeWidth="1.1" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M4.06641 11.216L4.59141 11.741L6.16641 10.166"
      stroke="#5C668C"
      strokeWidth="1.1"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.9 15.5H10.1C13.6 15.5 15 14.1 15 10.6V6.4C15 2.9 13.6 1.5 10.1 1.5H5.9C2.4 1.5 1 2.9 1 6.4V10.6C1 14.1 2.4 15.5 5.9 15.5Z"
      stroke="#5C668C"
      strokeWidth="1.1"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
