import { baseAxiosInstance } from 'axios-config';
import {
  CreatePositionSlotParams,
  ManageUsersParams,
  PositionSlot,
  PositionWithPath,
  CreatePositionBody,
  UpdatePositionBody,
  PositionsByDepartment,
  UpdatePositionSlotParams,
  PositionSlotByDepartmentId,
} from './position-slots.model';

export class PositionSlotsService {
  public static async getPositionSlotsByDepartment(departmentId: string | null) {
    const { data } = await baseAxiosInstance.get<PositionsByDepartment>(`position-slots/all-by-department/${departmentId}`);

    return data;
  }

  public static async getPositionSlotById(positionSlotId: string) {
    const { data } = await baseAxiosInstance.get<PositionSlot>(`position-slots/${positionSlotId}`);

    return data;
  }

  public static async createPositionSlot(body: CreatePositionSlotParams) {
    const { data } = await baseAxiosInstance.post<PositionSlot>(`position-slots/create`, body);

    return data;
  }

  public static async updatePositionSlot(id: string, body: UpdatePositionSlotParams) {
    const { data } = await baseAxiosInstance.put<PositionSlot>(`position-slots/update/${id}`, body);

    return data;
  }

  public static async deletePositionSlot(id: string) {
    const { data } = await baseAxiosInstance.delete<PositionSlot>(`position-slots/delete/${id}`);

    return data;
  }

  public static async manageUsersOnPositionSlot(body: ManageUsersParams) {
    const { data } = await baseAxiosInstance.post(`/user-position-slots/manage-users`, body);

    return data;
  }

  public static async updateParentDepartment(parentId: string, ids: string[]) {
    const { data } = await baseAxiosInstance.put('/position-slots/update-parent-department', {
      parent_department_id: parentId,
      ids,
    });
    return data;
  }

  public static async getAllPositionsWithPath(keyword: string) {
    const { data } = await baseAxiosInstance.get<PositionWithPath[]>('/position-slots/with-path', {
      params: {
        search: keyword,
      },
    });
    return data;
  }

  public static async descriptionsByPositionType(id?: string) {
    const { data } = await baseAxiosInstance.get<string[]>(`/position-slots/names-by-position-types/${id}`);
    return data;
  }

  public static async createWithAssignment(body: CreatePositionBody) {
    const { data } = await baseAxiosInstance.post<PositionSlotByDepartmentId>(
      `/user-position-slots/create-with-assignment`,
      body,
    );
    return data;
  }

  public static async updateWithAssignment(body: UpdatePositionBody) {
    const { data } = await baseAxiosInstance.post<PositionSlotByDepartmentId>(
      `/user-position-slots/update-with-assignment`,
      body,
    );
    return data;
  }

  public static async deleteSeveralPositionSlots(ids: string[]) {
    const { data } = await baseAxiosInstance.post(`/position-slots/delete-many`, ids);
    return data;
  }
}
