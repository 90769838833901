import Checkbox from 'components/checkbox/checkbox';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'redux/store';
import { Stack } from '@mui/material';
import { TasksActions, TasksSelectors } from 'pages/tasks/tasks.controller';
import { ICellRendererParams } from 'ag-grid-community';
import { TaskTableModel } from 'services/task-table.model';
import s from './checkbox-cell.module.scss';

type Props = ICellRendererParams<TaskTableModel>;

const CheckboxCell = ({ ...props }: Props) => {
  const { data } = props;
  if (!data) return null;
  const dispatch = useDispatch();
  const isSelectedTask = useSelector((state: AppState) => TasksSelectors.isSelectedTask(state, data.id));

  return (
    <Stack justifyContent="center" alignItems="center" height="100%" position="relative">
      <Checkbox
        size="medium"
        className={s.checkbox}
        checked={isSelectedTask}
        data-is-checkbox-visible
        onClick={() => dispatch(TasksActions.handleSelectDeselectTasks(data))}
      />
    </Stack>
  );
};

export default CheckboxCell;
