import { SVGProps, FC } from 'react';

const EditPencilUnderlineIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 20 20" {...props} fill="none">
    <path
      stroke="#050505"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M9.771 3.688c.579-.77 1.616-.882 2.321-.253l1.292 1.119c.775.521 1.015 1.63.536 2.475-.025.046-7.105 9.909-7.105 9.909a1.224 1.224 0 0 1-.976.525l-2.71.037-.612-2.88c-.086-.405 0-.829.235-1.157l7.02-9.775ZM9.096 6.174l2.782 2.38M10.487 17.5h7.5"
    />
  </svg>
);
export default EditPencilUnderlineIcon;
