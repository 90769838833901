import { FC, SVGProps } from 'react';

export const SecurityIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.37545 2.38853L4.87762 3.94312C3.432 4.58561 2.46993 6.02229 2.58672 7.59994C2.93455 12.2986 4.38504 14.5204 7.94439 17.0003C9.17759 17.8595 10.8237 17.8618 12.0555 17.0005C15.6213 14.5074 17.0257 12.2598 17.3953 7.62183C17.5219 6.03386 16.5583 4.58129 15.1026 3.93431L11.6246 2.38853C10.5903 1.92886 9.40971 1.92886 8.37545 2.38853Z"
      stroke={props.stroke || 'white'}
      strokeWidth="1.5"
      strokeLinejoin="round"
    />
    <path
      d="M7.5 10.0002L8.83615 11.0691C9.25403 11.4034 9.86103 11.3495 10.2134 10.9467L12.5 8.3335"
      stroke={props.stroke || 'white'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
