import { TextField, TextFieldProps } from '@mui/material';
import s from './input.module.scss';

type Props = TextFieldProps;

export const StartAdornmentIconProps = {
  fontSize: 'small',
  style: { fontSize: 18, marginLeft: -3, marginRight: 4 },
};

export function Input({ className, ...props }: Props) {
  return <TextField className={`${s.text_field} ${className && className}`} {...props} />;
}
