import { baseAxiosInstance } from 'axios-config';
import {
  CreateProductConfigurationPhotoData,
  CreateProductConfigurationPhotoUrlData,
  ProductConfigurationPhoto,
  SetProductConfigurationPhotoToCoverData,
} from 'services/product-configuration-photo.model';

export class ProductConfigurationPhotoService {
  public static async getAll(id: string) {
    const { data } = await baseAxiosInstance.get<ProductConfigurationPhoto[]>(`/product-configuration-photos/all`, {
      params: {
        product_configuration_id: id,
      },
    });
    return data;
  }

  public static async getById(id: string) {
    const { data } = await baseAxiosInstance.get<ProductConfigurationPhoto>(`/product-configuration-photos/${id}`);
    return data;
  }

  public static async create(body: CreateProductConfigurationPhotoData | FormData) {
    const { data } = await baseAxiosInstance.post<ProductConfigurationPhoto>(`/product-configuration-photos/create`, body);
    return data;
  }

  // TODO: mock
  public static async createByUrl(body: CreateProductConfigurationPhotoUrlData) {
    const { data } = await baseAxiosInstance.post<ProductConfigurationPhoto>(`/product-configuration-photos/create-by-url`, body);
    return data;
  }

  public static async delete(id: string) {
    const { data } = await baseAxiosInstance.delete(`/product-configuration-photos/${id}`);
    return data;
  }

  public static async setDefaultCover(id: string, body: SetProductConfigurationPhotoToCoverData) {
    const { data } = await baseAxiosInstance.put(`/product-configuration-photos/${id}`, body);
    return data;
  }
}
