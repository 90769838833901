import { GroupByEnum, ProductionWorkflow, ProductionWorkflowResponseDataT } from 'services/production-workflow.model';
import { WebsocketEvent } from 'types/common-enums';

export enum Page {
  Workflow = 'workflow',
  Production = 'production',
  InfoDropdownWorkflow = 'info_dropdown_workflow',
  InfoDropdownProduction = 'info_dropdown_production',
  InfoDropdownTask = 'info_dropdown_task',
}

export type ProductionItemsT = {
  groupBy: GroupByEnum;
  data: ProductionWorkflowResponseDataT;
};

export type GetAndSetProductionsByIdsArgs = {
  groupId: string;
  productionIds: string[];
  fetchedProductionWorkflowsNumber: number;
};

export type HandleLaunchingProductionsIdsArgs = {
  value: string[];
  replaceWithNewValue?: boolean;
  removeLaunchedProductionIds?: boolean;
};

export type SuccessWebsocketResponseForProductionWorkflowT = {
  event: WebsocketEvent.LaunchFinished;
  production: ProductionWorkflow;
  launchingProductionsCount: number;
};

export type ErrorWebsocketResponseForProductionWorkflowT = {
  event: WebsocketEvent.LaunchFailed;
  error: Error;
  production: ProductionWorkflow;
};

export type WebsocketResponseMessageForProductionWorkflowT =
  | SuccessWebsocketResponseForProductionWorkflowT
  | ErrorWebsocketResponseForProductionWorkflowT;
