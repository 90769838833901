import { FC, SVGProps, useMemo, useState } from 'react';

type Props = SVGProps<SVGSVGElement> & {
  linesColor?: string;
  withBorder?: boolean;
  withHoverEffect?: boolean;
};

export const DeactivatedIcon: FC<Props> = ({
  withBorder = false,
  linesColor = '#ee994c',
  className,
  withHoverEffect,
  ...props
}) => {
  const [isHover, setIsHover] = useState(false);
  const [color, setColor] = useState(linesColor);

  const style = useMemo(() => {
    if (withBorder) {
      const basik = {
        display: 'flex',
        padding: '4px 6px',
        border: `1px solid ${linesColor}`,
        borderRadius: '8px',
      };

      if (isHover) {
        setColor('#ffffff');
        return {
          ...basik,
          background: linesColor,
          cursor: 'pointer',
        };
      }

      setColor(linesColor);
      return basik;
    }
    return { display: 'flex' };
  }, [withBorder, isHover, linesColor]);

  return (
    <div
      style={style}
      className={className}
      onMouseEnter={() => withHoverEffect && setIsHover(true)}
      onMouseLeave={() => withHoverEffect && setIsHover(false)}
    >
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" stroke={color} {...props}>
        <g clipPath="url(#clip0_11233_6824)">
          <path
            d="M13.3334 2.35986C16.2765 3.64579 18.3334 6.58259 18.3334 9.99979C18.3334 14.6022 14.6024 18.3331 10 18.3331C5.39765 18.3331 1.66669 14.6022 1.66669 9.99979C1.66669 6.58259 3.72351 3.64579 6.66669 2.35986"
            stroke={props.stroke ?? '#616161'}
            strokeWidth="1.5"
            strokeLinecap="round"
          />
          <path
            d="M10 1.6665V4.99984"
            stroke={props.stroke ?? '#616161'}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_11233_6824">
            <rect width="20" height="20" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};
