import { StateController } from 'state-controller';
import { Actions as WorkflowTemplateController } from '../../workflow-template.controller';

export type WorkflowPreviewModalState = {
  isOpen: boolean;
};

const defaultState: WorkflowPreviewModalState = {
  isOpen: false,
};

const stateController = new StateController<WorkflowPreviewModalState>('WORKFLOW_TEMPLATE_PREVIEW', defaultState);

export class Actions {
  public static open(id: string) {
    return async (dispatch) => {
      dispatch(stateController.setState({ isOpen: true }));
      dispatch(WorkflowTemplateController.initWorkflowPage(id));
    };
  }

  public static disposeState() {
    return (dispatch) => {
      dispatch(stateController.setState({ ...defaultState }));
      dispatch(WorkflowTemplateController.clearWorkflowPage());
    };
  }
}

export class Selectors {}

export const reducer = stateController.getReducer();
