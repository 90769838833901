import { InputNames, SettingsActions } from 'pages/settings/settings.controller';
import { connect } from 'react-redux';
import { AppState } from 'redux/store';
import s from 'pages/settings/settings.module.scss';
import InputWithButtons from 'components/ui-new/input-with-buttons/input-with-buttons';
import { Tooltip } from '@mui/material';
import { InfoIcon } from 'icons/info';
import { onlyNumbersFormatter, reportingPeriodFormatter } from 'pages/settings/helpers/value-formatter';
import Checkbox from 'components/checkbox/checkbox';

type StateProps = {
  defaultDeadline: number;
  isDeadlineFromExternal: boolean;
  priorityTaskItemByXDays: number;
  priorityProductionItemByXDays: number;
  isAllowedUndoChangingTaskStatus: boolean;
  updateTaskPriorityBeforeDeadline: boolean;
  updateProductionPriorityBeforeDeadline: boolean;
  correctionDaysAfterReachingReportingPeriod: number;
};

type DispatchProps = {
  onChangeDeadlineFromExternal: () => void;
  onChangeUndoChangingTaskStatus: () => void;
  onChangePriorityTaskItemByXDays: () => void;
  onChangePriorityProductionItemByXDays: () => void;
  onSaveInputValue: (inputName: InputNames, value: string | number) => void;
};

type Props = StateProps & DispatchProps;

const ProductionTabContent = ({
  defaultDeadline,
  isDeadlineFromExternal,
  priorityTaskItemByXDays,
  priorityProductionItemByXDays,
  isAllowedUndoChangingTaskStatus,
  updateTaskPriorityBeforeDeadline,
  updateProductionPriorityBeforeDeadline,
  correctionDaysAfterReachingReportingPeriod,
  onSaveInputValue,
  onChangeDeadlineFromExternal,
  onChangeUndoChangingTaskStatus,
  onChangePriorityTaskItemByXDays,
  onChangePriorityProductionItemByXDays,
}: Props) => {
  return (
    <div className={s.section}>
      <div className={s.row}>
        <div className={s.title_container}>
          <b className={s.row_title}>Default production deadline</b>
          <p className={s.row_description}>Calendar days to perform production (will set on each newly created product)</p>
        </div>
        <div className={s.right_col}>
          <InputWithButtons
            value={`${defaultDeadline}`}
            placeholder="Specify the number of days"
            valueFormatter={(newValue) => onlyNumbersFormatter(newValue)}
            onConfirmClick={(value) => onSaveInputValue(InputNames.DefaultDeadline, parseInt(value, 10))}
          />
        </div>
      </div>
      <div className={s.row}>
        <b className={s.row_title}>Overwrite production deadline values from an external system</b>
        <Checkbox size="medium" checked={isDeadlineFromExternal} onClick={onChangeDeadlineFromExternal} />
      </div>
      <div className={s.row} data-with-checkbox>
        <div className={s.title_container}>
          <b className={s.row_title}>
            Change priority of production item X days before deadline
            <Tooltip
              placement="top"
              title={
                <>
                  &bull;If the checkbox is disabled, system won’t change priority automatically.
                  <br /> &bull;If the checkbox is enabled and the value in the input field is “0”, system will change priority on
                  the deadline day.
                </>
              }
            >
              <div className={s.icon_container}>
                <InfoIcon className={s.info_icon} />
              </div>
            </Tooltip>
          </b>
          <b className={s.row_description}>
            Establish number of days before deadline when system should automatically change production priority to Highest
          </b>
        </div>
        <div className={s.right_col}>
          <div className={s.right_col_group}>
            <InputWithButtons
              value={`${priorityProductionItemByXDays}`}
              disabled={!updateProductionPriorityBeforeDeadline}
              valueFormatter={(newValue) => onlyNumbersFormatter(newValue)}
              onConfirmClick={(value) => onSaveInputValue(InputNames.PriorityProductionItemByXDays, parseInt(value, 10))}
            />
            <div className={s.checkbox_container}>
              <span>Enable days</span>
              <Checkbox
                size="medium"
                checked={updateProductionPriorityBeforeDeadline}
                onClick={onChangePriorityProductionItemByXDays}
              />
            </div>
          </div>
        </div>
      </div>

      <div className={s.row} data-with-checkbox>
        <div className={s.title_container}>
          <b className={s.row_title}>
            Change priority of tasks of production item X days before deadline
            <Tooltip
              placement="top"
              title={
                <>
                  &bull;If the checkbox is disabled, system won’t change priority automatically.
                  <br /> &bull;If the checkbox is enabled and the value in the input field is “0”, system will change priority on
                  the deadline day.
                </>
              }
            >
              <div className={s.icon_container}>
                <InfoIcon className={s.info_icon} />
              </div>
            </Tooltip>
          </b>
          <b className={s.row_description}>
            Establish number of days before deadline when system should automatically change tasks priority to Highest
          </b>
        </div>
        <div className={s.right_col}>
          <div className={s.right_col_group}>
            <InputWithButtons
              value={`${priorityTaskItemByXDays}`}
              disabled={!updateTaskPriorityBeforeDeadline}
              valueFormatter={(newValue) => onlyNumbersFormatter(newValue)}
              onConfirmClick={(value) => onSaveInputValue(InputNames.PriorityTaskItemByXDays, parseInt(value, 10))}
            />
            <div className={s.checkbox_container}>
              <span>Enable days</span>
              <Checkbox size="medium" checked={updateTaskPriorityBeforeDeadline} onClick={onChangePriorityTaskItemByXDays} />
            </div>
          </div>
        </div>
      </div>

      <div className={s.row}>
        <b className={s.row_title}>
          Allow to change task status from “Done” to “In progress”
          <Tooltip
            title="By enabling this setting, performer is able to change task status from “Done” to “In progress” during 15 sec"
            placement="top"
          >
            <div className={s.icon_container}>
              <InfoIcon className={s.info_icon} />
            </div>
          </Tooltip>
        </b>
        <Checkbox size="medium" checked={isAllowedUndoChangingTaskStatus} onClick={onChangeUndoChangingTaskStatus} />
      </div>

      <div className={s.row}>
        <div className={s.title_container}>
          <b className={s.row_title}>
            Task correction days after reaching “reporting period”
            <Tooltip
              placement="top"
              title={
                <>
                  &bull; “Reporting period” is 1 calendar month. &bull; “Closed reporting period” is 1 calendar month + number of
                  days specified in this field.
                  <br /> When “closed reporting period” day has come, “Rewards” menu and “Reward summary” table can’t be edited.
                </>
              }
            >
              <div className={s.icon_container}>
                <InfoIcon className={s.info_icon} />
              </div>
            </Tooltip>
          </b>
          <b className={s.row_description}>
            Indicates number of days when fields “Time spent” and “Reward correction” can be edited after “reporting period” is
            reached
          </b>
        </div>
        <div className={s.right_col}>
          <InputWithButtons
            value={`${correctionDaysAfterReachingReportingPeriod}`}
            valueFormatter={(newValue) => reportingPeriodFormatter(newValue)}
            onConfirmClick={(value) =>
              onSaveInputValue(InputNames.CorrectionDaysAfterReachingReportingPeriod, parseInt(value, 10))
            }
          />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: AppState): StateProps => ({
  defaultDeadline: state.settings.defaultDeadline,
  isDeadlineFromExternal: state.settings.isDeadlineFromExternal,
  priorityTaskItemByXDays: state.settings.priorityTaskItemByXDays,
  priorityProductionItemByXDays: state.settings.priorityProductionItemByXDays,
  isAllowedUndoChangingTaskStatus: state.settings.isAllowedUndoChangingTaskStatus,
  updateTaskPriorityBeforeDeadline: state.settings.updateTaskPriorityBeforeDeadline,
  updateProductionPriorityBeforeDeadline: state.settings.updateProductionPriorityBeforeDeadline,
  correctionDaysAfterReachingReportingPeriod: state.settings.correctionDaysAfterReachingReportingPeriod,
});

const mapDispatchToProps: DispatchProps = {
  onSaveInputValue: SettingsActions.onSaveInputValue,
  onChangeDeadlineFromExternal: SettingsActions.onChangeDeadlineFromExternal,
  onChangeUndoChangingTaskStatus: SettingsActions.onChangeUndoChangingTaskStatus,
  onChangePriorityTaskItemByXDays: SettingsActions.onChangePriorityTaskItemByXDays,
  onChangePriorityProductionItemByXDays: SettingsActions.onChangePriorityProductionItemByXDays,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductionTabContent);
