import { FC, SVGProps } from 'react';

export const PriorityMediumIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 12 13" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M-0.000182962 10C-0.000182927 10.2761 0.223675 10.5 0.499817 10.5L2.99982 10.5C4.65667 10.5 5.99982 9.15685 5.99982 7.5C5.99982 6.39543 6.89525 5.5 7.99982 5.5L10.2929 5.5L9.14647 6.64645C8.95121 6.84171 8.95121 7.15829 9.14647 7.35355C9.34173 7.54881 9.65832 7.54881 9.85358 7.35355L11.8536 5.35355C11.9473 5.25978 12 5.13261 12 5C12 4.86739 11.9473 4.74021 11.8536 4.64645L9.85358 2.64645C9.65832 2.45118 9.34173 2.45118 9.14647 2.64645C8.95121 2.84171 8.95121 3.15829 9.14647 3.35355L10.2929 4.5L7.99982 4.5C6.34296 4.5 4.99982 5.84314 4.99982 7.5C4.99982 8.60457 4.10439 9.5 2.99982 9.5L0.499817 9.5C0.223675 9.5 -0.000182968 9.72386 -0.000182962 10Z"
      fill="#FFCD1E"
    />
  </svg>
);
