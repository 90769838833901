import s from './breadcrumb-badge.module.scss';

type OwnProps = {
  version?: string;
  isDraft?: boolean;
  isDeleted?: boolean;
  type: 'red' | 'gray' | 'white';
};

const BreadcrumbBadge: React.FC<OwnProps> = ({ type, version, isDraft, isDeleted }) => {
  return (
    <div
      className={`
      ${s.badge}
      ${type === 'red' && s.red}
      ${type === 'gray' && s.gray}
      ${type === 'white' && s.white}
    `}
    >
      {version && <span>{version}</span>}
      {isDraft && <span>Draft</span>}
      {isDeleted && <span>Deleted</span>}
    </div>
  );
};

export default BreadcrumbBadge;
