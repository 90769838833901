import { StateController } from 'state-controller';
import { Actions as WorkflowTaskTemplateController } from 'pages/product-flow/pages/workflow-task-template/workflow-task-template.controller';

export type TaskPreviewModalState = {
  isOpen: boolean;
};

const defaultState: TaskPreviewModalState = {
  isOpen: false,
};

const stateController = new StateController<TaskPreviewModalState>('WORKFLOW_TASK_TEMPLATE_PREVIEW', defaultState);

export class Actions {
  public static open(id: string) {
    return async (dispatch) => {
      dispatch(stateController.setState({ isOpen: true }));
      dispatch(WorkflowTaskTemplateController.init(id));
    };
  }

  public static disposeState() {
    return (dispatch) => {
      dispatch(stateController.setState({ ...defaultState }));
      dispatch(WorkflowTaskTemplateController.disposeState());
    };
  }
}

export class Selectors {}

export const reducer = stateController.getReducer();
