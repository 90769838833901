import { FC, SVGProps } from 'react';

export const RestoreIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none" {...props}>
    <path
      d="M5.69272 3.65663C6.30205 3.47453 6.97441 3.35547 7.71681 3.35547C11.0716 3.35547 13.7891 6.07294 13.7891 9.42775C13.7891 12.7826 11.0716 15.5 7.71681 15.5C4.362 15.5 1.64453 12.7826 1.64453 9.42775C1.64453 8.18108 2.02274 7.01845 2.66708 6.05193"
      stroke="#829DB6"
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M4.82422 3.82526L6.84831 1.5" stroke="#829DB6" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M4.82422 3.82617L7.18449 5.5491" stroke="#878B92" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);
