import { tooltipClasses, TooltipProps, Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';

export const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#d6e7f3',
    color: '#141414',
    boxShadow: '0px 5px 24px 0px rgba(0, 0, 0, 0.10)',
    fontSize: 14,
  },
}));
