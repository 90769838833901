import { FC, SVGProps } from 'react';

export const Hierarchy3: FC<SVGProps<SVGSVGElement>> = ({ ...props }) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M3.33366 5.33407L3.33366 11.6674C3.33366 12.4007 3.93366 13.0007 4.66699 13.0007H7.66699"
      stroke="#141414"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M6 14.668L8 13.0013L6 11.3346" stroke="#292D32" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M13.333 11.3335V4.66683" stroke="#292D32" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M13.3337 12.0013C12.5973 12.0013 12.0003 12.5983 12.0003 13.3346C12.0003 14.071 12.5973 14.668 13.3337 14.668C14.07 14.668 14.667 14.071 14.667 13.3346C14.667 12.5983 14.07 12.0013 13.3337 12.0013Z"
      stroke="#292D32"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.3337 1.33407C12.5973 1.33407 12.0003 1.93102 12.0003 2.6674C12.0003 3.40378 12.5973 4.00073 13.3337 4.00073C14.07 4.00073 14.667 3.40378 14.667 2.6674C14.667 1.93102 14.07 1.33407 13.3337 1.33407Z"
      stroke="#292D32"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.16634 1.00016C1.96972 1.00016 0.999674 1.97021 0.999674 3.16683C0.999674 4.36345 1.96972 5.3335 3.16634 5.3335C4.36296 5.3335 5.33301 4.36345 5.33301 3.16683C5.33301 1.97021 4.36296 1.00016 3.16634 1.00016Z"
      stroke="#292D32"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
