import { Button } from '@mui/material';

import { EmojiCompanyIcon } from 'icons/emodji-company';
import s from './sign-up.module.scss';
import shared_s from '../../shared-styles.module.scss';

const SignUp = () => {
  return (
    <div className={shared_s.success_wrapper_sign_up}>
      <div className={`${shared_s.emoji} ${s.background}`} style={{ backgroundColor: '#DBE9FF' }}>
        <EmojiCompanyIcon />
      </div>
      <h3 className={shared_s.heading}>Sign up a new company?</h3>
      <Button
        onClick={() => window.open('https://calendly.com/hesh-app/demo-session?utm_source=landing', '_blank')}
        className={shared_s.button}
      >
        Book a Demo
      </Button>
    </div>
  );
};

export default SignUp;
