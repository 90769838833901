import { FC, SVGProps } from 'react';

export const XCrossIcon: FC<SVGProps<SVGSVGElement>> = ({ ...props }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="black" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M9.70711 8.29289C9.31658 7.90237 8.68342 7.90237 8.29289 8.29289C7.90237 8.68342 7.90237 9.31658 8.29289 9.70711L10.5858 12L8.29292 14.2929C7.9024 14.6834 7.9024 15.3166 8.29292 15.7071C8.68345 16.0977 9.31661 16.0977 9.70714 15.7071L12 13.4142L14.2929 15.7071C14.6834 16.0976 15.3166 16.0976 15.7071 15.7071C16.0976 15.3166 16.0976 14.6834 15.7071 14.2929L13.4142 12L15.7071 9.70714C16.0977 9.31661 16.0977 8.68345 15.7071 8.29292C15.3166 7.9024 14.6834 7.9024 14.2929 8.29292L12 10.5858L9.70711 8.29289Z"
      fillOpacity="0.9"
      strokeWidth="1px"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
      fillOpacity="0.9"
      strokeWidth="1px"
    />
  </svg>
);
