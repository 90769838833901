import { AppState } from 'redux/store';
import { useSelector } from 'react-redux';
import { getDesiredTenantByUrl } from 'tenant-helpers';
import { PropsWithChildren, useState, useEffect } from 'react';
import { Selectors as AuthSelectors } from 'redux/auth.controller';
import Header from 'modules/authorized-layout/components/header/header';
import { SidebarMenu } from 'modules/authorized-layout/components/sidebar-menu/sidebar-menu';
import s from './authorize-layout.module.scss';

export const HEADER_HEIGHT = 56;

export const AuthorizeLayoutWrapper = ({ children }: PropsWithChildren<{}>) => {
  const [scrollCorrection, setScrollCorrection] = useState(0);
  const [isMobileMenuOpened, setIsMobileMenuOpened] = useState(false);

  const userTenants = useSelector((state: AppState) => state.auth.userTenants);
  const user_permissions = useSelector((state: AppState) => state.auth?.user?.user_permissions);
  const isUserActive = useSelector((state: AppState) => AuthSelectors.isUserActiveInTenant(state));

  const desiredTenant = getDesiredTenantByUrl(window.location.href, userTenants);
  const isShowSidebar = desiredTenant && (!isUserActive || user_permissions);

  const handleScroll = () => {
    const scrolledPixels = window.scrollY;
    if (scrolledPixels < HEADER_HEIGHT) {
      setScrollCorrection(scrolledPixels);
    } else {
      setScrollCorrection(HEADER_HEIGHT);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className={s.container}>
      <div className={s.content_wrapper}>
        <Header isMobileMenuOpened={isMobileMenuOpened} setIsMobileMenuOpened={setIsMobileMenuOpened} />
        <div className={s.content_with_sidebar}>
          <aside className={s.sidebar} style={{ height: `calc(100vh - ${HEADER_HEIGHT}px + ${scrollCorrection}px)` }}>
            {isShowSidebar && (
              <SidebarMenu isMobileMenuOpened={isMobileMenuOpened} setIsMobileMenuOpened={setIsMobileMenuOpened} />
            )}
          </aside>
          <div className={s.content}>{children}</div>
        </div>
      </div>
    </div>
  );
};
