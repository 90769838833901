import { FC, SVGProps } from 'react';

export const EyeIcon: FC<SVGProps<SVGSVGElement>> = ({ ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
    <path
      d="M15.738 12.3653C15.738 14.4327 14.0674 16.1033 12 16.1033C9.93258 16.1033 8.26196 14.4327 8.26196 12.3653C8.26196 10.2979 9.93258 8.62732 12 8.62732C14.0674 8.62732 15.738 10.2979 15.738 12.3653Z"
      stroke="#878B92"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.9998 21.0002C15.6857 21.0002 19.1209 18.8284 21.5119 15.0695C22.4517 13.5973 22.4517 11.1227 21.5119 9.65043C19.1209 5.89153 15.6857 3.71973 11.9998 3.71973C8.31404 3.71973 4.87883 5.89153 2.48775 9.65043C1.54803 11.1227 1.54803 13.5973 2.48775 15.0695C4.87883 18.8284 8.31404 21.0002 11.9998 21.0002Z"
      stroke="#878B92"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
