import { FC, SVGProps } from 'react';

export const TimeIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M15 9C15 12.864 11.864 16 8 16C4.136 16 1 12.864 1 9C1 5.136 4.136 2 8 2C11.864 2 15 5.136 15 9Z"
      stroke="#141414"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.5972 11.2259L8.42721 9.93093C8.04921 9.70693 7.74121 9.16793 7.74121 8.72693V5.85693"
      stroke="#141414"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
