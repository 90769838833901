import { IdName } from 'types/common-types';

export enum BonusType {
  Percentage = 'Percentage',
  FixedAmount = 'FixedAmount',
}
export type BonusOptions = {
  product_option: IdName;
  product_option_value: IdName;
};

export type WorkflowTaskTemplateBonus = {
  id?: string;
  task_template_id?: string;
  product_option_combinations: {
    product_option: IdName;
    product_option_value: IdName;
  }[];
  type?: BonusType;
  value?: string;
};

export type ManageWorkflowTaskTemplateBonuses = {
  task_template_id: string;
  values: WorkflowTaskTemplateBonus[];
};
