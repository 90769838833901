import s from './modal-footer.module.scss';

export type ModalFooterProps = React.HTMLAttributes<HTMLDivElement> & {
  className?: string;
  children: React.ReactNode;
  witohutShadow?: boolean;
  withBorderTop?: boolean;
  size?: Sizes;
};

type Sizes = 'small' | 'newSmall' | 'medium' | 'large' | 'newMedium' | 'newLarge';

export default function ModalFooter({
  children,
  size = 'medium',
  witohutShadow = false,
  withBorderTop = false,
  className = '',
  ...rest
}: ModalFooterProps) {
  const paddingSizes = {
    small: '24px',
    newSmall: '16px 24px 24px',
    medium: '8px 24px 24px 24px',
    newMedium: '14px 24px',
    large: '26px',
    newLarge: '20px 24px 24px 24px',
  };

  return (
    <div
      style={{ padding: paddingSizes[size] }}
      className={`${witohutShadow ? s.section_without_shadow : s.section} ${withBorderTop && s.with_border_top} ${className}`}
      {...rest}
    >
      {children}
    </div>
  );
}
