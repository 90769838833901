import { FC, SVGProps } from 'react';

export const TasksIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M20 8.25V18C20 21 18.21 22 16 22H8.00002C5.79002 22 4.00002 21 4.00002 18V8.25C4.00002 5 5.79002 4.25 8.00002 4.25C8.00002 4.87 8.25 5.43 8.66 5.84C9.07 6.25 9.63002 6.5 10.25 6.5H13.75C14.99 6.5 16 5.49 16 4.25C18.21 4.25 20 5 20 8.25Z"
      stroke="#878B92"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16 4.25C16 5.49 14.99 6.5 13.75 6.5H10.25C9.63001 6.5 9.06998 6.25 8.65998 5.84C8.24998 5.43 8.00001 4.87 8.00001 4.25C8.00001 3.01 9.01001 2 10.25 2H13.75C14.37 2 14.93 2.25 15.34 2.66C15.75 3.07 16 3.63 16 4.25Z"
      stroke="#878B92"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M8.00001 13H12" stroke="#878B92" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M8.00001 17H16" stroke="#878B92" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);
