import { createTheme, Shadows } from '@mui/material/styles';
import crossIcon from 'icons/svg/clouse.svg';
import { useMemo } from 'react';
import type {} from '@mui/x-data-grid-pro/themeAugmentation';
// import { getAllButtonsStyles } from 'styles/mui-theme-styles/button-styles';

export enum ThemeModeEnum {
  light = 'light',
  dark = 'dark',
}

declare module '@mui/material/Button' {
  interface ButtonPropsOvverrides {
    shape: true;
  }
  interface ButtonPropsSizeOverrides {
    XL: true;
    L: true;
    M: true;
    S: true;
    XS: true;
    XXS: true;
  }
  interface ButtonPropsColorOverrides {
    white: true;
    black: true;
    info_light: true;
  }
  interface ButtonPropsVariantOverrides {
    text_bg: true;
  }
}
declare module '@mui/material/styles' {
  interface Palette {
    black: string;
    white: string;
  }
  interface PaletteOptions {
    black: PaletteOptions['primary'];
    white: PaletteOptions['primary'];
    info_light: PaletteOptions['primary'];
  }
}

export const useMuiTheme = () => {
  return useMemo(() => theme, []);
};

const customShadows: Shadows = [
  'none',
  '0px 1px 3px -1px rgba(0,0,0,0.05),0px 1px 1px 0px rgba(0,0,0,0.035),0px 1px 1px 0px rgba(0,0,0,0.02)',
  '0px 2px 3px -2px rgba(0,0,0,0.05),0px 2px 2px 0px rgba(0,0,0,0.035),0px 1px 3px 0px rgba(0,0,0,0.02)',
  '0px 2px 5px -2px rgba(0,0,0,0.05),0px 3px 3px 0px rgba(0,0,0,0.035),0px 1px 5px 0px rgba(0,0,0,0.02)',
  '0px 2px 6px -1px rgba(0,0,0,0.05),0px 4px 4px 0px rgba(0,0,0,0.035),0px 1px 7px 0px rgba(0,0,0,0.02)',
  '0px 2px 7px -1px rgba(0,0,0,0.05),0px 5px 7px 0px rgba(0,0,0,0.035),0px 1px 11px 0px rgba(0,0,0,0.02)',
  '0px 2px 7px -1px rgba(0,0,0,0.05),0px 6px 9px 0px rgba(0,0,0,0.035),0px 1px 15px 0px rgba(0,0,0,0.02)',
  '0px 3px 7px -2px rgba(0,0,0,0.05),0px 7px 9px 1px rgba(0,0,0,0.035),0px 2px 14px 1px rgba(0,0,0,0.02)',
  '0px 4px 7px -3px rgba(0,0,0,0.05),0px 8px 9px 1px rgba(0,0,0,0.035),0px 3px 12px 2px rgba(0,0,0,0.02)',
  '0px 4px 8px -3px rgba(0,0,0,0.05),0px 9px 10px 1px rgba(0,0,0,0.035),0px 3px 14px 2px rgba(0,0,0,0.02)',
  '0px 5px 8px -3px rgba(0,0,0,0.05),0px 10px 12px 1px rgba(0,0,0,0.035),0px 4px 14px 3px rgba(0,0,0,0.02)',
  '0px 5px 9px -4px rgba(0,0,0,0.05),0px 11px 13px 1px rgba(0,0,0,0.035),0px 4px 16px 3px rgba(0,0,0,0.02)',
  '0px 6px 10px -4px rgba(0,0,0,0.05),0px 12px 15px 2px rgba(0,0,0,0.035),0px 5px 18px 4px rgba(0,0,0,0.02)',
  '0px 6px 10px -4px rgba(0,0,0,0.05),0px 13px 17px 2px rgba(0,0,0,0.035),0px 5px 20px 4px rgba(0,0,0,0.02)',
  '0px 6px 11px -4px rgba(0,0,0,0.05),0px 14px 19px 2px rgba(0,0,0,0.035),0px 5px 22px 4px rgba(0,0,0,0.02)',
  '0px 7px 11px -5px rgba(0,0,0,0.05),0px 15px 21px 2px rgba(0,0,0,0.035),0px 6px 24px 5px rgba(0,0,0,0.02)',
  '0px 7px 12px -5px rgba(0,0,0,0.05),0px 16px 21px 2px rgba(0,0,0,0.035),0px 6px 26px 5px rgba(0,0,0,0.02)',
  '0px 7px 13px -5px rgba(0,0,0,0.05),0px 17px 23px 2px rgba(0,0,0,0.035),0px 6px 28px 5px rgba(0,0,0,0.02)',
  '0px 8px 13px -5px rgba(0,0,0,0.05),0px 18px 25px 2px rgba(0,0,0,0.035),0px 7px 30px 6px rgba(0,0,0,0.02)',
  '0px 8px 14px -6px rgba(0,0,0,0.05),0px 19px 26px 2px rgba(0,0,0,0.035),0px 7px 32px 6px rgba(0,0,0,0.02)',
  '0px 9px 15px -6px rgba(0,0,0,0.05),0px 20px 28px 3px rgba(0,0,0,0.035),0px 8px 34px 7px rgba(0,0,0,0.02)',
  '0px 9px 15px -6px rgba(0,0,0,0.05),0px 21px 30px 3px rgba(0,0,0,0.035),0px 8px 36px 7px rgba(0,0,0,0.02)',
  '0px 9px 16px -6px rgba(0,0,0,0.05),0px 22px 32px 3px rgba(0,0,0,0.035),0px 8px 38px 7px rgba(0,0,0,0.02)',
  '0px 10px 16px -7px rgba(0,0,0,0.05),0px 23px 33px 3px rgba(0,0,0,0.035),0px 9px 40px 8px rgba(0,0,0,0.02)',
  '0px 10px 17px -7px rgba(0,0,0,0.05),0px 24px 35px 3px rgba(0,0,0,0.035),0px 9px 42px 8px rgba(0,0,0,0.02)',
];
export const grey = {
  disabled: '#F9F9F9',
  light: '#F8F8F8',
  main: '#F5F5F5',
  dark: '#eeeeee',
  darker: '#c2c2c2',
};

export const boxShadow = {
  main: '0px 1px 5px 2px rgb(0 0 0 / 5%)',
  dark: '0px 2px 2px 2px rgb(0 0 0 / 8%)',
  spreadLight: '0px 2px 15px rgb(0 0 0 / 2%)',
  spreadDark: '0px 2px 10px rgba(255 255 255 / 8%)',
};
export const tableRowHoverColor = 'rgba(231, 231, 231, 0.1)';

const { palette } = createTheme();
const createMuiTheme = () => {
  return createTheme({
    typography: {
      fontFamily: ['Montserrat', 'sans-serif'].join(','),
      button: {
        lineHeight: 1.5,
        fontSize: '14px',
      },
      allVariants: {},
    },
    transitions: {
      // create: () => 'none', // disabled animation at all
      // duration: {
      //   shortest: 50,
      //   shorter: 100,
      //   short: 150,
      //   // most basic recommended timing
      //   standard: 200,
      //   // this is to be used in complex animations
      //   complex: 250,
      //   // recommended when something is entering screen
      //   enteringScreen: 175,
      //   // recommended when something is leaving screen
      //   leavingScreen: 150,
      // },
    },
    palette: {
      text: {},
      action: {},
      background: {},
      // divider: '',

      primary: {
        main: '#0851FE',
      },
      secondary: {
        main: '#BCC4E3',
      },
      error: {
        main: '#FF4444',
      },
      black: palette.augmentColor({
        color: {
          light: '#454545',
          main: '#141414',
          dark: '#000000',
        },
      }),
      white: palette.augmentColor({
        color: {
          light: '#ffffff',
          main: '#ffffff',
          dark: '#f6f6f6',
        },
      }),
      info_light: palette.augmentColor({
        color: {
          main: '#EEF1FC',
        },
      }),
      warning: {
        main: '#F8A586',
      },
    },
    shadows: customShadows,
    shape: {
      borderRadius: 12,
    },
    components: {
      // *** BASE ***
      MuiCssBaseline: {
        styleOverrides: {
          // body: {
          //   '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
          //     backgroundColor: 'rgba(255,255,255,0.9)',
          //     backdropFilter: 'blur(3px)',
          //     width: 3,
          //     height: 3,
          //     '&:start': {
          //       height: 10,
          //       display: 'block',
          //     },
          //   },
          //   '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
          //     borderRadius: 7,
          //     backgroundColor: '#b3b3b3',
          //   },
          //   '&::-webkit-scrollbar-track, & *::-webkit-scrollbar-track': {
          //     backgroundColor: 'transparent',
          //     boxShadow: 'none',
          //   },
          // },
        },
      },

      // *** CARDS & CONTAINERS ***
      MuiCard: {
        styleOverrides: {
          root: {
            borderWidth: '1px',
            borderStyle: 'solid',
            borderColor: 'white',
            boxShadow: boxShadow.spreadLight,
          },
        },
      },
      MuiAccordion: {
        styleOverrides: {
          root: {
            boxShadow: boxShadow.spreadLight,
          },
        },
      },
      MuiAccordionSummary: {
        styleOverrides: {
          root: {},
          expandIconWrapper: {
            marginLeft: -5,
            marginRight: 5,
            transform: 'rotate(-90deg)',
            '&.Mui-expanded': {
              transform: 'rotate(0deg)',
            },
          },
        },
      },
      MuiSkeleton: {
        styleOverrides: {
          rectangular: {
            borderRadius: '12px',
          },
          root: {
            backgroundColor: 'rgb(0 0 0 / 4%)',
          },
        },
      },

      // *** BUTTONS ***
      MuiButtonBase: {
        defaultProps: {
          disableRipple: true,
        },
        styleOverrides: {
          root: {
            boxShadow: 'none',
            textTransform: 'none',
            transition: 'all 50ms ease-in-out',
            '*': {
              transition: 'all 50ms ease-in-out',
            },
            // fix for spinner loading view for Round and Square buttons with S / XS / XXS sizes
            '&.MuiButton-sizeS, &.MuiButton-sizeXS, &.MuiButton-sizeXXS ': {
              '.MuiCircularProgress-root': {
                width: 'unset !important',
                height: 'unset !important',
              },
            },
          },
        },
      },
      MuiButton: {
        defaultProps: {
          disableRipple: true,
          variant: 'contained',
        },
        // styleOverrides: {
        //   root: {
        //     transition: 'all 50ms ease-in-out',
        //     boxShadow: 'none',
        //     textTransform: 'none',
        //     borderRadius: '8px',
        //     '*': {
        //       transition: 'all 50ms ease-in-out',
        //     },
        //     '&:hover': {
        //       boxShadow: 'none',
        //       textTransform: 'none',
        //     },
        //     '& .MuiButton-startIcon': {
        //       marginLeft: '0px',
        //     },
        //     '& .MuiButton-endIcon': {},
        //     // shape & size styles
        //     '&.square, &.round': {
        //       padding: 'unset',
        //       minWidth: 'unset',
        //       display: 'flex',
        //       alignItem: 'center',
        //       justifyContent: 'center',
        //       borderRadius: '100%',
        //     },
        //     '&.MuiButton-sizeXL': {
        //       fontSize: 14,
        //       padding: '13px 16px',
        //       '&.square': {
        //         padding: '0px',
        //         fontSize: '14px',
        //         width: '49px',
        //         height: '49px',
        //         borderRadius: '12px',
        //       },
        //       '&.round': {
        //         padding: '0px',
        //         fontSize: '14px',
        //         width: '49px',
        //         height: '49px',
        //         borderRadius: '100%',
        //       },
        //     },
        //     '&.MuiButton-sizeL, &.MuiButton-sizeLarge': {
        //       fontSize: 14,
        //       padding: '11px 24px',
        //       '&.square': {
        //         padding: '0px',
        //         fontSize: '12px',
        //         width: '45.5px',
        //         height: '45.5px',
        //         borderRadius: '8px',
        //       },
        //       '&.round': {
        //         padding: '0px',
        //         fontSize: '12px',
        //         width: '45.5px',
        //         height: '45.5px',
        //         borderRadius: '100%',
        //       },
        //     },
        //     '&.MuiButton-sizeM, &.MuiButton-sizeMedium': {
        //       fontSize: 14,
        //       padding: '9px 16px',
        //       '&.square': {
        //         padding: '0px',
        //         width: '41px',
        //         height: '41px',
        //         borderRadius: '8px',
        //       },
        //       '&.round': {
        //         padding: '0px',
        //         width: '41px',
        //         height: '41px',
        //         borderRadius: '100%',
        //       },
        //     },
        //     '&.MuiButton-sizeS, &.MuiButton-sizeSmall': {
        //       fontSize: 12,
        //       padding: '7px 16px',
        //       '&.square': {
        //         padding: '0px',
        //         fontSize: '10px',
        //         width: '34px',
        //         height: '34px',
        //         borderRadius: '8px',
        //         svg: {
        //           width: '12px',
        //           height: '12px',
        //         },
        //       },
        //       '&.round': {
        //         padding: '0px',
        //         fontSize: '10px',
        //         width: '34px',
        //         height: '34px',
        //         borderRadius: '100%',
        //         svg: {
        //           width: '12px',
        //           height: '12px',
        //         },
        //       },
        //     },
        //     '&.MuiButton-sizeXS': {
        //       fontSize: 12,
        //       padding: '5px 10px',
        //       borderRadius: '6px',
        //       svg: {
        //         width: '14px',
        //         height: '14px',
        //       },
        //       '&.square': {
        //         padding: '0px',
        //         fontSize: '7px',
        //         width: '30px',
        //         height: '30px',
        //         borderRadius: '6px',
        //         svg: {
        //           width: '12px',
        //           height: '12px',
        //         },
        //       },
        //       '&.round': {
        //         padding: '0px',
        //         fontSize: '7px',
        //         width: '30px',
        //         height: '30px',
        //         borderRadius: '100%',
        //         svg: {
        //           width: '12px',
        //           height: '12px',
        //         },
        //       },
        //     },
        //     '&.MuiButton-sizeXXS': {
        //       fontSize: 12,
        //       padding: '3px 7px',
        //       borderRadius: '6px',
        //       svg: {
        //         width: '12px',
        //         height: '12px',
        //       },
        //       '&.square': {
        //         padding: '0px',
        //         fontSize: '5px',
        //         width: '26px',
        //         height: '26px',
        //         borderRadius: '6px',
        //         svg: {
        //           width: '10px',
        //           height: '10px',
        //         },
        //       },
        //       '&.round': {
        //         padding: '0px',
        //         fontSize: '5px',
        //         width: '26px',
        //         height: '26px',
        //         borderRadius: '100%',
        //         svg: {
        //           width: '10px',
        //           height: '10px',
        //         },
        //       },
        //     },
        //     variant & color styles
        //     ...getAllButtonsStyles(),
        //   },
        // text: {
        //   border: '1px solid transparent',
        // },
        // },
      },
      MuiIconButton: {},

      MuiListItemButton: {
        styleOverrides: {
          root: {
            '&:hover': {
              borderRadius: '12px',
              backgroundColor: '#F8F8F8',
            },
            '&.Mui-selected': {
              borderRadius: '12px',
              backgroundColor: '#f2f2f2',
            },
          },
        },
      },

      // *** CONTROLS ***
      MuiTextField: {
        styleOverrides: {
          root: {
            '& .MuiInputBase-root': {
              paddingTop: '6px',
              paddingBottom: '6px',
              backgroundColor: 'transparent',
              borderColor: '#bcc4e3',
              borderWidth: 3,
              '&:hover fieldset': {
                borderColor: '#0851FE !important',
              },
              '&.Mui-focused fieldset': {
                borderColor: '#0851FE !important',
              },

              '& .MuiChip-root': {
                '.MuiChip-label': {
                  fontWeight: '400',
                  fontSize: '12px',
                  lineHeight: 1,
                },
              },

              '& .MuiInputBase-input': {
                padding: '7.5px 14px',
                color: '#141414',
                fontWeight: '500',
                fontSize: '16px',
                lineHeight: 1,
              },
              '& .MuiAutocomplete-popupIndicator': {
                'svg *': {
                  fill: '#91979F !important',
                  stroke: '#91979F !important',
                },
                '&.MuiAutocomplete-popupIndicatorOpen': {
                  'svg *': {
                    fill: '#141414 !important',
                    stroke: '#141414 !important',
                  },
                },
              },
            },
            '& .MuiInputBase-sizeSmall': {
              '& .MuiInputBase-input': {
                padding: '4px 8px 4px 8px !important',
                color: '#141414',
                fontWeight: '500',
                fontSize: '14px',
                lineHeight: 1,
              },
            },
          },
        },
      },
      MuiInputBase: {
        defaultProps: {
          size: 'small',
        },
        styleOverrides: {
          root: {
            backgroundColor: 'transparent',

            fieldset: {
              backgroundColor: 'transparent',
              borderColor: '#bcc4e3',
              borderWidth: 1.5,
              borderRadius: '8px',
            },
            '&.MuiOutlinedInput-root': {
              borderColor: 'transparent',
              backgroundColor: 'transparent',
              '&:hover fieldset': {
                borderColor: '#0851FE !important',
              },
              '&.Mui-focused fieldset': {
                borderColor: '#0851FE !important',
              },
            },

            '& .MuiInputAdornment-positionStart': {
              'svg *': {
                stroke: '#141414',
              },
            },

            '&.Mui-disabled': {
              pointerEvents: 'none',
              fieldset: {
                borderColor: '#E7EDF1 !important',
              },
              'svg *': {
                stroke: '#B3B3B3 !important',
              },
            },

            '&:hover, &:focus, &:active, &.Mui-focused': {
              // boxShadow: '0px 1px 11px 2px rgb(96 165 251 / 15%)',
              fieldset: {
                border: '1px solid #a5cdff',
              },
            },

            '&:not(&.Mui-error)': {
              '&:hover, &:focus, &:active, &.Mui-focused': {
                fieldset: {
                  border: `1px solid #60A5FB`,
                },
              },
            },
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            backgroundColor: '#F8F8F8',
            border: 'none ',
          },
        },
      },
      MuiAutocomplete: {
        styleOverrides: {
          paper: {
            backgroundColor: 'rgba(255, 255, 255, 1)',
            marginTop: 4,
            borderRadius: 8,
            '& .MuiAutocomplete-option': {
              color: '#5a5a5a',
              backgroundColor: 'transparent !important',
              '&:hover': {
                color: '#141414',
              },
              '&[aria-selected="true"]': {
                color: '#D0D0D0',
              },
            },
          },
          listbox: {
            paddingBlock: 2,
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          select: {
            backgroundColor: 'transparent',
            paddingRight: '32px !important',
            '&.MuiInputBase-input': {
              padding: '13.5px 14px',
              color: '#141414',
              fontWeight: '500',
              fontSize: '16px',
            },
            '&.MuiInputBase-inputSizeSmall': {
              padding: '8.5px 14px',
              fontSize: '14px',
            },
          },
          icon: {
            fill: '#91979F !important',
            stroke: '#91979F !important',
            '&.MuiSelect-iconOpen': {
              fill: '#141414 !important',
              stroke: '#141414 !important',
            },
          },
        },
      },
      MuiToggleButtonGroup: {
        styleOverrides: {
          root: {
            borderColor: 'transparent',
            gap: 1,
          },
        },
      },
      MuiToggleButton: {
        styleOverrides: {
          root: {
            backgroundColor: grey.light,
            border: '1px solid white',
            color: '#a1a1a1',
            textTransform: 'none',
            flexWrap: 'wrap',
            svg: {
              width: 17,
              height: 17,
            },
            '&:hover': {
              backgroundColor: grey.main,
            },
            '&.Mui-selected': {
              color: '#4c4c4c',
              backgroundColor: grey.dark,
              '&:hover': {
                backgroundColor: grey.main,
              },
            },
          },
          sizeSmall: {
            paddingTop: 7,
            paddingBottom: 7,
            fontSize: 15,
          },
        },
      },
      MuiCheckbox: {
        // defaultProps: {
        //   icon: <CheckboxNotSelected />,
        //   checkedIcon: <CheckboxSelected />,
        // },
        styleOverrides: {
          root: {
            color: '#878B92',
            borderRadius: 4,
            padding: 0,
            '&.Mui-checked': {
              color: '#C3DF50',
              '&.Mui-disabled': {
                color: '#fff !important',
              },
            },
            '&.Mui-disabled': {
              svg: {
                opacity: 0.5,
              },
            },
          },
        },
      },
      MuiRadio: {
        styleOverrides: {
          root: {
            color: '#878B92',
            padding: 0,

            '&.Mui-checked': {
              color: '#0851FE',
            },
            '&.Mui-disabled': {
              svg: {
                opacity: 0.5,
              },
            },
          },
        },
      },
      MuiSwitch: {
        styleOverrides: {
          root: {
            width: 28,
            height: 16,
            padding: 0,
            border: '1px solid transparent',
            borderRadius: '10px',
            display: 'flex',
            '& .MuiSwitch-switchBase': {
              padding: '1px 2px',
              '&:hover': {
                backgroundColor: 'transparent',
              },
              '&.Mui-checked': {
                transform: 'translate(10px, 0)',
                color: '#fff !important',
                '& + .MuiSwitch-track': {
                  opacity: 1,
                  backgroundColor: '#C3DF50',
                },
              },
              '&.Mui-disabled': {
                '& + .MuiSwitch-track': {
                  opacity: 0.5,
                },
              },
            },
            '& .MuiSwitch-thumb': {
              width: 12,
              height: 12,
              borderRadius: '100%',
            },
            '& .MuiSwitch-track': {
              borderRadius: '10px',
              opacity: 1,
              backgroundColor: '#CED1D4',
              boxSizing: 'border-box',
            },
          },
        },
      },
      MuiChip: {
        defaultProps: {
          color: 'primary',
          size: 'small',
          variant: 'outlined',
        },
        styleOverrides: {
          root: {
            borderRadius: 8,
            padding: '4px 3px',
            span: {
              fontWeight: 400,
            },
          },
          outlined: {
            color: '#141414',
            borderColor: '#CED1D4',
            '&.MuiChip-colorInfo': {
              '.MuiChip-label': {
                color: '#4c4c4c',
              },
            },
          },
          filled: {
            '&.MuiChip-colorDefault': {
              backgroundColor: '#FCFDFC',
              border: '1px solid #F3F3F3',
            },
          },
          deleteIcon: {
            backgroundImage: `url(${crossIcon})`,
            backgroundSize: 'cover',
            transition: 'all 50ms ease-in-out',
            '&:hover': {
              transform: 'scale(1.2)',
            },
            path: {
              display: 'none',
            },
          },
        },
      },
      MuiAlert: {
        defaultProps: {
          variant: 'filled',
          elevation: 6,
        },
        styleOverrides: {
          root: {
            '.MuiAlert-icon': {
              flexSrink: 0,
            },
            '.MuiAlert-icon img': {
              width: '22px',
              height: '22px',
            },
          },
          action: {
            paddingTop: '2px',
          },
          filledWarning: {
            background:
              'linear-gradient(90deg, rgba(255,223,170,1) 0%, rgba(255,236,186,1) 39%, rgba(255,240,186,0.8995973389355743) 70%, rgba(255,248,185,0.7763480392156863) 100%)',
            color: 'rgb(102, 60, 0)',
          },
          filledError: {
            background:
              'linear-gradient(90deg, rgba(255,170,170,1) 0%, rgba(255,186,186,1) 39%, rgba(255,186,186,0.7987570028011204) 70%, rgba(255,185,185,0.6082808123249299) 100%)',
            color: 'rgb(95, 33, 32)',
          },
          filledInfo: {
            background:
              'linear-gradient(90deg, rgba(170,213,255,1) 0%, rgba(186,236,255,1) 39%, rgba(186,229,255,0.7987570028011204) 70%, rgba(185,243,255,0.6082808123249299) 100%)',
            color: 'rgb(1, 67, 97)',
          },
          filledSuccess: {
            background:
              'linear-gradient(90deg, rgb(148 224 151) 0%, rgb(172 236 173) 39%, rgb(192 237 180 / 80%) 70%, rgb(200 240 188 / 61%) 100%)',
            color: 'rgb(30, 70, 32)',
          },
        },
      },

      // *** TABS ***
      MuiTabs: {
        defaultProps: {
          scrollButtons: 'auto',
          variant: 'scrollable',
        },
        styleOverrides: {
          scrollButtons: {
            width: 25,
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            padding: '5px 10px',
            fontSize: 14,
            textTransform: 'none',
            svg: {
              width: 17,
              height: 17,
            },
          },
          labelIcon: {
            '&.Mui-selected svg': {
              transform: 'scale(1.13)',
            },
          },
        },
      },

      // *** TABLES ***
      MuiTableContainer: {
        styleOverrides: {
          root: {
            border: '1px solid #FAFAFA',
            boxShadow: boxShadow.spreadLight,
          },
        },
      },
      MuiTable: {
        styleOverrides: {
          root: {},
        },
      },
      MuiTableRow: {
        styleOverrides: {
          root: {
            ':not(.MuiTableRow-head):hover': {
              backgroundColor: tableRowHoverColor,
            },
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            wordBreak: 'break-word',
            padding: '10px 15px',
            borderColor: '#FAFAFA',
          },
          head: {
            padding: '10px 15px',
          },
        },
      },
      MuiTableHead: {
        styleOverrides: {
          root: {
            '& .MuiTableCell-root': {
              fontWeight: 600,
              backgroundColor: '#F8F8F8',
            },
          },
        },
      },
      MuiTablePagination: {
        styleOverrides: {
          selectRoot: {
            borderRadius: '10px',
            '.MuiTablePagination-select:focus': {
              backgroundColor: 'transparent',
            },
          },
        },
      },
      MuiTooltip: {
        styleOverrides: {
          arrow: {
            color: 'rgba(20, 20, 20, 0.5)',
          },
          tooltip: {
            borderRadius: 8,
            fontWeight: 400,
            margin: '3px !important',
            fontSize: '14px',
            color: '#141414',
            lineHeight: '150%',
            background: '#d6e7f3',
            padding: '8px 10px 8px 8px',
            boxShadow: '0px 5px 24px 0px rgba(0, 0, 0, 0.10)',
          },
        },
      },
      MuiDataGrid: {
        styleOverrides: {
          root: {
            borderColor: '#FAFAFA',
            '.MuiDataGrid-columnHeader:focus, .MuiDataGrid-columnHeader:focus-within, .MuiDataGrid-cell:focus, .MuiDataGrid-cell:focus-within':
              {
                outline: 'none',
              },
            '.MuiDataGrid-columnHeaders': {
              backgroundColor: '#fafafa',
              borderColor: '#FAFAFA',
            },
            '.MuiDataGrid-pinnedColumnHeaders': {
              backgroundColor: '#fafafa',
              borderColor: '#FAFAFA',
            },
            '.MuiDataGrid-columnHeaderTitle': {
              fontWeight: 600,
            },
            '.MuiDataGrid-row.Mui-hovered': {
              backgroundColor: tableRowHoverColor,
            },
            '.MuiDataGrid-cell': {
              borderColor: '#FAFAFA',
            },
            '.MuiDataGrid-footerContainer': {
              borderColor: '#FAFAFA',
            },

            '.MuiDataGrid-menuIcon': {
              marginRight: '-2px',
            },
            '.MuiDataGrid-iconButtonContainer': {
              marginLeft: '5px',
            },

            '.MuiDataGrid-row:hover, .MuiDataGrid-row.Mui-selected, .MuiDataGrid-row.Mui-selected:hover': {
              backgroundColor: `${tableRowHoverColor}`,
            },
          },
        },
      },

      // *** OTHER MENUS & DIALOGS ***
      MuiDialog: {
        styleOverrides: {
          root: {
            '.MuiBackdrop-root': {
              // backdropFilter: 'blur(7px)',
            },
          },
        },
      },
      MuiPopover: {
        styleOverrides: {
          paper: {
            backgroundColor: 'rgba(255,255,255)',
            borderRadius: 8,
            padding: '8px 4px 8px 10px',
          },
        },
      },
      MuiMenu: {
        styleOverrides: {
          paper: {
            backgroundColor: 'rgba(255, 255, 255, 1)',
            borderRadius: 8,

            ul: {
              padding: '2px 0',
            },
          },
        },
      },
      MuiBreadcrumbs: {
        styleOverrides: {
          separator: {
            marginLeft: 4,
            marginRight: 4,
            color: '#878b92',
          },
          ol: {
            flexWrap: 'nowrap',
          },
        },
      },

      // *** MENU ITEM ***
      MuiMenuItem: {
        styleOverrides: {
          root: {
            fontSize: '14px',
            lineHeight: '18px',
            alignItems: 'center',
            color: '#141414',
            paddingInline: '8px',

            '&:hover': {
              backgroundColor: 'transparent',
            },
          },
        },
      },

      // *** AVATARS ***
      MuiAvatarGroup: {
        styleOverrides: {
          root: ({ ownerState: { max, total } }) => ({
            ...((total || 0) > (max || 0)
              ? {
                  // bring last item forward (first-child mean last one in this case)
                  [`& > .MuiAvatar-root:first-of-type`]: {
                    zIndex: 1,
                    backgroundColor: '#fff',
                    borderColor: '#000',
                    borderWidth: 1,
                    color: '#000',
                    fontSize: 14,
                  },
                }
              : {}),
          }),
        },
      },
      MuiAvatar: {
        styleOverrides: {
          root: ({ ownerState }) => ({
            cursor: ownerState?.onClick ? 'pointer' : 'default',
            transition: 'all 50ms ease-in-out',
            [`&:hover`]: {
              borderColor: ownerState?.onClick ? '#0851FE !important' : '#fff',
              zIndex: 1,
            },
          }),
        },
      },
    },
  });
};

export const theme = createMuiTheme();
