import { FC, SVGProps } from 'react';

export const PerformersIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M12.2169 4.61373C12.1748 4.60672 12.1257 4.60672 12.0837 4.61373C11.116 4.57867 10.3447 3.78633 10.3447 2.80467C10.3447 1.80197 11.1511 0.995605 12.1538 0.995605C13.1565 0.995605 13.9628 1.80898 13.9628 2.80467C13.9558 3.78633 13.1845 4.57867 12.2169 4.61373Z"
      stroke="#141414"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.4952 9.71837C12.4558 9.87964 13.5146 9.71136 14.2578 9.21352C15.2465 8.5544 15.2465 7.47457 14.2578 6.81546C13.5076 6.31762 12.4347 6.14933 11.4741 6.31761"
      stroke="#141414"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.78307 4.61324C3.82514 4.60623 3.87422 4.60623 3.91629 4.61324C4.88393 4.57819 5.65523 3.78584 5.65523 2.80418C5.65523 1.80148 4.84887 0.995117 3.84617 0.995117C2.84348 0.995117 2.03711 1.8085 2.03711 2.80418C2.04412 3.78584 2.81543 4.57819 3.78307 4.61324Z"
      stroke="#141414"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.50418 9.71788C3.54355 9.87916 2.48476 9.71087 1.74151 9.21303C0.752832 8.55391 0.752832 7.47409 1.74151 6.81497C2.49178 6.31713 3.56459 6.14884 4.52522 6.31712"
      stroke="#141414"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.0084 9.85104C7.96633 9.84403 7.91725 9.84403 7.87517 9.85104C6.90754 9.81598 6.13623 9.02364 6.13623 8.04197C6.13623 7.03928 6.94259 6.23291 7.94529 6.23291C8.94799 6.23291 9.75436 7.04629 9.75436 8.04197C9.74734 9.02364 8.97604 9.82299 8.0084 9.85104Z"
      stroke="#141414"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.96807 12.0599C4.97939 12.719 4.97939 13.7988 5.96807 14.4579C7.08997 15.2082 8.92708 15.2082 10.049 14.4579C11.0376 13.7988 11.0376 12.719 10.049 12.0599C8.93409 11.3166 7.08997 11.3166 5.96807 12.0599Z"
      stroke="#141414"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
