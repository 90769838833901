import robot from 'icons/emoji-png/robot.png';
import s from './something-went-wrong.module.scss';

export const SomethingWentWrong = () => {
  return (
    <div className={s.container}>
      <div className={s.image_container}>
        <img className={s.image} src={robot} alt="Error" />
      </div>
      <span className={s.title}>Uh-oh, something went wrong...</span>
      <span className={s.text}>Try reloading the page or going back</span>
    </div>
  );
};
