import { FC, SVGProps } from 'react';

export const StartIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M7.98014 15.1673C11.662 15.1673 14.6468 12.1825 14.6468 8.50065C14.6468 4.81875 11.662 1.83398 7.98014 1.83398C4.29824 1.83398 1.31348 4.81875 1.31348 8.50065C1.31348 12.1825 4.29824 15.1673 7.98014 15.1673Z"
      stroke="#878B92"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.82617 8.65374V7.54041C5.82617 6.15374 6.80617 5.58707 8.00617 6.28041L8.97284 6.84041L9.93951 7.40041C11.1395 8.09374 11.1395 9.22707 9.93951 9.92041L8.97284 10.4804L8.00617 11.0404C6.80617 11.7337 5.82617 11.1671 5.82617 9.78041V8.65374Z"
      stroke="#878B92"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
