import { FC, SVGProps } from 'react';

export const ProductsCheckedIcon: FC<SVGProps<SVGSVGElement>> = ({ ...props }) => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M4.5 7L5.37641 7.70113C5.58535 7.86828 5.88885 7.84132 6.06505 7.63995L7.5 6M8 2H8.5C9.60457 2 10.5 2.89543 10.5 4V9C10.5 10.1046 9.60457 11 8.5 11H3.5C2.39543 11 1.5 10.1046 1.5 9V4C1.5 2.89543 2.39543 2 3.5 2H4M8 2C8 2.55228 7.55229 3 7 3H5C4.44772 3 4 2.55228 4 2M8 2C8 1.44772 7.55229 1 7 1H5C4.44772 1 4 1.44772 4 2"
      stroke="#878B92"
      strokeWidth="1.1"
      strokeLinecap="round"
    />
  </svg>
);
