import { IdNameType, IdName } from 'types/common-types';
import { TaskStatusEnum, ProductionStatusEnum } from 'types/status-enums';
import {
  DeadlineHistoryItem,
  ExternalOrderNumberHistoryItem,
  NoteHistoryItem,
  VariantHistoryItem,
} from 'services/production-workflow.model';
import { AssignmentType } from './workflow-task-template-responsibility.model';
import { UserShortInfo } from './user.model';

export type TaskInfoModel = {
  id: string;
  name: string;
  priority: string;
  failed_by: string;
  created_at: string;
  time_limit: number;
  started_at: string;
  description: string;
  deadline_at: string;
  is_control: boolean;
  is_in_queue: boolean;
  is_reopened: boolean;
  status: TaskStatusEnum;
  is_additional: boolean;
  taskTagRelations: any[];
  fail_comment: string;
  departmets_all: IdName[];
  task_template_id: string;
  status_changed_at: string;
  breadcrumbs: IdNameType[];
  rewards: TaskRewardsModel;
  total_paused_time: number;
  reporting_period_to: string;
  launched_by: LaunchedByModel;
  failed_by_info: UserShortInfo;
  reporting_period_from: string;
  production_workflow_id: string;
  total_in_progress_time: number;
  attachments: ProductFileModel[];
  failed_tasks: FailedTaskModel[];
  fail_reasons: FailReasonsModel[];
  total_in_progress_overtime: number;
  is_reporting_period_closed: boolean;
  is_root_workflow_completed: boolean;
  total_paused_out_of_work_time_range: number;
  is_production_has_task_in_done_status: boolean;
  taskResponsibilities: TaskResponsibilityModel[];
  next_items: { task: TaskItemModel }[] | { workflow: WorkflowItemModel }[];
  previous_items: { task: TaskItemModel }[] | { workflow: WorkflowItemModel }[];
  configuration: {
    id: string;
    sku: string;
    name: string;
  };
  variant: {
    id: string;
    sku: string;
    name: string;
    barcode: string;
  };
  orderInfo: {
    id: string;
    priority: string;
    order_key: string;
    order_number: string;
    external_order_id: string;
    external_order_number: string;
    externalOrderNumberHistory: any[];
  };
  product: {
    id: string;
    name: string;
    version: number;
    product_type: IdName;
    product_vendor: IdName;
    product_source_id: string;
    files: ProductFileModel[];
    photos: ProductPhotoModel[];
    product_configuration_details: string;
  };
  production_workflow: {
    id: string;
    title: string;
    total: number;
    progress: number;
    started_at: string;
    deadline_at: string;
    production_key: string;
    status: ProductionStatusEnum;
  };
  history: {
    notes: NoteHistoryItem[];
    variantsHistory: VariantHistoryItem[];
    deadlineHistory: DeadlineHistoryItem[];
    externalOrderNumberHistory: ExternalOrderNumberHistoryItem[];
  };
};

export type TaskResponsibilityModel = {
  assigment_type: AssignmentType;
  created_at: string;
  id: string;
  name: string;
  responsibility_count: number;
  reward_id: string;
  taskDepartmentRelations: any[];
  taskPositionTypeRelations: any[];
  taskSlots: TaskSlotModel[];
  taskUserRelations: any[];
  task_id: string;
};

export type TaskRewardsModel = {
  total_bonus: number;
  basic_reward: number;
  total_reward: number;
  bonuses: TaskBonusModel[];
  assignment_rewards: TaskAssignmentRewardModel[];
};

export type TaskReopenModel = Array<IdName & { taskKey: string }>;

export type TaskSlotModel = {
  id: string;
  taskAssignment: TaskAssignment;
  task_assignment_id: string;
  task_responsibility_id: string;
};

export type TaskAssignment = {
  id: string;
  task_id: string;
  user_id: string;
  user: UserModel;
  assign_at: string;
  total_sum: number;
  is_deleted: boolean;
  total_overtime: number;
  performer_slot_id: null;
  total_in_progress_time: number;
};

export type ProductFileModel = {
  bucket_key: string;
  content_type: string;
  created_at: string;
  created_by: string;
  deleted_at: string;
  deleted_by: string;
  id: string;
  is_deleted: boolean;
  link: string;
  modified_at: string;
  modified_by: string;
  name: string;
  product_configuration_id: string;
  size: number;
};

export type ProductPhotoModel = ProductFileModel & {
  is_show_by_default: boolean;
};

export type CreateUpdateTaskResponse = {
  id: string;
  task_template_id: string;
  production_workflow_id: string;
  name: string;
  description: string;
  priority: string;
  status: string;
  is_reopened: boolean;
  is_control: boolean;
  is_additional: boolean;
  is_in_queue: boolean;
  time_limit: number;
  order: number;
  total_in_progress_time: number;
  total_in_progress_overtime: number;
  total_paused_time: number;
  total_paused_out_of_work_time_range: number;
  created_at: string;
};

export type UpdateTaskBody = {
  name?: string;
  description?: string;
  priority?: string;
  status?: string;
  is_reopened?: boolean;
  is_control?: boolean;
  is_in_queue?: boolean;
  time_limit?: number;
  order?: number;
  basic_reward?: number;
  total_in_progress_time?: number;
  total_in_progress_overtime?: number;
  total_paused_time?: number;
  total_paused_out_of_work_time_range?: number;
  make_assignment?: boolean;
};

export type CreateTaskBody = {
  id?: string;
  name: string;
  order: number;
  status: string;
  priority: string;
  time_limit: number;
  description: string;
  is_control: boolean;
  is_reopened: boolean;
  basic_reward: number;
  is_in_queue: boolean;
  is_additional: boolean;
  task_template_id?: string;
  total_paused_time: number;
  production_workflow_id: string;
  total_in_progress_time: number;
  total_in_progress_overtime: number;
  total_paused_out_of_work_time_range: number;
};

export type ManageOrderT = {
  tasks: {
    id: string;
    order: number;
  }[];
};

export type TaskItemModel = {
  id: string;
  production_workflow_id: string;
  name: string;
  description: string;
  priority: string;
  status: string;
  task_key: string;
};

export type WorkflowItemModel = {
  id: string;
  workflow_name: string;
  priority: string;
  status: string;
  version: number;
  configuration: string;
  progress: number;
};

export type LaunchedByModel = {
  id: string;
  avatar_image_url: string;
  first_name: string;
  last_name: string;
};

export type TaskBonusModel = {
  id: string;
  task_id: string;
  bonus_id: string;
  name: string;
  value: number;
  reward_type: RewardType;
};

export enum RewardType {
  Percentage = 'Percentage',
  FixedAmount = 'FixedAmount',
}

export type TaskAssignmentRewardModel = {
  bonus: number;
  reward: number;
  total_sum: number;
  time_spent: number;
  correction_sum: number;
  task_assignment_id: string;
  user: UserIdNameAvatarModel;
  time_spent_in_percent: number;
  is_in_current_reporting_period: boolean;
};

export enum ReopenActionType {
  ReopenWithNewPayment = 'ReopenWithNewPayment',
  Reopen = 'Reopen',
}

export type ManageFailedTasksBody = {
  fail_reason_ids?: string[];
  comment?: string;
  tasks: { id: string; reopen_action_type: ReopenActionType }[];
};

export type FailReasonsModel = {
  id: string;
  en_reason: string;
  uk_reason?: string;
};

export type FailedTaskModel = {
  id: string;
  name: string;
  status: TaskStatusEnum;
  task_key: string;
  created_at: string;
  task_user_relation: FailedTaskUserModel[];
};

export type FailedTaskUserModel = {
  id: string;
  task_responsibility_id: string;
  user_id: string;
  user: {
    id: string;
    avatar_image_url: string;
    first_name: string;
    last_name: string;
  };
};

export type UserIdNameAvatarModel = {
  id: string;
  last_name: string;
  first_name: string;
  avatar_image_url: string;
};

export type UserModel = {
  avatar_image_url: string;
  created_at: string;
  email: string;
  first_name: string;
  id: string;
  last_name: string;
  phone: string;
  user_position_slots?: PositionSlotModel[];
};

export type PositionSlotModel = {
  position_slot: {
    department: IdName;
    position_type: IdName;
  };
};

export type UpdateTaskRewardsBody = {
  basic_reward?: number;
  correction_sum?: {
    task_assignment_id: string;
    value: number;
  }[];
  bonus?: {
    bonus_id: string;
    value: number;
  };
};
