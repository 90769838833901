import { connect } from 'react-redux';
import { AppState } from 'redux/store';
import { Box, Stack, Tab, Tabs } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import Skeleton from 'components/ui-new/skeleton/skeleton';
import { SettingsActions } from 'pages/settings/settings.controller';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import GeneralTabContent from 'pages/settings/modules/general-tab-content/general-tab-content';
import ProductionTabContent from 'pages/settings/modules/production-tab-content/production-tab-content';
import MobileTabContent from 'pages/settings/modules/mobile-tab-content/mobile-tab-content';
import { MobileApp } from 'icons/mobile-app';
import { ProductionWorkflowsIcon } from 'icons/production-workflows';
import { BillIcon } from 'icons/bill';
import { TabQueryParam } from 'pages/settings/enums';
import WidthContainer from 'components/width-container/width-container';
import s from './settings.module.scss';

type StateProps = {
  isFetching: boolean;
};
type DispatchProps = {
  init: () => void;
};
type Props = StateProps & DispatchProps;

const skeleton = (
  <div className={s.skeleton}>
    <Skeleton variant="text" width="120px" height={36} style={{ marginBottom: 24 }} />
    <div className={s.skeleton_tabs}>
      <Skeleton variant="text" width="122px" height={46} />
      <Skeleton variant="text" width="122px" height={46} />
      <Skeleton variant="text" width="122px" height={46} />
    </div>
    <div className={s.skeleton_row} style={{ marginTop: '40px' }}>
      <Skeleton variant="text" width="105px" height={21} />
      <Skeleton variant="rounded" width="400px" height={40} />
    </div>
    <div className={s.skeleton_row}>
      <Skeleton variant="text" width="113px" height={21} />
      <Skeleton variant="rounded" width="400px" height={40.13} />
    </div>
    <div className={s.skeleton_row}>
      <Skeleton variant="text" width="370px" height={21} />
      <Skeleton variant="rounded" width="400px" height={40.13} />
    </div>
    <div className={s.skeleton_row}>
      <Skeleton variant="text" width="65px" height={21} />
      <Skeleton variant="rounded" width="400px" height={40.13} />
    </div>
    <div className={s.skeleton_row}>
      <Skeleton variant="text" width="213px" height={21} />
      <Skeleton variant="rounded" width="400px" height={40.13} />
    </div>
  </div>
);

const tabContents = {
  general: <GeneralTabContent />,
  production: <ProductionTabContent />,
  mobile: <MobileTabContent />,
};

const Settings: FC<Props> = ({ isFetching, init }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [activeTab, setActiveTab] = useState<TabQueryParam>((queryParams.get('tab') as TabQueryParam) || TabQueryParam.General);

  const handleTabChange = (event: React.SyntheticEvent, value: TabQueryParam) => {
    setActiveTab(value);

    queryParams.set('tab', value);

    navigate({ search: queryParams.toString() }, { replace: true });
  };

  useEffect(() => {
    document.title = 'Settings';

    init();
  }, []);

  if (!queryParams.has('tab')) {
    queryParams.set('tab', TabQueryParam.General);

    return <Navigate to={`${location.pathname}?${queryParams.toString()}`} replace />;
  }

  return (
    <div>
      {isFetching ? (
        <WidthContainer>
          <div className={s.skeleton_wrapper}>{skeleton}</div>
        </WidthContainer>
      ) : (
        <>
          <WidthContainer>
            <h1 className={s.page_title}>Settings</h1>
          </WidthContainer>
          <Box borderBottom="1px solid #D5E4F2">
            <WidthContainer>
              <Tabs
                className={s.tabs}
                TabIndicatorProps={{ style: { borderRadius: 3, backgroundColor: '#437bff' } }}
                value={activeTab}
                onChange={handleTabChange}
              >
                <Tab
                  className={s.tab}
                  value={TabQueryParam.General}
                  label={
                    <Stack flexDirection="row" alignItems="center" gap="8px">
                      <BillIcon />
                      <div className={s.tab_label} data-tab={TabQueryParam.General} data-active-tab={activeTab}>
                        General
                      </div>
                    </Stack>
                  }
                />
                <Tab
                  className={s.tab}
                  value={TabQueryParam.Production}
                  label={
                    <Stack flexDirection="row" alignItems="center" gap="8px">
                      <ProductionWorkflowsIcon stroke="#141414" />
                      <div className={s.tab_label} data-tab={TabQueryParam.Production} data-active-tab={activeTab}>
                        Production
                      </div>
                    </Stack>
                  }
                />
                <Tab
                  className={s.tab}
                  value={TabQueryParam.Mobile}
                  label={
                    <Stack flexDirection="row" alignItems="center" gap="8px">
                      <MobileApp stroke="#141414" />
                      <div className={s.tab_label} data-tab={TabQueryParam.Mobile} data-active-tab={activeTab}>
                        Mobile app
                      </div>
                    </Stack>
                  }
                />
              </Tabs>
            </WidthContainer>
          </Box>
          <WidthContainer>{tabContents[activeTab]}</WidthContainer>
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state: AppState): StateProps => ({
  isFetching: state.settings.isFetching,
});

const mapDispatchToProps: DispatchProps = {
  init: SettingsActions.init,
};

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
