/* eslint-disable no-confusing-arrow */
/* eslint-disable no-await-in-loop */
import { MODALS } from 'modules/root-modals/modals';
import { DeleteConfirmationOwnProps } from 'modules/root-modals/modals/confirmation-modal/confirmation-modal';
import { ModalActions } from 'modules/root-modals/root-modals.controller';
import { t } from 'setup-localization';
import { GetStateFunction } from 'redux/store';
import { PermissionGuardActions } from 'modules/permission-guard/permission-guard.controller';
import { AccessLevel, Permission } from 'services/permission.model';
import { ProductConfigurationFile } from 'services/product-configuration-file.model';
import { ProductConfigurationFileService } from 'services/product-configuration-file.service';
import { Actions as ProductRootController } from 'pages/product-flow/pages/product/controllers/product-root.controller';
import { StateController } from 'state-controller';
import FireMinusIcon from 'icons/fire-minus';
import { notify } from 'notifications';
import { v4 as uuidv4 } from 'uuid';
import { FileItem } from 'types/common-types';
import { chunkedFileUploading, simpleFileUploading } from 'utils/file-uploading';

const fileMaxSize = 10 * 1024 * 1024;
const chunkSize = 48 * 1024 * 1024;

export type ProductState = {
  product_configuration_files: Array<FileItem & ProductConfigurationFile>;
  isInitLoading: boolean;
};

const defaultState: ProductState = {
  product_configuration_files: [],
  isInitLoading: true,
};

const stateController = new StateController<ProductState>('PRODUCT_FILE', defaultState);

export class Actions {
  public static init(files: Array<ProductConfigurationFile>) {
    return (dispatch) => {
      dispatch(stateController.setState((prev) => ({ ...prev, product_configuration_files: files, isInitLoading: false })));
    };
  }

  public static disposeState() {
    return (dispatch) => {
      dispatch(stateController.setState(defaultState));
    };
  }

  public static setInitLoading() {
    return (dispatch) => {
      dispatch(stateController.setState({ isInitLoading: true }));
    };
  }

  private static updateFileProgress = (dispatch, progress: number, id: string) => {
    dispatch(
      stateController.setState((prev) => ({
        ...prev,
        product_configuration_files: prev.product_configuration_files.map((item) =>
          item.id === id ? { ...item, progress } : item,
        ),
      })),
    );
  };

  private static async handleFileUpload(file: File, index: number, dispatch: any, addedFiles: FileItem[], configId: string) {
    const fileSize = file.size;
    const addedFileId = addedFiles[index].id;

    try {
      let newFile: File;

      if (fileSize < fileMaxSize) {
        newFile = await simpleFileUploading(file, configId, ProductConfigurationFileService.uploadSmallFile, (e) => {
          const newProgress = Math.round((e.loaded / e.total) * 100);
          Actions.updateFileProgress(dispatch, newProgress, addedFileId);
        });
      } else {
        newFile = await chunkedFileUploading({
          completeID: configId,
          file,
          chunkSize,
          openChunkStreamCallback: ProductConfigurationFileService.openChankedStream,
          uploadChunkCallback: ProductConfigurationFileService.uploadChunk,
          completeChunkStreamCallback: ProductConfigurationFileService.completeChankedStream,
          abortChunkCallback: ProductConfigurationFileService.abortChankedStream,
          chunkUploadingProgress: (progress) => {
            Actions.updateFileProgress(dispatch, progress, addedFileId);
          },
        });
      }

      if (!newFile) throw new Error('Bad file');

      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          product_configuration_files: prev.product_configuration_files.map((fileItem) =>
            addedFileId === fileItem.id ? newFile : fileItem,
          ),
        })),
      );
    } catch {
      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          product_configuration_files: prev.product_configuration_files.map((fileItem) =>
            addedFileId === fileItem.id ? { ...fileItem, isUploading: false } : fileItem,
          ),
        })),
      );
    }
  }

  public static uploadFile(files: File[], configId: string) {
    return async (dispatch, getState: GetStateFunction) => {
      if (!files.length) return;

      const { product_configuration_files } = getState().product.product_file;

      const addedFiles: FileItem[] = files.map((file) => ({
        id: `new-${uuidv4()}`,
        name: file.name,
        link: '',
        is_show_by_default: false,
        isUploading: true,
        progress: 0,
      }));
      const sortedProductConfigurationFiles = [...product_configuration_files, ...addedFiles].sort((a, b) =>
        a.name.localeCompare(b.name),
      );

      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          product_configuration_files: sortedProductConfigurationFiles,
        })),
      );

      const uploadPromises = files.map((file, index) => Actions.handleFileUpload(file, index, dispatch, addedFiles, configId));

      await Promise.all(uploadPromises);

      dispatch(ProductRootController.updateProductModifiedAt());
    };
  }

  public static openDeleteConfirmationModal(id: string, name: string) {
    return (dispatch) => {
      if (!dispatch(PermissionGuardActions.checkPermissionAndShowModal(Permission.webProductsEdit, [AccessLevel.access]))) {
        return;
      }

      dispatch(
        ModalActions.openModal<DeleteConfirmationOwnProps>({
          id: MODALS.CONFIRM,
          props: {
            title: <>{t('product_flow.delete_product_file_modal.title')}</>,
            text: (
              <div style={{ marginBottom: '7px' }}>
                Are you sure you want to delete the file <strong>{name}</strong>
              </div>
            ),
            icon: <FireMinusIcon />,
            withCloseButton: false,
            actionText: <>{t('global.button_delete')}</>,
            action: () => dispatch(Actions.deleteConfigurationFile(id)),
          },
        }),
      );
    };
  }

  public static deleteConfigurationFile(fileId: string) {
    return async (dispatch, getState: GetStateFunction) => {
      const { product_configuration_files } = getState().product.product_file;

      if (!fileId.includes('new-')) {
        await ProductConfigurationFileService.delete(fileId);
        dispatch(ProductRootController.updateProductModifiedAt());
        notify.success('Deleted successfully');
      }

      const filtered = product_configuration_files.filter((file) => file.id !== fileId);
      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          product_configuration_files: filtered,
        })),
      );
    };
  }
}

export class Selectors {}

export const reducer = stateController.getReducer();
