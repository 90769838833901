import { FC, SVGProps } from 'react';

export const DiamondsIcon: FC<SVGProps<SVGSVGElement>> = ({ ...props }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M13.1263 2.34961H15.9516C16.5589 2.34961 17.1373 2.6314 17.5408 3.1239L20.4629 6.69049C21.141 7.5182 21.1814 8.75193 20.559 9.6297L12.6854 20.7349C11.8342 21.9354 10.1658 21.9354 9.31463 20.7349L1.44095 9.6297C0.818605 8.75193 0.85896 7.5182 1.53709 6.69049L4.45918 3.1239C4.86269 2.6314 5.44106 2.34961 6.04842 2.34961H9.10994M13.1263 2.34961L15.2526 8.11695M13.1263 2.34961H9.10994M15.2526 8.11695H20.5684M15.2526 8.11695L11 21.3818L6.74736 8.11695M15.2526 8.11695H6.74736M9.10994 2.34961L6.74736 8.11695M1.43156 8.11695H6.74736"
      stroke="#5A5A5A"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21.1842 11.4016L22.7183 13.252C23.0743 13.6815 23.0955 14.3216 22.7687 14.777L18.6351 20.5386C18.1882 21.1615 17.3123 21.1615 16.8654 20.5386L15.8477 19.12M19.9829 13.9921H22.7737M19.9829 13.9921L17.7502 20.8743M19.9829 13.9921H18.8666"
      stroke="#5A5A5A"
      strokeWidth="0.9"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
