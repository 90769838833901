import { User } from 'services/user.model';
import { AppState } from 'redux/store';
import { notify } from 'notifications';
import { TaskTemplateResponsibilityT } from 'services/workflow-task-template.model';
import { DepartmentService } from 'services/department.service';
import { TaskResponsibilityModel } from 'services/production-task.model';
import { PositionTypesService } from 'services/position-types.service';
import { StateController } from 'state-controller';
import { IdName, MetaT, PaginationData } from 'types/common-types';
import { UserService } from 'services/user.service';
import { validators } from 'utils/validator';
import { AssignmentType } from 'services/workflow-task-template-responsibility.model';
import { UserStatusEnum } from 'types/status-enums';

export type CountChangeModeType = 'increment' | 'decrement' | 'set';
export type SearchType = 'departments' | 'positions' | 'users';

export type SelectedDepartmentType = IdName & {
  type: string;
  users_count?: number;
  avatar_image_url?: string;
};

export type SelectedPositionType = IdName & {
  department?: IdName;
  type: string;
  users_count?: number;
  avatar_image_url?: string;
};

export type SelectedWorkerType = IdName & {
  department?: IdName;
  position?: IdName;
  avatar_image_url?: string;
  type: string;
  first_name: string;
  last_name: string;
};

export type UserWithName = User & {
  name: string;
  type: string;
  users_count?: number;
};

type SearchItemType = {
  id: string;
  name: string;
  position?: IdName | null;
  department?: IdName | null;
  type: string;
};

export type SearchUserItemType = SearchItemType & { avatar_image_url: string };
export type SearchPositionItemType = SearchItemType;
export type SearchDepartmentItemType = SearchItemType & { path: Array<IdName> };

export enum SelectedItemEnum {
  DEPARTMENT = 'departments',
  USER = 'users',
  POSITION = 'positions',
}

export type GeneralData = {
  name: string;
  namesArray?: string[];
  workersCount: number;
  type: AssignmentType;
};

export type AccessData = {
  selectedWorkers: Array<SelectedWorkerType>;
  selectedDepartments: Array<SelectedDepartmentType>;
  selectedPositions: Array<SelectedPositionType>;
};

export type FieldsValidation = {
  name: string;
  uniqueName: string;
  workersCount: string;
  validatedName: string;
  numberOfPerformers: string;
};

export type OpenUpdateModal = {
  currentId: string;
  general: GeneralData;
  access: AccessData;
  names: string[];
  isNumberOfPerformersHidden?: boolean;
  taskTemplateId?: string;
};

export type ResponsibilityModalState = {
  isOpen: boolean;
  isUpdate: boolean;
  isLoading: boolean;
  isFetching: boolean;
  isFinalStep: boolean;
  isSearching: boolean;
  isAllConfigChanged: boolean;
  isSearchModeChanged: boolean;
  isGeneralConfigChanged: boolean;
  isNumberOfPerformersHidden: boolean;
  currentId: string;
  access: AccessData;
  searchedMeta: MetaT;
  general: GeneralData;
  searchString: string;
  allUsersCount: number;
  taskTemplateId: string;
  searchMode: SearchType | null;
  candidatesMeta: PaginationData;
  listOfCandidates: UserWithName[];
  fieldsValidation: FieldsValidation;
  searchedList: Array<SearchUserItemType | SearchDepartmentItemType | SearchPositionItemType>;
};

const defaultState: ResponsibilityModalState = {
  currentId: '',
  taskTemplateId: '',
  isOpen: false,
  isUpdate: false,
  isLoading: false,
  isFetching: false,
  general: {
    name: '',
    namesArray: [],
    workersCount: 1,
    type: AssignmentType.Manual,
  },
  access: {
    selectedWorkers: [],
    selectedDepartments: [],
    selectedPositions: [],
  },
  fieldsValidation: {
    name: '',
    uniqueName: '',
    workersCount: '',
    validatedName: '',
    numberOfPerformers: '',
  },
  allUsersCount: 0,
  listOfCandidates: [],
  searchMode: null,
  searchString: '',
  searchedList: [],
  searchedMeta: {
    next: 0,
    prev: 0,
    total: 0,
    perPage: 0,
    lastPage: 0,
    currentPage: 0,
  },
  candidatesMeta: {
    next: 0,
    prev: 0,
    total: 0,
    perPage: 0,
    lastPage: 0,
    currentPage: 0,
  },
  isSearching: true,
  isGeneralConfigChanged: false,
  isSearchModeChanged: false,
  isAllConfigChanged: false,
  isFinalStep: false,
  isNumberOfPerformersHidden: false,
};

const stateController = new StateController<ResponsibilityModalState>(
  'WORKFLOW_TASK_TEMPLATE_RESPONSIBILITY_MODAL',
  defaultState,
);

export class Actions {
  public static openModal(items: TaskTemplateResponsibilityT[] | TaskResponsibilityModel[]) {
    return async (dispatch) => {
      const itemsCount = items.length;
      const names = items.map((responsibility) => responsibility.name);

      const { meta } = await UserService.getAllUsers({ skip: 0, take: 1, status: UserStatusEnum.Active });

      dispatch(
        stateController.setState((prevState) => ({
          ...prevState,
          isOpen: true,
          general: {
            ...prevState.general,
            workersCount: 1,
            name: `Responsibility ${itemsCount + 1}`,
          },
          allUsersCount: meta.total,
        })),
      );

      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          general: {
            ...prev.general,
            namesArray: names,
          },
        })),
      );
      dispatch(Actions.fieldValidation());
    };
  }

  public static openModalWithoutData() {
    return async (dispatch) => {
      dispatch(stateController.setState({ isOpen: true }));
    };
  }

  public static setFetching(value: boolean) {
    return async (dispatch) => {
      dispatch(stateController.setState({ isFetching: value }));
    };
  }

  public static setLoading(value: boolean) {
    return async (dispatch) => {
      dispatch(stateController.setState({ isLoading: value }));
    };
  }

  public static openUpdateModal({
    currentId,
    general,
    access,
    names,
    isNumberOfPerformersHidden,
    taskTemplateId = '',
  }: OpenUpdateModal) {
    return async (dispatch) => {
      try {
        const namesArray = names.filter((i) => i !== general.name);
        const { meta } = await UserService.getAllUsers({ skip: 0, take: 1, status: UserStatusEnum.Active });

        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            currentId,
            isOpen: true,
            isUpdate: true,
            taskTemplateId,
            general: {
              namesArray,
              name: general.name,
              type: general.type,
              workersCount: general.workersCount,
            },
            access,
            allUsersCount: meta.total,
            ...(isNumberOfPerformersHidden ? { isNumberOfPerformersHidden } : {}),
          })),
        );
      } catch (err) {
        notify.error(err.message);
        throw err;
      }
    };
  }

  public static getCandidates(skip: number, take: number) {
    return async (dispatch, getState: () => AppState) => {
      try {
        const subState = getState().workflow_task_template_responsibility_modal.access;

        const userIds = subState.selectedWorkers.map((i) => i.id).join(',') || undefined;
        const positionIds = subState.selectedPositions.map((i) => i.id).join(',') || undefined;
        const departmentIds = subState.selectedDepartments.map((i) => i.id).join(',') || undefined;

        const { data, meta } = await UserService.getAllUsers({
          skip,
          take,
          user_id: userIds,
          department_id: departmentIds,
          position_type_id: positionIds,
          status: UserStatusEnum.Active,
        });

        if (!skip) {
          dispatch(
            stateController.setState((prev) => ({
              ...prev,
              candidatesMeta: meta,
              listOfCandidates: data.map((item) => ({
                ...item,
                name: `${item.first_name} ${item.last_name}`,
              })),
            })),
          );
        } else {
          dispatch(
            stateController.setState((prev) => ({
              ...prev,
              candidatesMeta: meta,
              listOfCandidates: [
                ...prev.listOfCandidates,
                ...data.map((item) => ({
                  ...item,
                  name: `${item.first_name} ${item.last_name}`,
                })),
              ],
            })),
          );
        }

        dispatch(Actions.fieldValidation());
      } catch (err) {
        notify.error(err.message);
        throw err;
      }
    };
  }

  public static closeModal() {
    return async (dispatch) => {
      dispatch(
        stateController.setState(() => ({
          ...defaultState,
        })),
      );
    };
  }

  public static changeResponsibilityName(name: string) {
    return async (dispatch) => {
      await dispatch(
        stateController.setState((prevState) => ({
          ...prevState,
          general: {
            ...prevState.general,
            name,
          },
        })),
      );
      dispatch(Actions.fieldValidation());
      dispatch(Actions.isConfigChanged());
    };
  }

  public static changeWorkersCount(mode?: CountChangeModeType, count?: number) {
    return async (dispatch, getState: () => AppState) => {
      const { general } = getState().workflow_task_template_responsibility_modal;
      let result = count || 0;

      if (mode === 'decrement') {
        result = general.workersCount - 1;
      }
      if (mode === 'increment') {
        result = general.workersCount + 1;
      }

      await dispatch(
        stateController.setState((prevState) => ({
          ...prevState,
          general: {
            ...prevState.general,
            workersCount: result < 1 ? 1 : result,
          },
        })),
      );
      dispatch(Actions.fieldValidation());
      dispatch(Actions.isConfigChanged());
    };
  }

  public static changeResponsibilityType(type: AssignmentType) {
    return async (dispatch) => {
      await dispatch(
        stateController.setState((prevState) => ({
          ...prevState,
          general: {
            ...prevState.general,
            type,
          },
        })),
      );
      dispatch(Actions.fieldValidation());
      dispatch(Actions.isConfigChanged());
    };
  }

  public static setSearchType(type: SearchType | null) {
    return async (dispatch) => {
      dispatch(
        stateController.setState((prevState) => ({
          ...prevState,
          searchMode: type,
          searchString: '',
          searchedList: [],
          searchedMeta: null,
          isSearching: true,
        })),
      );
      dispatch(Actions.isConfigChanged());
    };
  }

  public static searchByValue({
    skip = 0,
    ...args
  }: {
    value?: string;
    take?: number;
    skip?: number;
    isInfiniteLoading?: boolean;
  }) {
    return async (dispatch, getState: () => AppState) => {
      let searchedList: SearchItemType[] = [];
      let searchedMeta: PaginationData | null = null;
      const { searchMode, access, searchedList: alreadySearchedList } = getState().workflow_task_template_responsibility_modal;

      try {
        dispatch(
          stateController.setState((prevState) => ({
            ...prevState,
            isSearching: !args.isInfiniteLoading,
            searchString: args?.value,
          })),
        );

        if (searchMode === 'departments') {
          const { data, meta } = await DepartmentService.getAllDepartamentsByPage({
            skip,
            user_count: true,
            search: args?.value,
            take: args.take || 20,
          });
          let filteredList = data;
          access.selectedDepartments.forEach((item) => {
            filteredList = filteredList.filter((filtered) => item.id !== filtered.id);
          });
          const mappedList = filteredList.map((item) => ({
            id: item.id,
            name: item.name,
            path: item.path,
            type: searchMode,
            users_count: item.users_count,
          }));
          searchedList = meta.currentPage > 1 ? [...alreadySearchedList, ...mappedList] : mappedList;
          searchedMeta = meta;
        }

        if (searchMode === 'positions') {
          const departmentIds =
            getState()
              .workflow_task_template_responsibility_modal.access.selectedDepartments.map((i) => i.id)
              .join(',') || undefined;

          let filteredList = await PositionTypesService.getPositionTypes(args?.value, true, departmentIds);

          access.selectedPositions.forEach((item) => {
            filteredList = filteredList.filter((filtered) => item.id !== filtered.id);
          });

          searchedList = filteredList.map((item) => ({
            id: item.id,
            name: item.name,
            department: { id: item.id, name: item.description },
            type: searchMode,
            users_count: item.users_count,
          }));
        }

        if (searchMode === 'users') {
          const subState = getState().workflow_task_template_responsibility_modal.access;
          const positionIds = subState.selectedPositions.map((i) => i.id).join(',') || undefined;
          const departmentIds = subState.selectedDepartments.map((i) => i.id).join(',') || undefined;

          const { data, meta } = await UserService.getAllUsers({
            skip,
            search: args.value,
            take: args.take || 20,
            department_id: departmentIds,
            position_type_id: positionIds,
            status: UserStatusEnum.Active,
          });

          let filteredList = data;
          access.selectedWorkers.forEach((item) => {
            filteredList = filteredList.filter((filtered) => item.id !== filtered.id);
          });

          const mappedList = filteredList.map((item) => ({
            id: item.id,
            type: searchMode,
            last_name: item.last_name || '',
            first_name: item.first_name || '',
            avatar_image_url: item.avatar_image_url,
            name: `${item.first_name} ${item.last_name}`,
            position: { id: item.id, name: item.positions?.map((pos) => pos.name).join(', ') || 'відсутня' },
            department: { id: item.id, name: item.departments?.map((dep) => dep.name).join(', ') || 'відсутній' },
          }));
          searchedList = meta.currentPage > 1 ? [...alreadySearchedList, ...mappedList] : mappedList;
          searchedMeta = meta;
        }
      } finally {
        dispatch(
          stateController.setState((prevState) => ({
            ...prevState,
            searchedList,
            searchedMeta,
            isSearching: false,
            searchString: args?.value,
          })),
        );
        dispatch(Actions.isConfigChanged());
      }
    };
  }

  public static clearOptions() {
    return async (dispatch, getState: () => AppState) => {
      const { isFinalStep } = getState().workflow_task_template_responsibility_modal;
      dispatch(
        stateController.setState((prev) => ({
          ...defaultState,
          isOpen: !isFinalStep,
          isUpdate: prev.isUpdate,
        })),
      );
    };
  }

  public static addToSelectedList(data: SearchUserItemType | SearchPositionItemType | SearchDepartmentItemType) {
    return async (dispatch, getState: () => AppState) => {
      const { searchMode, searchedList, access } = getState().workflow_task_template_responsibility_modal;
      let newAccess;

      if (searchMode === 'departments') {
        newAccess = {
          ...access,
          selectedDepartments: [...access.selectedDepartments, data],
        };
      }

      if (searchMode === 'positions') {
        newAccess = {
          ...access,
          selectedPositions: [...access.selectedPositions, data],
        };
      }

      if (searchMode === 'users') {
        newAccess = {
          ...access,
          selectedWorkers: [...access.selectedWorkers, data],
        };
      }

      dispatch(
        stateController.setState((prevState) => ({
          ...prevState,
          access: newAccess,
          searchedList: searchedList.filter((item) => item.id !== data.id),
        })),
      );
      dispatch(Actions.isConfigChanged());
    };
  }

  public static deleteFromSelectedList(data: SelectedDepartmentType | SelectedPositionType | SelectedWorkerType) {
    return async (dispatch, getState: () => AppState) => {
      const { access, searchedList, searchMode } = getState().workflow_task_template_responsibility_modal;
      const { id, type } = data;
      let newAccess;

      if (type === 'departments') {
        newAccess = {
          ...access,
          selectedDepartments: access.selectedDepartments.filter((item) => item.id !== id),
        };
      }

      if (type === 'positions') {
        newAccess = {
          ...access,
          selectedPositions: access.selectedPositions.filter((item) => item.id !== id),
        };
      }

      if (type === 'users') {
        newAccess = {
          ...access,
          selectedWorkers: access.selectedWorkers.filter((item) => item.id !== id),
        };
      }

      dispatch(
        stateController.setState((prevState) => ({
          ...prevState,
          access: newAccess,
          searchedList: data.type === searchMode ? [...searchedList, data] : [...searchedList],
        })),
      );
      dispatch(Actions.searchByValue({}));
      dispatch(Actions.isConfigChanged());
    };
  }

  public static fieldValidation() {
    return (dispatch, getState: () => AppState) => {
      const { general, listOfCandidates } = getState().workflow_task_template_responsibility_modal;
      const { namesArray, workersCount } = getState().workflow_task_template_responsibility_modal.general;

      const [, nameErrorMessage] = validators.isNotEmpty(general.name, `The value must not be empty`);
      const [, workerErrorMessage] = validators.isGreaterThanZero(general.workersCount, `The value must not be less than 1`);
      const [, uniqueNameErrorMessage] = validators.isUniqueString(
        { value: general.name, valueArray: namesArray || [] },
        'Task responsibility title is not unique',
      );
      const validationNameErrorMessage =
        general.name === general.name.trim()
          ? ''
          : 'The value must be without leading or trailing spaces, and must not consist only of spaces';
      const numberOfPerformersErrorMessage = workersCount > listOfCandidates?.length ? 'Insufficient number of candidates' : null;

      const fieldsValidated = {
        name: nameErrorMessage,
        workersCount: workerErrorMessage,
        uniqueName: uniqueNameErrorMessage,
        validatedName: validationNameErrorMessage,
        numberOfPerformers: numberOfPerformersErrorMessage,
      };

      dispatch(
        stateController.setState((prevState) => ({
          ...prevState,
          fieldsValidation: fieldsValidated,
        })),
      );
    };
  }

  public static isConfigChanged() {
    return (dispatch, getState: () => AppState) => {
      const { general, searchMode } = getState().workflow_task_template_responsibility_modal;
      const isGeneralConfigChanged = !!general.name || !!general.workersCount;
      const isSearchModeChanged = searchMode !== null;
      dispatch(
        stateController.setState((prevState) => ({
          ...prevState,
          isGeneralConfigChanged,
          isSearchModeChanged,
          isAllConfigChanged: isGeneralConfigChanged || isSearchModeChanged,
        })),
      );
    };
  }
}

export class Selectors {
  public static countInSelectedDepartments(state: AppState) {
    const { selectedDepartments } = state.workflow_task_template_responsibility_modal.access;
    const countOfUsersArray = selectedDepartments?.map((i) => i.users_count);
    return (
      (countOfUsersArray?.length > 0 &&
        countOfUsersArray.reduce((acc, cur) => {
          if (!cur || !acc) return acc;

          return acc + cur;
        }, 0)) ||
      0
    );
  }
}

export const reducer = stateController.getReducer();
