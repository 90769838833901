import { QRCode } from 'react-qrcode-logo';
import Modal from 'components/ui-new/modal/modal';
import ModalHeader from 'components/modal-header/modal-header';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'redux/store';
import Subtitle from 'components/subtitle/subtitle';
import { Typography } from '@mui/material';

import { AppStoreIcon } from 'icons/app-store';
import { GooglePlayIcon } from 'icons/google-play';
import mobileQrLogo from './mobile-qr-logo.svg';
import s from './get-app-modal.module.scss';
import { Actions as AppModalActions } from './get-app-modal.controller';

enum Qr {
  AppStoreUrl = 'https://apps.apple.com/ua/app/hesh-manage-your-production/id6476871323?l=uk',
  GooglePlayUrl = 'https://play.google.com/store/apps/details?id=com.heshmobile',
}
type QrLabelData = {
  qrUrl: Qr;
};

export function GetAppModal() {
  const [qrData, setQrData] = useState<QrLabelData | null>(null);
  const dispatch = useDispatch();
  const isModalOpen = useSelector((state: AppState) => state.label_with_qr.isModalOpen);

  useEffect(() => {
    if (isModalOpen) {
      handleGenerateQrForAppStore();
    }
  }, [isModalOpen]);

  const handleGenerateQrForAppStore = () => {
    setQrData({ qrUrl: Qr.AppStoreUrl });
  };

  const handleGenerateQrForGooglePlay = () => {
    setQrData({ qrUrl: Qr.GooglePlayUrl });
  };

  const isAppStorButtonActive = qrData && qrData.qrUrl === Qr.AppStoreUrl;
  const isGooglePlayButtonActive = qrData && qrData.qrUrl === Qr.GooglePlayUrl;

  return (
    <Modal PaperProps={{ className: s.modal }} open={isModalOpen} onClose={() => dispatch(AppModalActions.closeLabelModal())}>
      <ModalHeader
        witohutShadow
        onClose={() => dispatch(AppModalActions.closeLabelModal())}
        title="Qr label"
        className={s.modal_header}
        withCloseButton
      >
        <div className={s.row}>
          <div>
            <Subtitle style={{ paddingBottom: '6px' }} text="Scan to download the app" fontSize="newLarge" />
            <Typography>Place your camera over the entire QR code to start scanning</Typography>
          </div>
          <div className={s.modal_header_buttons}>
            <div className={`${s.qr_button} ${isAppStorButtonActive ? s.active : ''}`} onClick={handleGenerateQrForAppStore}>
              <AppStoreIcon width={24} height={24} />
              <div>App store</div>
            </div>
            <div className={`${s.qr_button} ${isGooglePlayButtonActive ? s.active : ''}`} onClick={handleGenerateQrForGooglePlay}>
              <GooglePlayIcon width={24} height={24} />
              <div>Google play</div>
            </div>
          </div>
        </div>
      </ModalHeader>
      <LabelWithQr isHidden={!qrData} data={qrData} />
    </Modal>
  );
}

type Props = { isHidden?: boolean; data: QrLabelData | null };

function LabelWithQr(props: Props) {
  const { isHidden = true, data } = props;

  if (!data) return null;

  return (
    <div style={isHidden ? { zIndex: -1, position: 'fixed', pointerEvents: 'none', opacity: 0 } : {}}>
      <div className={s.container}>
        <div className={s.qr}>
          <QRCode
            size={224}
            quietZone={5}
            qrStyle="dots"
            logoImage={mobileQrLogo}
            logoPadding={11}
            eyeRadius={3}
            value={data.qrUrl}
          />
        </div>
      </div>
    </div>
  );
}
