import { AppState, GetStateFunction } from 'redux/store';
import { ProductConfigurationService } from 'services/product-configurations.service';
import { StateController } from 'state-controller';
import { Actions as ProductVariantController } from 'pages/product-flow/pages/product/controllers/product-variant.controller';
import { Actions as ProductRootController } from 'pages/product-flow/pages/product/controllers/product-root.controller';

export const SKU_VALIDATION_TEXT = 'To publish a product, you need to enter a SKU - a unique identifier of the configuration';

export type ProductDeskriptionValidations = {
  errors: {
    empty: string;
  };
};

export type ProductState = {
  isLoading: boolean;
  isOpened: boolean;
  isEditing: boolean;
  description: string;
  sku: string;
  validations: ProductDeskriptionValidations;
};

const validationsDefaultState: ProductDeskriptionValidations = {
  errors: {
    empty: '',
  },
};

const defaultState: ProductState = {
  isLoading: true,
  isOpened: true,
  isEditing: false,
  description: '', // editor description
  sku: '', // editor sku
  validations: validationsDefaultState,
};

const stateController = new StateController<ProductState>('PRODUCT_DESCRIPTION', defaultState);

export class Actions {
  public static init(description: string, sku: string) {
    return async (dispatch) => {
      await dispatch(stateController.setState((prev) => ({ ...prev, description, sku, isLoading: false })));
      dispatch(Actions.revalidate());
    };
  }

  public static disposeState() {
    return (dispatch) => {
      dispatch(stateController.setState(defaultState));
    };
  }

  public static setInitLoading() {
    return (dispatch) => {
      dispatch(stateController.setState({ isLoading: true }));
    };
  }

  public static toggleIsOpened() {
    return (dispatch) => {
      dispatch(stateController.setState((prev) => ({ ...prev, isOpened: !prev.isOpened })));
    };
  }

  public static toggleIsEditing() {
    return (dispatch) => {
      dispatch(stateController.setState((prev) => ({ ...prev, isEditing: !prev.isEditing })));
    };
  }

  public static onUpdateConfigurationSKU(sku: string) {
    return async (dispatch, getState: GetStateFunction) => {
      const productID = getState().product.product_root.id;
      const { active_configuration } = getState().product.product_configurations;

      try {
        await dispatch(stateController.setState((prev) => ({ ...prev, isEditing: false, sku })));
        await ProductConfigurationService.update(active_configuration.id, {
          product_id: productID,
          sku,
        });
        dispatch(ProductVariantController.refetchVariants(active_configuration.id));
        dispatch(Actions.revalidate());
        dispatch(ProductRootController.updateProductModifiedAt());
      } catch (error) {
        dispatch(stateController.setState((prev) => ({ ...prev, isEditing: false, sku: active_configuration.sku })));
        throw error;
      }
    };
  }

  public static setDescription(text: string) {
    return (dispatch) => {
      dispatch(stateController.setState({ description: text }));
    };
  }

  public static onUpdateConfigurationDescription(description: string) {
    return async (dispatch, getState: GetStateFunction) => {
      const productID = getState().product.product_root.id;
      const { active_configuration } = getState().product.product_configurations;

      try {
        await ProductConfigurationService.update(active_configuration.id, {
          product_id: productID,
          description,
        });
        dispatch(ProductRootController.updateProductModifiedAt());
      } catch (error) {
        dispatch(stateController.setState((prev) => ({ ...prev, isEditing: false, sku: active_configuration.description })));
        throw error;
      }
    };
  }

  public static revalidate() {
    return (dispatch, getState: GetStateFunction) => {
      const { sku } = getState().product.product_descrioption;

      const newValidation: ProductDeskriptionValidations = {
        errors: {
          empty: '',
        },
      };

      if (!sku) {
        newValidation.errors.empty = SKU_VALIDATION_TEXT;
      }

      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          validations: newValidation,
        })),
      );
    };
  }
}

export class Selectors {
  public static isConfigurationSkuValid(state: AppState) {
    const { validations } = state.product.product_descrioption;
    const { errors } = validations;

    return !errors.empty;
  }
}

export const reducer = stateController.getReducer();
