import { FC } from 'react';
import { PriorityEnum } from 'types/priority-enums';
import Tag from 'components/tag-outline/tag-outline';
import { PriorityLowIcon } from 'icons/priority-low';
import { PriorityHightIcon } from 'icons/priority-high';
import { PriorityMediumIcon } from 'icons/priority-medium';
import { PriorityLowestIcon } from 'icons/priority-lowest';
import { PriorityHighestIcon } from 'icons/priority-highest';
import s from './priority-tag.module.scss';

type OwnProps = {
  darkBackgroundMode?: boolean;
  priority: PriorityEnum | string;
};

export const PriorityTag: FC<OwnProps> = (props) => {
  const { priority, darkBackgroundMode = false } = props;
  switch (priority) {
    case PriorityEnum.Highest:
      return (
        <Tag
          color="red"
          onClick={() => {}}
          className={s.badge}
          active={darkBackgroundMode}
          iconLeft={<PriorityHighestIcon height="16px" width="16px" />}
        />
      );
    case PriorityEnum.High:
      return (
        <Tag
          color="red"
          onClick={() => {}}
          active={darkBackgroundMode}
          className={`${s.badge} ${s.badge_opacity}`}
          iconLeft={<PriorityHightIcon fill="#f99891" height="16px" width="16px" />}
        />
      );
    case PriorityEnum.Medium:
      return (
        <Tag
          color="yellow"
          onClick={() => {}}
          className={s.badge}
          active={darkBackgroundMode}
          iconLeft={<PriorityMediumIcon height="16px" width="16px" />}
        />
      );
    case PriorityEnum.Low:
      return (
        <Tag
          color="grey"
          onClick={() => {}}
          className={s.badge}
          active={darkBackgroundMode}
          iconLeft={<PriorityLowIcon height="16px" width="16px" />}
        />
      );
    case PriorityEnum.Lowest:
      return (
        <Tag
          color="grey"
          onClick={() => {}}
          active={darkBackgroundMode}
          className={`${s.badge} ${s.badge_opacity}`}
          iconLeft={<PriorityLowestIcon height="16px" width="16px" />}
        />
      );
    default:
      return null;
  }
};
