import { FC, SVGProps } from 'react';

export const EyeIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M15.7377 12.365C15.7377 14.4324 14.0671 16.103 11.9997 16.103C9.93234 16.103 8.26172 14.4324 8.26172 12.365C8.26172 10.2976 9.93234 8.62695 11.9997 8.62695C14.0671 8.62695 15.7377 10.2976 15.7377 12.365Z"
      stroke={props.stroke || '#878B92'}
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.9996 21.0002C15.6854 21.0002 19.1206 18.8284 21.5117 15.0695C22.4514 13.5973 22.4514 11.1227 21.5117 9.65043C19.1206 5.89153 15.6854 3.71973 11.9996 3.71973C8.3138 3.71973 4.87858 5.89153 2.48751 9.65043C1.54778 11.1227 1.54778 13.5973 2.48751 15.0695C4.87858 18.8284 8.3138 21.0002 11.9996 21.0002Z"
      stroke={props.stroke || '#878B92'}
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
