import { LogoBlueIcon } from 'icons/logo-blue';
import { UnionIcon } from 'icons/union';
import { Paths } from 'routes/paths';
import Spinner from 'components/spinner/spinner';
import { useLogIn } from 'pages/auth/useAuth';
import { Link } from 'react-router-dom';
import { ArrowLongLeftIcon } from 'icons/arrow-long-left';

import { EmojiStartIcon } from 'icons/emodji-start';
import s from './auth.module.scss';
import shared_s from './shared-styles.module.scss';

const AuthPage = ({ children }) => {
  const { location, isLoading } = useLogIn();

  if (isLoading) return <Spinner />;

  const isSignUpPage = location.pathname === Paths.SignUp;

  return (
    <div className={s.container}>
      <div className={s.wrapper}>
        <div className={s.left_column}>
          <div className={`${s.left_column_header} ${isSignUpPage ? s.left_column_header_sign_up : ''}`}>
            <a className={s.logo_conainer} href={window.location.origin}>
              <LogoBlueIcon />
            </a>
            <div className={s.content_wrapper}>{children}</div>
            {isSignUpPage && (
              <div className={shared_s.sign_up_in}>
                <ArrowLongLeftIcon fill="#0851FE" />
                <Link to={Paths.SignIn} className={shared_s.sign_up_in_arrow_text}>
                  Return to Log in
                </Link>
              </div>
            )}
          </div>
        </div>
        <div className={`${s.right_column} ${isSignUpPage ? s.sign_up_right_column : ''}`}>
          <div className={s.login_image}>
            {isSignUpPage ? (
              <div className={shared_s.success_wrapper}>
                <div className={`${shared_s.emoji} ${s.emoji}`}>
                  <EmojiStartIcon />
                </div>
                <h3 className={shared_s.heading}>Sign up a new user?</h3>
                <p className={s.underlined_text}>Ask your administrator</p>
              </div>
            ) : (
              <div className={s.text}>
                <h1>Real-time production framework</h1>
                <p>All your workers needs in one place</p>
              </div>
            )}
            <UnionIcon className={s.background_sign} />
          </div>
        </div>
        {isSignUpPage && (
          <p className={shared_s.sign_up_in_mobile}>
            Already have account? <Link to={Paths.SignIn}>Log in</Link>
          </p>
        )}
      </div>
    </div>
  );
};

export default AuthPage;
