import { UpdateTasksParamsArgs } from 'pages/tasks/types/function-types';
import { AssignResponsibleToTasksBody, SelectedTask, TaskTableModel } from 'services/task-table.model';

export const getAllTasksOnTheScreen = (tasks: TaskTableModel[]): SelectedTask[] => {
  return tasks.map((task) => ({
    id: task.id,
    departmentIds: task.department_ids,
    slots: task.assignee.map((assignee) => ({
      slot_id: assignee.slot_id,
      task_responsibility_id: assignee.task_responsibility_id,
      isUnassigned: !assignee.taskAssignment?.user,
    })),
  }));
};

export const prepareRequestBodyForBulkAssignUsers = (
  performers: Record<string, string | null>,
  tasks: SelectedTask[],
): AssignResponsibleToTasksBody[] => {
  const userIds = Object.values(performers);

  return userIds.map((userId, index) => {
    const taskSlotIds = tasks.map((task) => task.slots[index]?.slot_id);

    return {
      user_id: userId,
      task_slot_ids: taskSlotIds,
    };
  });
};

export const updateTasksFromWsResponse = ({ id, slotId, user, tasks }: UpdateTasksParamsArgs) => {
  return {
    ...tasks,
    data: tasks.data.map((task) => {
      if (task.id === id) {
        return {
          ...task,
          assignee: task.assignee.map((assignee) => {
            if (assignee.slot_id === slotId) {
              return {
                ...assignee,
                taskAssignment: { user },
              };
            }
            return assignee;
          }),
          task_slots: task.task_slots.map((slot) => (slot.id === slotId ? { ...slot, task_assignment_id: user.id } : slot)),
          is_assignment_in_progress: false,
        };
      }
      return task;
    }),
  };
};
