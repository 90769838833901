// import { useTranslation } from 'react-i18next';
import { PriorityEnum } from 'types/priority-enums';
import { PriorityHighestIcon } from 'icons/priority-highest';
import { PriorityHightIcon } from 'icons/priority-high';
import { PriorityMediumIcon } from 'icons/priority-medium';
import { PriorityLowIcon } from 'icons/priority-low';
import { PriorityLowestIcon } from 'icons/priority-lowest';
import s from './priority-option-item.module.scss';

type PriorityOptionProps = {
  option: PriorityEnum;
  onClick: (value: PriorityEnum) => void;
};
export const PriorityOptionItem = (props: PriorityOptionProps) => {
  const { option, onClick } = props;
  // const { t } = useTranslation();
  const handleClick = (clickedValue: PriorityEnum) => () => {
    onClick(clickedValue);
  };

  switch (option) {
    case PriorityEnum.Highest:
      return (
        <div className={`${s.container} ${s.container_highest}`} onClick={handleClick(option)}>
          <div className={s.icon_container}>
            <PriorityHighestIcon height="12px" width="12px" />
          </div>
          <span className={s.title}>{PriorityEnum.Highest}</span>
        </div>
      );
    case PriorityEnum.High:
      return (
        <div className={`${s.container} ${s.container_high}`} onClick={handleClick(option)}>
          <div className={s.icon_container}>
            <PriorityHightIcon fill="#f99891" height="12px" width="12px" />
          </div>
          <span className={s.title}>{PriorityEnum.High}</span>
        </div>
      );
    case PriorityEnum.Medium:
      return (
        <div className={`${s.container} ${s.container_medium}`} onClick={handleClick(option)}>
          <div className={s.icon_container}>
            <PriorityMediumIcon height="12px" width="12px" />
          </div>
          <span className={s.title}>{PriorityEnum.Medium}</span>
        </div>
      );
    case PriorityEnum.Low:
      return (
        <div className={`${s.container} ${s.container_low}`} onClick={handleClick(option)}>
          <div className={s.icon_container}>
            <PriorityLowIcon height="12px" width="12px" />
          </div>
          <span className={s.title}>{PriorityEnum.Low}</span>
        </div>
      );
    case PriorityEnum.Lowest:
      return (
        <div className={`${s.container} ${s.container_lowest}`} onClick={handleClick(option)}>
          <div className={s.icon_container}>
            <PriorityLowestIcon height="12px" width="12px" />
          </div>
          <span className={s.title}>{PriorityEnum.Lowest}</span>
        </div>
      );
    default:
      return null;
  }
};
