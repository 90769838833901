import { FC, SVGProps } from 'react';

const ProfileIcon: FC<SVGProps<SVGSVGElement>> = ({ ...props }) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_10000_21168)">
      <path
        d="M15.4658 16.2906C14.6379 14.0764 12.5031 12.5 10.0001 12.5C7.49706 12.5 5.36225 14.0764 4.53439 16.2906M15.4658 16.2906C17.2227 14.7628 18.3334 12.5111 18.3334 9.99996C18.3334 5.39759 14.6025 1.66663 10.0001 1.66663C5.39771 1.66663 1.66675 5.39759 1.66675 9.99996C1.66675 12.5111 2.77745 14.7628 4.53439 16.2906M15.4658 16.2906C14.0026 17.563 12.0913 18.3333 10.0001 18.3333C7.90885 18.3333 5.99753 17.563 4.53439 16.2906"
        stroke="#525558"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <circle
        cx="2.5"
        cy="2.5"
        r="2.5"
        transform="matrix(1 0 0 -1 7.5 10)"
        stroke="#525558"
        strokeWidth="2"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_10000_21168">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default ProfileIcon;
