import { FC, SVGProps } from 'react';

export const EstimatedCostIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M5.20459 7.5865C5.20459 8.12604 5.62501 8.56748 6.13652 8.56748H7.18757C7.63602 8.56748 8.00039 8.1821 8.00039 7.71263C8.00039 7.20111 7.77616 7.01893 7.44683 6.89981L5.76515 6.31122C5.42881 6.1921 5.20459 6.00992 5.20459 5.49841C5.20459 5.02894 5.56895 4.64355 6.0174 4.64355H7.06846C7.58697 4.65056 8.0074 5.085 8.0074 5.62454"
      stroke="#141414"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M6.60547 8.60254V9.12106" stroke="#141414" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M6.60547 4.09033V4.63688" stroke="#141414" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M6.5986 12.1972C9.69063 12.1972 12.1972 9.69063 12.1972 6.5986C12.1972 3.50658 9.69063 1 6.5986 1C3.50658 1 1 3.50658 1 6.5986C1 9.69063 3.50658 12.1972 6.5986 12.1972Z"
      stroke="#141414"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.69434 13.5288C9.32497 14.4187 10.355 15.0003 11.5322 15.0003C13.4451 15.0003 15.0006 13.4447 15.0006 11.5318C15.0006 10.3687 14.4261 9.33864 13.5502 8.70801"
      stroke="#141414"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
