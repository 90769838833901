import { useState, useEffect, MutableRefObject } from 'react';

const useRowWidth = (refRow: MutableRefObject<HTMLDivElement | null>) => {
  const [colWidth, setColWidth] = useState(0);

  const handleColWidth = async () => {
    await setColWidth(refRow?.current?.clientWidth || 0);
  };

  useEffect(() => {
    handleColWidth();
    window.addEventListener('resize', handleColWidth);
    return () => {
      window.removeEventListener('resize', handleColWidth);
    };
  }, [refRow?.current?.clientWidth]);

  return colWidth;
};

export default useRowWidth;
