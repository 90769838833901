import { FC, SVGProps } from 'react';

export const MoneyIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M8.70004 5.90039H4.5" stroke="#878B92" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M15 7.27843V8.72048C15 9.10549 14.692 9.42046 14.3 9.43446H12.9279C12.1719 9.43446 11.4789 8.88146 11.4159 8.12545C11.3739 7.68445 11.5419 7.27144 11.8359 6.98444C12.0949 6.71844 12.4519 6.56445 12.8439 6.56445H14.3C14.692 6.57845 15 6.89343 15 7.27843Z"
      stroke="#878B92"
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.8361 6.98484C11.5421 7.27184 11.3741 7.68485 11.4161 8.12585C11.4791 8.88186 12.1721 9.43486 12.9281 9.43486H14.3001V10.4499C14.3001 12.5499 12.9001 13.9499 10.8001 13.9499H4.50003C2.40001 13.9499 1 12.5499 1 10.4499V5.54984C1 3.64582 2.14801 2.31581 3.93303 2.0918C4.11503 2.0638 4.30403 2.0498 4.50003 2.0498H10.8001C10.9821 2.0498 11.1571 2.0568 11.3251 2.0848C13.1311 2.2948 14.3001 3.63182 14.3001 5.54984V6.56485H12.8441C12.4521 6.56485 12.0951 6.71884 11.8361 6.98484Z"
      stroke="#878B92"
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
