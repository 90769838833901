import { FC, SVGProps } from 'react';

export const VendorIcon: FC<SVGProps<SVGSVGElement>> = ({ ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
    <path
      d="M2.19141 1.47485V10.0151C2.19141 10.7143 2.5196 11.3778 3.08325 11.7988L6.80043 14.5813C7.59238 15.1735 8.68399 15.1735 9.47595 14.5813L13.1931 11.7988C13.7568 11.3778 14.085 10.7143 14.085 10.0151V1.47485H2.19141Z"
      stroke="#141414"
      strokeMiterlimit="10"
    />
    <path d="M1 1.47485H15.2694" stroke="#141414" strokeMiterlimit="10" strokeLinecap="round" />
    <path d="M5.28125 5.39905H10.989" stroke="#141414" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M5.28125 8.96643H10.989" stroke="#141414" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);
