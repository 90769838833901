import { FC, SVGProps } from 'react';

export const SettingsIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M9.27462 4.69231L9.93616 2.98462C10.0477 2.69548 10.244 2.44679 10.4993 2.2711C10.7546 2.09541 11.057 2.00092 11.3669 2H12.6285C12.9384 2.00092 13.2408 2.09541 13.4961 2.2711C13.7514 2.44679 13.9477 2.69548 14.0592 2.98462L14.7208 4.69231L16.9669 5.98462L18.7823 5.70769C19.0846 5.66666 19.3923 5.71642 19.6662 5.85065C19.9401 5.98488 20.168 6.19752 20.3208 6.46154L20.9362 7.53846C21.0938 7.80669 21.1665 8.11643 21.1445 8.4268C21.1226 8.73717 21.007 9.03359 20.8131 9.27692L19.69 10.7077V13.2923L20.8438 14.7231C21.0378 14.9664 21.1533 15.2628 21.1753 15.5732C21.1973 15.8836 21.1246 16.1933 20.9669 16.4615L20.3515 17.5385C20.1987 17.8025 19.9709 18.0151 19.697 18.1493C19.423 18.2836 19.1154 18.3333 18.8131 18.2923L16.9977 18.0154L14.7515 19.3077L14.09 21.0154C13.9784 21.3045 13.7822 21.5532 13.5269 21.7289C13.2716 21.9046 12.9691 21.9991 12.6592 22H11.3669C11.057 21.9991 10.7546 21.9046 10.4993 21.7289C10.244 21.5532 10.0477 21.3045 9.93616 21.0154L9.27462 19.3077L7.02847 18.0154L5.21308 18.2923C4.9108 18.3333 4.60314 18.2836 4.32921 18.1493C4.05527 18.0151 3.82742 17.8025 3.67462 17.5385L3.05923 16.4615C2.90154 16.1933 2.82889 15.8836 2.85086 15.5732C2.87284 15.2628 2.9884 14.9664 3.18231 14.7231L4.30539 13.2923V10.7077L3.15154 9.27692C2.95763 9.03359 2.84207 8.73717 2.8201 8.4268C2.79812 8.11643 2.87078 7.80669 3.02847 7.53846L3.64385 6.46154C3.79666 6.19752 4.0245 5.98488 4.29844 5.85065C4.57237 5.71642 4.88003 5.66666 5.18231 5.70769L6.9977 5.98462L9.27462 4.69231ZM8.92077 12C8.92077 12.6086 9.10123 13.2034 9.43933 13.7094C9.77742 14.2154 10.258 14.6098 10.8202 14.8427C11.3824 15.0756 12.0011 15.1365 12.598 15.0178C13.1948 14.8991 13.7431 14.606 14.1734 14.1757C14.6037 13.7454 14.8968 13.1971 15.0155 12.6003C15.1342 12.0034 15.0733 11.3847 14.8404 10.8225C14.6075 10.2603 14.2131 9.77973 13.7071 9.44163C13.2011 9.10354 12.6063 8.92308 11.9977 8.92308C11.1816 8.92308 10.399 9.24725 9.82198 9.82429C9.24495 10.4013 8.92077 11.1839 8.92077 12Z"
      stroke="#141414"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
