import CollapseIcon from 'icons/collapse';
import ExpandIcon from 'icons/expand';
import { ControlButton, MiniMap, Controls as ScaleControls } from 'reactflow';
import { useKeyPress } from 'ahooks';
import s from './minimap.module.scss';

type Props = {
  isFullScreen: boolean;
  setIsFullScreen: (value: boolean) => void;
};

const MinimapWithControls = ({ isFullScreen, setIsFullScreen }: Props) => {
  useKeyPress('esc', () => setIsFullScreen(false));

  return (
    <div className={s.container}>
      <MiniMap
        key={Number(isFullScreen)}
        className={s.minimap}
        nodeStrokeWidth={0}
        maskStrokeColor="black"
        maskStrokeWidth={2}
        nodeBorderRadius={10}
        zoomable
        pannable
      />
      <ScaleControls showInteractive={false} className={s.scale_controls}>
        {setIsFullScreen && (
          <ControlButton className={s.expand_button} onClick={() => setIsFullScreen(!isFullScreen)}>
            {isFullScreen ? <CollapseIcon /> : <ExpandIcon />}
          </ControlButton>
        )}
      </ScaleControls>
    </div>
  );
};

export default MinimapWithControls;
