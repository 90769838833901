import { FC, SVGProps } from 'react';

export const OrderNumberIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect x="1" y="1" width="14" height="14" rx="2.5" stroke="#141414" />
    <path d="M4.65723 8.39453H11.3672" stroke="#141414" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M4.66016 5.23047H7.66016" stroke="#141414" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M4.65723 11.5527H11.3678" stroke="#141414" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);
