import { CreateTaskFileData, TaskFile } from 'services/task-file.module';
import { baseAxiosInstance } from '../axios-config';

export class TaskFilesService {
  public static async create(body: CreateTaskFileData | FormData, onProgress) {
    const { data } = await baseAxiosInstance.post<TaskFile>(`/task-files/create`, body, {
      onUploadProgress: onProgress,
    });
    return data;
  }

  public static async delete(id: string) {
    const { data } = await baseAxiosInstance.delete(`/task-files/${id}`);
    return data;
  }
}
