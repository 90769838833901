import { FC, SVGProps } from 'react';

export const LockIcon: FC<SVGProps<SVGSVGElement>> = ({ ...props }) => (
  <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_1385_64166)">
      <path
        d="M4.26074 6.60042V5.20032C4.26074 2.88314 4.96079 1 8.46106 1C11.9613 1 12.6614 2.88314 12.6614 5.20032V6.60042"
        stroke="white"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.46107 12.5501C9.42764 12.5501 10.2112 11.7665 10.2112 10.7999C10.2112 9.83337 9.42764 9.0498 8.46107 9.0498C7.4945 9.0498 6.71094 9.83337 6.71094 10.7999C6.71094 11.7665 7.4945 12.5501 8.46107 12.5501Z"
        stroke="white"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.9607 15.0002H4.96022C2.16001 15.0002 1.45996 14.3002 1.45996 11.5V10.0999C1.45996 7.29966 2.16001 6.59961 4.96022 6.59961H11.9607C14.761 6.59961 15.461 7.29966 15.461 10.0999V11.5C15.461 14.3002 14.761 15.0002 11.9607 15.0002Z"
        stroke="white"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1385_64166">
        <rect width="16" height="16" fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </svg>
);
