import { ReactNode, useState } from 'react';
import Checkbox from 'components/checkbox/checkbox';
import s from './list-item-checkbox-wrapper.module.scss';

type Props = {
  itemId: string;
  isSelected: boolean;
  children: ReactNode;
  isEnableMultiActions: boolean;
  selectElement: (id: string) => void;
  deselectElement: (id: string) => void;
};

const ListItemCheckboxWrapper = ({
  itemId,
  children,
  isSelected,
  isEnableMultiActions,
  selectElement,
  deselectElement,
}: Props) => {
  const [isProductionHovered, setIsProductionHovered] = useState(false);

  const handleSelect = () => {
    if (isSelected) {
      deselectElement(itemId);
    } else {
      selectElement(itemId);
    }
  };

  return (
    <div
      className={s.production_wrapper}
      onMouseEnter={() => setIsProductionHovered(true)}
      onMouseLeave={() => setIsProductionHovered(false)}
    >
      <Checkbox
        size="medium"
        checked={isSelected}
        className={s.checkbox}
        data-is-checkbox-visible={isEnableMultiActions || isProductionHovered}
        onClick={handleSelect}
      />
      {children}
    </div>
  );
};

export default ListItemCheckboxWrapper;
