export const copyWithCounter = (name: string, names: string[] = []): string => {
  if (name) {
    const baseName = name.trim();
    let newName = baseName;
    let copyCount = 0;

    while (names.includes(newName)) {
      copyCount += 1;
      newName = `${baseName} copy${copyCount > 1 ? ` ${copyCount}` : ''}`;
    }

    return newName;
  }

  return 'copy';
};
