import { FC, useEffect } from 'react';
import { connect } from 'react-redux';
import { AppState } from 'redux/store';
import { useLocation } from 'react-router-dom';
import QuickSearch from 'pages/departments/modules/sub-header/components/quick-search/quick-search';
import { Actions as DepartmentsActions, QuickSearchState } from 'pages/departments/departments.controller';
import { Actions as MultiselectActions, Selectors as MultiselectSelectors } from 'pages/products/multiselect.controller';
import s from './sub-header.module.scss';

type StateProps = {
  selectedItemsCount: number;
  quickSearch: QuickSearchState;
};
type DispatchProps = {
  deselectAll: () => void;
  clearItemList: () => void;
  getItemsByKeyword: (keyword: string) => void;
};
type Props = StateProps & DispatchProps;

const SubHeader: FC<Props> = ({ quickSearch, selectedItemsCount, deselectAll, clearItemList, getItemsByKeyword }) => {
  const location = useLocation();
  useEffect(() => {
    if (selectedItemsCount) {
      deselectAll();
    }
  }, [location]);

  return (
    <div className={s.container}>
      <div className={s.row}>
        <QuickSearch
          items={quickSearch.items}
          clearItemList={clearItemList}
          isFetching={quickSearch.isFetching}
          getItemsByKeyword={getItemsByKeyword}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state: AppState): StateProps => ({
  quickSearch: state.departments.quickSearch,
  selectedItemsCount: MultiselectSelectors.selectedDepartmentsAndPositionsCount(state),
});
const mapDispatchToProps: DispatchProps = {
  deselectAll: MultiselectActions.deselectAll,
  clearItemList: DepartmentsActions.clearItemList,
  getItemsByKeyword: DepartmentsActions.getItemsByKeyword,
};
export default connect(mapStateToProps, mapDispatchToProps)(SubHeader);
