import { FC, SVGProps } from 'react';

export const PriorityHighest2Icon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="17" viewBox="0 0 12 17" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.33301 16.5C0.964817 16.5 0.666341 16.2015 0.666341 15.8333L0.666341 12.5C0.666341 10.2909 2.4572 8.5 4.66634 8.5C6.1391 8.5 7.33301 7.30609 7.33301 5.83333L7.33301 2.77586L5.80441 4.30446C5.54406 4.56481 5.12195 4.56481 4.8616 4.30446C4.60125 4.04411 4.60125 3.622 4.8616 3.36165L7.52827 0.694983C7.65329 0.569959 7.82286 0.499722 7.99967 0.499722C8.17649 0.499722 8.34605 0.569959 8.47108 0.694983L11.1377 3.36165C11.3981 3.622 11.3981 4.04411 11.1377 4.30446C10.8774 4.56481 10.4553 4.56481 10.1949 4.30446L8.66634 2.77586L8.66634 5.83333C8.66634 8.04247 6.87548 9.83333 4.66634 9.83333C3.19358 9.83333 1.99967 11.0272 1.99967 12.5L1.99967 15.8333C1.99967 16.2015 1.7012 16.5 1.33301 16.5Z"
      fill="#EC407A"
    />
  </svg>
);
