import { Paths } from 'routes/paths';
import { TasksFiltersEnum } from 'pages/tasks/types/types';
import s from './link-cell.module.scss';

const getHref = (fieldKey: string, { production_workflow_id, root_production_workflow_id, id }: Record<string, any>) => {
  const hrefMap = {
    [TasksFiltersEnum.ProductionKey]: `${Paths.ProductionWorkflow}/${production_workflow_id}`,
    [TasksFiltersEnum.RootProductionKey]: `${Paths.ProductionWorkflow}/${root_production_workflow_id}`,
  };

  return hrefMap[fieldKey] || `${Paths.Task}/${id}`;
};

const LinkCell = ({ fieldKey, data, value }) => (
  <a href={getHref(fieldKey, data)} className={s.link} target="_blank" rel="noreferrer">
    {value}
  </a>
);

export default LinkCell;
