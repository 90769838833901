import { Edge, Node } from 'reactflow';
import { ProductionWorkflowResponsibleT, ProductionWorkflowTaskResponsibility } from 'services/production-workflow.model';
import { StateController } from 'state-controller';
import { ProductionWorkflowService } from 'services/production-workflow.service';
import { ProductionStatusEnum, TaskStatusEnum } from 'types/status-enums';
import { AppState } from 'redux/store';
import { ProductionTaskService } from 'services/production-task.service';
import { Actions as ProductionWorkflowActions } from 'pages/production-workflow/controllers/production-workflow.controller';
import { FailReasonsModel, ReopenActionType, TaskReopenModel } from 'services/production-task.model';
import { ModalActions } from 'modules/root-modals/root-modals.controller';
import { notify } from 'notifications';
import { DeleteConfirmationOwnProps } from 'modules/root-modals/modals/confirmation-modal/confirmation-modal';
import { MODALS } from 'modules/root-modals/modals';
import FireMinusIcon from 'icons/fire-minus';
import { IdName } from 'types/common-types';
import ConfirmationWindow from 'modules/manage-failed-tasks-modal/components/confirmation-window';
import FailedInformationModal from 'modules/manage-failed-tasks-modal/components/failed-information-modal/failed-information-modal';

export type PageT = 'production' | 'task';

export type TaskData = {
  id: string;
  page: PageT;
  name: string;
  isFail: boolean;
  disable: boolean;
  isReopened: boolean;
  is_in_queue: boolean;
  isWithMoney: boolean;
  task_key: string;
  status: TaskStatusEnum;
  taskDepartmets: IdName[];
  responsibilities: ProductionWorkflowTaskResponsibility[];
};

export type NestedWorkflowData = {
  id: string;
  page: PageT;
  name: string;
  version: number;
  workflow: string;
  progress: number;
  isForFail: boolean;
  configuration: string;
  status: ProductionStatusEnum;
  responsible: ProductionWorkflowResponsibleT;
};

export type Nodes = Array<Node<TaskData, 'task'> | Node<NestedWorkflowData, 'workflow'>>;

export const MAX_REASONS_CHARS_COUNT = 250;

export type ManageFailedTasksState = {
  nodes: Nodes;
  edges: Edge[];
  isLoading: boolean;
  productionId: string;
  isModalOpen: boolean;
  isReopeningTasks: boolean;
  reasonForReopening: string;
  reasonOptions: FailReasonsModel[];
  selectedReasonOptions: FailReasonsModel[];
};

const defaultState: ManageFailedTasksState = {
  nodes: [],
  edges: [],
  isLoading: false,
  productionId: '',
  isModalOpen: false,
  reasonForReopening: '',
  reasonOptions: [],
  selectedReasonOptions: [],
  isReopeningTasks: false,
};

const stateController = new StateController<ManageFailedTasksState>('MANAGE_FAILED_TASKS_STATE', defaultState);

export class Actions {
  public static openManageFailedTasksModal(productionId: string, page: PageT) {
    return (dispatch) => {
      dispatch(stateController.setState({ isModalOpen: true }));
      dispatch(Actions.initData(productionId, page));
    };
  }

  public static closeManageFailedTasksModal() {
    return (dispatch) => {
      dispatch(stateController.setState(defaultState));
    };
  }

  public static initData(id: string, page: PageT) {
    return async (dispatch) => {
      try {
        dispatch(stateController.setState((prevState) => ({ ...prevState, isReopeningTasks: true, isLoading: true })));

        const data = await ProductionWorkflowService.getProductionWorkflowInfo(id);
        const reasonOptions = await ProductionTaskService.getAllFailReasons();

        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            productionId: id,
            reasonOptions,
            edges: data.edges.map((edge) => ({
              id: edge.id,
              source: edge.source_id,
              target: edge.target_id,
              type: 'smart',
            })),
            nodes: data.productionWorkflowItems.flatMap((item): Nodes => {
              if (item.task) {
                const task: Node<TaskData, 'task'> = {
                  id: item.id,
                  type: 'task',
                  position: { x: item.position_x, y: item.position_y },
                  data: {
                    page,
                    isFail: false,
                    id: item.task.id,
                    isReopened: false,
                    isWithMoney: false,
                    name: item.task.name,
                    task_key: item.task.task_key,
                    status: item.task.status,
                    is_in_queue: item.task.is_in_queue,
                    taskDepartmets: item.task.departmets_all,
                    disable: !!item.task.reopened_tasks?.length,
                    responsibilities: item.task.responsibilities,
                  },
                };

                const lastReopenedTask = item.task.reopened_tasks?.[item.task.reopened_tasks.length - 1];

                const reopenedTask: Node<TaskData, 'task'> | undefined = lastReopenedTask?.id
                  ? {
                      id: lastReopenedTask.id,
                      type: 'task',
                      position: { x: item.position_x + 37, y: item.position_y + 7 },
                      data: {
                        page,
                        isFail: false,
                        disable: false,
                        isReopened: true,
                        isWithMoney: false,
                        id: lastReopenedTask.id,
                        name: lastReopenedTask.name,
                        status: lastReopenedTask.status,
                        task_key: lastReopenedTask.task_key,
                        is_in_queue: lastReopenedTask.is_in_queue,
                        taskDepartmets: item.task.departmets_all,
                        responsibilities: lastReopenedTask.responsibilities,
                      },
                    }
                  : undefined;

                return [task, reopenedTask].filter((reopenTask) => !!reopenTask) as Nodes;
              }

              if (item.nestedProductionWorkflow) {
                const nestedWorkflow: Node<NestedWorkflowData, 'workflow'> = {
                  id: item.id,
                  type: 'workflow',
                  position: { x: item.position_x, y: item.position_y },
                  data: {
                    page,
                    isForFail: true,
                    id: item.nestedProductionWorkflow.id,
                    name: item.nestedProductionWorkflow.title,
                    status: item.nestedProductionWorkflow.status,
                    version: item.nestedProductionWorkflow.version,
                    progress: item.nestedProductionWorkflow.progress,
                    workflow: item.nestedProductionWorkflow.workflow,
                    responsible: item.nestedProductionWorkflow.responsible,
                    configuration: item.nestedProductionWorkflow.configuration,
                  },
                };

                return [nestedWorkflow];
              }

              return [];
            }),
          })),
        );
      } finally {
        dispatch(stateController.setState((prevState) => ({ ...prevState, isReopeningTasks: false, isLoading: false })));
      }
    };
  }

  public static handleFailTask(taskId: string, values: Partial<TaskData>) {
    return (dispatch) => {
      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          nodes: prev.nodes.map((node) => {
            return node.type === 'task' && node.data.id === taskId ? { ...node, data: { ...node.data, ...values } } : node;
          }),
        })),
      );
    };
  }

  public static openApplyChangesConfirmationModal() {
    return (dispatch, getState: () => AppState) => {
      const { nodes } = getState().manage_failed_tasks;
      const tasksToReopen: TaskReopenModel = [];
      const tasksToReopenWithPayment: TaskReopenModel = [];

      nodes.forEach((node) => {
        if (node.type !== 'task' || !node.data.isFail) return;

        const taskData = {
          id: node.data.id,
          taskKey: node.data.task_key,
          name: node.data.name,
        };

        if (!node.data.isWithMoney) {
          tasksToReopen.push(taskData);
        }

        if (node.data.isWithMoney) {
          tasksToReopenWithPayment.push(taskData);
        }
      });
      dispatch(
        ModalActions.openModal<DeleteConfirmationOwnProps>({
          id: MODALS.CONFIRM,
          props: {
            title: <>Apply failed changes?</>,
            text: (
              <ConfirmationWindow
                tasksToReopen={tasksToReopen}
                tasksToReopenWithPayment={tasksToReopenWithPayment}
                onReasonChange={(value) => dispatch(Actions.onReasonChange(value))}
                onReasonParametersChange={(value) => dispatch(Actions.onReasonParametersChange(value.id))}
              />
            ),
            icon: <FireMinusIcon />,
            withCloseButton: false,
            actionText: 'Apply',
            action: () => dispatch(Actions.handleApply()),
          },
        }),
      );
    };
  }

  public static onReasonChange(newReason: string) {
    return (dispatch) => {
      dispatch(
        stateController.setState((prevState) => ({
          ...prevState,
          reasonForReopening: newReason,
        })),
      );
    };
  }

  public static onReasonParametersChange(newId: string) {
    return (dispatch, getState: () => AppState) => {
      const { reasonOptions, selectedReasonOptions } = getState().manage_failed_tasks;

      const selectedReasonOption = reasonOptions.find((option) => option.id === newId);

      const alreadySelected = selectedReasonOptions.some((option) => option.id === newId);

      const updatedSelectedOptions = alreadySelected
        ? selectedReasonOptions.filter((option) => option.id !== newId)
        : [...selectedReasonOptions, selectedReasonOption];

      dispatch(
        stateController.setState((prevState) => ({
          ...prevState,
          selectedReasonOptions: updatedSelectedOptions,
        })),
      );
    };
  }

  public static openManageFailedTasksModalInfo() {
    return (dispatch, getState: () => AppState) => {
      const { nodes } = getState().manage_failed_tasks;
      const tasksToReopen: TaskReopenModel = [];
      const tasksToReopenWithPayment: TaskReopenModel = [];

      nodes.forEach((node) => {
        if (node.type !== 'task' || !node.data.isFail) return;

        const taskData = {
          id: node.data.id,
          taskKey: node.data.task_key,
          name: node.data.name,
        };

        if (!node.data.isWithMoney) {
          tasksToReopen.push(taskData);
        }

        if (node.data.isWithMoney) {
          tasksToReopenWithPayment.push(taskData);
        }
      });

      dispatch(
        ModalActions.openModal<DeleteConfirmationOwnProps>({
          id: MODALS.CONFIRM,
          props: {
            title: <>Reason for failure</>,
            text: <FailedInformationModal />,
            icon: <FireMinusIcon />,
            withCloseButton: false,
            cancelButton: true,
            cancelButtonText: 'Close',
          },
        }),
      );
    };
  }

  public static handleApply() {
    return async (dispatch, getState: () => AppState) => {
      const { productionId, nodes, reasonForReopening, selectedReasonOptions } = getState().manage_failed_tasks;

      const tasksForReopen = nodes
        .filter((node) => node.type === 'task' && node.data.isFail)
        .map((node) => ({
          id: node.data.id,
          reopen_action_type:
            node.type === 'task' && node.data.isFail && node.data.isWithMoney
              ? ReopenActionType.ReopenWithNewPayment
              : ReopenActionType.Reopen,
        }));

      const reasonIds = selectedReasonOptions.map((reason) => reason.id);

      try {
        await ProductionTaskService.manageFailedTask({
          fail_reason_ids: reasonIds,
          comment: reasonForReopening,
          tasks: tasksForReopen,
        });
        await dispatch(ProductionWorkflowActions.silentLoad({ id: productionId, disableAdditionalTasksSet: true }));

        dispatch(Actions.closeManageFailedTasksModal());
      } catch (error) {
        notify.error(error);
      }
    };
  }
}

export class Selectors {}

export const reducer = stateController.getReducer();
