import Button from 'components/button/button';
import ModalBody from 'components/modal-body/modal-body';
import ModalFooter from 'components/modal-footer/modal-footer';
import ModalHeader from 'components/modal-header/modal-header';
import Subtitle from 'components/subtitle/subtitle';
import Modal from 'components/ui-new/modal/modal';
import { FilledPlusIcon } from 'icons/filled-plus';
import SvgWrapper from 'icons/modal-svg-wrapper/svg-wrapper';
import { Actions as AddUserActions, AddUserFlowState } from 'modules/add-user-flow/add-user-flow.controller';
import { connect } from 'react-redux';
import { AppState } from 'redux/store';
import { FC } from 'react';
import { EditPencilColorIcon } from 'icons/edit-pencil-color';
import { DEFAULT_ALLOWED_MAX_LENGTH, EMAIL_ALLOWED_MAX_LENGTH, PHONE_ALLOWED_MAX_LENGTH } from 'modules/add-user-flow/constants';
import { renderTooltipTitle } from 'modules/add-user-flow/helpers';
import { UserData } from 'modules/add-user-flow/types';
import s from './add-user-flow.module.scss';
import InputModalItem from './components/input-modal-item/input-modal-item';

type StateProps = {
  user: UserData;
  isModalOpen: boolean;
  validation: UserData;
  isProcessing: boolean;
  editUserData: UserData;
  mode: AddUserFlowState['mode'];
};

type DispatchProps = {
  addUser: () => void;
  editUser: () => void;
  closeModal: () => void;
  onInputValueChange: (values: Partial<UserData>) => void;
};

type Props = StateProps & DispatchProps;

const AddUserFlow: FC<Props> = (props) => {
  const { isModalOpen, isProcessing, mode, user, validation, editUserData, onInputValueChange, closeModal, addUser, editUser } =
    props;

  return (
    <Modal open={isModalOpen} onClose={closeModal} PaperProps={{ className: s.modal }} disableScrollLock>
      <ModalHeader onClose={closeModal} witohutShadow size="newMedium" withCloseButton className="">
        <Subtitle
          className={s.subtitle}
          text={mode === 'add' ? 'Add user' : 'Edit'}
          icon={
            <SvgWrapper
              containerSize={38}
              iconSize={20}
              backgroundColor="#DBE9FF"
              SvgIcon={mode === 'add' ? FilledPlusIcon : EditPencilColorIcon}
              color="#000"
            />
          }
        />
      </ModalHeader>
      <ModalBody size="newMedium" className="">
        <InputModalItem
          title="First name *"
          value={user.firstName}
          inputProps={{ maxLength: DEFAULT_ALLOWED_MAX_LENGTH + 1 }}
          placeholder="Enter first name"
          tooltipProps={{
            title: renderTooltipTitle(DEFAULT_ALLOWED_MAX_LENGTH),
            placement: 'top',
            open: user.firstName?.length > DEFAULT_ALLOWED_MAX_LENGTH,
          }}
          errorMessage={validation.firstName}
          onChange={(value) => onInputValueChange({ firstName: value })}
        />
        <InputModalItem
          title="Last name *"
          value={user.lastName}
          inputProps={{ maxLength: DEFAULT_ALLOWED_MAX_LENGTH + 1 }}
          placeholder="Enter last name"
          tooltipProps={{
            title: renderTooltipTitle(DEFAULT_ALLOWED_MAX_LENGTH),
            placement: 'top',
            open: user.lastName?.length > DEFAULT_ALLOWED_MAX_LENGTH,
          }}
          errorMessage={validation.lastName}
          onChange={(value) => onInputValueChange({ lastName: value })}
        />
        <InputModalItem
          title="Email *"
          value={user.email}
          inputProps={{ maxLength: EMAIL_ALLOWED_MAX_LENGTH + 1 }}
          placeholder="Enter email"
          tooltipProps={{
            title: renderTooltipTitle(EMAIL_ALLOWED_MAX_LENGTH),
            placement: 'top',
            open: user.email?.length > EMAIL_ALLOWED_MAX_LENGTH,
          }}
          errorMessage={validation.email}
          disabled={mode !== 'add'}
          onChange={(value) => {
            if (mode === 'add') onInputValueChange({ email: value });
          }}
        />
        <InputModalItem
          title="Phone"
          value={user.phone}
          inputProps={{ maxLength: PHONE_ALLOWED_MAX_LENGTH + 1 }}
          placeholder="Enter phone number"
          tooltipProps={{
            title: renderTooltipTitle(PHONE_ALLOWED_MAX_LENGTH),
            placement: 'top',
            open: (user.phone?.length || 0) > PHONE_ALLOWED_MAX_LENGTH,
          }}
          errorMessage={validation.phone}
          onChange={(value) => onInputValueChange({ phone: value })}
        />
        <InputModalItem
          title="External user id"
          value={user.externalUserId}
          inputProps={{ maxLength: DEFAULT_ALLOWED_MAX_LENGTH + 1 }}
          placeholder="Enter external user id"
          tooltipProps={{
            title: renderTooltipTitle(DEFAULT_ALLOWED_MAX_LENGTH),
            placement: 'top',
            open: (user.externalUserId?.length || 0) > DEFAULT_ALLOWED_MAX_LENGTH,
          }}
          errorMessage={validation.externalUserId}
          onChange={(value) => onInputValueChange({ externalUserId: value })}
        />

        <p className={s.info}>The user will receive an invitation letter to the specified e-mail</p>
      </ModalBody>
      <ModalFooter size="newMedium" className={s.footer}>
        <Button
          fullWidth
          variant="contained"
          color="primary"
          size="XL"
          disabled={!user.email || !user.firstName || !user.lastName || JSON.stringify(user) === JSON.stringify(editUserData)}
          loading={isProcessing}
          onClick={() => {
            if (mode === 'add') {
              addUser();
            } else {
              editUser();
            }
          }}
        >
          {mode === 'add' ? 'Add' : 'Edit'}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

const mapStateToProps = (state: AppState): StateProps => ({
  user: state.addUserFlow.user,
  mode: state.addUserFlow.mode,
  validation: state.addUserFlow.validation,
  isModalOpen: state.addUserFlow.isModalOpen,
  isProcessing: state.addUserFlow.isProcessing,
  editUserData: state.addUserFlow.editUserData,
});

const mapDispatchToProps: DispatchProps = {
  addUser: AddUserActions.addUser,
  editUser: AddUserActions.editUser,
  closeModal: AddUserActions.closeUserFlowModal,
  onInputValueChange: AddUserActions.onInputValueChange,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddUserFlow);
