import { StateController } from 'state-controller';

export type ProductionsLaunchingProgressStateType = {
  isShow: boolean;
  launchingProductionsCount: number;
};

const defaultState: ProductionsLaunchingProgressStateType = {
  isShow: false,
  launchingProductionsCount: 0,
};

const stateController = new StateController<ProductionsLaunchingProgressStateType>('PRODUCTION_LAUNCHING_PROGRESS', defaultState);

export class ProductionsLaunchingProgressActions {
  static showModalAndSetLaunchingProductionsCount(launchingProductionsCount: number) {
    return (dispatch) => {
      dispatch(stateController.setState({ isShow: true, launchingProductionsCount }));
    };
  }

  static setProductionsLaunchingCount = (value: number) => {
    return (dispatch) => {
      dispatch(stateController.setState({ launchingProductionsCount: value }));
    };
  };

  static hideModal() {
    return (dispatch) => {
      dispatch(stateController.setState(defaultState));
    };
  }
}

export const ProductionsLaunchingProgressReducer = stateController.getReducer();
