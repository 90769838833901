import React from 'react';
// eslint-disable-next-line no-restricted-imports
import { LoadingButton } from '@mui/lab';

type Props = React.ComponentPropsWithRef<typeof LoadingButton> & {
  shape?: 'round' | 'square'; // styles for shape is defined in use-mui-theme.tsx
  iconStyle?: 'fill_icon' | 'stroke_icon' | 'custom_icon'; // custom_icon = absolute custom styles for icon (button component should not apply any styles to it)
};

const Button = React.forwardRef<HTMLButtonElement, Props>((props, ref) => {
  const { shape, children, iconStyle = 'stroke_icon', className = '', ...rest } = props;

  return (
    <LoadingButton ref={ref} className={`${shape} ${className} ${iconStyle}`} {...rest}>
      <div style={{ display: 'flex', alignItems: 'center' }}>{children}</div>
    </LoadingButton>
  );
});

Button.displayName = 'Button';

export default Button;
