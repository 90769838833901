import { FC, SVGProps } from 'react';

export const ArrowsIcon: FC<SVGProps<SVGSVGElement>> = ({ ...props }) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M7.50755 17.0832L3.32422 12.9082"
      stroke="#7B78FF"
      strokeWidth="1.1"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.50781 2.91699V17.0837"
      stroke="#7B78FF"
      strokeWidth="1.1"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.4922 2.91699L16.6755 7.09199"
      stroke="#7B78FF"
      strokeWidth="1.1"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.4922 17.0837V2.91699"
      stroke="#7B78FF"
      strokeWidth="1.1"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
