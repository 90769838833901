import { FC, SVGProps } from 'react';

export const PluginIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none" {...props}>
    <path
      d="M13.334 2.5H5.33398C4.5976 2.5 4.00065 3.09695 4.00065 3.83333V5.83333C4.00065 6.20152 3.70217 6.5 3.33398 6.5C2.22941 6.5 1.33398 7.39543 1.33398 8.5C1.33398 9.60457 2.22941 10.5 3.33398 10.5C3.70217 10.5 4.00065 10.7985 4.00065 11.1667V13.1667C4.00065 13.903 4.5976 14.5 5.33398 14.5H6.66732C7.03551 14.5 7.33398 14.2015 7.33398 13.8333C7.33398 12.7288 8.22941 11.8333 9.33398 11.8333C10.4386 11.8333 11.334 12.7288 11.334 13.8333C11.334 14.2015 11.6325 14.5 12.0007 14.5H13.334C14.0704 14.5 14.6673 13.903 14.6673 13.1667V11.1667C14.6673 10.7985 14.3688 10.5 14.0007 10.5C12.8961 10.5 12.0007 9.60457 12.0007 8.5C12.0007 7.39543 12.8961 6.5 14.0007 6.5C14.3688 6.5 14.6673 6.20152 14.6673 5.83333V3.83333C14.6673 3.09695 14.0704 2.5 13.334 2.5Z"
      stroke="#5E7285"
      strokeLinejoin="round"
    />
  </svg>
);
