import { FC, SVGProps } from 'react';

export const FilterIcon: FC<SVGProps<SVGSVGElement>> = ({ ...props }) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M3.39535 1.29391H12.6047C13.3721 1.29391 14 1.92182 14 2.68926V4.22415C14 4.78229 13.6512 5.47996 13.3023 5.8288L10.3023 8.47996C9.88372 8.8288 9.60465 9.52647 9.60465 10.0846V13.0846C9.60465 13.5032 9.32558 14.0614 8.97674 14.2707L8 14.8986C7.09302 15.4567 5.83721 14.8288 5.83721 13.7125V10.0148C5.83721 9.52647 5.55814 8.89857 5.27907 8.54973L2.62791 5.75903C2.27907 5.41019 2 4.78229 2 4.36368V2.75903C2 1.92182 2.62791 1.29391 3.39535 1.29391Z"
      stroke="#141414"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
