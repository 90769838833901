import { FC, SVGProps } from 'react';

export const MagicPenIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
    <path
      d="M2.91719 17.0835C3.60885 17.7752 4.72552 17.7752 5.41719 17.0835L16.2505 6.2502C16.9422 5.55853 16.9422 4.44186 16.2505 3.7502C15.5589 3.05853 14.4422 3.05853 13.7505 3.7502L2.91719 14.5835C2.22552 15.2752 2.22552 16.3919 2.91719 17.0835Z"
      stroke={props.stroke || '#239868'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M15.0078 7.4917L12.5078 4.9917" stroke={props.stroke || '#239868'} strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M7.08203 2.03317L8.33203 1.6665L7.96536 2.9165L8.33203 4.1665L7.08203 3.79984L5.83203 4.1665L6.1987 2.9165L5.83203 1.6665L7.08203 2.03317Z"
      stroke={props.stroke || '#239868'}
      strokeWidth="0.7"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.75 7.03317L5 6.6665L4.63333 7.9165L5 9.1665L3.75 8.79984L2.5 9.1665L2.86667 7.9165L2.5 6.6665L3.75 7.03317Z"
      stroke={props.stroke || '#239868'}
      strokeWidth="0.7"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.25 11.2002L17.5 10.8335L17.1333 12.0835L17.5 13.3335L16.25 12.9668L15 13.3335L15.3667 12.0835L15 10.8335L16.25 11.2002Z"
      stroke={props.stroke || '#239868'}
      strokeWidth="0.7"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
