import { FC, SVGProps } from 'react';

export const SortArrowUpIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M8 3.5C7.72386 3.5 7.5 3.72386 7.5 4C7.5 4.27614 7.72386 4.5 8 4.5V3.5ZM14 4.5C14.2761 4.5 14.5 4.27614 14.5 4C14.5 3.72386 14.2761 3.5 14 3.5V4.5ZM8 7.5C7.72386 7.5 7.5 7.72386 7.5 8C7.5 8.27614 7.72386 8.5 8 8.5V7.5ZM12 8.5C12.2761 8.5 12.5 8.27614 12.5 8C12.5 7.72386 12.2761 7.5 12 7.5V8.5ZM8 11.5C7.72386 11.5 7.5 11.7239 7.5 12C7.5 12.2761 7.72386 12.5 8 12.5V11.5ZM10 12.5C10.2761 12.5 10.5 12.2761 10.5 12C10.5 11.7239 10.2761 11.5 10 11.5V12.5ZM1.6263 5.25115C1.44284 5.45754 1.46143 5.77358 1.66782 5.95704C1.87421 6.1405 2.19025 6.12191 2.3737 5.91552L1.6263 5.25115ZM3.00345 4.45445L2.62975 4.12227L2.62975 4.12227L3.00345 4.45445ZM4.99655 4.45445L5.37025 4.12227V4.12227L4.99655 4.45445ZM5.6263 5.91552C5.80975 6.12191 6.12579 6.1405 6.33218 5.95704C6.53857 5.77358 6.55716 5.45754 6.3737 5.25115L5.6263 5.91552ZM4.5 4C4.5 3.72386 4.27614 3.5 4 3.5C3.72386 3.5 3.5 3.72386 3.5 4H4.5ZM3.5 12C3.5 12.2761 3.72386 12.5 4 12.5C4.27614 12.5 4.5 12.2761 4.5 12H3.5ZM8 4.5H14V3.5H8V4.5ZM8 8.5H12V7.5H8V8.5ZM8 12.5H10V11.5H8V12.5ZM2.3737 5.91552L3.37716 4.78663L2.62975 4.12227L1.6263 5.25115L2.3737 5.91552ZM4.62284 4.78663L5.6263 5.91552L6.3737 5.25115L5.37025 4.12227L4.62284 4.78663ZM3.37716 4.78663C3.70865 4.4137 4.29135 4.4137 4.62284 4.78663L5.37025 4.12227C4.64097 3.30182 3.35903 3.30182 2.62975 4.12227L3.37716 4.78663ZM3.5 4V12H4.5V4H3.5Z"
      fill="#28303F"
    />
  </svg>
);
