import { useEffect, useState } from 'react';
import { useDebounceEffect } from 'ahooks';

export default function useDebounceSearch(search: string, onSearchChange: (search: string) => void, debounce: number = 1000) {
  const [searchState, setSearch] = useState(search);
  useEffect(() => {
    setSearch(search);
  }, [search]);

  useDebounceEffect(
    () => {
      if (search !== searchState) {
        onSearchChange(searchState);
      }
    },
    [searchState],
    { wait: debounce },
  );

  return {
    searchState,
    setSearch,
  };
}
