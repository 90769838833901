import { FC, SVGProps } from 'react';

const ErrorIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none" {...props}>
    <circle cx="8.49935" cy="11.8333" r="0.666667" transform="rotate(-180 8.49935 11.8333)" fill="#FF6464" />
    <path
      d="M8.50065 9.8335L8.50065 5.16683M15.1673 8.50016C15.1673 12.1821 12.1825 15.1668 8.50065 15.1668C4.81875 15.1668 1.83398 12.1821 1.83398 8.50016C1.83398 4.81826 4.81875 1.8335 8.50065 1.8335C12.1825 1.8335 15.1673 4.81826 15.1673 8.50016Z"
      stroke="#FF6464"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ErrorIcon;
