import { Paths } from 'routes/paths';
import { connect } from 'react-redux';
import { Button } from '@mui/material';
import { AppState } from 'redux/store';
import { rules } from 'utils/validator';
import Input from 'pages/auth/components/input/input';
import Success from 'pages/auth/components/success/success';
import { Actions as SignInActions, SignInState } from 'pages/auth/auth.controller';
import shared_s from '../../shared-styles.module.scss';
import s from './forgot-password.module.scss';

type StateProps = {
  resetPasswordEmail: string;
  isSentResetPasswordLink: boolean;
};
type DispatchProps = {
  sendEmailWithLink: VoidFunction;
  onChange: (values: Partial<SignInState>) => void;
  clearForgotPasswordState: () => void;
};
type Props = StateProps & DispatchProps;

const ForgotPassword = ({
  resetPasswordEmail,
  isSentResetPasswordLink,
  onChange,
  sendEmailWithLink,
  clearForgotPasswordState,
}: Props) => {
  if (isSentResetPasswordLink) {
    return (
      <Success
        isShowButton
        path={Paths.SignIn}
        header="Password link has been sent"
        onClickCallback={clearForgotPasswordState}
        description={
          <>
            Please check your email <span className={s.email}>{resetPasswordEmail}</span>
          </>
        }
      />
    );
  }

  return (
    <form className={shared_s.form} onSubmit={(e) => e.preventDefault()}>
      <div className={shared_s.intro}>
        <h3>Forgot password</h3>
        <p>Enter your email address and we&apos;ll send you a password reset link</p>
      </div>
      <div className={shared_s.fields}>
        <Input
          type="email"
          value={resetPasswordEmail}
          onChange={(value) => onChange({ resetPasswordEmail: value })}
          placeHolder="Enter email"
          label="Email"
        />
      </div>
      <Button type="submit" disabled={!rules.isEmail(resetPasswordEmail)} className={shared_s.button} onClick={sendEmailWithLink}>
        Send reset link
      </Button>
    </form>
  );
};

const mapStateToProps = (state: AppState): StateProps => ({
  resetPasswordEmail: state.authentication.resetPasswordEmail,
  isSentResetPasswordLink: state.authentication.isSentResetPasswordLink,
});
const mapDispatchToProps: DispatchProps = {
  onChange: SignInActions.onChange,
  sendEmailWithLink: SignInActions.sendEmailWithLink,
  clearForgotPasswordState: SignInActions.clearForgotPasswordState,
};
export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
