import { FC, SVGProps } from 'react';

export const Hierarchy2: FC<SVGProps<SVGSVGElement>> = ({ ...props }) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M5.99984 3.33325L10.6665 3.33325" stroke="#292D32" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M1.33317 3.49992C1.33317 4.69654 2.30322 5.66659 3.49984 5.66659C4.69645 5.66659 5.6665 4.69654 5.6665 3.49992C5.6665 2.3033 4.69645 1.33325 3.49984 1.33325C2.30322 1.33325 1.33317 2.3033 1.33317 3.49992Z"
      stroke="#292D32"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.9998 3.33333C10.9998 4.06971 11.5968 4.66667 12.3332 4.66667C13.0696 4.66667 13.6665 4.06971 13.6665 3.33333C13.6665 2.59695 13.0696 2 12.3332 2C11.5968 2 10.9998 2.59695 10.9998 3.33333Z"
      stroke="#292D32"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.9998 12.0001C10.9998 12.7365 11.5968 13.3334 12.3332 13.3334C13.0696 13.3334 13.6665 12.7365 13.6665 12.0001C13.6665 11.2637 13.0696 10.6667 12.3332 10.6667C11.5968 10.6667 10.9998 11.2637 10.9998 12.0001Z"
      stroke="#292D32"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.00002 3.42065C7.16669 3.72065 8.03336 4.78732 8.02669 6.04732L8.02002 8.33399C8.01335 10.0807 9.13336 11.5673 10.6934 12.114"
      stroke="#292D32"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
