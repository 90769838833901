type QueryParamsArgs = Record<string, string | number | boolean | undefined | null>;

export const buildQueryString = (params: QueryParamsArgs): string => {
  const queryParams = new URLSearchParams();

  for (const [key, value] of Object.entries(params)) {
    if (value !== undefined && value !== null && value !== '') {
      queryParams.append(key, String(value));
    }
  }

  return queryParams.toString();
};
