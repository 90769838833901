import { Typography } from '@mui/material';
import s from './label.module.scss';

export type Props = {
  children: React.ReactNode;
  className?: string;
  labelStyle?: string;
  [key: string]: any;
};

export default function Label({ children, className, labelStyle, ...rest }: Props) {
  return (
    <div className={`${s.label} ${className}`} {...rest}>
      <Typography className={labelStyle} color="text.primary" fontWeight={500} variant="body1">
        {children}
      </Typography>
    </div>
  );
}
