import { FC, SVGProps } from 'react';

export const DuplicateIcon: FC<SVGProps<SVGSVGElement>> = ({ ...props }) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M5.33301 5.33333V3.99999C5.33301 2.52724 6.52692 1.33333 7.99967 1.33333L11.9997 1.33333C13.4724 1.33333 14.6663 2.52724 14.6663 3.99999V8C14.6663 9.47275 13.4724 10.6667 11.9997 10.6667H10.6663M5.33301 5.33333H3.99967C2.52692 5.33333 1.33301 6.52724 1.33301 8V12C1.33301 13.4728 2.52692 14.6667 3.99967 14.6667H7.99967C9.47243 14.6667 10.6663 13.4728 10.6663 12V10.6667M5.33301 5.33333H7.99967C9.47243 5.33333 10.6663 6.52724 10.6663 8V10.6667"
      stroke="#878B92"
      strokeWidth="1.2"
      strokeLinejoin="round"
    />
    <path d="M5.99902 7V13" stroke="#878B92" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M3 9.9812H9" stroke="#878B92" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);
