import { Stack, Tooltip } from '@mui/material';
import { WarningIcon } from 'icons/warning';

const WarningCell = (params) => {
  if (!params.value) return null;

  return (
    <Stack width="100%" height="100%" alignItems="center" justifyContent="center">
      <Tooltip title={params.tooltipText} placement="top">
        <Stack padding="5px" border="1px solid #F39E03" borderRadius="8px">
          <WarningIcon width={20} height={20} />
        </Stack>
      </Tooltip>
    </Stack>
  );
};

export default WarningCell;
