import { FC, SVGProps } from 'react';

export const ComponentsIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M14.9998 5.564V2.386C14.9998 1.399 14.5518 1 13.4388 1H10.6108C9.4978 1 9.0498 1.399 9.0498 2.386V5.557C9.0498 6.551 9.4978 6.943 10.6108 6.943H13.4388C14.5518 6.95 14.9998 6.551 14.9998 5.564Z"
      stroke="#141414"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.9998 13.4393V10.6113C14.9998 9.49829 14.5518 9.05029 13.4388 9.05029H10.6108C9.4978 9.05029 9.0498 9.49829 9.0498 10.6113V13.4393C9.0498 14.5523 9.4978 15.0003 10.6108 15.0003H13.4388C14.5518 15.0003 14.9998 14.5523 14.9998 13.4393Z"
      stroke="#141414"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.95 5.564V2.386C6.95 1.399 6.502 1 5.389 1H2.561C1.448 1 1 1.399 1 2.386V5.557C1 6.551 1.448 6.943 2.561 6.943H5.389C6.502 6.95 6.95 6.551 6.95 5.564Z"
      stroke="#141414"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.95 13.4393V10.6113C6.95 9.49829 6.502 9.05029 5.389 9.05029H2.561C1.448 9.05029 1 9.49829 1 10.6113V13.4393C1 14.5523 1.448 15.0003 2.561 15.0003H5.389C6.502 15.0003 6.95 14.5523 6.95 13.4393Z"
      stroke="#141414"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
