import { baseAxiosInstance } from 'axios-config';
import { TimeTrackingResponseModel } from 'services/time-tracking.model';
import { TaskStatusEnum } from 'types/status-enums';

export class TaskTimeTrackingService {
  public static async setStatus(
    taskId: string,
    status: TaskStatusEnum.Done | TaskStatusEnum.In_Progress | TaskStatusEnum.On_Hold,
  ): Promise<TimeTrackingResponseModel> {
    const { data } = await baseAxiosInstance.put(`/tasks/${taskId}`, { status });

    return data;
  }
}
