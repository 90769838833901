import { FC } from 'react';
import { connect } from 'react-redux';
import { AppState } from 'redux/store';
import Button from 'components/button/button';
import Modal from 'components/ui-new/modal/modal';
import { Typography } from '@mui/material';
import { EditPencilIcon } from 'icons/edit-pencil';
import { Persone2Icon } from 'icons/persone-2';
import useDisableDocumentScroll from 'hooks/use-disable-document-scroll';
import { AvatarCircle } from 'components/ui-new/avatar-circle/avatar-circle';
import { FilledPlusIcon } from 'icons/filled-plus';
import ModalItem from 'components/modal-item/modal-item';
import ModalSvgWrapper from 'icons/modal-svg-wrapper/svg-wrapper';
import {
  Actions as AddPositionModalActions,
  Selectors as AddPositionModalSelectors,
  EmployeeInputItem,
  InputItem,
  Mode,
  Page,
} from 'components/add-position-modal/add-position-modal.controller';
import Subtitle from 'components/subtitle/subtitle';
import s from './add-position-modal.module.scss';

type OwnProps = {
  page: Page;
};
type StateProps = {
  mode: Mode;
  isLoading: boolean;
  jobTitle: InputItem;
  isModalOpen: boolean;
  isProcessing: boolean;
  positionTitle: InputItem;
  employee: EmployeeInputItem;
  isConfirmButtonDisabled: boolean;
};
type DispatchProps = {
  closeModal: () => void;
  initEmployee: () => void;
  onConfirmClick: (page: Page) => void;
  onChangeJobTitle: (value: any) => void;
  onChangeEmployee: (value: any) => void;
  onChangePositionTitle: (value: any) => void;
  loadMoreOrSearchEmployee: (value?: string, isScroll?: boolean) => void;
};
type Props = OwnProps & StateProps & DispatchProps;

const AddPositionModal: FC<Props> = ({
  mode,
  page,
  jobTitle,
  employee,
  isLoading,
  isModalOpen,
  isProcessing,
  positionTitle,
  isConfirmButtonDisabled,
  closeModal,
  initEmployee,
  onConfirmClick,
  onChangeJobTitle,
  onChangeEmployee,
  onChangePositionTitle,
  loadMoreOrSearchEmployee,
}) => {
  useDisableDocumentScroll(isModalOpen, 'addPositionModal');

  const confirmButtonText = mode === Mode.Add ? 'Save' : 'Edit';
  const subtitleText = mode === Mode.Add ? 'Add position' : 'Edit position';
  const headerIcon = mode === Mode.Add ? <FilledPlusIcon /> : <EditPencilIcon />;
  const personeIcon = employee.value.avatar ? (
    <AvatarCircle
      isEmpty={false}
      className={s.avatar}
      imageSrc={employee.value.avatar || ''}
      lastName={employee.value.lastName || ''}
      firstName={employee.value.firstName || ''}
    />
  ) : (
    <div className={s.persone_icon}>
      <Persone2Icon />
    </div>
  );

  useDisableDocumentScroll(isModalOpen, 'addPositionModal');

  return (
    <Modal open={isModalOpen} onClose={closeModal} PaperProps={{ className: s.modal }}>
      <div className={s.modal_header}>
        <Subtitle
          text={subtitleText}
          fontSize="large"
          className={s.subtitle}
          icon={<ModalSvgWrapper containerSize={38} iconSize={20} backgroundColor="#DBE9FF" icon={headerIcon} />}
        />
        <Typography className={s.block_title}>You can add a position and one employee.</Typography>
      </div>

      <div className={s.body}>
        <ModalItem
          loading={isLoading}
          optionClassName={s.text_input_option}
          disabled={jobTitle.isDisabled}
          value={jobTitle.value}
          options={jobTitle.options}
          key="main-job-title"
          label="Main job title"
          placeholder="Choose main job title"
          onAutocompleteChange={(e, newValue: string) => onChangeJobTitle(newValue)}
          onTextFieldChange={(event) => onChangeJobTitle({ id: 'new', name: event.target.value })}
        />
        <ModalItem
          loading={isLoading}
          optionClassName={s.text_input_option}
          disabled={positionTitle.isDisabled}
          value={positionTitle.value}
          options={positionTitle.options}
          key="position-title"
          label="Detailed position title"
          placeholder="Choose detailed position title"
          onAutocompleteChange={(e, newValue: string) => onChangePositionTitle(newValue)}
          onTextFieldChange={(event) => onChangePositionTitle({ id: 'new', name: event.target.value })}
        />
        <ModalItem
          withAvatar
          optionClassName={s.users_input_option}
          loading={employee.isLoading}
          value={employee.value}
          options={employee.options}
          key="employee"
          label="Employee"
          placeholder="Employee not added"
          startAdornment={personeIcon}
          onAutocompleteChange={(e, newValue: string) => onChangeEmployee(newValue)}
          onTextFieldChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            loadMoreOrSearchEmployee(event.target.value, false);
          }}
          loadMoreOptions={loadMoreOrSearchEmployee}
          onInputOpen={initEmployee}
          withInfinityScroll
        />
      </div>

      <div className={s.footer}>
        <Button size="L" color="secondary" variant="contained" className={s.button} onClick={closeModal}>
          Cancel
        </Button>
        <Button
          size="L"
          color="primary"
          variant="contained"
          className={s.button}
          loading={isProcessing}
          disabled={isConfirmButtonDisabled}
          onClick={() => onConfirmClick(page)}
        >
          {confirmButtonText}
        </Button>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state: AppState): StateProps => ({
  mode: state.addPositionModal.mode,
  jobTitle: state.addPositionModal.jobTitle,
  employee: state.addPositionModal.employee,
  isLoading: state.addPositionModal.isLoading,
  isModalOpen: state.addPositionModal.isModalOpen,
  isProcessing: state.addPositionModal.isProcessing,
  positionTitle: state.addPositionModal.positionTitle,
  isConfirmButtonDisabled: AddPositionModalSelectors.isConfirmButtonDisabled(state),
});
const mapDispatchToProps: DispatchProps = {
  closeModal: AddPositionModalActions.closeModal,
  initEmployee: AddPositionModalActions.initEmployee,
  onConfirmClick: AddPositionModalActions.onConfirmClick,
  onChangeJobTitle: AddPositionModalActions.onChangeJobTitleValue,
  onChangeEmployee: AddPositionModalActions.onChangeEmployeeValue,
  onChangePositionTitle: AddPositionModalActions.onChangePositionTitleValue,
  loadMoreOrSearchEmployee: AddPositionModalActions.loadMoreOrSearchEmployee,
};
export default connect(mapStateToProps, mapDispatchToProps)(AddPositionModal);
