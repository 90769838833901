import { HandIcon } from 'icons/hand';
import { MagicPenIcon } from 'icons/magic-pen';
import { StarredUserIcon } from 'icons/starred-user';
import { AssignmentType } from 'services/workflow-task-template-responsibility.model';

export const PAGE_SIZE = 100;
export const INITIAL_ITEMS_LIMIT = 6;

export const TASK_ASSIGNMENT_TYPE_MAP = {
  [AssignmentType.Manual]: {
    text: 'Manual assignment',
    style: {
      color: '#3153D6',
      borderColor: '#3153D6',
    },
    IconComponent: HandIcon,
  },
  [AssignmentType.Auto]: {
    text: 'Automatic assignment',
    style: {
      color: '#239868',
      borderColor: '#239868',
    },
    IconComponent: MagicPenIcon,
  },
  [AssignmentType.Self_Assignment]: {
    text: 'Self assignment',
    style: {
      color: '#D5951B',
      borderColor: '#D5951B',
    },
    IconComponent: StarredUserIcon,
  },
};
