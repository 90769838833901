import { FC, MouseEvent } from 'react';
import { CheckIcon } from 'icons/check';
import { CrossIcon } from 'icons/cross';
import Button from 'components/button/button';
import s from './input-buttons.module.scss';

type OwnProps = {
  isLoading?: boolean;
  isConfirmDisabled?: boolean;
  onCancelClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  onConfirmClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  onCancelMouseDown?: (event: MouseEvent<HTMLButtonElement>) => void;
  onConfirmMouseDown?: (event: MouseEvent<HTMLButtonElement>) => void;
};

const InputButtons: FC<OwnProps> = ({
  isLoading,
  isConfirmDisabled,
  onCancelClick,
  onConfirmClick,
  onCancelMouseDown,
  onConfirmMouseDown,
}) => {
  return (
    <div className={s.container}>
      <Button
        size="XXS"
        color="white"
        shape="square"
        variant="contained"
        loading={isLoading}
        className={s.action_button}
        disabled={isConfirmDisabled}
        data-disabled={isConfirmDisabled}
        onClickCapture={(event) => {
          event.stopPropagation();
          if (!isConfirmDisabled && onConfirmClick) {
            onConfirmClick(event);
          }
        }}
        onMouseDownCapture={(event) => {
          event.stopPropagation();
          if (!isConfirmDisabled && onConfirmMouseDown) {
            onConfirmMouseDown(event);
          }
        }}
      >
        <CheckIcon />
      </Button>
      <Button
        size="XXS"
        color="white"
        shape="square"
        variant="contained"
        iconStyle="custom_icon"
        className={s.action_button}
        onClickCapture={(event) => {
          event.stopPropagation();
          if (onCancelClick) {
            onCancelClick(event);
          }
        }}
        onMouseDownCapture={(event) => {
          event.stopPropagation();
          if (onCancelMouseDown) {
            onCancelMouseDown(event);
          }
        }}
      >
        <CrossIcon />
      </Button>
    </div>
  );
};

export default InputButtons;
