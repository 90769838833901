import { baseAxiosInstance } from 'axios-config';
import { DayOff, DayOffPayload } from 'services/vacation.model';

export class VacationService {
  public static async getUserDayOff(userId: string): Promise<DayOff[]> {
    const { data } = await baseAxiosInstance.get(`/user-day-offs/get-all/${userId}`);
    return data;
  }

  public static async create(payload: DayOffPayload) {
    const { data } = await baseAxiosInstance.post(`/user-day-offs/create`, payload);
    return data;
  }

  public static async delete(id: string) {
    const { data } = await baseAxiosInstance.delete(`/user-day-offs/delete/${id}`);
    return data;
  }

  public static async edit(id: string, payload: DayOffPayload) {
    const { data } = await baseAxiosInstance.put(`/user-day-offs/update/${id}`, payload);
    return data;
  }
}
