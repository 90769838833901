import { FC, SVGProps } from 'react';

export const PlusIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M6 1V11"
      stroke={props.color || 'white'}
      strokeWidth={props.strokeWidth || '1.2'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1 5.96875H11"
      stroke={props.color || 'white'}
      strokeWidth={props.strokeWidth || '1.2'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
