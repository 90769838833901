import { FC, SVGProps } from 'react';

export const NavMenuOpen2Icon: FC<SVGProps<SVGSVGElement>> = ({ ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none" {...props}>
    <path
      d="M2.96289 3L5.92567 5.96278L2.96289 8.92555M5.66797 3L8.63075 5.96278L5.66797 8.92555"
      stroke="#878B92"
      strokeWidth="1.1"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
