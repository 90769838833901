import { ToDoIcon } from 'icons/to-do';
import s from './tag-contain.module.scss';

type RendererFunctionWithColors = () => JSX.Element;

type Props = {
  color?: Colors | string;
  onClick?: VoidFunction;
  text?: string | JSX.Element | RendererFunctionWithColors;
  active?: boolean;
  disabled?: boolean;
  iconLeft?: React.ReactElement | RendererFunctionWithColors;
  iconRight?: React.ReactElement | RendererFunctionWithColors;
  [key: string]: any;
};

type Colors = 'light_blue' | 'blue' | 'grey_dark_text' | 'light_green' | 'green' | 'deep_green' | 'dark_orange' | 'red';

export default function TagContain({ color, text, active, disabled, iconLeft, onClick, iconRight, ...rest }: Props) {
  const classNames = `${s.container} ${s[color || '']} ${onClick && !disabled ? s.pressable : ''} ${active ? s.active : ''} ${
    disabled ? s.disabled : ''
  } ${rest?.className}`;

  return (
    <div {...rest} className={classNames} onClick={onClick}>
      {iconLeft && (
        <span className={`${s.icon} ${s[color || '']}`}>{typeof iconLeft === 'function' ? iconLeft() : iconLeft}</span>
      )}
      {text && <span className={`${s.text} ${color}`}>{typeof text === 'function' ? text() : text}</span>}
      {iconRight && <span className={`${s.icon} ${color}`}>{typeof iconRight === 'function' ? iconRight() : iconRight}</span>}
    </div>
  );
}

export const TagsContainedExample = () => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 16, marginTop: 30 }}>
      <div style={{ display: 'flex', gap: 16, flexWrap: 'wrap', flexShrink: 0 }}>
        <TagContain onClick={() => {}} text="In queue" color="light_blue" iconLeft={<ToDoIcon />} />
        <TagContain onClick={() => {}} text="In queue" color="light_blue" iconLeft={<ToDoIcon />} active />
        <TagContain onClick={() => {}} text="In queue" color="light_blue" iconLeft={<ToDoIcon />} disabled />
      </div>
      <div style={{ display: 'flex', gap: 16, flexWrap: 'wrap', flexShrink: 0 }}>
        <TagContain onClick={() => {}} text="To Do" color="light_blue" iconLeft={<ToDoIcon />} />
        <TagContain onClick={() => {}} text="To Do" color="light_blue" iconLeft={<ToDoIcon />} active />
        <TagContain onClick={() => {}} text="To Do" color="light_blue" iconLeft={<ToDoIcon />} disabled />
      </div>
      <div style={{ display: 'flex', gap: 16, flexWrap: 'wrap' }}>
        <TagContain onClick={() => {}} text="In Progress" color="blue" iconLeft={<ToDoIcon />} />
        <TagContain onClick={() => {}} text="In Progress" color="blue" iconLeft={<ToDoIcon />} active />
        <TagContain onClick={() => {}} text="In Progress" color="blue" iconLeft={<ToDoIcon />} disabled />
      </div>
      <div style={{ display: 'flex', gap: 16, flexWrap: 'wrap' }}>
        <TagContain onClick={() => {}} text="On-hold" color="blue" iconLeft={<ToDoIcon />} />
        <TagContain onClick={() => {}} text="On-hold" color="blue" iconLeft={<ToDoIcon />} active />
        <TagContain onClick={() => {}} text="On-hold" color="blue" iconLeft={<ToDoIcon />} disabled />
      </div>

      <div style={{ display: 'flex', gap: 16, flexWrap: 'wrap' }}>
        <TagContain onClick={() => {}} text="Done" color="green" iconLeft={<ToDoIcon />} />
        <TagContain onClick={() => {}} text="Done" color="green" iconLeft={<ToDoIcon />} active />
        <TagContain onClick={() => {}} text="Done" color="green" iconLeft={<ToDoIcon />} disabled />
      </div>
      <div style={{ display: 'flex', gap: 16, flexWrap: 'wrap' }}>
        <TagContain onClick={() => {}} text="From Stock" color="green" iconLeft={<ToDoIcon />} />
        <TagContain onClick={() => {}} text="From Stock" color="green" iconLeft={<ToDoIcon />} active />
        <TagContain onClick={() => {}} text="From Stock" color="green" iconLeft={<ToDoIcon />} disabled />
      </div>
      <div style={{ display: 'flex', gap: 16, flexWrap: 'wrap' }}>
        <TagContain onClick={() => {}} text="Canceled" color="light_green" iconLeft={<ToDoIcon />} />
        <TagContain onClick={() => {}} text="Canceled" color="light_green" iconLeft={<ToDoIcon />} active />
        <TagContain onClick={() => {}} text="Canceled" color="light_green" iconLeft={<ToDoIcon />} disabled />
      </div>
      <div style={{ display: 'flex', gap: 16, flexWrap: 'wrap' }}>
        <TagContain onClick={() => {}} text="Auto" color="deep_green" iconLeft={<ToDoIcon />} />
        <TagContain onClick={() => {}} text="Auto" color="deep_green" iconLeft={<ToDoIcon />} active />
        <TagContain onClick={() => {}} text="Auto" color="deep_green" iconLeft={<ToDoIcon />} disabled />
      </div>
      <div style={{ display: 'flex', gap: 16, flexWrap: 'wrap' }}>
        <TagContain onClick={() => {}} text="Issue" color="dark_orange" iconLeft={<ToDoIcon />} />
        <TagContain onClick={() => {}} text="Issue" color="dark_orange" iconLeft={<ToDoIcon />} active />
        <TagContain onClick={() => {}} text="Issue" color="dark_orange" iconLeft={<ToDoIcon />} disabled />
      </div>
      <div style={{ display: 'flex', gap: 16, flexWrap: 'wrap' }}>
        <TagContain onClick={() => {}} text="Blocked" color="red" iconLeft={<ToDoIcon />} />
        <TagContain onClick={() => {}} text="Blocked" color="red" iconLeft={<ToDoIcon />} active />
        <TagContain onClick={() => {}} text="Blocked" color="red" iconLeft={<ToDoIcon />} disabled />
      </div>
    </div>
  );
};
