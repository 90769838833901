import { GetStateFunction } from 'redux/store';
import { StateController } from 'state-controller';
import { AccessLevel, Permission } from 'services/permission.model';
import { PermissionGuardActions } from 'modules/permission-guard/permission-guard.controller';
import { Actions as ProductConfigurationActions } from 'pages/product-flow/pages/product/controllers/product-configurations.controller';
import { ProductConfiguration } from 'services/product-configurations.model';

export enum ProductConfigurationsModalsNameEnum {
  ManageConfigurations = 'manageConfigurationsModal',
  Remove = 'removeModal',
}

export enum ConfigurationMode {
  Updated = 'updated',
  Created = 'created',
  Duplicated = 'duplicated',
}

export type ExtendedProductConfiguration = ProductConfiguration & {
  mode?: ConfigurationMode;
  sourceConfigurationId?: string;
};

export type ProductConfigurationsModalsState = {
  [ProductConfigurationsModalsNameEnum.Remove]: {
    isOpen: boolean;
    isDeleteProcessing: boolean;
    removeOnlyOnManagementModal: boolean;
    entityId: string;
    entityName: string;
    isLastConfiguration: boolean;
  };
};

const defaultState: ProductConfigurationsModalsState = {
  removeModal: {
    isOpen: false,
    isDeleteProcessing: false,
    removeOnlyOnManagementModal: false,
    entityId: '',
    entityName: '',
    isLastConfiguration: false,
  },
};

const stateController = new StateController<ProductConfigurationsModalsState>(
  'PRODUCT_CONFIGURATIONS_MODALS_STATE',
  defaultState,
);

export class Actions {
  public static openRemoveProductConfigurationModal(configuration: ProductConfiguration, removeOnlyOnManagementModal?: boolean) {
    return (dispatch, getState: GetStateFunction) => {
      if (!dispatch(PermissionGuardActions.checkPermissionAndShowModal(Permission.webProductsEdit, [AccessLevel.access]))) {
        return;
      }

      const isLastConfiguration = getState().product.product_configurations.product_configurations.length === 1;

      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          removeModal: {
            ...prev.removeModal,
            isOpen: true,
            removeOnlyOnManagementModal: !!removeOnlyOnManagementModal,
            entityId: configuration.id,
            entityName: configuration.name,
            isLastConfiguration,
          },
        })),
      );
    };
  }

  public static onDeleteProductConfiguration() {
    return async (dispatch, getState: GetStateFunction) => {
      const { entityId } = getState().product.product_configurations_modals.removeModal;

      try {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            removeModal: {
              ...prev.removeModal,
              isDeleteProcessing: true,
            },
          })),
        );
        await dispatch(ProductConfigurationActions.deleteConfiguration(entityId));
        dispatch(Actions.closeModal(ProductConfigurationsModalsNameEnum.Remove));
      } finally {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            removeModal: {
              ...prev.removeModal,
              isDeleteProcessing: false,
            },
          })),
        );
      }
    };
  }

  public static closeModal(modalName: ProductConfigurationsModalsNameEnum) {
    return (dispatch) => {
      dispatch(stateController.setState((prev) => ({ ...prev, [modalName]: defaultState[modalName] })));
    };
  }
}

export class Selectors {}

export const reducer = stateController.getReducer();
