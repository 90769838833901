import { FC, SVGProps } from 'react';

export const XCrossIcon2: FC<SVGProps<SVGSVGElement>> = ({ ...props }) => (
  <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_15430_96502)">
      <circle cx="8" cy="8.5" r="6.5" stroke="#141414" />
      <path d="M5.16992 11.3299L10.8299 5.66992" stroke="#141414" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M10.8299 11.3299L5.16992 5.66992" stroke="#141414" strokeLinecap="round" strokeLinejoin="round" />
    </g>
    <defs>
      <clipPath id="clip0_15430_96502">
        <rect y="0.5" width="16" height="16" rx="8" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
