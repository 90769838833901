import { SVGProps, useMemo } from 'react';
import s from './svg-wrapper.module.scss';

export type SvgWrapperProps = React.HTMLAttributes<HTMLDivElement> & {
  SvgIcon?: React.ComponentType<SVGProps<SVGSVGElement>>;
  icon?: string | React.ReactNode;
  size?: Sizes;
  containerSize?:
    | number
    | {
        width: number;
        height: number;
      };
  iconSize?:
    | number
    | {
        width: number;
        height: number;
      };
  backgroundColor?: string;
  className?: string;
};
type Sizes = 'small' | 'medium' | 'newMedium' | 'large';

const containerSizes = {
  small: 32,
  medium: 40,
  newMedium: 38,
  large: 48,
};
const iconSizes = {
  small: 20,
  medium: 26,
  newMedium: 20,
  large: 32,
};

const SvgWrapper = ({ SvgIcon, icon, size = 'large', containerSize, iconSize, backgroundColor, className }: SvgWrapperProps) => {
  const containerWidth = useMemo(() => {
    if (containerSize) {
      return typeof containerSize === 'number' ? containerSize : containerSize.width;
    }

    return containerSizes[size];
  }, [size, containerSize]);

  const containerHeight = useMemo(() => {
    if (containerSize) {
      return typeof containerSize === 'number' ? containerSize : containerSize.height;
    }

    return containerSizes[size];
  }, [size, containerSize]);

  const iconWidth = useMemo(() => {
    if (iconSize) {
      return typeof iconSize === 'number' ? iconSize : iconSize.width;
    }

    return iconSizes[size];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [size, containerSize]);
  const iconHeight = useMemo(() => {
    if (iconSize) {
      return typeof iconSize === 'number' ? iconSize : iconSize.height;
    }

    return iconSizes[size];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [size, containerSize]);

  return (
    <div
      className={`${s.container_with_image} ${className || ''}`}
      style={{ width: containerWidth, height: containerHeight, backgroundColor }}
    >
      <div className={s.svg}>{icon || (SvgIcon ? <SvgIcon width={iconWidth} height={iconHeight} fill="white" /> : null)}</div>
    </div>
  );
};

export default SvgWrapper;
