import s from './horisontal-shift-animation.module.scss';

type OwnProps = {
  delay?: number;
  isVisible: boolean;
  fadeEffect?: boolean;
  animationTime?: number;
  children: React.ReactNode;
  componentClassName?: string;
  maxWidthWhenIsHiden?: number;
  maxWidthWhenIsShown?: number;
  isReservedPlaceForIcon?: boolean;
};

const HorizontalShiftAnimation = ({
  children,
  isVisible,
  delay = 0,
  fadeEffect = true,
  componentClassName,
  animationTime = 0.2,
  isReservedPlaceForIcon,
  maxWidthWhenIsHiden = 0,
  maxWidthWhenIsShown = 40,
}: OwnProps) => {
  return (
    <div style={{ minWidth: isReservedPlaceForIcon ? maxWidthWhenIsShown : 0 }} className={s.wrapper}>
      <div
        className={`${s.inner_container} ${componentClassName}`}
        style={{
          opacity: !isVisible && fadeEffect ? 0 : 1,
          pointerEvents: isVisible ? 'inherit' : 'none',
          transition: `${animationTime}s ease-in-out ${delay}s`,
          maxWidth: isVisible ? maxWidthWhenIsShown : maxWidthWhenIsHiden,
        }}
      >
        {children}
      </div>
    </div>
  );
};

export default HorizontalShiftAnimation;
