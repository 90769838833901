import { baseAxiosInstance } from 'axios-config';
import { CreateWorkflowTaskTemplateFileData, WorkflowTaskTemplateFile } from 'services/workflow-task-template-model.';

export class WorkflowTaskTemplateFileService {
  public static async getAll(id: string) {
    const { data } = await baseAxiosInstance.get<WorkflowTaskTemplateFile[]>(`/workflow-task-template-files/all`, {
      params: {
        workflow_task_template_id: id,
      },
    });
    return data;
  }

  public static async getById(id: string) {
    const { data } = await baseAxiosInstance.get<WorkflowTaskTemplateFile>(`/workflow-task-template-files/${id}`);
    return data;
  }

  public static async create(body: CreateWorkflowTaskTemplateFileData | FormData, onProgress) {
    const { data } = await baseAxiosInstance.post<WorkflowTaskTemplateFile>(`/workflow-task-template-files/create`, body, {
      onUploadProgress: onProgress,
    });
    return data;
  }

  public static async delete(id: string) {
    const { data } = await baseAxiosInstance.delete(`/workflow-task-template-files/${id}`);
    return data;
  }
}
