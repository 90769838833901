import { Paths } from 'routes/paths';
import { TasksIcon } from 'icons/tasks';
import { DiamondIcon } from 'icons/diamond';
import { CategoryIcon } from 'icons/category';
import { SettingsIcon } from 'icons/settings';
import { OrderKeyIcon } from 'icons/order-key';
import { KeySquareIcon } from 'icons/key-square';
import { DepartmentsIcon } from 'icons/departments';
import { CategoriesSmall } from 'icons/categories-small';
import { ProductionKeyIcon } from 'icons/production-key';
import { ProductionWorkflowsIcon } from 'icons/production-workflows';
import { Breadcrumb } from 'components/ui-new/breadcrumbs/breadcrumbs';
import BreadcrumbBadge from 'components/ui-new/breadcrumbs/components/breadcrumb-badge/breadcrumb-badge';

export enum VersionBackground {
  Gray = 'gray',
  White = 'white',
}

export enum ItemType {
  Product = 'product',
  TaskKey = 'task_key',
  OrderKey = 'order_key',
  Production = 'production',
  Department = 'department',
  TaskTemplate = 'task_template',
  Configuration = 'configuration',
  ProductionKey = 'production_key',
  ProductCategory = 'product_category',
  WorkflowTemplate = 'workflow_template',
}

export type BreadcrumbItem = {
  id: string;
  name: string;
  type: ItemType;
  version?: number;
  isDraft?: boolean;
  product_id?: string;
  is_deleted?: boolean;
  isDisabled?: boolean;
  isHaveTooltip?: string;
  isAllowedCopy?: boolean;
};

export const getBreadcrumbWidth = (maxWidth: number, refRow: any, refArray: any) => {
  const width = refRow?.current?.clientWidth;
  const remainingWidth = maxWidth - width;
  const elementsWidth = refArray.current.map((element) => element.current?.textRef?.current?.clientWidth);

  if (remainingWidth <= 0) {
    if (elementsWidth.some((i) => i > 430)) {
      for (let i = 0; i < refArray.current.length; i += 1) {
        const isKey = refArray.current[i].current.textRef.current.dataset.iskey;
        const breadcrumbText = refArray.current[i].current.textRef.current;
        if (breadcrumbText.clientWidth > 430 && !isKey) {
          breadcrumbText.style.maxWidth = '430px';
          return;
        }
      }
    } else if (elementsWidth.some((i) => i > 230 && i <= 430)) {
      for (let i = 0; i < refArray.current.length; i += 1) {
        const isKey = refArray.current[i].current.textRef.current.dataset.iskey;
        const breadcrumbText = refArray.current[i].current.textRef.current;
        if (breadcrumbText.clientWidth > 230 && !isKey) {
          breadcrumbText.style.maxWidth = '230px';
          return;
        }
      }
    } else if (elementsWidth.some((i) => i > 0 && i <= 230)) {
      for (let i = 0; i < refArray.current.length; i += 1) {
        const isKey = refArray.current[i].current.textRef.current.dataset.iskey;
        const breadcrumbText = refArray.current[i].current.textRef.current;
        if (breadcrumbText.clientWidth > 0 && !isKey && i !== refArray.current.length - 1) {
          breadcrumbText.style.maxWidth = '0px';
          return;
        }
      }
    }
  } else if (remainingWidth > 0) {
    if (elementsWidth.some((i) => i === 0)) {
      for (let i = refArray.current.length - 1; i >= 0 && remainingWidth > 230; i -= 1) {
        const breadcrumbText = refArray.current[i].current.textRef.current;
        if (breadcrumbText.clientWidth === 0) {
          breadcrumbText.style.maxWidth = '230px';
          return;
        }
      }
    } else if (elementsWidth.some((i) => i === 230)) {
      for (let i = refArray.current.length - 1; i >= 0 && remainingWidth > 200; i -= 1) {
        const breadcrumbText = refArray.current[i].current.textRef.current;
        if (breadcrumbText.clientWidth === 230) {
          breadcrumbText.style.maxWidth = '430px';
          return;
        }
      }
    }
  }
};

const getIcon = (item: BreadcrumbItem, index: number) => {
  switch (item.type) {
    case ItemType.Product:
      return <DiamondIcon />;
    case ItemType.Configuration:
      return <SettingsIcon width="14" height="14" />;
    case ItemType.ProductCategory:
      return index === 0 ? <CategoryIcon /> : <CategoriesSmall />;
    case ItemType.TaskTemplate:
      return <TasksIcon width="12px" height="12px" />;
    case ItemType.Production:
      return <ProductionWorkflowsIcon width="12px" height="12px" />;
    case ItemType.OrderKey:
      return <OrderKeyIcon width="14" height="14" />;
    case ItemType.ProductionKey:
      return <ProductionKeyIcon width="14" height="14" />;
    case ItemType.TaskKey:
      return <KeySquareIcon width="14" height="14" />;
    case ItemType.Department:
      return <DepartmentsIcon width="12" height="12" />;
    default:
      return null;
  }
};

const getRoute = (item: BreadcrumbItem) => {
  switch (item.type) {
    case ItemType.Product:
      return `${Paths.Product}/${item.id}`;
    case ItemType.Configuration:
      return `${Paths.Product}/${item.product_id}?configuration=${item.id}`;
    case ItemType.ProductCategory:
      return `${Paths.Products}/all-products?categoryId=${item.id}`;
    case ItemType.TaskTemplate:
      return `${Paths.TaskTemplate}/${item.id}`;
    case ItemType.WorkflowTemplate:
      return `${Paths.WorkFlowTemplate}/${item.id}`;
    case ItemType.Production:
      return Paths.Production;
    case ItemType.OrderKey:
      return `${Paths.Production}?limit_by=order_key&value=${item.name}`;
    case ItemType.ProductionKey:
      return `${Paths.ProductionWorkflow}/${item.id}`;
    case ItemType.TaskKey:
      return `${Paths.Task}/${item.id}`;
    case ItemType.Department:
      return `${Paths.Departments}/${item.id}`;
    default:
      return null;
  }
};

const getTypeTooltip = (item: BreadcrumbItem) => {
  switch (item.type) {
    case ItemType.OrderKey:
      return 'Order key';
    case ItemType.ProductionKey:
      return 'Production key';
    case ItemType.TaskKey:
      return 'Task key';
    default:
      return null;
  }
};

export const getBreadcrumbsItems = (items: BreadcrumbItem[], versionBackground: VersionBackground = VersionBackground.White) => {
  return items?.map((item, index) => {
    let badge: JSX.Element | null = null;

    if (item.version) {
      badge = <BreadcrumbBadge version={`v.${item.version}`} type={item.isDraft ? 'red' : versionBackground} />;
    } else if (item.is_deleted) {
      badge = <BreadcrumbBadge type="red" isDeleted />;
    }

    return {
      route: getRoute(item),
      additionalContent: badge,
      disabled: item.isDisabled,
      icon: getIcon(item, index),
      isDeleted: item.is_deleted,
      label: item.name || 'Undefined',
      isAllowedCopy: item.isAllowedCopy,
      tooltip: item.isHaveTooltip && getTypeTooltip(item),
    };
  }) as Breadcrumb[];
};
