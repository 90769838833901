import { StateController } from 'state-controller';
import { Actions as AuthActions } from 'redux/auth.controller';
import { AppState, GetStateFunction } from 'redux/store';
import { notify } from 'notifications';
import { AuthService } from 'services/auth.service';

export type ValidationCriteria = {
  length: boolean;
  number: boolean;
  specialChar: boolean;
  uppercaseLowercase: boolean;
  isPasswordMatch?: boolean;
};

export type SignInState = {
  email: string;
  password: string;
  newPassword: string;
  confirmPassword: string;
  isPasswordReset: boolean;
  resetPasswordEmail: string;
  isSignInProcessing: boolean;
  isSentResetPasswordLink: boolean;
  validationCriteria: ValidationCriteria;
};

const defaultState: SignInState = {
  email: '',
  password: '',
  newPassword: '',
  confirmPassword: '',
  resetPasswordEmail: '',
  isPasswordReset: false,
  isSignInProcessing: false,
  isSentResetPasswordLink: false,
  validationCriteria: { length: false, number: false, specialChar: false, uppercaseLowercase: false },
};

const stateController = new StateController<SignInState>('SIGN_IN_STATE', defaultState);

export class Actions {
  public static onChange(values: Partial<SignInState>) {
    return (dispatch) => {
      dispatch(stateController.setState((prev) => ({ ...prev, ...values })));
    };
  }

  public static handleClickSignIn() {
    return async (dispatch, getState: () => AppState) => {
      const { email, password } = getState().authentication;

      try {
        dispatch(stateController.setState({ isSignInProcessing: true }));
        await dispatch(AuthActions.login(email, password));

        dispatch(stateController.setState(defaultState));
      } catch (error) {
        notify.error(`${error.response.data.message || error.response.data.message[0]}`);
        throw error;
      } finally {
        dispatch(stateController.setState({ isSignInProcessing: false }));
      }
    };
  }

  public static sendEmailWithLink() {
    return async (dispatch, getState: GetStateFunction) => {
      const { resetPasswordEmail } = getState().authentication;

      try {
        dispatch(stateController.setState({ isSentResetPasswordLink: true }));

        await AuthService.sendResetPasswordEmail({ email: resetPasswordEmail });
      } catch (error) {
        dispatch(stateController.setState({ isSentResetPasswordLink: false }));
        notify.error('Something went wrong. Please reset page');
      }
    };
  }

  public static clearForgotPasswordState() {
    return async (dispatch) => {
      dispatch(stateController.setState({ isSentResetPasswordLink: false, resetPasswordEmail: '' }));
    };
  }

  public static resetPassword(token: string) {
    return async (dispatch, getState: GetStateFunction) => {
      const { newPassword, confirmPassword } = getState().authentication;

      try {
        dispatch(stateController.setState({ isPasswordReset: true }));

        await AuthService.resetPassword({ token, password: newPassword, confirmPassword });
      } catch (error) {
        dispatch(stateController.setState({ isPasswordReset: false }));
        notify.error('Something went wrong. Please contact support');
      }
    };
  }

  public static disposeState() {
    return async (dispatch) => {
      dispatch(stateController.setState(defaultState));
    };
  }
}

export class Selectors {
  public static returnIsPasswordMatch(state: AppState) {
    return state.authentication.newPassword === state.authentication.confirmPassword;
  }
}

export const reducer = stateController.getReducer();
