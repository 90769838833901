import { FC, SVGProps } from 'react';

export const BadgeIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect x="0.6" y="0.6" width="28.8" height="28.8" rx="7.4" stroke="#5C668C" strokeWidth="1.2" />
    <path
      d="M24.2612 17.0593L23.9007 21.5662C23.7655 22.9453 23.6573 23.9998 21.2146 23.9998H9.87545C7.43275 23.9998 7.32459 22.9453 7.18939 21.5662L6.82884 17.0593C6.75673 16.3112 6.99109 15.6172 7.41473 15.0854C7.42374 15.0763 7.42374 15.0763 7.43275 15.0673C7.92851 14.4634 8.67664 14.0848 9.51491 14.0848H21.5752C22.4134 14.0848 23.1525 14.4634 23.6393 15.0493C23.6483 15.0583 23.6573 15.0673 23.6573 15.0763C24.099 15.6081 24.3424 16.3022 24.2612 17.0593Z"
      stroke="#5C668C"
      strokeWidth="1.2"
      strokeMiterlimit="10"
    />
    <path
      d="M7.88354 14.4728V9.8308C7.88354 6.76616 8.6497 6 11.7143 6H12.8591C14.0038 6 14.2652 6.34252 14.6979 6.91939L15.8426 8.45171C16.131 8.83028 16.3023 9.06464 17.0684 9.06464H19.3669C22.4316 9.06464 23.1977 9.8308 23.1977 12.8954V14.5089"
      stroke="#5C668C"
      strokeWidth="1.2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.2285 19.493H17.8615"
      stroke="#5C668C"
      strokeWidth="1.2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
