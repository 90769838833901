import { FC, SVGProps } from 'react';

export const OpenRootIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M13.3333 9.83334C14.0697 9.83334 14.6667 9.23638 14.6667 8.50001C14.6667 7.76363 14.0697 7.16667 13.3333 7.16667C12.597 7.16667 12 7.76363 12 8.50001C12 9.23638 12.597 9.83334 13.3333 9.83334Z"
      stroke="#141414"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.3333 4.49999C14.0697 4.49999 14.6667 3.90304 14.6667 3.16666C14.6667 2.43028 14.0697 1.83333 13.3333 1.83333C12.597 1.83333 12 2.43028 12 3.16666C12 3.90304 12.597 4.49999 13.3333 4.49999Z"
      stroke="#141414"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.3333 15.1667C14.0697 15.1667 14.6667 14.5697 14.6667 13.8333C14.6667 13.097 14.0697 12.5 13.3333 12.5C12.597 12.5 12 13.097 12 13.8333C12 14.5697 12.597 15.1667 13.3333 15.1667Z"
      stroke="#141414"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.50065 10.8333C4.69727 10.8333 5.66732 9.86328 5.66732 8.66667C5.66732 7.47005 4.69727 6.5 3.50065 6.5C2.30403 6.5 1.33398 7.47005 1.33398 8.66667C1.33398 9.86328 2.30403 10.8333 3.50065 10.8333Z"
      stroke="#141414"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M6 8.5H12" stroke="#141414" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M12.0007 3.16667H9.33398C8.00065 3.16667 7.33398 3.83334 7.33398 5.16667V11.8333C7.33398 13.1667 8.00065 13.8333 9.33398 13.8333H12.0007"
      stroke="#141414"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
