import React, { SVGProps } from 'react';

const PendingIcon: React.FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none" {...props}>
      <path
        d="M4.18055 8.36108C1.125 8.36108 1.125 9.72844 1.125 11.4166V12.1805C1.125 14.2889 1.125 16 4.94444 16H11.0556C14.1111 16 14.875 14.2889 14.875 12.1805V11.4166C14.875 9.72844 14.875 8.36108 11.8194 8.36108C11.0556 8.36108 10.8417 8.5215 10.4444 8.81942L9.66527 9.64442C8.76389 10.6069 7.23611 10.6069 6.32708 9.64442L5.55555 8.81942C5.15833 8.5215 4.94444 8.36108 4.18055 8.36108Z"
        stroke={props.color || '#5E7285'}
        strokeWidth="1.1"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.3473 8.36154V3.77821C13.3473 2.09002 13.3473 0.722656 10.2917 0.722656H5.70839C2.65283 0.722656 2.65283 2.09002 2.65283 3.77821V8.36154"
        stroke={props.color || '#5E7285'}
        strokeWidth="1.1"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default PendingIcon;
