import { AppState } from 'redux/store';
import { notify } from 'notifications';
import { IdName } from 'types/common-types';
import { StateController } from 'state-controller';
import { OrdersService } from 'services/orders.service';
import { ProductionWorkflowService } from 'services/production-workflow.service';
import { Page } from 'pages/production/controllers/production-list-controller/types';
import { ProductionFiltersActions } from 'pages/production/controllers/production-filters-controller/production-filters.controller';
import { Actions as ProductionWorkflowActions } from 'pages/production-workflow/controllers/production-workflow.controller';
import { loadData } from 'pages/production/components/info-dropdown/use-info-dropdown';
import { PermissionGuardActions } from 'modules/permission-guard/permission-guard.controller';
import { AccessLevel, Permission } from 'services/permission.model';

export type EditOrderModalArgs = {
  page?: Page;
  order: IdName;
  productName?: string;
  productionId?: string;
  productionKey?: string;
  isOpenFromOrder?: boolean;
  externalOrderNumber?: string;
  marketplaceOrderNumber?: string;
};

export type EditOrderModalState = {
  orderId: string;
  isOpen: boolean;
  orderKey: IdName;
  page: Page | null;
  isLoading: boolean;
  orderKeys: IdName[];
  isFetching: boolean;
  productName: string;
  productionId: string;
  productionKey: string;
  isValueChanged: boolean;
  isOpenFromOrder: boolean;
  externalOrderNumber: IdName;
  externalOrderNumbers: IdName[];
  marketplaceOrderNumber: IdName;
  marketplaceOrderNumbers: IdName[];
  applyChangesToAllProductions: boolean;
  initialData: {
    orderKeyId: string;
    externalOrderNumber: string;
    marketplaceOrderNumber: string;
  };
};

const defaultState: EditOrderModalState = {
  page: null,
  orderId: '',
  isOpen: false,
  orderKeys: [],
  productName: '',
  isLoading: false,
  productionId: '',
  productionKey: '',
  isFetching: false,
  isValueChanged: false,
  isOpenFromOrder: false,
  externalOrderNumbers: [],
  marketplaceOrderNumbers: [],
  initialData: {
    orderKeyId: '',
    externalOrderNumber: '',
    marketplaceOrderNumber: '',
  },
  orderKey: {
    id: '',
    name: '',
  },
  externalOrderNumber: {
    id: '',
    name: '',
  },
  marketplaceOrderNumber: {
    id: '',
    name: '',
  },
  applyChangesToAllProductions: false,
};

const stateController = new StateController<EditOrderModalState>('EDIT_ORDER_MODAL', defaultState);

export class EditOrderActions {
  public static openModal({
    page,
    order,
    productName,
    productionId,
    productionKey,
    externalOrderNumber = '',
    marketplaceOrderNumber = '',
    isOpenFromOrder = false,
  }: EditOrderModalArgs) {
    return async (dispatch) => {
      if (!dispatch(PermissionGuardActions.checkPermissionAndShowModal(Permission.webProductionEdit, [AccessLevel.access]))) {
        return;
      }
      try {
        dispatch(stateController.setState({ isFetching: true }));
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            page,
            productName,
            productionId,
            productionKey,
            isOpenFromOrder,
            orderId: order.id,
            isOpen: true,
            initialData: {
              orderKeyId: order.id || '',
              externalOrderNumber,
              marketplaceOrderNumber,
            },
            orderKey: {
              id: order?.id || '',
              name: order?.name || '',
            },
            externalOrderNumber: {
              id: externalOrderNumber,
              name: externalOrderNumber,
            },
            marketplaceOrderNumber: {
              id: marketplaceOrderNumber,
              name: marketplaceOrderNumber,
            },
          })),
        );

        const [orderKeysData, externalOrderNumbersData, marketplaceOrderNumbersData] = await Promise.all([
          (await OrdersService.getOrderKeys('', 0, 9000)).data,
          (await OrdersService.getExternalOrderNumbers('', 0, 9000)).data,
          (await OrdersService.getMarketplaceOrderNumbers('', 0, 9000)).data,
        ]);

        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            orderKeys: orderKeysData,
            externalOrderNumbers: externalOrderNumbersData,
            marketplaceOrderNumbers: marketplaceOrderNumbersData,
          })),
        );
      } finally {
        dispatch(stateController.setState({ isFetching: false }));
      }
    };
  }

  public static closeModal() {
    return async (dispatch) => {
      dispatch(stateController.setState({ isOpen: false }));
      setTimeout(() => dispatch(stateController.setState({ ...defaultState })), 100);
    };
  }

  public static onSave() {
    return async (dispatch, getState: () => AppState) => {
      try {
        dispatch(stateController.setState({ isLoading: true }));

        const { productionId, orderKey, initialData, page, orderId, applyChangesToAllProductions } =
          getState().production.editOrderModal;

        if (initialData.orderKeyId !== orderKey.id) {
          const body = {
            current_order_id: orderId,
            new_order_id: orderKey.id,
          };

          const params = {
            change_all_productions: applyChangesToAllProductions,
          };

          await ProductionWorkflowService.updateProductionWorkflowDetailsByOrder(productionId, body, params);
        }

        if (page === Page.InfoDropdownWorkflow) {
          await loadData(productionId);
          await dispatch(ProductionWorkflowActions.silentLoad({ id: productionId, disableAdditionalTasksSet: true }));
        }

        if (page === Page.InfoDropdownProduction) {
          await loadData(productionId);

          const customGroupBy = getState().production.filters.groupBy;
          await dispatch(
            ProductionFiltersActions.getProductionsByFilter({
              customGroupBy,
              showFetchEffect: false,
              resetSkipPreserveTake: true,
            }),
          );
        }

        if (page === Page.Production) {
          const customGroupBy = getState().production.filters.groupBy;
          await dispatch(
            ProductionFiltersActions.getProductionsByFilter({
              customGroupBy,
              showFetchEffect: false,
              resetSkipPreserveTake: true,
            }),
          );
        }

        if (page === Page.Workflow) {
          await dispatch(ProductionWorkflowActions.silentLoad({ id: productionId, disableAdditionalTasksSet: true }));
        }

        dispatch(EditOrderActions.closeModal());
        notify.success('Successfully updated');
      } finally {
        dispatch(stateController.setState({ isLoading: false }));
      }
    };
  }

  public static switchShowWarning() {
    return async (dispatch, getState: () => AppState) => {
      const { orderKey, externalOrderNumber, marketplaceOrderNumber, initialData } = getState().production.editOrderModal;
      if (
        initialData.orderKeyId !== orderKey?.id ||
        initialData.externalOrderNumber !== externalOrderNumber?.id ||
        initialData.marketplaceOrderNumber !== marketplaceOrderNumber?.id
      ) {
        dispatch(stateController.setState({ isValueChanged: true }));
      } else {
        dispatch(stateController.setState({ isValueChanged: false }));
      }
    };
  }

  public static onChangeOrderKey(value: IdName) {
    return async (dispatch, getState: () => AppState) => {
      const { externalOrderNumbers, marketplaceOrderNumbers } = getState().production.editOrderModal;
      const chosenExternalOrderNumber = externalOrderNumbers.find((item) => item.id === value.id);
      const chosenMarketplaceOrderNumber = marketplaceOrderNumbers.find((item) => item.id === value.id);

      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          orderKey: value,
          externalOrderNumber: chosenExternalOrderNumber || { id: '', name: '' },
          marketplaceOrderNumber: chosenMarketplaceOrderNumber || { id: '', name: '' },
        })),
      );
      dispatch(EditOrderActions.switchShowWarning());
    };
  }

  public static onChangeExternalOrderNumber(value: IdName) {
    return async (dispatch, getState: () => AppState) => {
      const { orderKeys, marketplaceOrderNumbers } = getState().production.editOrderModal;
      const chosenOrderKey = orderKeys.find((item) => item.id === value.id);
      const chosenMarketplaceOrderNumber = marketplaceOrderNumbers.find((item) => item.id === value.id);

      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          orderKey: chosenOrderKey,
          externalOrderNumber: value,
          marketplaceOrderNumber: chosenMarketplaceOrderNumber || { id: '', name: '' },
        })),
      );
      dispatch(EditOrderActions.switchShowWarning());
    };
  }

  public static onChangeMarketplaceOrderNumber(value: IdName) {
    return async (dispatch, getState: () => AppState) => {
      const { orderKeys, externalOrderNumbers } = getState().production.editOrderModal;
      const chosenOrderKey = orderKeys.find((item) => item.id === value.id);
      const chosenExternalOrderNumber = externalOrderNumbers.find((item) => item.id === value.id);

      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          orderKey: chosenOrderKey,
          externalOrderNumber: chosenExternalOrderNumber || { id: '', name: '' },
          marketplaceOrderNumber: value,
        })),
      );
      dispatch(EditOrderActions.switchShowWarning());
    };
  }

  public static onChangeApplyChangesToAllProductions(value: boolean) {
    return async (dispatch) => {
      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          applyChangesToAllProductions: value,
        })),
      );
    };
  }
}

export const reducer = stateController.getReducer();
