import { ReactNode } from 'react';
import { Person3Icon } from 'icons/person-3';
import { Avatar, AvatarProps } from '@mui/material';
import s from './avatar-circle.module.scss';

const getAbbreviation = (firstName: string, lastName: string) => {
  return `${firstName[0]}${lastName[0]}`.toUpperCase();
};

type AvatarCircleProps = AvatarProps & {
  isEmpty: boolean;
  lastName?: string;
  imageSrc?: string;
  isActive?: boolean;
  className?: string;
  firstName?: string;
  withHover?: boolean;
  image?: string | ReactNode;
};

export const AvatarCircle = ({
  isEmpty,
  lastName,
  imageSrc,
  isActive,
  firstName,
  className,
  withHover = true,
  ...rest
}: AvatarCircleProps) => {
  if (isEmpty) {
    return (
      <Avatar
        data-with-hover={withHover}
        data-is-active={isActive}
        className={`${s.avatar} ${className}`}
        data-is-empty={isEmpty}
        {...rest}
      >
        <Person3Icon />
      </Avatar>
    );
  }

  if (imageSrc && typeof imageSrc === 'string') {
    return (
      <Avatar
        data-with-hover={withHover}
        data-is-active={isActive}
        className={`${s.avatar} ${className}`}
        src={imageSrc}
        {...rest}
      />
    );
  }

  if (firstName && lastName) {
    return (
      <Avatar data-with-hover={withHover} data-is-active={isActive} className={`${s.avatar} ${className}`} {...rest}>
        {getAbbreviation(firstName, lastName)}
      </Avatar>
    );
  }

  return (
    <Avatar data-with-hover={withHover} data-is-active={isActive} className={`${s.avatar} ${className}`} {...rest}>
      <Person3Icon />
    </Avatar>
  );
};
