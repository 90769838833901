import { baseAxiosInstance } from 'axios-config';
import { CreateTaskTagData, TaskTag } from 'services/task-tags.model';

export class TaskTagsService {
  public static async getAllTaskTags() {
    const { data } = await baseAxiosInstance.get<TaskTag[]>('/task-tags/all');

    return data;
  }

  public static async createTaskTag(body: CreateTaskTagData) {
    const { data } = await baseAxiosInstance.post<TaskTag>('/task-tags/create', body);

    return data;
  }
}
