import { FC, SVGProps } from 'react';

export const AddLineIcon: FC<SVGProps<SVGSVGElement>> = ({ ...props }) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_15_4141)">
      <path
        d="M8.00146 0.617188V15.4743"
        stroke={props.color || 'white'}
        strokeWidth="1.44"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M0.573486 8H15.4306"
        stroke={props.color || 'white'}
        strokeWidth="1.44"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_15_4141">
        <rect width="16" height="16" stroke={props.color || 'white'} />
      </clipPath>
    </defs>
  </svg>
);
