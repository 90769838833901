import { WarningIcon } from 'icons/warning';
import s from './add-user-flow.module.scss';

export const renderTooltipTitle = (charCount: number) => {
  return (
    <div className={s.tooltip_title}>
      <WarningIcon stroke="#ff6464" />
      <div>Maximum character limit is {charCount}. Please, shorten the text to the allowable size.</div>
    </div>
  );
};

export const validateMaxLength = (value: string | undefined, maxLength: number): string => {
  if (!value) {
    return '';
  }
  return value.length > maxLength ? `You have reached the maximum allowed number of characters (${maxLength})` : '';
};
