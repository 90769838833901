import { FC, SVGProps } from 'react';

const MoveToIcon: FC<SVGProps<SVGSVGElement>> = ({ ...props }) => (
  <svg width="20" height="20" viewBox="0 0 20 20" {...props} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.79188 7.22585C9.04044 6.84437 10.367 6.79554 11.6402 7.08418V3.51251L17.5319 9.40418L11.6402 15.2975V11.2575C9.77299 10.8324 7.8139 11.1373 6.16421 12.1098C4.51452 13.0823 3.29909 14.6488 2.76688 16.4883C2.21805 14.5937 2.43769 12.5592 3.37822 10.8254C4.31875 9.09155 5.90445 7.79888 7.79188 7.22585Z"
      stroke={props.fill || props.color || 'black'}
      strokeWidth="1.5"
      strokeLinejoin="round"
    />
  </svg>
);

export default MoveToIcon;
