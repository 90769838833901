import { FC, SVGProps } from 'react';

export const PauseOurlinedIcon: FC<SVGProps<SVGSVGElement>> = ({ ...props }) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_1639_96219)">
      <path
        d="M6.9125 13.4775V2.0225C6.9125 0.935 6.45333 0.5 5.29333 0.5H2.36917C1.20917 0.5 0.75 0.935 0.75 2.0225V13.4775C0.75 14.565 1.20917 15 2.36917 15H5.29333C6.45333 15 6.9125 14.565 6.9125 13.4775Z"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.2494 13.4775V2.0225C15.2494 0.935 14.7902 0.5 13.6302 0.5H10.7061C9.55414 0.5 9.08691 0.935 9.08691 2.0225V13.4775C9.08691 14.565 9.54608 15 10.7061 15H13.6302C14.7902 15 15.2494 14.565 15.2494 13.4775Z"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1639_96219">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
