import { FC, SVGProps } from 'react';

export const BuildingsIcon: FC<SVGProps<SVGSVGElement>> = ({ ...props }) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M4.46684 12.0002H2.76685C1.81351 12.0002 1.3335 11.5201 1.3335 10.5668V2.76685C1.3335 1.81351 1.81351 1.3335 2.76685 1.3335H5.6335C6.58684 1.3335 7.06681 1.81351 7.06681 2.76685V4.00016"
      stroke="#292D32"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.5801 5.61332V13.0533C11.5801 14.1267 11.0468 14.6667 9.97347 14.6667H6.08012C5.00679 14.6667 4.4668 14.1267 4.4668 13.0533V5.61332C4.4668 4.53999 5.00679 4 6.08012 4H9.97347C11.0468 4 11.5801 4.53999 11.5801 5.61332Z"
      stroke="#292D32"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.93359 4.00016V2.76685C8.93359 1.81351 9.41357 1.3335 10.3669 1.3335H13.2336C14.1869 1.3335 14.6669 1.81351 14.6669 2.76685V10.5668C14.6669 11.5201 14.1869 12.0002 13.2336 12.0002H11.5802"
      stroke="#292D32"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M6.6665 7.3335H9.33317" stroke="#292D32" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M6.6665 9.3335H9.33317" stroke="#292D32" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M8 14.6665V12.6665" stroke="#292D32" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);
