import { FC, SVGProps } from 'react';

export const ClipboardTickIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M7 13L8.75282 14.4023C9.1707 14.7366 9.7777 14.6826 10.1301 14.2799L13 11M14 3H15C17.2091 3 19 4.79086 19 7V17C19 19.2091 17.2091 21 15 21H5C2.79086 21 1 19.2091 1 17V7C1 4.79086 2.79086 3 5 3H6M14 3C14 4.10457 13.1046 5 12 5H8C6.89543 5 6 4.10457 6 3M14 3C14 1.89543 13.1046 1 12 1H8C6.89543 1 6 1.89543 6 3"
      stroke="#5A5A5A"
      strokeWidth="1.2"
      strokeLinecap="round"
    />
  </svg>
);
