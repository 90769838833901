import { FC, SVGProps } from 'react';

const CollapseIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="#878B92" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M14.0882 2.90161C13.798 2.90161 13.5624 3.13717 13.5624 3.42741V9.47419C13.5624 9.76444 13.798 10 14.0882 10H20.135C20.4253 10 20.6608 9.76444 20.6608 9.47419C20.6608 9.18395 20.4253 8.94839 20.135 8.94839H15.3667L22.3011 2.01404C22.5064 1.80872 22.5064 1.47588 22.3011 1.27029C22.1986 1.16776 22.0639 1.11649 21.9293 1.11649C21.7947 1.11649 21.6601 1.16776 21.5576 1.27029L14.614 8.21383V3.42741C14.614 3.13717 14.3785 2.90161 14.0882 2.90161Z"
      stroke="#878B92"
      strokeWidth="0.7"
    />
    <path
      d="M2.65284 22.4886C2.85791 22.6939 3.19127 22.6939 3.39633 22.4886L10.3859 15.499V20.2765C10.3859 20.5668 10.6214 20.8023 10.9117 20.8023C11.2019 20.8023 11.4375 20.5668 11.4375 20.2765V14.2297C11.4375 13.9395 11.2019 13.7039 10.9117 13.7039H4.86491C4.57467 13.7039 4.33911 13.9395 4.33911 14.2297C4.33911 14.52 4.57467 14.7555 4.86491 14.7555H9.64213L2.65284 21.7451C2.44751 21.9504 2.44751 22.2833 2.65284 22.4886Z"
      stroke="#878B92"
      strokeWidth="0.7"
    />
  </svg>
);
export default CollapseIcon;
