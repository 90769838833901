import { combineReducers } from 'redux';
import { reducer as multiselectReducer } from 'pages/products/multiselect.controller';
import { reducer as allProductsReducer } from './products.controller';
import { reducer as allProductsModalsReducer } from './products-modals.controller';
import { reducer as productsCategoriesReducer } from './products-categories.controller';

export const reducer = combineReducers({
  products: allProductsReducer,
  productsModals: allProductsModalsReducer,
  categories: productsCategoriesReducer,
  multiselect: multiselectReducer,
});
