import { FC, SVGProps } from 'react';

export const EditPencilIcon: FC<SVGProps<SVGSVGElement>> = ({ ...props }) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M12.1091 2.73432C12.7524 1.87748 13.9074 1.75284 14.6917 2.45363C14.7356 2.49149 16.1294 3.69826 16.1294 3.69826C16.9916 4.2781 17.2586 5.51164 16.7255 6.4525C16.6973 6.50328 8.8192 17.4787 8.8192 17.4787C8.55721 17.8425 8.15841 18.0585 7.73308 18.0632L4.71683 18.1047L4.03614 14.9008C3.94079 14.4493 4.03614 13.9775 4.29813 13.6119L12.1091 2.73432Z"
      stroke="#878B92"
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M11.3573 5.50098L14.4527 8.14895" stroke="#878B92" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);
