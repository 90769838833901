import { Stack } from '@mui/material';
import CustomProgress from 'components/ui-new/custom-progress/custom-progress';
import { returnProgressColor } from 'pages/production/components/production-item/controls/helpers';

const ProductionProgressCell = (params) => {
  return (
    <Stack flexDirection="row" alignItems="center" justifyContent="center" gap="8px" width="100%" height="100%">
      <div>{params.value}%</div>
      <CustomProgress
        style={{ width: '77px' }}
        value={params.value}
        variant="determinate"
        type={returnProgressColor(params.value)}
      />
    </Stack>
  );
};

export default ProductionProgressCell;
