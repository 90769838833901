import { FC, SVGProps } from 'react';

export const Hierarchy1: FC<SVGProps<SVGSVGElement>> = ({ ...props }) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M13.3333 9.33341C14.0697 9.33341 14.6667 8.73646 14.6667 8.00008C14.6667 7.2637 14.0697 6.66675 13.3333 6.66675C12.597 6.66675 12 7.2637 12 8.00008C12 8.73646 12.597 9.33341 13.3333 9.33341Z"
      stroke="#292D32"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.3333 3.99992C14.0697 3.99992 14.6667 3.40297 14.6667 2.66659C14.6667 1.93021 14.0697 1.33325 13.3333 1.33325C12.597 1.33325 12 1.93021 12 2.66659C12 3.40297 12.597 3.99992 13.3333 3.99992Z"
      stroke="#292D32"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.3333 14.6667C14.0697 14.6667 14.6667 14.0697 14.6667 13.3333C14.6667 12.597 14.0697 12 13.3333 12C12.597 12 12 12.597 12 13.3333C12 14.0697 12.597 14.6667 13.3333 14.6667Z"
      stroke="#292D32"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.50016 10.3333C4.69678 10.3333 5.66683 9.36328 5.66683 8.16667C5.66683 6.97005 4.69678 6 3.50016 6C2.30355 6 1.3335 6.97005 1.3335 8.16667C1.3335 9.36328 2.30355 10.3333 3.50016 10.3333Z"
      stroke="#292D32"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M6 8H12" stroke="#292D32" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M12.0002 2.66675H9.3335C8.00016 2.66675 7.3335 3.33341 7.3335 4.66675V11.3334C7.3335 12.6667 8.00016 13.3334 9.3335 13.3334H12.0002"
      stroke="#292D32"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
