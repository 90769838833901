import { authAxiosInstance } from 'axios-config';
import { AuthTokens } from 'redux/auth.controller';
import { ResetPassword, SendResetPasswordEmail, ValidateResetPassword } from './auth.model';

export class AuthService {
  public static async login(credentials: { username: string; password: string }) {
    const { data } = await authAxiosInstance.post<AuthTokens>(`/auth/login`, credentials);
    return data;
  }

  public static async refreshToken(refreshToken: string) {
    const { data } = await authAxiosInstance.post<AuthTokens>(`/auth/refresh`, { refreshToken });
    return data;
  }

  public static async logout(refreshToken: string) {
    const { data } = await authAxiosInstance.post(`/auth/logout`, { refreshToken });
    return data;
  }

  public static async sendResetPasswordEmail(body: SendResetPasswordEmail) {
    const { data } = await authAxiosInstance.post(`/auth/send-reset-password-email`, body);
    return data;
  }

  public static async validateResetPassword(body: ValidateResetPassword) {
    const { data } = await authAxiosInstance.post(`/auth/validate-reset-password`, body);
    return data;
  }

  public static async resetPassword(body: ResetPassword) {
    const { data } = await authAxiosInstance.put(`/auth/reset-password`, body);
    return data;
  }
}
