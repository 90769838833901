import { combineReducers } from 'redux';
import { reducer as ProductConfigurationsModalsReducer } from 'pages/product-flow/pages/product/components/product-configurations-tabs/product-configurations-modals.controller';
import { reducer as EditCodesModalReducer } from 'pages/product-flow/pages/product/controllers/edit-codes-modal.controller';
import { reducer as productRootReducer } from './controllers/product-root.controller';
import { reducer as productConfigurationsReducer } from './controllers/product-configurations.controller';
import { reducer as productDescriptionReducer } from './controllers/product-description.controller';
import { reducer as productFileReducer } from './controllers/product-file.controller';
import { reducer as productMediaReducer } from './controllers/product-media.controller';
import { reducer as productWorkflowReducer } from './controllers/product-workflow.controller';
import { reducer as productParameterReducer } from './controllers/product-parameter.controller';
import { reducer as productVariantReducer } from './controllers/product-variant.controller';
import { reducer as productVersionReducer } from './controllers/product-version.controller';
import { reducer as ProductPreviewModalReducer } from './controllers/product-preview.controller';
import { reducer as ProductFlowLayoutReducer } from '../../product-flow-layout/product-flow-layout.controller';

export const reducer = combineReducers({
  product_configurations: productConfigurationsReducer,
  product_descrioption: productDescriptionReducer,
  product_file: productFileReducer,
  product_flow_layout: ProductFlowLayoutReducer,
  product_media: productMediaReducer,
  product_workflow: productWorkflowReducer,
  product_parameter: productParameterReducer,
  product_variant: productVariantReducer,
  product_configurations_modals: ProductConfigurationsModalsReducer,
  product_preview: ProductPreviewModalReducer,
  product_version: productVersionReducer,
  product_root: productRootReducer,
  edit_codes_modal: EditCodesModalReducer,
});
