import { FC, SVGProps } from 'react';

export const ArrowSquareRight: FC<SVGProps<SVGSVGElement>> = ({ ...props }) => (
  <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M6.00065 15.1666H10.0007C13.334 15.1666 14.6673 13.8333 14.6673 10.4999V6.49992C14.6673 3.16659 13.334 1.83325 10.0007 1.83325H6.00065C2.66732 1.83325 1.33398 3.16659 1.33398 6.49992V10.4999C1.33398 13.8333 2.66732 15.1666 6.00065 15.1666Z"
      stroke="#5E7285"
      strokeWidth="0.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.16627 9.90922L6.73738 9.97778C7.10393 9.98699 7.39528 9.70104 7.38997 9.33819L7.35421 7.8184C7.3489 7.45556 7.64025 7.16962 8.00152 7.1747L10.4869 7.23336"
      stroke="#5E7285"
      strokeWidth="0.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.03589 5.45537L11.408 6.99585L9.86754 9.36796"
      stroke="#5E7285"
      strokeWidth="0.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
