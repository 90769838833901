import { FC, SVGProps } from 'react';

export const CanceledIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle cx="10" cy="10" r="9.25" stroke={props.stroke || '#878B92'} strokeWidth="1.5" />
    <path
      d="M6.31372 13.6225L13.6863 6.25"
      stroke={props.stroke || '#878B92'}
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.6863 13.6225L6.31372 6.25"
      stroke={props.stroke || '#878B92'}
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
