import { FC } from 'react';
import { UserShortModel } from 'services/user.model';
import OptionItem from 'components/ui-new/dropdown-user-search-selector/components/option-item/option-item';
import s from './list-for-task.module.scss';

type OwnProps = {
  value: string;
  keyword: string;
  isBiggerPopup?: boolean;
  isAllUsersAreSuggested?: boolean;
  otherUsersArray: UserShortModel[];
  suggestedUsersArray: UserShortModel[];
  onOptionClick: (userId: string, user: UserShortModel) => void;
};

export const ListForTask: FC<OwnProps> = ({
  value,
  keyword,
  isBiggerPopup,
  otherUsersArray,
  suggestedUsersArray,
  isAllUsersAreSuggested,
  onOptionClick,
}) => {
  const isShowOtherUsers = Boolean(otherUsersArray.length) && !isAllUsersAreSuggested;

  return (
    <>
      {Boolean(suggestedUsersArray.length) && (
        <div>
          {!isAllUsersAreSuggested && <p className={s.option_text}>Suggested candidates</p>}
          <div>
            {suggestedUsersArray.map((user) => (
              <OptionItem
                key={user.id}
                keyword={keyword}
                position={user.position}
                last_name={user.last_name}
                first_name={user.first_name}
                selected={value === user.id}
                department={user.department}
                isBiggerPopup={isBiggerPopup}
                avatarImageSource={user.avatar_image_url}
                onClick={() => onOptionClick(user.id, user)}
              />
            ))}
          </div>
        </div>
      )}

      {isShowOtherUsers && Boolean(suggestedUsersArray.length) && <div className={s.divider} />}

      {isShowOtherUsers && <p className={s.option_text}>Other users</p>}
      {isShowOtherUsers && (
        <div>
          {otherUsersArray.map((user) => {
            return (
              <OptionItem
                key={user.id}
                keyword={keyword}
                position={user.position}
                last_name={user.last_name}
                first_name={user.first_name}
                selected={value === user.id}
                department={user.department}
                isBiggerPopup={isBiggerPopup}
                avatarImageSource={user.avatar_image_url}
                onClick={() => onOptionClick(user.id, user)}
              />
            );
          })}
        </div>
      )}
    </>
  );
};
