export enum FilterCriteriaEnum {
  Sku = 'sku',
  Tags = 'tags',
  Vendor = 'vendor',
  Options = 'options',
  Category = 'category',
  LastUpdate = 'last_update',
  ProductName = 'product_name',
  PublishDate = 'publish_date',
  ContainsDraft = 'contains_draft',
  ProductionStatus = 'production_status',
}

export enum SingleOptionEnum {
  All = 'all',
  Yes = 'yes',
  No = 'no',
}

export enum SortOptionsEnum {
  Name = 'productName',
  Type = 'productType',
  Date = 'publishedDate',
}
