/* eslint-disable @typescript-eslint/no-unused-vars */
import { Typography } from '@mui/material';
import { CSSProperties } from 'react';
import styles from './subtitle.module.scss';

export type SubtitleProps = React.HTMLAttributes<HTMLDivElement> & {
  className?: string;
  icon?: string | React.ReactNode;
  text?: string | React.ReactNode;
  fontSize?: FontSize;
  isCenterModeWithoutTitle?: boolean;
};
type FontSize = 'small' | 'newSmall' | 'medium' | 'newMedium' | 'large' | 'newLarge' | 'extraLarge';

function Subtitle({
  className = '',
  text = '',
  icon = '',
  fontSize = 'medium',
  isCenterModeWithoutTitle = false,
  ...rest
}: SubtitleProps) {
  const fontSizes: { [key in FontSize]: CSSProperties } = {
    small: {
      fontSize: '14px',
      fontWeight: '600',
      lineHeight: '22px',
    },
    medium: {
      fontSize: '16px',
      fontWeight: '600',
      lineHeight: '24px',
    },
    newSmall: {
      fontSize: '14px',
      fontWeight: '500',
      lineHeight: '130%',
    },
    newMedium: {
      fontSize: '18px',
      fontWeight: '600',
      lineHeight: '150%',
    },
    large: {
      fontSize: '22px',
      fontWeight: '700',
      lineHeight: '150%',
    },
    extraLarge: {
      fontSize: '28px',
      fontWeight: '700',
      lineHeight: '130%',
    },
    newLarge: {
      fontSize: '20px',
      fontWeight: '700',
      lineHeight: '140%',
    },
  };

  return (
    <div className={`${styles.subtitle} ${className}`} {...rest}>
      {icon && <div className={styles.bar}>{icon}</div>}
      {!isCenterModeWithoutTitle && (
        <div className={styles.text}>
          <Typography style={fontSizes[fontSize]}>{text}</Typography>
        </div>
      )}
    </div>
  );
}

export default Subtitle;
