import { connect } from 'react-redux';
import { AppState } from 'redux/store';
import MoveToIcon from 'icons/move-to';
import { CrossIcon } from 'icons/cross';
import { TrashIcon } from 'icons/trash';
import { useEffect, useRef, useState } from 'react';
import { PageName } from 'types/common-enums';
import { FormControlLabel } from '@mui/material';
import Checkbox from 'components/checkbox/checkbox';
import { Actions as DepartmentsActions } from 'pages/departments/departments.controller';
import ActionItem from 'pages/production/actions-panel/components/action-item/action-item';
import { Actions as MultiselectActions, Selectors as MultiselectSelectors } from 'pages/products/multiselect.controller';
import s from './actions-panel.module.scss';

type StateProps = {
  selectedItemsCount: number;
  isEnableMultiActions: boolean;
};
type DispatchProps = {
  deselectAll: () => void;
  openMoveModal: () => void;
  selectAll: (page: PageName) => void;
  openDeleteConfirmationModal: () => void;
  toggleIsEnableMultiActions: (value: boolean) => void;
};
type Props = StateProps & DispatchProps;

const ActionsPanel = ({
  selectedItemsCount,
  isEnableMultiActions,
  selectAll,
  deselectAll,
  openMoveModal,
  toggleIsEnableMultiActions,
  openDeleteConfirmationModal,
}: Props) => {
  const [inStickyPosition, setInStickyPosition] = useState(false);
  const stickyRef = useRef<HTMLDivElement | null>(null);
  const selectButtonText = isEnableMultiActions ? `${selectedItemsCount} selected` : 'Select';

  const checkStickyPosition = () => {
    const stickyElement = stickyRef.current;
    const rect = stickyElement?.getBoundingClientRect();

    if (window.innerWidth < 1055) {
      setInStickyPosition(rect?.top === 55);
    } else {
      setInStickyPosition(rect?.top === 0);
    }
  };

  const handleSelect = () => {
    if (selectedItemsCount) {
      deselectAll();
    } else {
      selectAll(PageName.Departments);
    }
  };

  const handleOnClick = () => {
    if (isEnableMultiActions) {
      handleSelect();
    } else {
      toggleIsEnableMultiActions(true);
    }
  };

  useEffect(() => {
    if (stickyRef.current) {
      checkStickyPosition();
      window.addEventListener('scroll', checkStickyPosition);
    }
    return () => {
      window.removeEventListener('scroll', checkStickyPosition);
    };
  }, []);

  useEffect(() => {
    checkStickyPosition();
  }, [selectedItemsCount]);

  return (
    <div
      ref={stickyRef}
      className={s.container}
      data-in-position={inStickyPosition}
      style={{
        maxWidth: isEnableMultiActions ? '100%' : '110px',
        position: selectedItemsCount || isEnableMultiActions ? 'sticky' : 'static',
      }}
    >
      <div className={s.left_col}>
        <FormControlLabel
          className={s.form_control}
          label={<div className={s.select_button}>{selectButtonText}</div>}
          control={
            <Checkbox
              size="medium"
              className={s.checkbox}
              data-show-checkbox={isEnableMultiActions}
              checked={Boolean(selectedItemsCount)}
              onClick={handleOnClick}
            />
          }
        />
        <ActionItem
          text="Close"
          Icon={CrossIcon}
          enableWithoutVisualEffect
          visible={Boolean(isEnableMultiActions)}
          onClick={() => toggleIsEnableMultiActions(false)}
        />
      </div>

      <div className={s.actions_container}>
        <ActionItem
          text="Move to"
          Icon={MoveToIcon}
          withLeftDivider={false}
          visible={isEnableMultiActions}
          enable={Boolean(selectedItemsCount)}
          onClick={openMoveModal}
        />
        <ActionItem
          text="Delete"
          Icon={TrashIcon}
          visible={isEnableMultiActions}
          enable={Boolean(selectedItemsCount)}
          onClick={openDeleteConfirmationModal}
        />
      </div>
    </div>
  );
};

const mapStatToProps = (state: AppState): StateProps => ({
  isEnableMultiActions: state.products.multiselect.isMultiselect,
  selectedItemsCount: MultiselectSelectors.selectedDepartmentsAndPositionsCount(state),
});
const mapDispatchToProps: DispatchProps = {
  selectAll: MultiselectActions.selectAll,
  deselectAll: MultiselectActions.deselectAll,
  openMoveModal: DepartmentsActions.openMoveModal,
  toggleIsEnableMultiActions: MultiselectActions.changeMultiselectMode,
  openDeleteConfirmationModal: DepartmentsActions.openDeleteConfirmationModal,
};

export default connect(mapStatToProps, mapDispatchToProps)(ActionsPanel);
