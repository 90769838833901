import { ProductOptionTemplate, ProductOptionTemplateValue } from 'services/product-option-templates.model';
import { ProductOptionTemplateService } from 'services/product-option-templates.service';
import { v4 as uuidv4 } from 'uuid';
import { StateController } from 'state-controller';
import { AppState, GetStateFunction } from 'redux/store';
import { ItemInterface } from 'react-sortablejs';
import { validators } from 'utils/validator';
import { notify } from 'notifications';

export type FieldsValidation = {
  template: string | null;
  parameter: string | null;
  emptyState: string | null;
};

export type FieldsValidated = { [key: string]: string | null };

export type TemplateManagementState = {
  isProcessing: boolean;
  isOpen: boolean;
  isCreateProcessing: boolean;
  selectedTemplateId: string;
  fieldsValidation: FieldsValidation;
  productOptionTemplate: Array<ProductOptionTemplate>;
  applyTemplateModal: {
    isOpen: boolean;
    selectedApplyTemplateId: string;
  };
};

const defaultState: TemplateManagementState = {
  isProcessing: false,
  isOpen: false,
  isCreateProcessing: false,
  selectedTemplateId: '',
  productOptionTemplate: [],
  fieldsValidation: {
    template: null,
    parameter: null,
    emptyState: null,
  },
  applyTemplateModal: {
    isOpen: false,
    selectedApplyTemplateId: '',
  },
};

const stateController = new StateController<TemplateManagementState>('TEMPLATE_MANAGEMENT_STATE', defaultState);

export class Actions {
  public static init() {
    return async (dispatch) => {
      try {
        dispatch(stateController.setState({ isProcessing: true }));
        const productOptionTemplate = await ProductOptionTemplateService.getAll();
        dispatch(stateController.setState({ productOptionTemplate }));
        dispatch(Actions.setSelectedTemplateId(productOptionTemplate[0]?.id));
        dispatch(Actions.productOptionTemplateValidation());
      } finally {
        dispatch(stateController.setState({ isProcessing: false }));
      }
    };
  }

  public static closeModal() {
    return (dispatch) => {
      dispatch(stateController.setState(defaultState));
    };
  }

  public static openTemplateManagementModal() {
    return (dispatch) => {
      dispatch(stateController.setState({ isOpen: true }));
      dispatch(Actions.init());
    };
  }

  public static openApplyTemplateModal() {
    return (dispatch) => {
      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          applyTemplateModal: {
            isOpen: true,
          },
        })),
      );
      dispatch(Actions.init());
    };
  }

  public static closeApplyTemplateModal() {
    return (dispatch) => {
      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          applyTemplateModal: {
            isOpen: false,
          },
        })),
      );
    };
  }

  public static setSelectedApplyTemplateId(newId: string) {
    return (dispatch) => {
      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          applyTemplateModal: {
            ...prev.applyTemplateModal,
            selectedApplyTemplateId: newId,
          },
        })),
      );
    };
  }

  public static setSelectedTemplateId(newId: string) {
    return (dispatch) => {
      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          selectedTemplateId: newId,
        })),
      );
    };
  }

  public static addProductOptionTemplate(productOptionName: string) {
    return (dispatch) => {
      const temporaryId = uuidv4();

      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          productOptionTemplate: [
            {
              id: temporaryId,
              name: productOptionName,
              created_at: new Date(),
              product_option_template_values: [],
            },
            ...prev.productOptionTemplate,
          ],
        })),
      );

      dispatch(Actions.setSelectedTemplateId(temporaryId));
      dispatch(Actions.productOptionTemplateValidation());
    };
  }

  public static addProductOptionValueTemplate(productOptionValueName: string, productOptionId: string) {
    return (dispatch, getState: GetStateFunction) => {
      const { productOptionTemplate } = getState().template_management_modal;
      const selectedOption = productOptionTemplate.find((item) => item.id === productOptionId);

      if (selectedOption) {
        const newValue: ProductOptionTemplateValue = {
          id: uuidv4(),
          created_at: new Date(),
          value: productOptionValueName,
          product_option_template_id: selectedOption.id,
          order: selectedOption.product_option_template_values.length,
        };

        const updatedOption = {
          ...selectedOption,
          product_option_template_values: [...selectedOption.product_option_template_values, newValue],
        };

        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            productOptionTemplate: prev.productOptionTemplate.map((item) => (item.id !== productOptionId ? item : updatedOption)),
          })),
        );
        dispatch(Actions.productOptionTemplateValidation());
      }
    };
  }

  public static updateProductOptionTemplateName(newName: string) {
    return (dispatch, getState: GetStateFunction) => {
      const { productOptionTemplate, selectedTemplateId } = getState().template_management_modal;
      dispatch(Actions.productOptionTemplateValidation());
      const selectedOption = productOptionTemplate.find((item) => item.id === selectedTemplateId);

      if (selectedOption) {
        const updatedOption = {
          ...selectedOption,
          name: newName,
        };
        const updatedTemplate = productOptionTemplate.map((item) => (item.id === selectedTemplateId ? updatedOption : item));

        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            productOptionTemplate: updatedTemplate,
          })),
        );
        dispatch(Actions.productOptionTemplateValidation());
      }
    };
  }

  public static updateProductOptionValueName(productOptionValueId: string, newName: string) {
    return (dispatch, getState: GetStateFunction) => {
      const { productOptionTemplate, selectedTemplateId } = getState().template_management_modal;
      const selectedOption = productOptionTemplate.find((item) => item.id === selectedTemplateId);

      if (selectedOption) {
        const valuesArray = selectedOption.product_option_template_values;
        const selectedValueIndex = valuesArray.findIndex((value) => value.id === productOptionValueId);

        if (selectedValueIndex !== -1) {
          const updatedValue = {
            ...valuesArray[selectedValueIndex],
            value: newName,
          };

          const updatedValuesArray = [...valuesArray];
          updatedValuesArray[selectedValueIndex] = updatedValue;

          const updatedOption = {
            ...selectedOption,
            product_option_template_values: updatedValuesArray,
          };

          const updatedTemplateWithValues = productOptionTemplate.map((item) => {
            return item.id === selectedTemplateId ? updatedOption : item;
          });

          dispatch(
            stateController.setState((prev) => ({
              ...prev,
              productOptionTemplate: updatedTemplateWithValues,
            })),
          );
          dispatch(Actions.productOptionTemplateValidation());
        }
      }
    };
  }

  public static deleteProductOptionTemplateFromList(productOptionId: string) {
    return (dispatch, getState: GetStateFunction) => {
      const { productOptionTemplate, selectedTemplateId } = getState().template_management_modal;
      const updatedProductOptionTemplate = productOptionTemplate.filter((item) => item.id !== productOptionId);
      let newSelectedTemplateId = selectedTemplateId;

      if (selectedTemplateId === productOptionId) {
        if (updatedProductOptionTemplate.length > 0) {
          newSelectedTemplateId = updatedProductOptionTemplate[0].id;
        } else {
          newSelectedTemplateId = '';
        }
      }

      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          productOptionTemplate: updatedProductOptionTemplate,
          selectedTemplateId: newSelectedTemplateId,
        })),
      );
      dispatch(Actions.productOptionTemplateValidation());
    };
  }

  public static deleteProductOptionValueTemplateFromList(productOptionValueId: string) {
    return (dispatch, getState: GetStateFunction) => {
      const { productOptionTemplate, selectedTemplateId } = getState().template_management_modal;

      const selectedOption = productOptionTemplate.find((item) => item.id === selectedTemplateId);

      if (selectedOption) {
        const updatedValues = selectedOption.product_option_template_values
          .filter((value) => value.id !== productOptionValueId)
          .map((item, index) => ({
            ...item,
            order: index,
          }));

        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            productOptionTemplate: prev.productOptionTemplate.map((item) => {
              return item.id === selectedTemplateId
                ? {
                    ...item,
                    product_option_template_values: updatedValues,
                  }
                : item;
            }),
          })),
        );
        dispatch(Actions.productOptionTemplateValidation());
      }
    };
  }

  public static onSetProductOptionValues(updatedValues: ItemInterface[]) {
    return (dispatch, getState: GetStateFunction) => {
      const { productOptionTemplate, selectedTemplateId } = getState().template_management_modal;
      const selectedOption = productOptionTemplate.find((item) => item.id === selectedTemplateId);

      if (selectedOption) {
        const updatedOption = {
          ...selectedOption,
          product_option_template_values: updatedValues,
        };

        const updatedTemplateWithValues = productOptionTemplate.map((item) => {
          return item.id === selectedTemplateId ? updatedOption : item;
        });

        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            productOptionTemplate: updatedTemplateWithValues,
          })),
        );
      }
    };
  }

  public static onCreateProductOptionTemplate() {
    return async (dispatch, getState: GetStateFunction) => {
      const { productOptionTemplate } = getState().template_management_modal;
      try {
        dispatch(stateController.setState({ isCreateProcessing: true }));
        const saveProductOptionTemplate = await ProductOptionTemplateService.manage(productOptionTemplate);
        notify.success(saveProductOptionTemplate.message);
      } finally {
        dispatch(stateController.setState({ isCreateProcessing: false }));
      }
    };
  }

  public static productOptionTemplateValidation() {
    return (dispatch, getState) => {
      const { productOptionTemplate } = getState().template_management_modal;

      const fieldsValidated: FieldsValidated = { emptyState: null };

      if (!productOptionTemplate || productOptionTemplate.length === 0) {
        fieldsValidated.emptyState = 'No templates';
      } else {
        productOptionTemplate.forEach((item, index) => {
          const [, nameErrorMessage] = validators.isNotEmpty(item.name, `No name for template ${index + 1}`);
          fieldsValidated[`template${index}`] = nameErrorMessage;

          if (item.name.length >= 256) {
            fieldsValidated[`template${index}`] = 'Template name should not exceed 255 characters';
          }

          if (!item.product_option_template_values || item.product_option_template_values.length === 0) {
            fieldsValidated[`parameter${index}`] = `No values for template ${index + 1}`;
          } else {
            item.product_option_template_values.forEach((param, paramIndex) => {
              const [, paramErrorMessage] = validators.isNotEmpty(
                param.value,
                `Value ${paramIndex + 1} of template ${index + 1} is missing`,
              );
              if (param.value.length >= 256) {
                fieldsValidated[`parameter${index}_${paramIndex}`] = `Value ${paramIndex + 1} of template ${
                  index + 1
                } should not exceed 255 characters`;
              } else {
                fieldsValidated[`parameter${index}_${paramIndex}`] = paramErrorMessage;
              }
            });
          }
        });
      }

      dispatch(
        stateController.setState((prevState) => ({
          ...prevState,
          fieldsValidation: fieldsValidated,
        })),
      );
    };
  }
}

export class Selectors {
  public static findProductOptionTemplateById(state: AppState) {
    const { productOptionTemplate, selectedTemplateId } = state.template_management_modal;
    return productOptionTemplate.find((item) => item.id === selectedTemplateId);
  }

  public static isHadWarnings(state: AppState) {
    const { fieldsValidation } = state.template_management_modal;
    return Object.values(fieldsValidation).every((warning) => warning === null);
  }

  public static filteredWarnings(state: AppState): string[] {
    const { fieldsValidation } = state.template_management_modal;
    return Object.values(fieldsValidation).filter((warning) => !!warning) as string[];
  }
}

export const reducer = stateController.getReducer();
