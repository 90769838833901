import { FC, SVGProps } from 'react';

export const CategoryLargeIcon: FC<SVGProps<SVGSVGElement>> = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M29.3333 22.6667V13.3333C29.3333 10.3878 26.9455 8 24 8H20.4444C19.2905 8 18.1676 7.62572 17.2445 6.93333L14.7556 5.06667C13.8324 4.37428 12.7095 4 11.5556 4H8.00001C5.05449 4 2.66667 6.38781 2.66667 9.33333V22.6667C2.66667 25.6122 5.05449 28 8 28H24C26.9455 28 29.3333 25.6122 29.3333 22.6667Z"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
