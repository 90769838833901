/* eslint-disable no-await-in-loop */
import {
  ChankedStreamInfo,
  CloseChankedStreamCreateData,
  OpenChankedStreamCreateData,
  UploadChankInfo,
} from 'services/product-configuration-file.model';
import { getChunks } from 'utils/chunks';
import { getMimeType } from 'utils/mime-type';

export async function simpleFileUploading(
  file: File,
  configId: string,
  callback: (data: FormData, progress: (e: any) => void) => any,
  progress: (e: any) => void,
) {
  const formData = new FormData();
  formData.append('file', file, file.name);
  formData.append('product_configuration_id', configId);

  try {
    const newFile = await callback(formData, (e) => progress(e));
    return newFile;
  } catch {
    return null;
  }
}

export type ChunkedUplodaing = {
  completeID: string;
  file: File;
  chunkSize: number;
  openChunkStreamCallback: (body: OpenChankedStreamCreateData) => Promise<ChankedStreamInfo>;
  uploadChunkCallback: (body: FormData, onProgress) => Promise<UploadChankInfo>;
  chunkUploadingProgress: (progress: number) => void;
  completeChunkStreamCallback: (id: string, body: CloseChankedStreamCreateData) => Promise<any>;
  abortChunkCallback: (body: ChankedStreamInfo) => Promise<void>;
};
export async function chunkedFileUploading({
  completeID,
  file,
  chunkSize,
  openChunkStreamCallback,
  uploadChunkCallback,
  chunkUploadingProgress,
  completeChunkStreamCallback,
  abortChunkCallback,
}: ChunkedUplodaing): Promise<any> {
  const contentType = getMimeType(file.name.split('.').pop() || '');
  const chunks = getChunks(file, chunkSize);
  const progressArr = Array(chunks.length);

  let chunkStreamInfo: ChankedStreamInfo;
  const loadedChunksInfo: UploadChankInfo[] = [];

  try {
    // Open chunk stream
    chunkStreamInfo = await openChunkStreamCallback({
      contentType,
      fileName: file.name,
    });

    // Create chunks generator
    const generator = chunkUploadGen(
      chunks,
      uploadChunkCallback,
      (e, index) => {
        const newProgress = Math.round((e.loaded / e.total) * 100);
        progressArr[index] = newProgress;
        const progressSumm = progressArr.reduce((prev, current) => prev + current, 0) / chunks.length;
        chunkUploadingProgress(progressSumm);
      },
      chunkStreamInfo,
    );

    let c = generator.next();

    // Uploading chunks step by step
    while (!c.done) {
      try {
        const data = await c.value;
        loadedChunksInfo.push(data);
        c = generator.next();
      } catch (err) {
        generator.throw(err);
        await abortChunkCallback({
          fileKey: chunkStreamInfo.fileKey,
          uploadId: chunkStreamInfo.uploadId,
        });
        return null;
      }
    }

    // Complete chunk uploading and get full uploaded file
    const completedFile = await completeChunkStreamCallback(completeID, {
      contentType,
      fileKey: chunkStreamInfo.fileKey,
      fileName: file.name,
      parts: loadedChunksInfo.sort((a, b) => {
        if (Number(a.PartNumber) > Number(b.PartNumber)) return 1;
        if (Number(a.PartNumber) === Number(b.PartNumber)) return 0;
        return -1;
      }),
      size: file.size,
      uploadId: chunkStreamInfo.uploadId,
    });

    return completedFile;
  } catch {
    return null;
  }
}

export function* chunkUploadGen(
  chunks,
  callback: (data: FormData, progress: (e: any) => void) => Promise<any>,
  progress: (e: any, index: number) => void,
  chunkStreamInfo,
) {
  for (let i = 0; i < chunks.length; i += 1) {
    const chunkformData = new FormData();
    chunkformData.append('file', chunks[i]);
    chunkformData.append('fileKey', chunkStreamInfo.fileKey);
    chunkformData.append('uploadId', chunkStreamInfo.uploadId);
    chunkformData.append('partNumber', String(i + 1));

    yield callback(chunkformData, (e) => progress(e, i));
  }
}
