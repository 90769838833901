import { FC, SVGProps } from 'react';

export const GalleryIcon: FC<SVGProps<SVGSVGElement>> = ({ ...props }) => (
  <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M5.9 15.5H10.1C13.6 15.5 15 14.1 15 10.6V6.4C15 2.9 13.6 1.5 10.1 1.5H5.9C2.4 1.5 1 2.9 1 6.4V10.6C1 14.1 2.4 15.5 5.9 15.5Z"
      stroke="#829DB6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.9 7.10078C6.6732 7.10078 7.3 6.47398 7.3 5.70078C7.3 4.92758 6.6732 4.30078 5.9 4.30078C5.1268 4.30078 4.5 4.92758 4.5 5.70078C4.5 6.47398 5.1268 7.10078 5.9 7.10078Z"
      stroke="#829DB6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.46875 13.3648L4.91975 11.0478C5.47275 10.6768 6.27075 10.7188 6.76775 11.1458L6.99875 11.3488C7.54475 11.8178 8.42675 11.8178 8.97275 11.3488L11.8847 8.8498C12.4307 8.3808 13.3128 8.3808 13.8588 8.8498L14.9998 9.8298"
      stroke="#829DB6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
