import { FC, SVGProps } from 'react';

export const PlayCircleIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M7.98002 15.1668C11.6619 15.1668 14.6467 12.1821 14.6467 8.50016C14.6467 4.81826 11.6619 1.8335 7.98002 1.8335C4.29812 1.8335 1.31335 4.81826 1.31335 8.50016C1.31335 12.1821 4.29812 15.1668 7.98002 15.1668Z"
      stroke={props.stroke || '#fff'}
      strokeWidth="1.2px"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.82666 8.65325V7.53992C5.82666 6.15325 6.80666 5.58659 8.00666 6.27992L8.97333 6.83992L9.93999 7.39992C11.14 8.09325 11.14 9.22659 9.93999 9.91992L8.97333 10.4799L8.00666 11.0399C6.80666 11.7333 5.82666 11.1666 5.82666 9.77992V8.65325Z"
      stroke={props.stroke || '#fff'}
      strokeWidth="1.2px"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
