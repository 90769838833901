import { FC, SVGProps } from 'react';

export const StatisticsIcon: FC<SVGProps<SVGSVGElement>> = ({ ...props }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M8.71429 3H4.42857C3.63959 3 3 3.63959 3 4.42857V8.71429C3 9.50326 3.63959 10.1429 4.42857 10.1429H8.71429C9.50326 10.1429 10.1429 9.50326 10.1429 8.71429V4.42857C10.1429 3.63959 9.50326 3 8.71429 3Z"
      stroke="#91979F"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.144 3H15.8583C15.0693 3 14.4297 3.63959 14.4297 4.42857V8.71429C14.4297 9.50326 15.0693 10.1429 15.8583 10.1429H20.144C20.933 10.1429 21.5725 9.50326 21.5725 8.71429V4.42857C21.5725 3.63959 20.933 3 20.144 3Z"
      stroke="#91979F"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.71429 14.4285H4.42857C3.63959 14.4285 3 15.0681 3 15.857V20.1428C3 20.9317 3.63959 21.5713 4.42857 21.5713H8.71429C9.50326 21.5713 10.1429 20.9317 10.1429 20.1428V15.857C10.1429 15.0681 9.50326 14.4285 8.71429 14.4285Z"
      stroke="#91979F"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.144 14.4285H15.8583C15.0693 14.4285 14.4297 15.0681 14.4297 15.857V20.1428C14.4297 20.9317 15.0693 21.5713 15.8583 21.5713H20.144C20.933 21.5713 21.5725 20.9317 21.5725 20.1428V15.857C21.5725 15.0681 20.933 14.4285 20.144 14.4285Z"
      stroke="#91979F"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <defs>
      <clipPath id="clip0_167_606">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
