import { FC, SVGProps } from 'react';

export const Calendar2Icon: FC<SVGProps<SVGSVGElement>> = ({ ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none" {...props}>
    <path
      d="M5.69995 1.5V3.59999"
      stroke="#141414"
      strokeWidth="1.3"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.3 1.5V3.59999"
      stroke="#141414"
      strokeWidth="1.3"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.55005 6.46317H14.45"
      stroke="#141414"
      strokeWidth="1.3"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.7999 6.05009V12.0001C14.7999 14.1 13.7499 15.5 11.2999 15.5H5.69993C3.24994 15.5 2.19995 14.1 2.19995 12.0001V6.05009C2.19995 3.9501 3.24994 2.55011 5.69993 2.55011H11.2999C13.7499 2.55011 14.7999 3.9501 14.7999 6.05009Z"
      stroke="#141414"
      strokeWidth="1.3"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
