import { FC, SVGProps } from 'react';

export const ProductIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect x="1.25" y="2" width="13.5" height="13.5" rx="2.5" stroke="#141414" />
    <path d="M4.65723 12.0527H11.3678" stroke="#141414" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M9.41992 4.5V5.86C9.41992 6.608 10.0319 7.22 10.7799 7.22H12.1399"
      stroke="#141414"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
