import { baseAxiosInstance } from 'axios-config';
import {
  ChankedStreamInfo,
  CloseChankedStreamCreateData,
  CloseChankedStreamResponseData,
  CreateProductConfigurationFileData,
  OpenChankedStreamCreateData,
  ProductConfigurationFile,
  UploadChankInfo,
} from 'services/product-configuration-file.model';

export class ProductConfigurationFileService {
  public static async getAll(id: string) {
    const { data } = await baseAxiosInstance.get<ProductConfigurationFile[]>(`/product-configuration-files/all`, {
      params: {
        product_configuration_id: id,
      },
    });
    return data;
  }

  public static async getById(id: string) {
    const { data } = await baseAxiosInstance.get<ProductConfigurationFile>(`/product-configuration-files/${id}`);
    return data;
  }

  public static async delete(id: string) {
    const { data } = await baseAxiosInstance.delete(`/product-configuration-files/${id}`);
    return data;
  }

  public static async uploadSmallFile(body: CreateProductConfigurationFileData | FormData, onProgress) {
    const { data } = await baseAxiosInstance.post<ProductConfigurationFile>(`/product-configuration-files/create`, body, {
      onUploadProgress: onProgress,
    });
    return data;
  }

  public static async openChankedStream(body: OpenChankedStreamCreateData) {
    const { data } = await baseAxiosInstance.post<ChankedStreamInfo>(
      `/product-configuration-files/create-multipart-upload`,
      body,
    );
    return data;
  }

  // FormData content === UploadChankCreateData from models
  public static async uploadChunk(body: FormData, onProgress) {
    const { data } = await baseAxiosInstance.post<UploadChankInfo>(`/product-configuration-files/upload-part`, body, {
      onUploadProgress: onProgress,
    });
    return data;
  }

  public static async completeChankedStream(id: string, body: CloseChankedStreamCreateData) {
    const { data } = await baseAxiosInstance.post<CloseChankedStreamResponseData>(
      `/product-configuration-files/complete-multipart-upload/${id}`,
      body,
    );
    return data;
  }

  public static async abortChankedStream(body: ChankedStreamInfo) {
    const { data } = await baseAxiosInstance.post(`/product-configuration-files/abort-multipart-upload`, body);
    return data;
  }
}
