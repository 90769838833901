import { FC, SVGProps } from 'react';

export const CategoriesSmall: FC<SVGProps<SVGSVGElement>> = ({ ...props }) => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g id="Icons/XXS/Line/Category">
      <path
        id="Vector"
        d="M5.76 2.93L5.75999 2.93001L5.76211 2.9328C5.76757 2.93998 5.77329 2.94756 5.77928 2.95551C5.86142 3.06445 5.99415 3.24049 6.19926 3.36446C6.43374 3.50618 6.70036 3.55 7 3.55H8.5C9.50393 3.55 9.911 3.68881 10.1111 3.88891C10.3112 4.089 10.45 4.49607 10.45 5.5V8.5C10.45 9.50393 10.3112 9.911 10.1111 10.1111C9.911 10.3112 9.50393 10.45 8.5 10.45H3.5C2.49607 10.45 2.089 10.3112 1.88891 10.1111C1.68881 9.911 1.55 9.50393 1.55 8.5V3.5C1.55 2.49607 1.68881 2.089 1.88891 1.88891C2.089 1.68881 2.49607 1.55 3.5 1.55H4.25C4.57739 1.55 4.69182 1.59934 4.74115 1.62897C4.80349 1.66642 4.85437 1.7225 5.01 1.93L5.76 2.93Z"
        stroke="#878B92"
        strokeWidth="1.1"
        strokeMiterlimit="10"
      />
    </g>
  </svg>
);
