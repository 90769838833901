import { FC, SVGProps } from 'react';

export const ArrowLongLeftIcon: FC<SVGProps<SVGSVGElement>> = ({ fill = '#878B92', ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
    <path
      d="M17 9.3C17.3866 9.3 17.7 9.6134 17.7 10C17.7 10.3866 17.3866 10.7 17 10.7V9.3ZM2.50503 10.495C2.23166 10.2216 2.23166 9.77839 2.50503 9.50503L6.9598 5.05025C7.23316 4.77689 7.67638 4.77689 7.94975 5.05025C8.22311 5.32362 8.22311 5.76684 7.94975 6.0402L3.98995 10L7.94975 13.9598C8.22311 14.2332 8.22311 14.6764 7.94975 14.9497C7.67638 15.2231 7.23316 15.2231 6.9598 14.9497L2.50503 10.495ZM17 10.7H3V9.3H17V10.7Z"
      fill={fill}
    />
  </svg>
);
