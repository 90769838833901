import { FC, SVGProps } from 'react';

export const PriorityHight2Icon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_5823_31858)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9997 5.16683C9.42239 5.16683 7.33305 7.25617 7.33305 9.8335C7.33305 13.1472 4.64676 15.8335 1.33306 15.8335L0.666108 15.8335C0.297917 15.8335 -0.000558866 15.535 -0.000558882 15.1668C-0.000558898 14.7986 0.297918 14.5002 0.666108 14.5002L1.33306 14.5002C3.91038 14.5002 5.99972 12.4108 5.99972 9.8335C5.99972 6.51979 8.68601 3.8335 11.9997 3.8335L13.7239 3.8335L12.1953 2.3049C11.9349 2.04455 11.9349 1.62244 12.1953 1.3621C12.4556 1.10175 12.8777 1.10175 13.1381 1.3621L15.8047 4.02876C15.9298 4.15378 16 4.32335 16 4.50016C16 4.67698 15.9298 4.84654 15.8047 4.97157L13.1381 7.63824C12.8777 7.89858 12.4556 7.89858 12.1953 7.63824C11.9349 7.37789 11.9349 6.95578 12.1953 6.69543L13.7239 5.16683L11.9997 5.16683Z"
        fill="#F06292"
      />
    </g>
    <defs>
      <clipPath id="clip0_5823_31858">
        <rect width="16" height="16" fill="white" transform="translate(0 16.5) rotate(-90)" />
      </clipPath>
    </defs>
  </svg>
);
