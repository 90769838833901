import { Stack } from '@mui/material';
import StatusSelector from 'components/status-selector/status-selector';

const ProductionStatusCell = ({ value }) => {
  return (
    <Stack justifyContent="center" alignItems="center" width="100%" height="100%">
      <StatusSelector optionsFor="production" status={value} disableDropDown />
    </Stack>
  );
};

export default ProductionStatusCell;
