import { FC, SVGProps } from 'react';

export const DownloadIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M11.7649 6.31543C14.4171 6.54381 15.5 7.90672 15.5 10.8904V10.9862C15.5 14.2792 14.1813 15.5979 10.8882 15.5979H6.09226C2.79918 15.5979 1.48047 14.2792 1.48047 10.9862V10.8904C1.48047 7.92882 2.54869 6.56591 5.15664 6.3228"
      stroke="#141414"
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M8.49414 1.23145V10.7202" stroke="#141414" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M10.9613 9.07812L8.49336 11.5461L6.02539 9.07812"
      stroke="#141414"
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
