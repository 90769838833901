import { ValidationCriteria } from 'pages/auth/auth.controller';
import { AppState } from 'redux/store';
import { StateController } from 'state-controller';
import { StateModeEnum } from 'types/common-enums';

export type ChangeUserPasswordState = {
  newPassword: string;
  confirmPassword: string;
  validationCriteria: ValidationCriteria;
};

export type ChangeUserPassword = {
  mode: StateModeEnum;
  isOpen: boolean;
  newPassword: string;
  confirmPassword: string;
  validationCriteria: ValidationCriteria;
};

const defaultState: ChangeUserPassword = {
  mode: StateModeEnum.View,
  isOpen: false,
  confirmPassword: '',
  newPassword: '',
  validationCriteria: { length: false, number: false, specialChar: false, uppercaseLowercase: false },
};

const stateController = new StateController<ChangeUserPassword>('CHANGE_USER_PASSWORD', defaultState);

export class Actions {
  public static openChangeUserPasswordModal() {
    return (dispatch) => {
      dispatch(stateController.setState({ isOpen: true, mode: StateModeEnum.Create }));
    };
  }

  public static closeChangeUserPasswordModal() {
    return (dispatch) => {
      dispatch(stateController.setState({ ...defaultState }));
    };
  }

  public static onChange(values: Partial<ChangeUserPasswordState>) {
    return (dispatch) => {
      dispatch(stateController.setState((prev) => ({ ...prev, ...values })));
    };
  }
}

export class Selectors {
  public static returnIsPasswordMatch(state: AppState) {
    return state.changeUserPassword.newPassword === state.changeUserPassword.confirmPassword;
  }
}

export const reducer = stateController.getReducer();
