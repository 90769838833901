import { ActiveTickIcon } from 'icons/active-tick';
import FireMinusIcon from 'icons/fire-minus';
import { MODALS } from 'modules/root-modals/modals';
import { PermissionGuardActions } from 'modules/permission-guard/permission-guard.controller';
import { AccessLevel, Permission } from 'services/permission.model';
import { DeleteConfirmationOwnProps } from 'modules/root-modals/modals/confirmation-modal/confirmation-modal';
import { ModalActions } from 'modules/root-modals/root-modals.controller';
import { notify } from 'notifications';
import { Actions as ProductRootController } from 'pages/product-flow/pages/product/controllers/product-root.controller';
import { AppState, GetStateFunction } from 'redux/store';
import { ManageWorkflowTemplatesData, WorkflowTemplate } from 'services/workflow-templates.model';
import { WorkflowTemplatesService } from 'services/workflow-templates.service';
import { StateController } from 'state-controller';
import { copyWithCounter } from 'utils/copy-with-counter';
import { v4 as uuidv4 } from 'uuid';
import { Actions as ProductConfigurationController } from './product-configurations.controller';
import s from '../product.module.scss';

export const WORKFLOW_EMPTY_LIST_VALIDATION_TEXT = 'Unable to publish product version. Add a workflow template';
export const WORKFLOW_INVALID_ITEMS_VALIDATION_TEXT =
  'Cannot publish product version. One or more workflow patterns contain warnings';

export type WorkflowOrderT = { id: string; order: number };

export type ProductWorkflowValidations = {
  errors: {
    empty: string;
    itemsWarning: string;
  };
};

export type ProductState = {
  isInitLoading: boolean;
  workflow_templates: Array<WorkflowTemplate>;
  validations: ProductWorkflowValidations;
  changeIsActiveModal: {
    isOpen: boolean;
  };
};

const validationsDefaultState: ProductWorkflowValidations = {
  errors: {
    empty: '',
    itemsWarning: '',
  },
};

const defaultState: ProductState = {
  isInitLoading: true,
  workflow_templates: [],
  validations: validationsDefaultState,
  changeIsActiveModal: {
    isOpen: false,
  },
};

const stateController = new StateController<ProductState>('PRODUCT_WORKFLOW', defaultState);

export class Actions {
  public static init(workflowTemplates: Array<WorkflowTemplate>) {
    return async (dispatch) => {
      await dispatch(
        stateController.setState((prev) => ({ ...prev, workflow_templates: workflowTemplates, isInitLoading: false })),
      );
      dispatch(Actions.revalidate());
    };
  }

  public static disposeState() {
    return (dispatch) => {
      dispatch(stateController.setState(defaultState));
    };
  }

  public static setInitLoading() {
    return (dispatch) => {
      dispatch(stateController.setState({ isInitLoading: true }));
    };
  }

  public static addTemporaryWorkflowTemplate() {
    return (dispatch, getState: GetStateFunction) => {
      if (!dispatch(PermissionGuardActions.checkPermissionAndShowModal(Permission.webProductsEdit, [AccessLevel.access]))) {
        return;
      }

      const { workflow_templates } = getState().product.product_workflow;
      const { id: product_configuration_id } = getState().product.product_configurations.active_configuration;

      const workflowTemplateTemporaryId = `new-${uuidv4()}`;
      const newWorkflowTemplateOrder = workflow_templates.length
        ? Math.min(...workflow_templates.map(({ order }) => order)) - 1
        : 0;

      const temporaryWorkflowTemplate: WorkflowTemplate = {
        id: workflowTemplateTemporaryId,
        name: '',
        order: newWorkflowTemplateOrder,
        tasks_count: 0,
        products_count: 0,
        is_active: true,
        product_configuration_id,
      };

      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          workflow_templates: [temporaryWorkflowTemplate, ...workflow_templates],
        })),
      );
    };
  }

  public static changeWorkflowTemplateData(id: string, data: Partial<WorkflowTemplate>) {
    return async (dispatch, getState: GetStateFunction) => {
      const { workflow_templates } = getState().product.product_workflow;

      const mapped = workflow_templates.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            ...data,
          };
        }
        return item;
      });

      await dispatch(
        stateController.setState((prev) => ({
          ...prev,
          workflow_templates: mapped,
        })),
      );

      dispatch(Actions.revalidate());
    };
  }

  public static createWorkflowTemplate(temporaryId: string, name: string) {
    return async (dispatch, getState: GetStateFunction) => {
      try {
        const { id } = getState().product.product_configurations.active_configuration;
        const { workflow_templates } = getState().product.product_workflow;

        const temporaryWorkflowTemplate = workflow_templates.find((workflow) => workflow.id === temporaryId);

        if (!temporaryWorkflowTemplate) throw new Error('temporaryWorkflowTemplate is undefined');

        const workflowTemplate = await WorkflowTemplatesService.create({
          name,
          product_configuration_id: id,
          order: temporaryWorkflowTemplate.order,
        });
        dispatch(ProductRootController.updateProductModifiedAt());
        dispatch(Actions.changeWorkflowTemplateData(temporaryId, workflowTemplate));
      } catch (err) {
        notify.error(err.message);
        throw err;
      }
    };
  }

  public static renameWorkflowTemplate(id: string, name: string) {
    return async (dispatch, getState: GetStateFunction) => {
      const { workflow_templates } = getState().product.product_workflow;
      const { id: configId } = getState().product.product_configurations.active_configuration;

      const currentWorkflow = workflow_templates.find((item) => item.id === id);

      try {
        dispatch(Actions.changeWorkflowTemplateData(id, { name }));

        if (!currentWorkflow) throw new Error('currentWorkflow is undefined');

        await WorkflowTemplatesService.update(id, {
          order: currentWorkflow.order,
          product_configuration_id: configId,
          name,
        });
        dispatch(ProductRootController.updateProductModifiedAt());
      } catch (error) {
        notify.error(error.message);
        if (currentWorkflow) await dispatch(Actions.changeWorkflowTemplateData(id, { name: currentWorkflow.name }));
      } finally {
        dispatch(Actions.revalidate());
      }
    };
  }

  public static duplicateWorkflowTemplate(sourceWorkflowTemplate: WorkflowTemplate, workflowNames: string[]) {
    return async (dispatch, getState: GetStateFunction) => {
      if (!dispatch(PermissionGuardActions.checkPermissionAndShowModal(Permission.webProductsEdit, [AccessLevel.access]))) {
        return;
      }

      const { workflow_templates } = getState().product.product_workflow;

      const duplicatedTemporaryId = `new-${uuidv4()}`;

      const duplicatedWorkflowTemplate: WorkflowTemplate = {
        ...sourceWorkflowTemplate,
        name: copyWithCounter(sourceWorkflowTemplate.name, workflowNames),
        id: duplicatedTemporaryId,
      };

      try {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            workflow_templates: [...workflow_templates, duplicatedWorkflowTemplate],
          })),
        );

        const newWorkflowTemplate = await WorkflowTemplatesService.duplicate({
          id: sourceWorkflowTemplate.id,
          name: copyWithCounter(sourceWorkflowTemplate.name, workflowNames),
        });
        dispatch(ProductRootController.updateProductModifiedAt());

        dispatch(Actions.changeWorkflowTemplateData(duplicatedTemporaryId, newWorkflowTemplate));
      } catch (error) {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            workflow_templates,
          })),
        );
        notify.error(error.message);
      }
    };
  }

  public static reorderWorkflowTemplates(orderList: WorkflowOrderT[]) {
    return async (dispatch, getState: GetStateFunction) => {
      const { workflow_templates } = getState().product.product_workflow;

      const data: ManageWorkflowTemplatesData = {
        templates: orderList,
      };

      try {
        const newList = orderList.map<WorkflowTemplate>(({ id, order }) => {
          const template = workflow_templates.find((item) => item.id === id);

          if (!template) throw new Error(`Template with id ${id} not found`);

          return {
            ...template,
            order,
          };
        });

        await WorkflowTemplatesService.manageOrders(data);
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            workflow_templates: newList,
          })),
        );
      } catch (error) {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            workflow_templates,
          })),
        );
        notify.error(error.message);
      }
    };
  }

  public static deleteTemporaryWorkflowTemplate(temporaryId: string) {
    return (dispatch, getState: GetStateFunction) => {
      const { workflow_templates } = getState().product.product_workflow;

      const filteredWorkflowTemplates = workflow_templates.filter((item) => item.id !== temporaryId);

      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          workflow_templates: filteredWorkflowTemplates,
        })),
      );

      dispatch(Actions.revalidate());
    };
  }

  public static openDeleteConfirmationModal(id: string, name: string) {
    return (dispatch) => {
      if (!dispatch(PermissionGuardActions.checkPermissionAndShowModal(Permission.webProductsEdit, [AccessLevel.access]))) {
        return;
      }

      dispatch(
        ModalActions.openModal<DeleteConfirmationOwnProps>({
          id: MODALS.CONFIRM,
          props: {
            title: 'Delete production template?',
            text: (
              <>
                Deleting production template <strong>{name}</strong> will result in the removal of all configured tasks, bonuses,
                and responsibilities <br />
                <br />
                Are you sure you want to delete the production template <strong>{name}?</strong>
              </>
            ),
            icon: <FireMinusIcon />,
            withCloseButton: false,
            actionText: 'Delete',
            action: () => dispatch(Actions.deleteWorkflowTemplate(id)),
          },
        }),
      );
    };
  }

  public static deleteWorkflowTemplate(id: string) {
    return async (dispatch, getState: GetStateFunction) => {
      const { workflow_templates } = getState().product.product_workflow;

      const filteredWorkflowTemplates = workflow_templates.filter((item) => item.id !== id);

      try {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            workflow_templates: filteredWorkflowTemplates,
          })),
        );

        await WorkflowTemplatesService.delete(id);
        dispatch(ProductRootController.updateProductModifiedAt());
        dispatch(Actions.revalidate());
        notify.success('Successfully deleted');
      } catch (error) {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            workflow_templates,
          })),
        );
        notify.error(error.message);
      }
    };
  }

  public static revalidate() {
    return (dispatch, getState: GetStateFunction) => {
      const { workflow_templates } = getState().product.product_workflow;

      const newValidation: ProductWorkflowValidations = {
        errors: {
          empty: '',
          itemsWarning: '',
        },
      };

      if (!workflow_templates.length) {
        newValidation.errors.empty = WORKFLOW_EMPTY_LIST_VALIDATION_TEXT;
      }

      if (!workflow_templates.every((item) => item.is_valid)) {
        newValidation.errors.itemsWarning = WORKFLOW_INVALID_ITEMS_VALIDATION_TEXT;
      }

      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          validations: newValidation,
        })),
      );
    };
  }

  public static changeIsActive(id: string, is_active: boolean) {
    return async (dispatch) => {
      try {
        await WorkflowTemplatesService.update(id, { is_active });
        dispatch(stateController.setState({ isInitLoading: true }));
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            workflow_templates: prev.workflow_templates.map((item) => {
              if (item.id === id) {
                return {
                  ...item,
                  is_active,
                };
              }
              return item;
            }),
          })),
        );
        dispatch(ProductRootController.updateProductModifiedAt());
        dispatch(Actions.closeModal('changeIsActiveModal'));
      } finally {
        dispatch(stateController.setState({ isInitLoading: false }));
      }
    };
  }

  public static openChangeIsActiveConfirmationModal(wokrflowTemplateId: string, is_active: boolean) {
    return (dispatch, getState: GetStateFunction) => {
      try {
        if (!dispatch(PermissionGuardActions.checkPermissionAndShowModal(Permission.webProductsEdit, [AccessLevel.access]))) {
          return;
        }

        const { name: productName } = getState().product.product_root.product_meta;
        const { product_id: productId } = getState().product.product_configurations.active_configuration;
        const { version } = getState().product.product_root;

        const workflowName = getState().product.product_workflow.workflow_templates.find(
          (wt) => wt.id === wokrflowTemplateId,
        )?.name;

        if (!productId) throw new Error('productId is undefined');

        dispatch(
          ModalActions.openModal<DeleteConfirmationOwnProps>({
            id: MODALS.CONFIRM,
            props: {
              title: is_active ? 'Activate workflow template?' : 'Inactivate workflow template?',
              text: is_active ? (
                <div className={s.activate_product_container}>
                  <span className={s.text}>
                    Opening access to workflow <strong>{workflowName}</strong> of <strong>{productName}</strong> will allow this
                    workflow to be selected for production or used in{' '}
                    <span
                      className={s.related_product}
                      onClick={() => {
                        dispatch(ProductConfigurationController.openRelationProductModal(productId, productName, version));
                      }}
                    >
                      related products.
                    </span>
                  </span>
                  <span className={s.text}>
                    Are you sure you want to activate <strong>{workflowName}</strong>?
                  </span>
                </div>
              ) : (
                <div className={s.activate_product_container}>
                  <span className={s.text}>
                    Closing access to workflow <strong>{workflowName}</strong> of <strong>{productName}</strong> will prevent this
                    workflow from being selected for production or used in{' '}
                    <span
                      className={s.related_product}
                      onClick={() => {
                        dispatch(ProductConfigurationController.openRelationProductModal(productId, productName, version));
                      }}
                    >
                      related products.
                    </span>
                  </span>
                  <span className={s.text}>
                    Are you sure you want to inactivate <strong>{workflowName}</strong> of the product?
                  </span>
                </div>
              ),
              icon: is_active ? <ActiveTickIcon /> : <FireMinusIcon />,
              backgroundColor: is_active ? '#BCF4DE' : '#FFCECE',
              withCloseButton: false,
              actionText: is_active ? 'Activate' : 'Inactivate',
              actionButtonColor: is_active ? 'primary' : 'error',
              action: () => dispatch(Actions.changeIsActive(wokrflowTemplateId, is_active)),
            },
          }),
        );
      } catch (err) {
        notify.error(err.message);
        throw err;
      }
    };
  }

  public static closeModal(modalName: string) {
    return (dispatch) => {
      dispatch(
        stateController.setState((prevState) => ({
          ...prevState,
          [modalName]: defaultState[modalName],
        })),
      );
    };
  }
}

export class Selectors {
  public static isWorkflowsValid(state: AppState) {
    const { validations } = state.product.product_workflow;
    const { errors } = validations;

    return !errors.empty && !errors.itemsWarning;
  }
}

export const reducer = stateController.getReducer();
