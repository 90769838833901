import s from './company-logo.module.scss';

type Props = {
  imgSrc: string;
  companyName: string;
  size: 'S' | 'M' | 'L';
  [key: string]: any;
};

export const CompanyLogo = (props: Props) => {
  const { imgSrc, companyName, size = 'M', ...rest } = props;

  function getCompanyAbbreviation(name: string) {
    const parts = name.split(' ');

    if (parts.length === 1) {
      return name.substring(0, 2).toUpperCase();
    }
    const firstLetter = parts[0][0];
    const secondLetter = parts[1][0];
    return `${firstLetter}${secondLetter}`.toUpperCase();
  }

  const companyAbbreviation = getCompanyAbbreviation(companyName);

  return (
    <div {...rest} className={`${s.container} ${size} ${rest?.className}`}>
      {imgSrc ? (
        <img className={s.image} src={imgSrc} alt={`${companyName} logo`} />
      ) : (
        <div className={`${s.abbreviation} ${size}`}>{companyAbbreviation}</div>
      )}
    </div>
  );
};
