import { FC, SVGProps } from 'react';

export const CheckIcon: FC<SVGProps<SVGSVGElement>> = ({ stroke = '#878B92', ...props }) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M1.75 9.66667L7.41667 15.3333L18.75 4"
      stroke={stroke}
      strokeWidth="1.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
