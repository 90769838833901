import Cookies from 'js-cookie';

// Motivation:
// EnvCookies layer is requred to divide environments cookies.
// User which is logged in stage.hesh.app and stored cookie with JWT should not be able to get this same JWT from dev.hesh.app
// Notice: domain based cookie isolation not suitable approach. "domain=.hesh.app" will not isolate dev.hesh.app from company.hesh.app

export type Envs = 'local' | 'development' | 'stage' | 'production'; // configured in .env.* files
export const ENV = process.env.REACT_APP_ENV_NAME as Envs;

export const LOCAL_COOKIE_DOMAIN = '.local.domain.localhost';
export const DEV_COOKIE_DOMAIN = '.dev.hesh.app';
export const STAGE_COOKIE_DOMAIN = '.stage.hesh.app';
export const PROD_COOKIE_DOMAIN = '.hesh.app';

let DOMAIN = '';

if (ENV === 'local') {
  DOMAIN = LOCAL_COOKIE_DOMAIN;
} else if (ENV === 'development') {
  DOMAIN = DEV_COOKIE_DOMAIN;
} else if (ENV === 'stage') {
  DOMAIN = STAGE_COOKIE_DOMAIN;
} else if (ENV === 'production') {
  DOMAIN = PROD_COOKIE_DOMAIN;
}

export class EnvCookies {
  public static get(name) {
    const args = {
      name,
    };

    args.name = modifyCookieName(name);

    return Cookies.get(args.name);
  }

  public static set(name, value, attributes = {}) {
    const args = {
      name,
      value,
      attributes: { ...attributes, path: '/', domain: DOMAIN, sameSite: 'Strict' } as typeof Cookies.attributes,
    };

    args.name = modifyCookieName(name);

    if (ENV === 'local') {
      args.attributes = { ...args.attributes };
    }

    Cookies.set(args.name, args.value, args.attributes);
  }

  public static remove(name, attributes = {}) {
    const args = {
      name,
      attributes: { ...attributes, path: '/', domain: DOMAIN } as typeof Cookies.attributes,
    };

    args.name = modifyCookieName(name);

    Cookies.remove(args.name, args.attributes);
  }
}

function modifyCookieName(name: string) {
  if (ENV === 'local') {
    return `local-${name}`;
  }
  if (ENV === 'development') {
    return `dev-${name}`;
  }
  if (ENV === 'stage') {
    return `stage-${name}`;
  }
  // production cookie names stored without additional prefix;
  return name;
}
