import { baseAxiosInstance } from 'axios-config';
import { UpdateDeleteResponsibilityResponse, ResponsibilityData } from 'services/workflow-task-template-responsibility.model';

export class WorkflowTaskTemplateResponsibilityService {
  public static async create(body: ResponsibilityData) {
    const { data } = await baseAxiosInstance.post<ResponsibilityData>('/workflow-task-template-responsibilities/create', body);

    return data;
  }

  public static async update(id: string, body: ResponsibilityData) {
    const { data } = await baseAxiosInstance.put<UpdateDeleteResponsibilityResponse>(
      `/workflow-task-template-responsibilities/update/${id}`,
      body,
    );

    return data;
  }

  public static async delete(id: string) {
    const { data } = await baseAxiosInstance.delete<UpdateDeleteResponsibilityResponse>(
      `/workflow-task-template-responsibilities/delete/${id}`,
    );

    return data;
  }
}
