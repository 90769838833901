import { Stack } from '@mui/material';
import { AppState } from 'redux/store';
import { useDispatch, useSelector } from 'react-redux';
import { TasksActions } from 'pages/tasks/tasks.controller';
import { AccessLevel, Permission } from 'services/permission.model';
import PrioritySelector from 'components/priority-select/priority-select';
import { PermissionGuardSelectors } from 'modules/permission-guard/permission-guard.controller';

const TaskPriorityCell = ({ value, data }) => {
  const dispatch = useDispatch();

  const taskDepartments = data.department_ids.map((i: string) => ({ id: i })) || [];
  const accessLevels = [AccessLevel.userDepartment, AccessLevel.userDepartmentSubDepartments, AccessLevel.allDepartments];
  const isPermitted = useSelector((state: AppState) =>
    PermissionGuardSelectors.checkEditLevel(state, Permission.webTaskEditLevel, accessLevels, taskDepartments),
  );

  return (
    <Stack justifyContent="center" alignItems="center" width="100%" height="100%">
      <PrioritySelector
        enableStopPropagation={false}
        priority={value}
        isEditPermitted={isPermitted}
        onSelect={(selectedPriority) => dispatch(TasksActions.updateTask(data.id, { priority: selectedPriority }))}
      />
    </Stack>
  );
};

export default TaskPriorityCell;
