import { FC, SVGProps } from 'react';

export const CostIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M5.67139 9.63079C5.67139 10.5338 6.36439 11.2618 7.22539 11.2618H8.98239C9.73139 11.2618 10.3404 10.6248 10.3404 9.84079C10.3404 8.98679 9.96939 8.68579 9.41639 8.48979L6.59539 7.50979C6.04239 7.31379 5.67139 7.01279 5.67139 6.15879C5.67139 5.37479 6.28039 4.73779 7.02939 4.73779H8.78639C9.64739 4.73779 10.3404 5.46579 10.3404 6.36879"
      stroke="#141414"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M8 3.80029V12.2003" stroke="#141414" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15Z"
      stroke="#141414"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
