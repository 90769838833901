import { InputNames, SettingsActions } from 'pages/settings/settings.controller';
import s from 'pages/settings/settings.module.scss';
import { connect } from 'react-redux';
import { AppState } from 'redux/store';
import InputWithButtons from 'components/ui-new/input-with-buttons/input-with-buttons';
import Spinner from 'components/spinner/spinner';
import { LOGO_FILES_TYPES } from 'constants/file-types';
import { abbreviationFormatter, currencyFormatter } from 'pages/settings/helpers/value-formatter';
import { Stack } from '@mui/material';
import FileUploadButton from 'pages/settings/components/file-upload-button/file-upload-button';
import { CompanyLogo } from 'components/company-logo/company-logo';

type StateProps = {
  currency: string;
  companyName: string;
  abbreviation: string;
  isLogoLoading: boolean;
  analyticalLink: string;
  companyLogoUrl: string;
};

type DispatchProps = {
  removeCompanyLogo: () => void;
  uploadCompanyLogo: (file: any) => void;
  onSaveInputValue: (inputName: InputNames, value: string | number) => void;
};

type Props = StateProps & DispatchProps;

const GeneralTabContent = ({
  currency,
  companyName,
  abbreviation,
  isLogoLoading,
  analyticalLink,
  companyLogoUrl,
  onSaveInputValue,
  removeCompanyLogo,
  uploadCompanyLogo,
}: Props) => {
  return (
    <div className={s.section}>
      <div className={s.row}>
        <b className={s.row_title}>Company logo</b>
        <div className={s.right_col}>
          <Stack flexDirection="row" justifyContent="flex-end" alignItems="center" gap="24px">
            {isLogoLoading ? (
              <div className={s.spinner_container}>
                <Spinner containerClassName={s.spinner} disableShrink size={30} />
              </div>
            ) : (
              <CompanyLogo className={s.logo} imgSrc={companyLogoUrl} companyName={companyName} size="M" />
            )}
            <Stack flexDirection="row" justifyContent="flex-end" alignItems="center" gap="12px">
              <FileUploadButton title="Upload logo" accept={LOGO_FILES_TYPES} onFileSelect={uploadCompanyLogo} />
              {companyLogoUrl && (
                <FileUploadButton
                  title="Remove logo"
                  variant="secondary"
                  shouldOpenFileDialog={false}
                  onClick={removeCompanyLogo}
                />
              )}
            </Stack>
          </Stack>
        </div>
      </div>
      <div className={s.row}>
        <b className={s.row_title}>Company name</b>
        <div className={s.right_col}>
          <InputWithButtons
            value={companyName}
            placeholder="Enter company name"
            onConfirmClick={(value) => onSaveInputValue(InputNames.CompanyName, value)}
          />
        </div>
      </div>
      <div className={s.row}>
        <div className={s.title_container}>
          <b className={s.row_title}>Key abbreviation</b>
          <p className={s.row_description}>Is used to generate identifiers for orders, productions and tasks</p>
        </div>
        <div className={s.right_col}>
          <InputWithButtons
            value={abbreviation}
            placeholder="Enter a shortened form of company name (e.g., MN...)"
            valueFormatter={(newValue) => abbreviationFormatter(newValue)}
            onConfirmClick={(value) => onSaveInputValue(InputNames.Abbreviation, value)}
          />
        </div>
      </div>
      <div className={s.row}>
        <b className={s.row_title}>Currency</b>
        <div className={s.right_col}>
          <InputWithButtons
            value={currency}
            placeholder="Enter a payment currency (e.g., UAH, USD, Coins, ...)"
            valueFormatter={(newValue) => currencyFormatter(newValue)}
            onConfirmClick={(value) => onSaveInputValue(InputNames.Currency, value)}
          />
        </div>
      </div>

      <div className={s.row}>
        <div className={s.title_container}>
          <b className={s.row_title}>Analytics report link</b>
          <p className={s.row_description}>Is used to embed your web-published reports to HESH Analytics page</p>
        </div>
        <div className={s.right_col}>
          <InputWithButtons
            allowedEmpty
            value={analyticalLink}
            placeholder="https://..."
            onConfirmClick={(value) => onSaveInputValue(InputNames.AnalyticalLink, value)}
          />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: AppState): StateProps => ({
  currency: state.settings.currency,
  companyName: state.settings.companyName,
  abbreviation: state.settings.abbreviation,
  analyticalLink: state.settings.analyticalLink,
  companyLogoUrl: state.settings.companyLogo.url,
  isLogoLoading: state.settings.companyLogo.isLoading,
});

const mapDispatchToProps: DispatchProps = {
  onSaveInputValue: SettingsActions.onSaveInputValue,
  removeCompanyLogo: SettingsActions.removeCompanyLogo,
  uploadCompanyLogo: SettingsActions.uploadCompanyLogo,
};

export default connect(mapStateToProps, mapDispatchToProps)(GeneralTabContent);
