import { FC, SVGProps } from 'react';

export const HomeFilledIcon: FC<SVGProps<SVGSVGElement>> = ({ ...props }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="black" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 21.9991C3.34315 21.9991 2 20.6559 2 18.9991V11.3352C2 10.4847 2.36096 9.6742 2.99311 9.10527L9.9931 2.80527C11.134 1.77845 12.866 1.77845 14.0069 2.80527L21.0069 9.10527C21.639 9.6742 22 10.4847 22 11.3352V18.9991C22 20.6559 20.6569 21.9991 19 21.9991H5ZM20 11.3352V18.9991C20 19.5514 19.5523 19.9991 19 19.9991H16V14.9991C16 13.3422 14.6569 11.9991 13 11.9991H11C9.34315 11.9991 8 13.3422 8 14.9991V19.9991H5C4.44772 19.9991 4 19.5514 4 18.9991V11.3352C4 11.0517 4.12032 10.7815 4.33104 10.5919L11.331 4.29186C11.7113 3.94959 12.2887 3.94959 12.669 4.29186L19.669 10.5919C19.8797 10.7815 20 11.0517 20 11.3352ZM10 19.9991V14.9991C10 14.4468 10.4477 13.9991 11 13.9991H13C13.5523 13.9991 14 14.4468 14 14.9991V19.9991H10Z"
      fillOpacity="0.9"
    />
  </svg>
);
