import { Stack, TextareaAutosize, Typography } from '@mui/material';
import { useState } from 'react';
import DropdownReason from 'modules/manage-failed-tasks-modal/components/dropdown-reason';

import { useSelector } from 'react-redux';
import { AppState } from 'redux/store';
import { FailReasonsModel, TaskReopenModel } from 'services/production-task.model';
import { MAX_REASONS_CHARS_COUNT } from 'modules/manage-failed-tasks-modal/manage-failed-tasks.controller';
import s from './confirmation-window.module.scss';

type Props = {
  tasksToReopen: TaskReopenModel;
  tasksToReopenWithPayment: TaskReopenModel;
  onReasonChange: (value: string) => void;
  onReasonParametersChange: (value: FailReasonsModel) => void;
};

const ConfirmationWindow = ({ tasksToReopen, tasksToReopenWithPayment, onReasonChange, onReasonParametersChange }: Props) => {
  // The states is used here to create rerender when typing in textarea
  const [value, setValue] = useState('');
  const options = useSelector((state: AppState) => state.manage_failed_tasks.reasonOptions);
  const selectedReasonOptions = useSelector((state: AppState) => state.manage_failed_tasks.selectedReasonOptions);
  const reasonForReopening = useSelector((state: AppState) => state.manage_failed_tasks.reasonForReopening);

  const isOverLimit = reasonForReopening.length > MAX_REASONS_CHARS_COUNT;

  return (
    <Stack gap="16px">
      <Stack gap="12px">
        {!!tasksToReopen.length && (
          <Stack gap="3px">
            <Typography className={s.tasks_heading}>Reopen tasks:</Typography>
            <div>
              {tasksToReopen.map(({ id, name, taskKey }) => (
                <Stack key={id} className={s.tasks_reopen_wrapper}>
                  <div className={s.bullet_point} />
                  <Stack direction="row" gap={0.5}>
                    <a href={`${window.location.origin}/task/${id}`} target="_blank" className={s.link} rel="noreferrer">
                      {taskKey}
                    </a>
                    <Typography className={s.tasks_item}>{name}</Typography>
                  </Stack>
                </Stack>
              ))}
            </div>
          </Stack>
        )}
        <div className={s.divider} />
        {!!tasksToReopenWithPayment.length && (
          <Stack gap="3px">
            <Typography className={s.tasks_heading}>Duplicate tasks (with new payment):</Typography>
            <div>
              {tasksToReopenWithPayment.map(({ id, name }) => (
                <Stack key={id} className={s.tasks_reopen_wrapper}>
                  <div className={s.bullet_point_secondary} />
                  <Typography className={s.tasks_item}>{name}</Typography>
                </Stack>
              ))}
            </div>
            <div className={s.divider} />
          </Stack>
        )}
      </Stack>

      <Typography className={s.tasks_heading}>
        Describe the reason why you fail this task(s). It will be saved and appear at production in task that was failed.
      </Typography>

      <Stack gap={0.5}>
        <Typography className={s.tasks_item}>Reason</Typography>
        <DropdownReason
          label="Select reason"
          options={options || []}
          values={selectedReasonOptions as FailReasonsModel[]}
          onChange={(selectedItems) => {
            onReasonParametersChange(selectedItems);
          }}
        />
      </Stack>

      <Stack gap={0.5}>
        <div className={s.comment_header}>
          <Typography className={s.tasks_item}>Comment</Typography>
          <Typography style={{ transition: 'color 0.3s' }}>
            <span style={{ color: isOverLimit ? 'red' : 'inherit' }}>{reasonForReopening.length}</span>/{MAX_REASONS_CHARS_COUNT}
          </Typography>
        </div>
        <TextareaAutosize
          minRows={4}
          maxRows={4}
          value={value}
          color="primary"
          className={s.textarea}
          placeholder="Enter a comment"
          onChange={(e) => {
            setValue(e.target.value);
            onReasonChange(e.target.value);
          }}
        />
      </Stack>
    </Stack>
  );
};

export default ConfirmationWindow;
