import { StateController } from 'state-controller';
import { MODALS } from './modals';

export type ModalItem<TModalProps = unknown> = {
  id: MODALS;
  props?: TModalProps;
};

export type ModalsState = {
  modal_items: ModalItem[];
};

const defaultState: ModalsState = {
  modal_items: [],
};

const stateController = new StateController<ModalsState>('MODALS_CONTROLLER', defaultState);

export class ModalActions {
  public static openModal<TModalProps = unknown>(modalData: ModalItem<TModalProps>) {
    return (dispatch) => {
      dispatch(stateController.setState((prevState) => ({ modal_items: [...prevState.modal_items, modalData] })));
    };
  }

  public static closeModal(modalId: MODALS) {
    return (dispatch) => {
      dispatch(
        stateController.setState((prevState) => ({ modal_items: prevState.modal_items.filter((item) => item.id !== modalId) })),
      );
    };
  }
}

export class Selectors {}

export const reducer = stateController.getReducer();
