import { configureStore } from '@reduxjs/toolkit';
import { reducer as RootModalsReducer } from 'modules/root-modals/root-modals.controller';
import { reducer as AuthenticationReducer } from 'pages/auth/auth.controller';
import { reducer as SettingsReducer } from 'pages/settings/settings.controller';
import { reducer as ProductionReducer } from 'pages/production/production.controller.entry';
import { reducer as WorkflowEditorReducer } from 'pages/product-flow/pages/workflow-template/workflow-template.controller';
import { reducer as WorkflowPreviewModalReducer } from 'pages/product-flow/pages/workflow-template/modals/workflow-template-preview/workflow-preview-modal.controller';
import { reducer as WorkflowTaskTemplateReducer } from 'pages/product-flow/pages/workflow-task-template/workflow-task-template.controller';
import { reducer as WorkflowTaskTemplatePreviewModalReducer } from 'pages/product-flow/pages/workflow-task-template/modals/workflow-task-template-preview/workflow-task-template-preview-modal.controller';
import { reducer as WorkflowTaskTemplateResponsibilityModalReducer } from 'modules/task-template-responsibility-modal/workflow-task-template-responsibility-modal.controller';
import { reducer as TaskReducer } from 'pages/task/task.controller.entry';
import { reducer as TemplateManagementReducer } from 'pages/product-flow/pages/product/components/options/modals/template-management-modal/template-management-modal.controller';
import { reducer as ProductConfigurationsModalsReducer } from 'pages/product-flow/pages/product/components/product-configurations-tabs/product-configurations-modals.controller';
import { reducer as ProductReducer } from 'pages/product-flow/pages/product/product.controller.entry';
import { reducer as ProductSearchReducer } from 'pages/product-search/product-search.controller';
import { reducer as WorkflowTaskTemplateBonusesModal } from 'pages/product-flow/pages/workflow-task-template/workflow-task-template-bonuses-modal.controller';
import { reducer as ProductionWorkflowReducer } from 'pages/production-workflow/controllers/production-workflow.controller';
import { reducer as ProductionWorkflowAdditionalTasksReducer } from 'pages/production-workflow/controllers/production-workflow-additional-tasks.controller';
import { reducer as ProductionWorkflowAdditionalComponentsReducer } from 'pages/production-workflow/controllers/production-workflow-additional-components.controller';
import { reducer as AddUserReducer } from 'modules/add-user-flow/add-user-flow.controller';
import { reducer as DepartmentsReducer } from 'pages/departments/departments.controller';
import { reducer as ManageFailedTasksReducer } from 'modules/manage-failed-tasks-modal/manage-failed-tasks.controller';
import { reducer as AddPositionModalReducer } from 'components/add-position-modal/add-position-modal.controller';
import { reducer as UserPermissionsReducer } from 'pages/user/components/tabs/permissions/permissions.controller';
import { reducer as AuthSelectCompanyReducer } from 'pages/auth-select-company/auth-select-company.controller';
import { reducer as PrintLabelWithQrReducer } from 'modules/print-label-with-qr/label-with-qr.controller';
import { ProductionsLaunchingProgressReducer } from 'pages/production/production-launching-progress-modal/production-launching-progress-modal.controller';
import { GetAppModalReducer } from 'modules/authorized-layout/components/header/components/get-app-modal.tsx/get-app-modal.controller';
import { tasksReducer } from 'pages/tasks/tasks.controller';
import { TasksAssignUsersProgressReducer } from 'pages/tasks/components/task-assign-users-progress-modal/task-assign-users-progress-modal.controller';
import { reducer as AllProductsReducer } from '../pages/products/products.controller.entry';
import { reducer as ChangeUserPasswordReducer } from '../modules/change-user-password/change-user-password.controller';
import { reducer as UsersReducer } from '../pages/users/users.controller';
import { reducer as AuthReducer } from './auth.controller';
import { reducer as UserReducer } from '../pages/user/user.controller';
import { reducer as PermissionGuardReducer } from '../modules/permission-guard/permission-guard.controller';

const store = configureStore({
  reducer: {
    auth: AuthReducer,
    authSelectCompany: AuthSelectCompanyReducer,
    root_modals: RootModalsReducer,
    permissionGuard: PermissionGuardReducer,
    addPositionModal: AddPositionModalReducer,
    products: AllProductsReducer,
    product: ProductReducer,
    productSearch: ProductSearchReducer,
    product_configurations_modals: ProductConfigurationsModalsReducer,
    print_label_with_qr: PrintLabelWithQrReducer,
    label_with_qr: GetAppModalReducer,
    task: TaskReducer,
    tasks: tasksReducer,
    template_management_modal: TemplateManagementReducer,
    production: ProductionReducer,
    production_workflow: ProductionWorkflowReducer,
    production_workflow_additional_tasks: ProductionWorkflowAdditionalTasksReducer,
    production_workflow_additional_components: ProductionWorkflowAdditionalComponentsReducer,
    manage_failed_tasks: ManageFailedTasksReducer,
    workflow_editor: WorkflowEditorReducer,
    workflow_preview_modal: WorkflowPreviewModalReducer,
    workflow_task_template: WorkflowTaskTemplateReducer,
    workflow_task_template_preview_modal: WorkflowTaskTemplatePreviewModalReducer,
    workflow_task_template_responsibility_modal: WorkflowTaskTemplateResponsibilityModalReducer,
    workflow_task_template_bonuses_modal: WorkflowTaskTemplateBonusesModal,
    users: UsersReducer,
    departments: DepartmentsReducer,
    settings: SettingsReducer,
    authentication: AuthenticationReducer,
    user: UserReducer,
    userPermissions: UserPermissionsReducer,
    addUserFlow: AddUserReducer,
    changeUserPassword: ChangeUserPasswordReducer,
    productions_launching_progress: ProductionsLaunchingProgressReducer,
    tasks_assign_users_progress: TasksAssignUsersProgressReducer,
  },

  devTools: process.env.NODE_ENV === 'development',
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      serializableCheck: false,
      // immutableCheck: false,
    });
  },
});

export type AppState = ReturnType<typeof store.getState>;
export type GetStateFunction = () => AppState;

export default store;
