import { FC, SVGProps } from 'react';

export const UserGeneralIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <ellipse
      cx="9.99984"
      cy="14.5832"
      rx="5.83333"
      ry="2.91667"
      stroke={props.stroke || 'white'}
      strokeWidth="1.5"
      strokeLinejoin="round"
    />
    <ellipse
      cx="9.99984"
      cy="5.83333"
      rx="3.33333"
      ry="3.33333"
      stroke={props.stroke || 'white'}
      strokeWidth="1.5"
      strokeLinejoin="round"
    />
  </svg>
);
