import { FC, SVGProps } from 'react';

export const DepartmentsIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M0.982422 14.667H15.0175"
      stroke={props.stroke || '#141414'}
      strokeWidth="1.1"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.3358 1.33301H4.66911C2.66911 1.33301 2.00244 2.52634 2.00244 3.99967V14.6663H14.0024V3.99967C14.0024 2.52634 13.3358 1.33301 11.3358 1.33301Z"
      stroke={props.stroke || '#141414'}
      strokeWidth="1.1"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.6665 11H6.6665"
      stroke={props.stroke || '#141414'}
      strokeWidth="1.1"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.33203 11H11.332"
      stroke={props.stroke || '#141414'}
      strokeWidth="1.1"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.6665 8H6.6665"
      stroke={props.stroke || '#141414'}
      strokeWidth="1.1"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.33203 8H11.332"
      stroke={props.stroke || '#141414'}
      strokeWidth="1.1"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.6665 5H6.6665"
      stroke={props.stroke || '#141414'}
      strokeWidth="1.1"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.33203 5H11.332"
      stroke={props.stroke || '#141414'}
      strokeWidth="1.1"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
