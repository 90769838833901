import { FC, SVGProps } from 'react';

export const SortArrowDownIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M8 3.5C7.72386 3.5 7.5 3.72386 7.5 4C7.5 4.27614 7.72386 4.5 8 4.5V3.5ZM14 4.5C14.2761 4.5 14.5 4.27614 14.5 4C14.5 3.72386 14.2761 3.5 14 3.5V4.5ZM8 7.5C7.72386 7.5 7.5 7.72386 7.5 8C7.5 8.27614 7.72386 8.5 8 8.5V7.5ZM12 8.5C12.2761 8.5 12.5 8.27614 12.5 8C12.5 7.72386 12.2761 7.5 12 7.5V8.5ZM8 11.5C7.72386 11.5 7.5 11.7239 7.5 12C7.5 12.2761 7.72386 12.5 8 12.5V11.5ZM10 12.5C10.2761 12.5 10.5 12.2761 10.5 12C10.5 11.7239 10.2761 11.5 10 11.5V12.5ZM2.3737 10.0845C2.19025 9.87809 1.87421 9.8595 1.66782 10.043C1.46143 10.2264 1.44284 10.5425 1.6263 10.7488L2.3737 10.0845ZM3.00345 11.5456L2.62975 11.8777H2.62975L3.00345 11.5456ZM4.99655 11.5456L5.37025 11.8777L4.99655 11.5456ZM6.3737 10.7488C6.55716 10.5425 6.53857 10.2264 6.33218 10.043C6.12579 9.8595 5.80975 9.87809 5.6263 10.0845L6.3737 10.7488ZM3.5 12C3.5 12.2761 3.72386 12.5 4 12.5C4.27614 12.5 4.5 12.2761 4.5 12H3.5ZM4.5 4C4.5 3.72386 4.27614 3.5 4 3.5C3.72386 3.5 3.5 3.72386 3.5 4H4.5ZM8 4.5H14V3.5H8V4.5ZM8 8.5H12V7.5H8V8.5ZM8 12.5H10V11.5H8V12.5ZM1.6263 10.7488L2.62975 11.8777L3.37716 11.2134L2.3737 10.0845L1.6263 10.7488ZM5.37025 11.8777L6.3737 10.7488L5.6263 10.0845L4.62284 11.2134L5.37025 11.8777ZM2.62975 11.8777C3.35903 12.6982 4.64097 12.6982 5.37025 11.8777L4.62284 11.2134C4.29135 11.5863 3.70865 11.5863 3.37716 11.2134L2.62975 11.8777ZM4.5 12V4H3.5V12H4.5Z"
      fill="#28303F"
    />
  </svg>
);
