import { FC, SVGProps } from 'react';

export const Position2Icon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect x="1.66699" y="5" width="16.6667" height="13.3333" rx="3" stroke="#5C668C" />
    <path
      d="M6.66699 4.99996V4.66663C6.66699 3.00977 8.01014 1.66663 9.66699 1.66663H10.3337C11.9905 1.66663 13.3337 3.00977 13.3337 4.66663V4.99996"
      stroke="#5C668C"
      strokeLinecap="round"
    />
    <path
      d="M1.75 9.16626C1.75 9.16626 6.70199 12.4996 9.875 12.4996C13.048 12.4996 18 9.16626 18 9.16626"
      stroke="#5C668C"
      strokeLinecap="round"
    />
    <path
      d="M11.6663 12.5C11.6663 13.4205 10.9201 14.1667 9.99967 14.1667C9.0792 14.1667 8.33301 13.4205 8.33301 12.5C8.33301 11.5796 9.0792 10.8334 9.99967 10.8334C10.9201 10.8334 11.6663 11.5796 11.6663 12.5Z"
      fill="#5C668C"
    />
  </svg>
);
