import { Skeleton as MuiSkeleton, SkeletonProps } from '@mui/material';
import s from './skeleton.module.scss';

type Props = SkeletonProps & {};

export default function Skeleton({ children, ...rest }: Props) {
  return (
    <MuiSkeleton className={s.skeleton} {...rest}>
      {children}
    </MuiSkeleton>
  );
}
