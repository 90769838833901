import { FC, SVGProps } from 'react';

export const ProductionKeyIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_13508_55540)">
      <path
        d="M13.5077 10.2264C12.1175 11.6099 10.1266 12.035 8.37867 11.4884L5.20003 14.6603C4.97058 14.8965 4.51841 15.0382 4.19448 14.991L2.72326 14.7885C2.23735 14.721 1.78519 14.2621 1.71096 13.7762L1.5085 12.305C1.46125 11.981 1.61647 11.5289 1.83918 11.2994L5.01107 8.12753C4.47117 6.37287 4.88959 4.382 6.27983 2.99852C8.27069 1.00765 11.5033 1.00765 13.5009 2.99852C15.4985 4.98939 15.4985 8.23551 13.5077 10.2264Z"
        stroke="#141414"
        strokeWidth="1.1"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.80176 11.9541L6.35396 13.5063"
        stroke="#141414"
        strokeWidth="1.1"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.9376 7.57393C10.4967 7.57393 10.9499 7.1207 10.9499 6.56162C10.9499 6.00254 10.4967 5.54932 9.9376 5.54932C9.37852 5.54932 8.92529 6.00254 8.92529 6.56162C8.92529 7.1207 9.37852 7.57393 9.9376 7.57393Z"
        stroke="#141414"
        strokeWidth="1.1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_13508_55540">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
