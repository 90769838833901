import { FC, SVGProps } from 'react';

export const ShowIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M8.91406 7.29859L14.6541 1.55859" stroke="#141414" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M15.2145 4.36V1H11.8545" stroke="#141414" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M7.51387 1H6.11387C2.61387 1 1.21387 2.4 1.21387 5.9V10.1C1.21387 13.6 2.61387 15 6.11387 15H10.3139C13.8139 15 15.2139 13.6 15.2139 10.1V8.7"
      stroke="#141414"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <defs>
      <clipPath id="clip0_13002_109744">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
