import { connect } from 'react-redux';
import { PlusIcon } from 'icons/plus';
import { AppState } from 'redux/store';
import { FC, useMemo, useRef } from 'react';
import Button from 'components/button/button';
import Subtitle from 'components/subtitle/subtitle';
import Breadcrumbs, { Breadcrumb } from 'components/ui-new/breadcrumbs/breadcrumbs';
import useRowWidth from 'components/ui-new/breadcrumbs/components/hooks/use-row-width';
import { PositionSlotByDepartmentId as PositionItem } from 'services/position-slots.model';
import { getBreadcrumbsItems, BreadcrumbItem } from 'components/ui-new/breadcrumbs/components/helpers/helpers';
import { Actions as AddPositionModalActions } from 'components/add-position-modal/add-position-modal.controller';
import { Actions as DepartmentsActions, Selectors as DepartmentsSelectors } from 'pages/departments/departments.controller';
import s from './header.module.scss';

type StateProps = {
  isEmpty: boolean;
  breadcrumbsArray: any;
  isMultiselect: boolean;
};
type DispatchProps = {
  addDepartment: () => void;
  addPositionModalOpen: (item?: PositionItem) => void;
};
type Props = StateProps & DispatchProps;

const Header: FC<Props> = ({ isEmpty, isMultiselect, breadcrumbsArray, addDepartment, addPositionModalOpen }) => {
  const breadcrumbsItems: BreadcrumbItem[] = JSON.parse(JSON.stringify(breadcrumbsArray));
  const bread: Breadcrumb[] = getBreadcrumbsItems(breadcrumbsItems);
  const breadcrumbs = useMemo(() => [...bread], [bread]);

  const refRow = useRef<HTMLDivElement>(null);
  const colWidth = useRowWidth(refRow);

  return (
    <div>
      <div className={s.container}>
        <Subtitle fontSize="extraLarge" text="Departments" />
        <div className={s.buttons}>
          {!isEmpty && (
            <>
              <Button
                size="L"
                color="primary"
                variant="contained"
                className={s.button}
                disabled={isMultiselect}
                startIcon={<PlusIcon />}
                onClick={addDepartment}
              >
                Add department
              </Button>
              <Button
                size="L"
                color="primary"
                variant="outlined"
                className={s.button}
                disabled={isMultiselect}
                startIcon={<PlusIcon />}
                onClick={() => addPositionModalOpen()}
              >
                Add position
              </Button>
            </>
          )}
        </div>
      </div>

      <div className={s.breadcrumbs} ref={refRow}>
        <Breadcrumbs items={breadcrumbs} maxWidth={colWidth} withHomeButton={false} />
      </div>
    </div>
  );
};

const mapStateToProps = (state: AppState): StateProps => ({
  isEmpty: DepartmentsSelectors.isEmpty(state),
  breadcrumbsArray: state.departments.breadcrumbs,
  isMultiselect: state.products.multiselect.isMultiselect,
});
const mapDispatchToProps: DispatchProps = {
  addPositionModalOpen: AddPositionModalActions.openModal,
  addDepartment: DepartmentsActions.createCandidateDepartment,
};
export default connect(mapStateToProps, mapDispatchToProps)(Header);
