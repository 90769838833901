import { useDispatch } from 'react-redux';
import { Menu, Tooltip } from '@mui/material';
import { PriorityEnum } from 'types/priority-enums';
import { usePopupState } from 'material-ui-popup-state/hooks';
import { FC, MouseEventHandler, useRef, useState } from 'react';
import { bindMenu, bindTrigger } from 'material-ui-popup-state/core';
import useDisableDocumentScroll from 'hooks/use-disable-document-scroll';
import { PermissionGuardActions } from 'modules/permission-guard/permission-guard.controller';
import { PriorityTag } from 'components/priority-select/components/priority-tag/priority-tag';
import { PriorityOptionItem } from 'components/priority-select/components/priority-option-item/priority-option-item';
import s from './priority-select.module.scss';

export type OwnProps = {
  isPreview?: boolean;
  tooltipTitle?: string;
  isEditPermitted?: boolean;
  darkBackgroundMode?: boolean;
  priority: PriorityEnum | string;
  enableStopPropagation?: boolean;
  onClose?: VoidFunction;
  onSelect: (status: PriorityEnum) => void;
};

const PriorityArray = Object.values(PriorityEnum);

const PrioritySelector: FC<OwnProps> = ({
  priority,
  isPreview,
  tooltipTitle = '',
  isEditPermitted = true,
  darkBackgroundMode = false,
  enableStopPropagation = true,
  onClose,
  onSelect,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const rootRef = useRef<HTMLDivElement>(null);
  const optionsListWithoutSelected = PriorityArray.filter((option) => option !== priority);
  const dispatch = useDispatch();

  useDisableDocumentScroll(isOpen, 'prioritySelector');

  const popupState = usePopupState({ variant: 'dialog', popupId: `actions-${priority}` });

  const handleOptionClick = (value: PriorityEnum) => {
    setIsOpen(false);
    onSelect?.(value);
    onClose?.();
  };

  const handleTagClick: MouseEventHandler<HTMLDivElement> = (e) => {
    if (!isPreview) {
      if (enableStopPropagation) e.stopPropagation();
      if (!isEditPermitted) {
        dispatch(PermissionGuardActions.openModal());
        return;
      }
      setIsOpen((prev) => !prev);
    }
  };

  return (
    <div
      className={s.selectWrapper}
      ref={rootRef}
      {...bindTrigger(popupState)}
      onClick={(event) => {
        if (enableStopPropagation) event.stopPropagation();
      }}
    >
      <Tooltip classes={{ popper: s.tooltip }} title={tooltipTitle && tooltipTitle} placement="top-end">
        <div onClick={handleTagClick} className={`${isPreview ? s.not_clickable : ''}`}>
          <PriorityTag priority={priority} darkBackgroundMode={darkBackgroundMode} />
        </div>
      </Tooltip>
      <div
        onClick={(event) => {
          if (enableStopPropagation) event.stopPropagation();
          onClose?.();
          setIsOpen(false);
        }}
      >
        <Menu
          {...bindMenu(popupState)}
          open={isOpen}
          className={s.option_list}
          anchorEl={rootRef.current}
          transformOrigin={{
            vertical: 'top',
            horizontal: 70,
          }}
          disableAutoFocusItem
        >
          {optionsListWithoutSelected.map((option) => (
            <PriorityOptionItem key={option} option={option} onClick={handleOptionClick} />
          ))}
        </Menu>
      </div>
    </div>
  );
};

export default PrioritySelector;
