export type HighlitedText = {
  inputValue: string;
  highliteValue: string;
  searchValue: string;
};

export const replaceStr = (inputValue: string, searchValue: string) => {
  // Escape special characters for regex
  const escapedSearchValue = getEscapedSearchValue(searchValue);

  const regex = new RegExp(`(${escapedSearchValue || ''})`, 'gi');
  const selectedStr = searchValue ? `<span>$1</span>` : '';

  return inputValue?.replace(regex, selectedStr);
};

export const getEscapedSearchValue = (value: string) => {
  if (!value) {
    return '';
  }
  // Escape special characters for regex
  return value.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
};

export const searchReplacer = (inputValue: string, searchValue: string, style?: string): HighlitedText => {
  // Escape special characters for regex
  const escapedSearchValue = getEscapedSearchValue(searchValue);

  const regex = new RegExp(`(${escapedSearchValue || ''})`, 'gi');

  const highliteValue = inputValue.replace(regex, (match) => {
    if (!match) return '';
    return `<span class="search-match" style="${style || 'border-radius: 0px; background: #c2d3ff;'}">${match}</span>`;
  });

  return { inputValue, highliteValue, searchValue: escapedSearchValue };
};
