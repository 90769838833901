import { FC, SVGProps } from 'react';

export const OpenSecondaryIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M13.9996 4.39V6.61C13.9996 7.96 13.4596 8.5 12.1096 8.5H10.4896C9.13961 8.5 8.59961 7.96 8.59961 6.61V4.39C8.59961 3.04 9.13961 2.5 10.4896 2.5H12.1096C13.4596 2.5 13.9996 3.04 13.9996 4.39Z"
      stroke="#141414"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.4 10.39V12.61C7.4 13.96 6.86 14.5 5.51 14.5H3.89C2.54 14.5 2 13.96 2 12.61V10.39C2 9.04 2.54 8.5 3.89 8.5H5.51C6.86 8.5 7.4 9.04 7.4 10.39Z"
      stroke="#141414"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.0008 10.3008C14.0008 12.6228 12.1228 14.5008 9.80078 14.5008L10.4308 13.4508"
      stroke="#141414"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M2 6.7C2 4.378 3.878 2.5 6.2 2.5L5.57 3.55" stroke="#141414" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);
