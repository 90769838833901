import { LinearProgress, LinearProgressProps } from '@mui/material';
import { LightTooltip } from 'components/ui-new/light-tooltip/light-tooltip';
import s from './custom-progress.module.scss';

export type Props = LinearProgressProps & {
  type?: 'green' | 'light_blue' | 'blue' | 'red' | 'default' | 'primary_blue';
  tooltip?: any;
};

export default function CustomProgress({ ...rest }: Props) {
  if (rest.tooltip) {
    return (
      <LightTooltip enterDelay={100} title={rest.tooltip} placement="top">
        <LinearProgress className={`${s.progress} ${!rest.value && s.default} ${s[`${rest.type}`]}`} {...rest} />
      </LightTooltip>
    );
  }

  return (
    <LinearProgress
      data-tooltip={!!rest.tooltip}
      className={`${s.progress} ${!rest.value && s.default} ${s[`${rest.type}`]}`}
      {...rest}
    />
  );
}
