import { forwardRef } from 'react';
import { Checkbox as MuiCheckbox, CheckboxProps, styled } from '@mui/material';
import { CheckboxNotSelected } from 'icons/checkbox-not-selected';
import { CheckboxSelected } from 'icons/checkbox-selected';
import { CSSProperties } from '@mui/material/styles/createMixins';
import { CheckboxIndeterminate } from 'icons/checkbox-indeterminate';

type Size = 'small' | 'medium' | 'large';

export type Props = Omit<CheckboxProps, 'size'> & {
  size?: Size;
};

const MuiCHeckboxStyled = styled(MuiCheckbox)({
  '&:hover': {
    'svg *': {
      stroke: '#0851FE',
    },
  },
  '&.MuiCheckbox-indeterminate': {
    '&:hover': {
      'svg rect': {
        stroke: '#0851FE !important',
      },
      'svg path': {
        stroke: 'black !important',
      },
    },
  },
  '&.Mui-checked': {
    'svg rect': {
      stroke: '#0851FE',
      fill: '#0851FE',
    },
    '&:hover': {
      'svg *': {
        fill: 'transparent',
      },
    },
  },
});

const Checkbox = forwardRef<HTMLButtonElement, Props>(({ size = 'small', ...rest }, ref) => {
  const sizes: { [key in Size]: CSSProperties } = {
    small: {
      checkedIcon: <CheckboxSelected height="16px" width="16px" />,
      icon: <CheckboxNotSelected height="16px" width="16px" />,
      indeterminateIcon: <CheckboxIndeterminate height="16px" width="16px" />,
    },
    medium: {
      checkedIcon: <CheckboxSelected />,
      icon: <CheckboxNotSelected />,
      indeterminateIcon: <CheckboxIndeterminate />,
    },
    large: {
      checkedIcon: <CheckboxSelected height="24px" width="24px" />,
      icon: <CheckboxNotSelected height="24px" width="24px" />,
      indeterminateIcon: <CheckboxIndeterminate height="24px" width="24px" />,
    },
  };
  const { checkedIcon, icon, indeterminateIcon } = sizes[size];

  return (
    <MuiCHeckboxStyled
      ref={ref}
      sx={{
        '&:hover': { bgcolor: 'transparent' },
      }}
      disableRipple
      checkedIcon={checkedIcon}
      icon={icon}
      color="default"
      indeterminateIcon={indeterminateIcon}
      {...rest}
    />
  );
});

Checkbox.displayName = 'CustomCheckbox';

export default Checkbox;
