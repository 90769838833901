import { FC, SVGProps } from 'react';

export const ProductTypeIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M11.3135 8.1665V11.1665C11.3135 13.6665 10.3135 14.6665 7.81348 14.6665H4.81348C2.31348 14.6665 1.31348 13.6665 1.31348 11.1665V8.1665C1.31348 5.6665 2.31348 4.6665 4.81348 4.6665H7.81348C10.3135 4.6665 11.3135 5.6665 11.3135 8.1665Z"
      stroke="#141414"
      strokeWidth="1.1"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.6468 5.00016C14.6468 6.9135 13.1868 8.48016 11.3135 8.64683V8.16683C11.3135 5.66683 10.3135 4.66683 7.8135 4.66683H7.3335C7.50016 2.7935 9.06683 1.3335 10.9802 1.3335C12.8402 1.3335 14.3735 2.7135 14.6068 4.5135C14.6335 4.66683 14.6468 4.8335 14.6468 5.00016Z"
      stroke="#141414"
      strokeWidth="1.1"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
